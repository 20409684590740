import Axios from "axios";
import { URLS } from "../../utils";
import { getTokenFromStorage } from "../../utils/fn";

export const InstitutionImport = async (file) => {
    let formData = new FormData();
    formData.append("institutions", file);
    const response = await Axios.post(
        `${URLS.API_URL}v1/institutions/import`,
        formData,
        {
            headers: {
                Authorization: getTokenFromStorage()
            }
        }
    );
    return response.data;
};

export const StudentsCreateMultiple = async (file) => {
    let formData = new FormData();
    formData.append("students", file);
    const response = await Axios.post(
        `${URLS.API_URL}v1/students/import`,
        formData,
        {
            headers: {
                Authorization: getTokenFromStorage()
            }
        }
    );
    return response.data;
};

export const StudentsUpdateMultiple = async (file) => {
    let formData = new FormData();
    formData.append("students", file);
    const response = await Axios.patch(
        `${URLS.API_URL}v1/students/`,
        formData,
        {
            headers: {
                Authorization: getTokenFromStorage()
            }
        }
    );
    return response.data;
};

export const TeachersImport = async (file) => {
    let formData = new FormData();
    formData.append("teachers", file);
    const response = await Axios.post(
        `${URLS.API_URL}v1/teachers/import`,
        formData,
        {
            headers: {
                Authorization: getTokenFromStorage()
            }
        }
    );
    return response.data;
};

export const UpdateStudentPassword = async (studentId, type = "user") => {
    const response = await Axios.patch(
        `${URLS.API_URL}v1/${type !== "user" ? "teachers" : "students"}/${studentId}/password`,
        {},
        {
            headers: {
                Authorization: getTokenFromStorage()
            }
        }
    );
    return response.data;
};
