import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BackButton from "../../components/common/BackButton";
import ForumListItemTemplate, {
    ForumCommentItemTemplate
} from "../../components/common/forum/ListItem";
import DefaultCardLoader from "../../components/common/Loader";
import ListUIComponent from "../../components/UI/List";
import ForumCommentComponent from "../../components/user/forum/Comment";
import { useAuthContext } from "../../context/auth-context";
import { useModalContext } from "../../context/modal-context";
import ReportModal from "../../modules/admin/modal/Report";
import {
    MarkAsClosedForumTopic,
    RemoveForumTopic
} from "../../services/common/reducer/forum";
import {
    RemoveForumComment,
    fetchForumTopicCommentData,
    fetchForumTopicData,
    removeForumComment,
    setForumCommentPageParams,
    unmountForumCommentData,
    updateSingleForumTopic
} from "../../services/common/reducer/forum/single";
import { URLS } from "../../utils";
import { PAGE_LIMIT } from "../../utils/constants";
import { ExtractErrorMessage, KeyGenerator } from "../../utils/fn";
import { useTranslation } from "react-i18next";

const SingleForumComponent = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { userObject, recentForum: RecentForum } = useAuthContext();
    const { toggle } = useModalContext();
    const { id } = useParams();

    const { forum, items, isLoading, isError, pageNumber, createdAtFirstItem } =
        useSelector((state) => state.forumDetail);
    const cacheKey = KeyGenerator({ id });
    const forumComment = items && items[cacheKey] ? items[cacheKey] : {};

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchForumTopicData({ id: id }));
    }, [dispatch, id]);

    useEffect(() => {
        dispatch(
            fetchForumTopicCommentData({
                id: id,
                pageNumber: pageNumber || 1,
                createdAtFirstItem: createdAtFirstItem || null
            })
        );
    }, [dispatch, id, pageNumber, createdAtFirstItem]);

    useEffect(() => {
        return () => {
            dispatch(unmountForumCommentData());
        };
    }, [dispatch]);

    useEffect(() => {
        const currentForumTopic = forum[cacheKey];
        if (
            currentForumTopic &&
            !_.isEmpty(currentForumTopic) &&
            !_.isEmpty(currentForumTopic?.data) &&
            RecentForum
        ) {
            const fn = RecentForum();
            fn.set(currentForumTopic?.data?.id, currentForumTopic.data);
        }
    }, [forum]);

    const handleReport = (record) => {
        toggle(<ReportModal record={record} />);
    };

    const handleForumTopicRemoval = useCallback(
        async (record) => {
            const sure = window.confirm(t("common.removeForumConfirmTopic"));
            if (!sure) return;
            try {
                setLoading(true);
                await RemoveForumTopic(record.id);
                navigate(`${URLS.COURSE_BASE_URL}/${record.courseId}/forum`, {
                    replace: true
                });
                toast.success(`Forum - ${record.title}, Removed successfully!`);
            } catch (error) {
                const { toast: toastMsg } = ExtractErrorMessage(
                    error?.response
                );
                toast.error(toastMsg);
            } finally {
                setLoading(false);
            }
        },
        [id]
    );

    const handleMarkAsClosed = useCallback(
        async (record) => {
            try {
                setLoading(true);
                const response = await MarkAsClosedForumTopic(
                    record.id,
                    record.title
                );
                dispatch(
                    updateSingleForumTopic({
                        meta: { id: id },
                        data: response.data
                    })
                );
                toast.success(`Forum - ${record.title}, Closed successfully!`);
            } catch (error) {
                const { toast: toastMsg } = ExtractErrorMessage(
                    error?.response
                );
                toast.error(toastMsg);
            } finally {
                setLoading(false);
            }
        },
        [id]
    );

    const handleCommentRemove = useCallback(
        async (record) => {
            const sure = window.confirm(t("common.removeForumConfirmMsg"));
            if (!sure) return;
            try {
                setLoading(true);
                const response = await RemoveForumComment(record.id);
                dispatch(
                    removeForumComment({
                        meta: { id: id },
                        id: response?.data?.id
                    })
                );
                toast.success(
                    `Forum Comment - ${record.title}, Remove successfully!`
                );
            } catch (error) {
                const { toast: toastMsg } = ExtractErrorMessage(
                    error?.response
                );
                toast.error(toastMsg);
            } finally {
                setLoading(false);
            }
        },
        [id]
    );

    const handlePagination = () => {
        dispatch(
            setForumCommentPageParams({
                pageNumber: (pageNumber || 1) + 1,
                createdAtFirstItem: forumComment?.data[0]?.createdAt
            })
        );
    };

    return (
        <div className="container mt-4 mb-5">
            <div className="row">
                <div className="d-flex">
                    <BackButton
                        btnText={t("common.backToForum")}
                        redirectionKey="courses"
                        redirectionConfig={{
                            slug: forum[cacheKey]?.data?.courseId || null,
                            path: "forum"
                        }}
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-md-9">
                    <div className="card content-card bg-white forumCardShadow pb-4">
                        <div className="card-body">
                            <ForumListItemTemplate
                                redirection={false}
                                isForumPage={true}
                                data={forum[cacheKey]?.data || {}}
                                userObject={userObject}
                                handleReport={handleReport}
                                handleMarkAsClosed={handleMarkAsClosed}
                                handleRemove={handleForumTopicRemoval}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-9">
                    <InfiniteScroll
                        dataLength={forumComment?.data?.length || 0}
                        next={handlePagination}
                        hasMore={
                            !_.isEmpty(forumComment?.data || 0)
                                ? forumComment.data.length % PAGE_LIMIT === 0
                                : false
                        }
                        scrollableTarget="scrollableDiv"
                    >
                        <ListUIComponent
                            data={forumComment?.data || []}
                            separator={true}
                            render={(record) => (
                                <ForumCommentItemTemplate
                                    data={record}
                                    userObject={userObject}
                                    handleReport={handleReport}
                                    handleRemove={handleCommentRemove}
                                />
                            )}
                        />
                    </InfiniteScroll>
                </div>
                {forum[cacheKey]?.data?.isOpen && (
                    <div className="col-md-9">
                        <ForumCommentComponent />
                    </div>
                )}
            </div>
            <DefaultCardLoader show={isLoading || loading} />
        </div>
    );
};

export default SingleForumComponent;
