import PropTypes from "prop-types";
import classNames from "classnames";
import "./calendar.scss";

import {
    daysOfWeek,
    createDaysForCurrentMonth,
    createDaysForNextMonth,
    createDaysForPreviousMonth,
    isWeekendDay,
    getMonthDropdownOptions
} from "./helpers";

import _ from "lodash";

Calendar.propTypes = {
    className: PropTypes.string,
    yearAndMonth: PropTypes.arrayOf(PropTypes.number).isRequired, // e.g. [2021, 6] for June 2021
    // onYearAndMonthChange: PropTypes.func.isRequired,
    renderDay: PropTypes.func
};

export default function Calendar({
    data = {},
    yearAndMonth = [2021, 6],
    renderDay = () => null
}) {
    const [year, month] = yearAndMonth;

    let currentMonthDays = createDaysForCurrentMonth(year, month);
    let previousMonthDays = createDaysForPreviousMonth(
        year,
        month,
        currentMonthDays
    );
    let nextMonthDays = createDaysForNextMonth(year, month, currentMonthDays);
    let calendarGridDayObjects = [
        ...previousMonthDays,
        ...currentMonthDays,
        ...nextMonthDays
    ];

    return (
        <div className="calendar-root">
            <div className="days-of-week">
                {daysOfWeek.map((day, index) => (
                    <div
                        key={day}
                        className={classNames("day-of-week-header-cell", {
                            "weekend-day": [6, 0].includes(index)
                        })}
                    >
                        {day}
                    </div>
                ))}
            </div>
            <div className="days-grid">
                {calendarGridDayObjects.map((day) => (
                    <div
                        key={day.dateString}
                        className={classNames("day-grid-item-container", {
                            "weekend-day": isWeekendDay(day.dateString),
                            "current-month": day.isCurrentMonth,
                            "current-date": day.isCurrentDate || false
                        })}
                    >
                        <div className="day-content-wrapper">
                            {renderDay(day)}
                            {day.isCurrentMonth &&
                                data &&
                                data[day.dateString] && (
                                    <ul className="action-pills mt-1">
                                        {_.map(
                                            data[day.dateString],
                                            (item, index) => {
                                                return (
                                                    <li
                                                        key={item.id}
                                                        className="month-item"
                                                    >
                                                        <div className="d-flex align-items-center">
                                                            <div className="d-flex align-items-start flex-column">
                                                                <span className="fs-10 text-nowrap">
                                                                    {item.start.toUpperCase()}
                                                                </span>
                                                                <span className="fs-10 mt-0 text-nowrap">
                                                                    {item.diff}
                                                                    hr
                                                                </span>
                                                            </div>
                                                            <a
                                                                href={`${item.href}`}
                                                                target={
                                                                    "_blank"
                                                                }
                                                                className={classNames(
                                                                    "fs-10 text-decoration-none fw-semibold rounded-pill py-2 px-2 flex-grow-1 ms-3",
                                                                    {
                                                                        "bs-1":
                                                                            index %
                                                                                2 ===
                                                                            0,
                                                                        "bs-2":
                                                                            index %
                                                                                2 !==
                                                                            0
                                                                    }
                                                                )}
                                                            >
                                                                {item.title}
                                                            </a>
                                                        </div>
                                                    </li>
                                                );
                                            }
                                        )}
                                    </ul>
                                )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

CalendarDayHeader.propTypes = {
    calendarDayObject: PropTypes.object.isRequired
};
export function CalendarDayHeader({ calendarDayObject }) {
    return (
        <div className="day-grid-item-header">
            <span>{calendarDayObject.dayOfMonth}</span>
        </div>
    );
}
