import _ from "lodash";
import { useEffect } from "react";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import AdminDashboardTitle from "../../../components/admin/DashboardTitle";
import AdminDashboardTabs from "../../../components/admin/Tabs";
import DefaultCardLoader from "../../../components/common/Loader";
import ButtonUIComponent from "../../../components/UI/Button";
import DropdownUIComponent, {
    DropdownItemUIComponent
} from "../../../components/UI/Dropdown";
import TableUIComponent from "../../../components/UI/Table";
import { useModalContext } from "../../../context/modal-context";
import { fetchInstitutionList } from "../../../services/admin/reducer/institution";
import { GetAvatarColor, GetAvatarInitials } from "../../../utils/fn";
import AddEditInstitutionModal from "../modal/CreateEditInstitution";
import InstitutionListFilter from "../../../components/admin/filters/institution";
import ImportCSV from "../../../components/admin/import";
import { InstitutionImport } from "../../../services/admin";
import { useTranslation } from "react-i18next";

const AdminInstitutionComponent = () => {
    const { toggle } = useModalContext();
    const { t } = useTranslation();
    const { items, isLoading, isError, filters } = useSelector(
        (state) => state.institution
    );
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchInstitutionList(filters || {}));
    }, [dispatch, filters]);

    const importCallback = () => {
        dispatch(fetchInstitutionList(filters || {}));
    };

    const handleEdit = (record) => {
        toggle(<AddEditInstitutionModal action={"edit"} record={record} />);
    };

    const handleView = (record) => {
        toggle(<AddEditInstitutionModal action={"view"} record={record} />);
    };

    return (
        <Fragment>
            <AdminDashboardTitle
                title={t(`common.institution`)}
                showResult={items?.count > 0}
                count={items?.count || 0}
                btnText={t("modal.institution.create")}
                onClick={() => toggle(<AddEditInstitutionModal />)}
            >
                <ImportCSV
                    ExportFn={InstitutionImport}
                    callbackFn={importCallback}
                    toastKey={"Institution"}
                />
            </AdminDashboardTitle>
            <AdminDashboardTabs />
            <div className="container mt-4 mb-5">
                <InstitutionListFilter />
                <TableUIComponent
                    columns={[
                        {
                            title: t("tableHead.name"),
                            render: (record) => {
                                const astr = GetAvatarInitials(record.name);
                                const acolor = GetAvatarColor(astr);
                                return (
                                    <div className="d-flex align-items-center text-nowrap">
                                        <div className="d-flex">
                                            <div
                                                className="avatar"
                                                data-color={acolor || ""}
                                            >
                                                {astr}
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column ms-2">
                                            <div className="text-gray-600 fs-14 fw-semibold">
                                                {record.name}
                                            </div>
                                            <span className="text-gray-300 fs-12">
                                                {record.uidCode}
                                            </span>
                                        </div>
                                    </div>
                                );
                            },
                            width: 280
                        },
                        {
                            title: t("tableHead.type"),
                            render: (record) => (
                                <span className="ellipsis text-gray-300 fs-14 fw-medium">
                                    {t(`constants.${record.type}`)}
                                </span>
                            ),
                            width: 280
                        },
                        {
                            title: t("tableHead.principal"),
                            render: (record) => (
                                <span className="text-gray-300 fs-14 fw-medium">
                                    {record.principalName}
                                </span>
                            ),
                            width: 240
                        },
                        {
                            title: "",
                            render: (record) => (
                                <DropdownUIComponent
                                    className="border-0 rounded-2 d-inline-block lh-1"
                                    btnClassName={`def-color btn-sm rounded-2 border-0 px-2 py-1 bg-transparent mw-22`}
                                    isPrimary={false}
                                    btnText={
                                        <img
                                            src={`/assets/icons/three-dot.svg`}
                                            className={"img-fluid"}
                                            alt={"Three Dot Menu"}
                                        />
                                    }
                                    toggleIcon={false}
                                >
                                    <DropdownItemUIComponent>
                                        <ButtonUIComponent
                                            className={`pt-0 px-0 pb-0 btn-sm bg-transparent border-0 btn-danger text-dark no-hover`}
                                            buttonInner={
                                                <div className="d-flex align-items-center">
                                                    <img
                                                        width={14}
                                                        src={`/assets/icons/pencil.svg`}
                                                    />
                                                    <span className="ms-2 fs-12 fw-semibold">
                                                        {t(
                                                            "common.editDetails"
                                                        )}
                                                    </span>
                                                </div>
                                            }
                                            onClick={() => handleEdit(record)}
                                        />
                                    </DropdownItemUIComponent>
                                    {/* <DropdownItemUIComponent>
                                        <ButtonUIComponent
                                            className={`pb-0 px-0 btn-sm bg-transparent border-0 btn-danger text-danger no-hover`}
                                            buttonInner={
                                                <div className="d-flex align-items-center">
                                                    <img
                                                        width={14}
                                                        src={`/assets/icons/red-minus.svg`}
                                                    />
                                                    <span className="ms-2 fs-12 fw-semibold">
                                                        Delete
                                                    </span>
                                                </div>
                                            }
                                        />
                                    </DropdownItemUIComponent> */}
                                </DropdownUIComponent>
                            ),
                            width: 40
                        }
                    ]}
                    dataSource={items?.data || []}
                    showEmpty={_.isEmpty(items?.data || [])}
                    isLoading={isLoading}
                    tableKey={"institution-list"}
                    onRowClick={handleView}
                />
            </div>
            <DefaultCardLoader isFixed={true} show={isLoading} />
        </Fragment>
    );
};

export default AdminInstitutionComponent;
