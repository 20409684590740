import Axios from "axios";
import _ from "lodash";
import { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AdminAuthPage from "../pages/auth/admin";
import { JWT_TOKEN_KEY, URLS } from "../utils";
import { getTokenFromStorage } from "../utils/fn";
import DefaultCardLoader from "../components/common/Loader";

const AuthContext = createContext({
    userObject: {},
    isLoading: false,
    authErrorObject: {},
    sessionId: "",
    getOTP: async (payload) => {},
    loginInitiate: async (payload) => {},
    loginVerify: async (payload) => {},
    resetPassword: async (payload) => {},
    logout: () => {}
});

const ErrorObjectTemplate = {
    error: false,
    message: "",
    statusCode: undefined
};

export const AdminAuthContextProvider = (props) => {
    const [initialLoad, setInitialLoad] = useState(true);
    const [sessionId, setSessionId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState({ ...ErrorObjectTemplate });
    const [authObject, setAuthObject] = useState({});
    const navigate = useNavigate();
    const { pathname } = useLocation();

    useEffect(() => {
        checkLogin();

        return () => {
            setAuthObject({});
            setIsLoading(false);
            setIsError({ ...ErrorObjectTemplate });
        };
    }, []);

    useEffect(() => {
        if (
            ["/", ""].includes(pathname) &&
            !_.isEmpty(authObject) &&
            !initialLoad
        ) {
            navigate(`/courses`);
        }
    }, [pathname, initialLoad, authObject]);

    const checkLogin = async () => {
        const token = getTokenFromStorage();
        if (!token) {
            setInitialLoad(false);
            return false;
        }

        try {
            setInitialLoad(true);
            const response = await Axios.get(`${URLS.API_URL}v1/admins/me`, {
                headers: { Authorization: `${token}` }
            });
            setAuthObject(response?.data?.data || {});
        } catch (error) {
            localStorage.removeItem(JWT_TOKEN_KEY.ADMIN);
        } finally {
            setInitialLoad(false);
        }
    };

    const fakeLogin = () => {
        setIsLoading(false);
        setInitialLoad(false);
        localStorage.setItem(JWT_TOKEN_KEY.ADMIN, "hello-world");
        setAuthObject({ name: "Rahul Goyal", lang: "EN" });
    };

    const loginInitiate = async (payload) => {
        try {
            setIsLoading(true);
            const response = await Axios.post(
                `${URLS.API_URL}v1/admins/login/initiate`,
                {
                    email: payload.email,
                    password: payload.password,
                    recaptchaToken: payload.recaptchaToken
                }
            );
            setSessionId(response.data?.data?.verificationAttemptId);
            setIsError({ ...ErrorObjectTemplate });
        } catch (error) {
            setIsError({
                error: true,
                message: error.response?.data?.error,
                statusCode: error.status
            });
            localStorage.removeItem(JWT_TOKEN_KEY.ADMIN);
            let message =
                error.response?.data?.error || "Some error ocurred, try again";
            if (error?.response?.status === 429) {
                message = "Too many requests. Please try again later";
            }
            toast.error(message);
        } finally {
            setIsLoading(false);
        }
    };

    const loginVerify = async (payload) => {
        try {
            setIsLoading(true);
            const response = await Axios.post(
                `${URLS.API_URL}v1/admins/login/verify`,
                {
                    oneTimePassword: payload.oneTimePassword,
                    verificationAttemptId: sessionId
                }
            );
            localStorage.setItem(
                JWT_TOKEN_KEY.ADMIN,
                response.data.data.authToken
            );
            setAuthObject(response?.data?.data?.admin || {});
            setIsError({ ...ErrorObjectTemplate });
        } catch (error) {
            setIsError({
                error: true,
                message: error.response?.data?.error,
                statusCode: error.status
            });
            localStorage.removeItem(JWT_TOKEN_KEY);
            toast.error(
                error.response?.data?.error || "Some error ocurred, try again"
            );
        } finally {
            setIsLoading(false);
        }
    };

    const getOTP = async (payload) => {
        try {
            setIsLoading(true);
            const response = await Axios.post(
                `${URLS.API_URL}v1/admins/login/forgot-password`,
                {
                    ...payload
                }
            );
            setSessionId(response.data?.data?.verificationAttemptId);
            setIsError({ ...ErrorObjectTemplate });
            return true;
        } catch (error) {
            setIsError({
                error: true,
                message: error.response?.data,
                statusCode: error.status
            });
            let message =
                error.response?.data?.error || "Some error ocurred, try again";
            if (error?.response?.status === 429) {
                message = "Too many requests. Please try again later";
            }
            throw new Error(message);
        } finally {
            setIsLoading(false);
        }
    };

    const resetPassword = async (payload) => {
        try {
            setIsLoading(true);
            const response = await Axios.post(
                `${URLS.API_URL}v1/admins/reset-password`,
                {
                    newPassword: payload.password,
                    oneTimePassword: payload.otp,
                    verificationAttemptId: sessionId
                }
            );
            localStorage.setItem(
                JWT_TOKEN_KEY.ADMIN,
                response.data.data.authToken
            );
            setAuthObject(response?.data?.data?.admin || {});
            setSessionId("");
            setIsError({ ...ErrorObjectTemplate });
        } catch (error) {
            setIsError({
                error: true,
                message: error.response?.data?.error,
                statusCode: error.status
            });
            localStorage.removeItem(JWT_TOKEN_KEY);
            throw new Error(
                error.response?.data?.error || "Some error ocurred, try again"
            );
        } finally {
            setIsLoading(false);
        }
    };

    const logout = () => {
        setAuthObject({});
        setIsLoading(false);
        setIsError(false);
        setSessionId("");
        localStorage.removeItem(JWT_TOKEN_KEY.ADMIN);
    };

    return (
        <AuthContext.Provider
            value={{
                sessionId,
                userObject: authObject,
                isLoading,
                authErrorObject: isError,
                loginInitiate,
                loginVerify,
                resetPassword,
                logout,
                getOTP
            }}
        >
            {initialLoad ? (
                <DefaultCardLoader isFixed show={true} />
            ) : _.isEmpty(authObject) ? (
                <AdminAuthPage />
            ) : (
                props.children
            )}
        </AuthContext.Provider>
    );
};

export const useAdminAuthContext = () => useContext(AuthContext);
