import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./styles/index.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import TagManager from "react-gtm-module";

const tagManagerArgs = {
    gtmId: "GTM-TZ6FCHKD"
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(<App />, document.getElementById("root"));
