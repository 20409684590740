import { Fragment } from "react";
import CustomPageHeadComponent from "../../components/common/reusable/CustomPageHead";
import CalendarComponent from "../../modules/user/calendar";
import { useTranslation } from "react-i18next";

const CalendarPage = () => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <CustomPageHeadComponent title={t("common.calendar")} />
            <div className="container mt-4">
                <CalendarComponent />
            </div>
        </Fragment>
    );
};

export default CalendarPage;
