import Axios from "axios";
import _ from "lodash";
import { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthPage from "../pages/auth";
import { JWT_TOKEN_KEY, RECENT_FORUM_KEY, URLS } from "../utils";
import { ExtractErrorMessage, getTokenFromStorage } from "../utils/fn";
import { RetrieveRecentActivity, SaveRecentActivity } from "../services/user";
import DefaultCardLoader from "../components/common/Loader";
import UpdateEmailModal from "../components/user/modal/UpdateEmail";
// import RecentlyViewedForum from "../components/user/viewed";

const AuthContext = createContext({
    userObject: {},
    isLoading: false,
    authErrorObject: {},
    latestNotifications: {},
    recentForum: () => {},
    getOTP: async (payload) => {},
    login: async (payload) => {},
    resetPassword: async (payload) => {},
    fetchLatestNotifications: async () => {},
    logout: () => {},
    updateProfile: async () => {}
});

const ErrorObjectTemplate = {
    error: false,
    message: "",
    statusCode: undefined
};

export const AuthContextProvider = (props) => {
    const [initialLoad, setInitialLoad] = useState(true);
    const [sessionId, setSessionId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState({ ...ErrorObjectTemplate });
    const [authObject, setAuthObject] = useState({});
    const [recentForum, setRecentForum] = useState(new Map());
    const [latestNotifications, setLatestNotifications] = useState({
        announcement: null,
        notification: null
    });
    const navigate = useNavigate();
    const { pathname } = useLocation();

    useEffect(() => {
        checkLogin();
        RetrieveRecentActivity((itemMap) => {
            setRecentForum(itemMap);
        });

        return () => {
            setAuthObject({});
            setIsLoading(false);
            setIsError({ ...ErrorObjectTemplate });
        };
    }, []);

    useEffect(() => {
        if (
            ["/user/login"].includes(pathname) &&
            !_.isEmpty(authObject) &&
            !initialLoad
        ) {
            navigate(`/user/dashboard`);
        }
    }, [pathname, initialLoad, authObject]);

    const checkLogin = async () => {
        const token = getTokenFromStorage();
        if (!token) {
            setInitialLoad(false);
            return false;
        }

        try {
            setInitialLoad(true);
            const response = await Axios.get(`${URLS.API_URL}v1/me/`, {
                headers: { Authorization: `${token}` }
            });
            // fetch notifications
            fetchLatestNotifications();

            let objKey =
                response?.data?.data?.type === "STUDENT"
                    ? "student"
                    : "teacher";
            let dataObj = response?.data?.data[objKey];
            Object.assign(dataObj, { type: response?.data?.data?.type });
            setAuthObject(dataObj || {});
        } catch (error) {
            localStorage.removeItem(JWT_TOKEN_KEY.USER);
        } finally {
            setInitialLoad(false);
        }
    };

    const login = async (payload) => {
        try {
            setIsLoading(true);
            const response = await Axios.post(`${URLS.API_URL}v1/login`, {
                ...payload
            });

            // fetch notifications
            fetchLatestNotifications();

            localStorage.setItem(
                JWT_TOKEN_KEY.USER,
                response.data.data.authToken
            );
            let dataObj = response?.data?.data?.user;
            Object.assign(dataObj, { type: response?.data?.data?.type });
            setAuthObject(dataObj || {});
            setIsError({ ...ErrorObjectTemplate });
        } catch (error) {
            setIsError({
                error: true,
                message: error.response?.data,
                statusCode: error.status
            });
            localStorage.removeItem(JWT_TOKEN_KEY.USER);
            let { toast } = ExtractErrorMessage(error?.response);
            if (error?.response?.status === 429) {
                toast = "Too many requests. Please try again later";
            }
            throw new Error(toast);
        } finally {
            setIsLoading(false);
        }
    };

    const getOTP = async (payload) => {
        try {
            setIsLoading(true);
            let response = await Axios.post(
                `${URLS.API_URL}v1/login/forgot-password`,
                {
                    ...payload
                }
            );
            setSessionId(response?.data?.data?.verificationAttemptId);
            setIsError({ ...ErrorObjectTemplate });
            return true;
        } catch (error) {
            setIsError({
                error: true,
                message: error.response?.data,
                statusCode: error.status
            });
            let { toast } = ExtractErrorMessage(error?.response);
            if (error?.response?.status === 429) {
                toast = "Too many requests. Please try again later";
            }
            throw new Error(error?.response?.data?.error);
        } finally {
            setIsLoading(false);
        }
    };

    const resetPassword = async (payload) => {
        try {
            setIsLoading(true);
            await Axios.post(`${URLS.API_URL}v1/reset-password`, {
                oneTimePassword: payload.otp,
                newPassword: payload.password,
                verificationAttemptId: sessionId
            });
            setIsError({ ...ErrorObjectTemplate });
            setSessionId("");
            return true;
        } catch (error) {
            setIsError({
                error: true,
                message: error.response?.data,
                statusCode: error.status
            });
            throw new Error(
                error.response?.data?.error || "Some error ocurred, try again"
            );
        } finally {
            setIsLoading(false);
        }
    };

    const logout = () => {
        setAuthObject({});
        setIsLoading(false);
        setIsError(false);
        setRecentForum(new Map());
        localStorage.removeItem(RECENT_FORUM_KEY);
        localStorage.removeItem(JWT_TOKEN_KEY.USER);
    };

    const updateAvatar = (data) => {
        setAuthObject({
            ...authObject,
            profileImageUri: data?.profileImageUri || null
        });
    };

    const recentForumFn = () => {
        let currentMap = _.cloneDeep(recentForum);
        const capacity = 5;
        return {
            set: (key, value) => {
                if (currentMap.get(key)) {
                    currentMap.delete(key);
                } else {
                    if (currentMap.size >= capacity) {
                        let lastKey = currentMap.keys().next().value;
                        currentMap.delete(lastKey);
                    }
                }
                currentMap.set(key, value);
                setRecentForum(currentMap);
                SaveRecentActivity(currentMap);
            },
            getAll: () => {
                return Array.from(currentMap, ([name, value]) => value);
            }
        };
    };

    const fetchLatestNotifications = async () => {
        try {
            const announcements = await Axios.get(
                `${URLS.API_URL}v1/announcements?isGetLatestOne=true`,
                {
                    headers: {
                        Authorization: getTokenFromStorage()
                    }
                }
            );
            const notifications = await Axios.get(
                `${URLS.API_URL}v1/notifications?isGetLatestOne=true`,
                {
                    headers: {
                        Authorization: getTokenFromStorage()
                    }
                }
            );
            setLatestNotifications({
                announcement:
                    announcements?.data?.data &&
                    announcements?.data?.data[0] &&
                    announcements?.data?.data[0].createdAt,
                notification:
                    notifications?.data?.data &&
                    notifications?.data?.data[0] &&
                    notifications?.data?.data[0].createdAt
            });
        } catch (error) {}
    };

    const updateProfile = data => {
        if (!_.isEmpty(data)) {
            setAuthObject(data);
        };
    }

    return (
        <AuthContext.Provider
            value={{
                userObject: authObject,
                isLoading,
                authErrorObject: isError,
                latestNotifications: latestNotifications,
                recentForum: recentForumFn,
                login,
                resetPassword,
                logout,
                getOTP,
                updateAvatar,
                updateProfile
            }}
        >
            {initialLoad ? (
                <DefaultCardLoader isFixed show={true} />
            ) : _.isEmpty(authObject) ? (
                <AuthPage />
            ) : (
                props.children
            )}
            {!_.isEmpty(authObject) && <UpdateEmailModal record={authObject} />}
        </AuthContext.Provider>
    );
};

export const useAuthContext = () => useContext(AuthContext);
