// src/courseBundleListSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { URLS } from "../../../../utils";
import Axios from "axios";
import { getTokenFromStorage } from "../../../../utils/fn";
import { SortingCriteria } from "../../../../utils/constants";

// Define async thunk
export const fetchCourseBundleList = createAsyncThunk(
    "courseBundleList/fetchData",
    async (params, { getState }) => {
        try {
            if (!params?.sortingCriteria) {
                params.sortingCriteria = SortingCriteria.ALPHABETICAL;
            }

            let qp = new URLSearchParams(params).toString();
            qp = qp ? `?${qp}` : "";

            const response = await Axios.get(
                `${URLS.API_URL}v1/course-bundles${qp}`,
                {
                    headers: {
                        Authorization: getTokenFromStorage()
                    }
                }
            );
            return response.data;
        } catch (error) {
            throw new Error(error.response.data.error);
        }
    }
);

export const fetchCourseBundleById = createAsyncThunk(
    "courseBundleById/fetchData",
    async (id, { getState }) => {
        try {
            const currentState = getState().coursesBundle;
            if (id in currentState.bundle) {
                return { data: currentState.bundle[id] };
            }
            const response = await Axios.get(
                `${URLS.API_URL}v1/course-bundles/${id}`,
                {
                    headers: {
                        Authorization: getTokenFromStorage()
                    }
                }
            );
            return response.data;
        } catch (error) {
            throw new Error(error.response.data.error);
        }
    }
);

const formatCourseBundleData = (data) => {
    let fd = new FormData();
    let fields = [
        "uidCode",
        "title",
        "languageCode",
        "category",
        "listCourseIds",
        "listCourseBundleIds"
    ];

    if (data.thumbnailImageUri) {
        fd.append(
            `thumbnail`,
            _.isObject(data.thumbnailImageUri) &&
                data.thumbnailImageUri instanceof File
                ? data.thumbnailImageUri[0]
                : data.thumbnailImageUri
        );
    }

    let payload = _.omitBy(_.pick(data, fields), _.isNil);

    payload.listCourseIds = _.map(
        payload?.listCourseIds || [],
        (item) => item.value
    );
    payload.listCourseBundleIds = _.map(
        payload?.listCourseBundleIds || [],
        (item) => item.value
    );
    fd.append(`data`, JSON.stringify(payload));
    return fd;
};

// action => 0: POST, 1: PUT
export const CourseBundleOperation = async (payload, action = 0) => {
    let config = {
        url: `${URLS.API_URL}v1/course-bundles`,
        method: "POST"
    };
    if (action !== 0) {
        config.url = `${config.url}/${payload.id}`;
        config.method = "PATCH";
    }

    const response = await Axios({
        ...config,
        data: formatCourseBundleData(payload),
        headers: {
            Authorization: getTokenFromStorage()
        }
    });
    return response.data;
};

// Create slice
export const courseBundleListSlice = createSlice({
    name: "coursesBundle",
    initialState: {
        bundle: {},
        items: {
            count: null,
            data: []
        },
        filters: {},
        isLoading: false,
        isError: false,
        status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null
    },
    reducers: {
        setCourseBundleFilter: (state, action) => {
            state.items = {
                count: null,
                data: []
            };
            state.filters = action.payload || {};
        },
        updateCourseBundleList: (state, action) => {
            try {
                let { payload } = action;
                // // console.log("Updating the course list item state: ", {...payload.data});
                if (payload.action === "create") {
                    let existingData = _.cloneDeep(state.items?.data) || [];
                    state.items.data = [{ ...payload.data }, ...existingData];
                    state.items.count = state.items.count || 1;
                } else {
                    let existingData = _.cloneDeep(state.items.data);
                    let index = _.findIndex(
                        existingData,
                        (e) => e.id === payload.data.id
                    );
                    state.items.data[index] = payload.data;
                }
            } catch (er) {
                // console.log(er);
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCourseBundleList.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
                state.isError = false;
            })
            .addCase(fetchCourseBundleList.fulfilled, (state, action) => {
                state.status = "succeeded";
                // Add fetched data to the state array
                state.items = action.payload;
                state.isLoading = false;
                state.isError = false;
            })
            .addCase(fetchCourseBundleList.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(fetchCourseBundleById.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
                state.isError = false;
            })
            .addCase(fetchCourseBundleById.fulfilled, (state, action) => {
                state.status = "succeeded";
                // Add fetched data to the state array
                let id = action.meta.arg;
                Object.assign(state.bundle, { [id]: action.payload?.data });
                state.isLoading = false;
                state.isError = false;
            })
            .addCase(fetchCourseBundleById.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.isLoading = false;
                state.isError = true;
            });
    }
});

export const { updateCourseBundleList, setCourseBundleFilter } =
    courseBundleListSlice.actions;

export default courseBundleListSlice.reducer;
