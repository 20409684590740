import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import HeaderComponent from "../../components/common/header";
import { useAdminAuthContext } from "../../context/admin-context";

const AdminDashboardPage = () => {
    const { userObject, logout } = useAdminAuthContext();
    return (
        <Fragment>
            <HeaderComponent userObject={userObject} logout={logout} />
            <Outlet />
        </Fragment>
    );
};

export default AdminDashboardPage;
