import _ from "lodash";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useQueryParams from "../../../hooks/useSearchQuery";
import SearchBarComponent from "../../UI/Search";
import { setReportedFilters } from "../../../services/admin/reducer/activity";
import { useTranslation } from "react-i18next";

const ForumActivityFilter = ({ placeholder = "" }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const query = useQueryParams();
    const navigate = useNavigate();

    const handleSearch = (search) => {
        let payload = { ...query, search };
        if (!payload.search || _.isEmpty(payload.search)) {
            delete payload.search;
        }
        navigate({
            search: queryString.stringify(payload)
        });
        dispatch(setReportedFilters(payload));
    };

    const handleResolvedFilter = (e) => {
        let value = e.target.value;
        let payload = { ...query, isResolved: value };
        if (
            payload.isResolved === null ||
            payload.isResolved === undefined ||
            payload.isResolved === ""
        ) {
            delete payload.isResolved;
        }
        navigate({
            search: queryString.stringify(payload)
        });
        dispatch(setReportedFilters(payload));
    };

    return (
        <div className="row my-3">
            <div className="col-lg-4 col-6">
                <SearchBarComponent
                    placeholder={placeholder || t(`formFields.search`)}
                    onChange={handleSearch}
                />
            </div>
            <div className="col-lg-3 col-6 ms-auto">
                <div
                    className="d-flex align-items-center justify-content-end"
                    style={{ gap: 20 }}
                >
                    <select
                        className="form-select normal fs-14 br-60"
                        onChange={handleResolvedFilter}
                        value={query.isResolved}
                    >
                        <option value={""}>{t("formFields.allItems")}</option>
                        <option value={false}>{t("common.unresolved")}</option>
                        <option value={true}>{t("common.resolved")}</option>
                    </select>
                </div>
            </div>
        </div>
    );
};

export default ForumActivityFilter;
