import { useEffect, useState } from "react";
import { useAuthContext } from "../../../context/auth-context";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import _ from "lodash";
import { NavLink } from "react-router-dom";
import { URLS } from "../../../utils";

const RecentForumActivity = ({ size = "" }) => {
    const { t } = useTranslation();
    const { recentForum: RecentForum } = useAuthContext();
    const [recentArr, setRecentArr] = useState([]);

    useEffect(() => {
        if (RecentForum) {
            const recentMap = RecentForum().getAll();
            setRecentArr(recentMap);
        }

        return () => {
            setRecentArr([]);
        };
    }, []);

    if (recentArr && recentArr.length <= 0) return <></>;

    return (
        <div className="card bg-white content-card">
            <div className="card-heading d-flex justify-content-between align-items-center">
                <div
                    className={classNames("fw-semibold text-gray-600", {
                        "fs-20": size === "md",
                        "fs-16": !size || size === "sm"
                    })}
                >
                    {t("common.recentItems")}
                </div>
            </div>
            <div className="card-body p-0">
                <ul className="p-0 m-0 list-unstyled recent-list">
                    {_.map(recentArr, (item) => (
                        <li
                            key={item.id}
                            className="fs-14 fw-semibold text-gray-400"
                        >
                            <NavLink
                                to={`${URLS.DASHBOARD_URL}forum/${item.id}`}
                                className={"text-gray-400 text-decoration-none"}
                            >
                                {item.title}
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default RecentForumActivity;
