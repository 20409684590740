import _ from "lodash";
import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import DefaultCardLoader from "../../../components/common/Loader";
import ButtonUIComponent from "../../../components/UI/Button";
import MultiSelectUIComponent from "../../../components/UI/Input/multi-select";
import { useModalContext } from "../../../context/modal-context";
import useGetCourseList from "../../../hooks/useGetCourseList";
import useGetInstitutionList from "../../../hooks/useGetInstitutionList";
import useQueryParams from "../../../hooks/useSearchQuery";
import {
    SingleTeacherOperation,
    updateTeacherList
} from "../../../services/admin/reducer/teachers";
import { Genders, LanguageCode } from "../../../utils/constants";
import { ExtractErrorMessage, formValidations } from "../../../utils/fn";
import useGetCourseBundleList from "../../../hooks/useGetCourseBundleList";

const AddEditTeacherModal = ({ action = "create", record = {} }) => {
    const isEdit = action !== "create";
    const viewOnly = action === "view";
    const queryParam = useQueryParams();
    const { toggle } = useModalContext();
    const { t } = useTranslation();
    const fields = [
        "uidCode",
        "editTeacher",
        "createTeacher",
        "name",
        "email",
        "phone",
        "dob",
        "qualifications",
        "lang",
        "yoe",
        "courses",
        "gender",
        "institution",
        "view",
        "courseBundle",
        "enterName",
        "enterEmail",
        "enterPhone",
        "selectGender",
        "selectInstitution",
        "selectCourses",
        "selectCourseBundle",
        "selectLang",
        "enterYOE",
        "enterQualification"
    ];
    const titles = _.map(fields, (field) => {
        return { value: t(`modal.teacher.${field}`), key: field };
    }).reduce((p, c, i) => {
        p[c.key] = c.value;
        return p;
    }, {});

    const {
        list: courseList,
        isLoading: isLoadingCourseList,
        isError: isErrorCourseList
    } = useGetCourseList();
    const {
        list: courseBundleList,
        isLoading: isLoadingCourseBundleList,
        isError: isErrorCourseBundleList
    } = useGetCourseBundleList();
    const { list: institutionList } = useGetInstitutionList();

    const [payload, setPayload] = useState({
        uidCode: undefined,
        name: undefined,
        email: undefined,
        phoneNumber: undefined,
        dateOfBirth: undefined,
        highestQualification: undefined,
        experienceYears: undefined,
        gender: undefined,
        listCourseIds: [],
        listCourseBundleIds: [],
        languageCode: undefined,
        institutionId: undefined
    });
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (record && !_.isEmpty(record)) {
            let courseOptions = [];
            let courseBundleOptions = [];
            if (courseList && !_.isEmpty(courseList)) {
                let recordCourses = _.map(
                    record?.listAssociatedCourses || [],
                    (e) => e.courseId
                );
                courseOptions = _.filter(courseList || [], (opts) =>
                    [...recordCourses].includes(opts.id)
                ).map((item) => ({
                    label: `${item.title} [#${item.uidCode}]`,
                    value: item.id
                }));
            }
            if (courseBundleList && !_.isEmpty(courseBundleList)) {
                let recordCourses = _.map(
                    record?.listAssociatedCourseBundles || [],
                    (e) => e.courseBundleId || e
                );
                courseBundleOptions = _.filter(courseBundleList || [], (opts) =>
                    [...recordCourses].includes(opts.id)
                ).map((item) => ({
                    label: `${item.title} [#${item.uidCode}]`,
                    value: item.id
                }));
            }
            setPayload({
                ...record,
                phoneNumber: record?.phoneNumber?.replace("+91", ""),
                listCourseIds: [...courseOptions],
                listCourseBundleIds: courseBundleOptions
            });
        }
    }, [record, courseList, courseBundleList]);

    const handleOnChange = (inputs) => {
        setPayload({
            ...payload,
            [inputs.name]: inputs.value
        });
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            if (!payload.listCourseIds || _.isEmpty(payload.listCourseIds)) {
                throw new Error("Select atleast one course");
            }
            if (payload.phoneNumber) {
                formValidations("phoneNumber", payload.phoneNumber);
            }

            setLoading(true);
            const response = await SingleTeacherOperation(
                payload,
                isEdit ? 1 : 0
            );
            // dispatch update action once this operation is finised!
            dispatch(
                updateTeacherList({
                    meta: queryParam,
                    action,
                    data: {
                        ...response.data?.teacher,
                        listAssociatedCourses:
                            response?.data?.listAssociatedCourses,
                        listAssociatedCourseBundles:
                            response?.data?.listCourseBundleIds ||
                            response?.data?.listAssociatedCourseBundles ||
                            []
                    }
                })
            );
            toast.success(
                `Teacher ${isEdit ? "updated" : "added"} successfully!`
            );
            toggle();
        } catch (error) {
            const { toast: toastMsg } = ExtractErrorMessage(
                error.response || error
            );
            toast.error(toastMsg);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Form onSubmit={submitHandler} autoComplete={"off"}>
            <Modal.Header style={{ padding: "24px 16px" }}>
                <Modal.Title className="fs-20">
                    {viewOnly
                        ? t(titles.view)
                        : !isEdit
                          ? t(titles.createTeacher)
                          : t(titles.editTeacher)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                <div className="d-flex flex-column">
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                        <div className="col-md-3">
                            <label
                                htmlFor="name"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.uidCode}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <input
                                autoComplete={"off"}
                                required
                                className="form-control fs-14"
                                placeholder={t("common.enterUidCode")}
                                value={payload.uidCode || ""}
                                onChange={(e) =>
                                    handleOnChange({
                                        name: e.target.name,
                                        value: e.target.value
                                    })
                                }
                                disabled={viewOnly}
                                name={"uidCode"}
                            />
                        </div>
                    </div>
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                        <div className="col-md-3">
                            <label
                                htmlFor="name"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.name}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <input
                                autoComplete={"off"}
                                required
                                className="form-control fs-14"
                                placeholder={titles.enterName}
                                value={payload.name || ""}
                                onChange={(e) =>
                                    handleOnChange({
                                        name: e.target.name,
                                        value: e.target.value
                                    })
                                }
                                name={"name"}
                                disabled={viewOnly}
                            />
                        </div>
                    </div>
                    {(!viewOnly || (viewOnly && payload.email)) && (
                        <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                            <div className="col-md-3">
                                <label
                                    htmlFor="email"
                                    className="fs-14 text-gray-300 fw-semibold"
                                >
                                    {titles.email}
                                </label>
                            </div>
                            <div className="col-md-9 mt-2 mt-md-0">
                                <input
                                    autoComplete={"off"}
                                    required={false}
                                    className="form-control fs-14"
                                    placeholder={titles.enterEmail}
                                    value={payload.email || ""}
                                    onChange={(e) =>
                                        handleOnChange({
                                            name: e.target.name,
                                            value: e.target.value
                                        })
                                    }
                                    type={"email"}
                                    name={"email"}
                                    disabled={viewOnly}
                                />
                            </div>
                        </div>
                    )}
                    {(!viewOnly || (viewOnly && payload.phoneNumber)) && (
                        <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                            <div className="col-md-3">
                                <label
                                    htmlFor="phone"
                                    className="fs-14 text-gray-300 fw-semibold"
                                >
                                    {titles.phone}
                                </label>
                            </div>
                            <div className="col-md-9 mt-2 mt-md-0">
                                <div className="input-group">
                                    <span className="input-group-text fs-14 text-gray-600 fw-semibold phoneExtension">
                                        +91
                                    </span>
                                    <input
                                        autoComplete={"off"}
                                        required={false}
                                        maxLength={10}
                                        className="form-control fs-14"
                                        placeholder={titles.enterPhone}
                                        value={payload.phoneNumber || ""}
                                        onChange={(e) =>
                                            handleOnChange({
                                                name: e.target.name,
                                                value: e.target.value
                                            })
                                        }
                                        type={"text"}
                                        name={"phoneNumber"}
                                        disabled={viewOnly}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {(!viewOnly || (viewOnly && payload.dateOfBirth)) && (
                        <div className="form-row split d-flex flex-column flex-md-row align-items-md-center border-bottom">
                            <div
                                className="d-flex flex-column flex-md-row align-items-md-center border-end"
                                style={{ flexBasis: "50%" }}
                            >
                                <div className="col-md-4">
                                    <label
                                        htmlFor="dob"
                                        className="fs-14 text-gray-300 fw-semibold"
                                    >
                                        {titles.dob}
                                    </label>
                                </div>
                                <div className="col-md-8 col-lg-6 offset-lg-2 mt-2 mt-md-0 ps-lg-2">
                                    <input
                                        autoComplete={"off"}
                                        type={"date"}
                                        className="form-control fs-14"
                                        placeholder={`Select Date`}
                                        name={"dateOfBirth"}
                                        required={false}
                                        value={payload.dateOfBirth || ""}
                                        onChange={(e) =>
                                            handleOnChange({
                                                name: e.target.name,
                                                value: e.target.value
                                            })
                                        }
                                        disabled={viewOnly}
                                    />
                                </div>
                            </div>
                            <div
                                className="d-flex flex-column flex-md-row align-items-md-center ms-md-auto"
                                style={{ flexBasis: "50%" }}
                            >
                                <div className="col-md-4">
                                    <label
                                        htmlFor="gender"
                                        className="fs-14 text-gray-300 fw-semibold"
                                    >
                                        {titles.gender}
                                    </label>
                                </div>
                                <div className="col-md-8 mt-2 mt-md-0">
                                    <select
                                        required={false}
                                        className="form-select fs-14"
                                        placeholder={titles.selectGender}
                                        name={"gender"}
                                        value={payload.gender || ""}
                                        onChange={(e) =>
                                            handleOnChange({
                                                name: e.target.name,
                                                value: e.target.value
                                            })
                                        }
                                        disabled={viewOnly}
                                    >
                                        <option value="">
                                            {titles.selectGender}
                                        </option>
                                        {Genders &&
                                            _.map(Genders, (item, key) => {
                                                return (
                                                    <option
                                                        value={key}
                                                        key={key}
                                                    >
                                                        {t(`constants.${item}`)}
                                                    </option>
                                                );
                                            })}
                                    </select>
                                </div>
                            </div>
                        </div>
                    )}
                    {(!viewOnly || (viewOnly && payload.qualifications)) && (
                        <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                            <div className="col-md-3">
                                <label
                                    htmlFor="qualifications"
                                    className="fs-14 text-gray-300 fw-semibold"
                                >
                                    {titles.qualifications}
                                </label>
                            </div>
                            <div className="col-md-9 mt-2 mt-md-0">
                                <input
                                    autoComplete={"off"}
                                    className="form-control fs-14"
                                    placeholder={titles.enterQualification}
                                    name={"highestQualification"}
                                    required={false}
                                    value={payload.highestQualification || ""}
                                    onChange={(e) =>
                                        handleOnChange({
                                            name: e.target.name,
                                            value: e.target.value
                                        })
                                    }
                                    disabled={viewOnly}
                                />
                            </div>
                        </div>
                    )}
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                        <div className="col-md-3">
                            <label
                                htmlFor="institution"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.institution}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <select
                                required
                                className="form-select fs-14"
                                placeholder={titles.selectInstitution}
                                name={"institutionId"}
                                value={payload.institutionId || ""}
                                onChange={(e) =>
                                    handleOnChange({
                                        name: e.target.name,
                                        value: e.target.value
                                    })
                                }
                                disabled={viewOnly}
                            >
                                <option value="">
                                    {titles.selectInstitution}
                                </option>
                                {institutionList &&
                                    _.map(institutionList, (item, key) => {
                                        return (
                                            <option value={item.id} key={key}>
                                                {item.name} [{item.type}]
                                            </option>
                                        );
                                    })}
                            </select>
                        </div>
                    </div>
                    {(!viewOnly || (viewOnly && payload.experienceYears)) && (
                        <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                            <div className="col-md-3">
                                <label
                                    htmlFor="yoe"
                                    className="fs-14 text-gray-300 fw-semibold"
                                >
                                    {titles.yoe}
                                </label>
                            </div>
                            <div className="col-md-9 mt-2 mt-md-0">
                                <input
                                    autoComplete={"off"}
                                    type={"number"}
                                    className="form-control fs-14"
                                    placeholder={titles.enterYOE}
                                    name={"experienceYears"}
                                    required={false}
                                    value={payload.experienceYears || ""}
                                    onChange={(e) =>
                                        handleOnChange({
                                            name: e.target.name,
                                            value: e.target.value
                                        })
                                    }
                                    disabled={viewOnly}
                                />
                            </div>
                        </div>
                    )}
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                        <div className="col-md-3">
                            <label
                                htmlFor="courses"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.courses}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <MultiSelectUIComponent
                                name={"listCourseIds"}
                                placeholder={titles.selectCourses}
                                value={payload.listCourseIds || []}
                                isLoading={
                                    isLoadingCourseList || isErrorCourseList
                                }
                                options={[
                                    ..._.map(courseList || [], (item) => {
                                        return {
                                            label: `${item.title} [${item.uidCode}]`,
                                            value: item.id
                                        };
                                    })
                                ]}
                                onChange={(e) => {
                                    handleOnChange({
                                        name: "listCourseIds",
                                        value: e
                                    });
                                }}
                                disabled={viewOnly}
                            />
                        </div>
                    </div>
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                        <div className="col-md-3">
                            <label
                                htmlFor="courseBundle"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.courseBundle}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <MultiSelectUIComponent
                                name={"listCourseIds"}
                                placeholder={titles.selectCourseBundle}
                                value={payload.listCourseBundleIds || []}
                                isLoading={
                                    isLoadingCourseBundleList ||
                                    isErrorCourseBundleList
                                }
                                options={[
                                    ..._.map(courseBundleList || [], (item) => {
                                        return {
                                            label: `${item.title} [${item.uidCode}]`,
                                            value: item.id
                                        };
                                    })
                                ]}
                                onChange={(e) => {
                                    handleOnChange({
                                        name: "listCourseBundleIds",
                                        value: e
                                    });
                                }}
                                disabled={viewOnly}
                            />
                        </div>
                    </div>
                    {(!viewOnly || (viewOnly && payload.languageCode)) && (
                        <div className="form-row d-flex flex-column flex-md-row align-items-md-center">
                            <div className="col-md-3">
                                <label
                                    htmlFor="lang"
                                    className="fs-14 text-gray-300 fw-semibold"
                                >
                                    {titles.lang}
                                </label>
                            </div>
                            <div className="col-md-9 mt-2 mt-md-0">
                                <select
                                    required={false}
                                    className="form-select fs-14"
                                    placeholder={titles.selectLang}
                                    name={"languageCode"}
                                    value={payload.languageCode || ""}
                                    onChange={(e) =>
                                        handleOnChange({
                                            name: e.target.name,
                                            value: e.target.value
                                        })
                                    }
                                    disabled={viewOnly}
                                >
                                    <option value="">
                                        {titles.selectLang}
                                    </option>
                                    {LanguageCode &&
                                        _.map(LanguageCode, (item, key) => {
                                            return (
                                                <option
                                                    value={item.value}
                                                    key={key}
                                                >
                                                    {t(
                                                        `constants.${item.value}`
                                                    )}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex">
                    <ButtonUIComponent
                        className={`fs-14 fw-semibold`}
                        buttonInner={t("common.cancel")}
                        type={"button"}
                        onClick={() => toggle()}
                    />
                    {!viewOnly && (
                        <ButtonUIComponent
                            isPrimary={true}
                            className={`fs-14 ms-3 fw-semibold`}
                            buttonInner={
                                !isEdit
                                    ? t(titles.createTeacher)
                                    : t(titles.editTeacher)
                            }
                            type={"submit"}
                        />
                    )}
                </div>
            </Modal.Footer>
            <DefaultCardLoader show={loading} />
        </Form>
    );
};

export default AddEditTeacherModal;
