import _ from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { useEffect, useState } from "react";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import AdminDashboardTitle from "../../../components/admin/DashboardTitle";
import EventListFilter from "../../../components/admin/filters/live";
import AdminDashboardTabs from "../../../components/admin/Tabs";
import DefaultCardLoader from "../../../components/common/Loader";
import ButtonUIComponent from "../../../components/UI/Button";
import DropdownUIComponent, {
    DropdownItemUIComponent
} from "../../../components/UI/Dropdown";
import TableUIComponent from "../../../components/UI/Table";
import { useModalContext } from "../../../context/modal-context";
import {
    RemoveLiveClass,
    fetchLiveClassList,
    removeLiveClass,
    setLiveFilters,
    setLivePage
} from "../../../services/admin/reducer/liveClass";
import {
    checkNextPage,
    ExtractErrorMessage,
    timeFormatter
} from "../../../utils/fn";
import CreateEditLiveClassModal from "../modal/CreateEditLiveClass";
import { PAGE_LIMIT } from "../../../utils/constants";
import useGetCourseList from "../../../hooks/useGetCourseList";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const AdminLiveClassBaseComponent = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { toggle } = useModalContext();
    const {
        items,
        isLoading,
        isError,
        currentPage,
        createdAtFirstItem,
        filters
    } = useSelector((state) => state.liveClass);
    const dispatch = useDispatch();
    const { list: CourseList } = useGetCourseList();
    const hasNextPage = checkNextPage(items?.data || [], currentPage);

    useEffect(() => {
        dispatch(
            fetchLiveClassList({
                pageNumber: currentPage || 1,
                createdAtFirstItem: createdAtFirstItem,
                ...filters
            })
        );
    }, [dispatch, currentPage, createdAtFirstItem, filters]);

    useEffect(() => {
        return () => {
            dispatch(setLiveFilters({}));
        };
    }, []);

    const handleEdit = (record) => {
        toggle(<CreateEditLiveClassModal action={"edit"} record={record} />);
    };

    const handleView = (record) => {
        toggle(<CreateEditLiveClassModal action={"view"} record={record} />);
    };

    const handleDelete = async (record) => {
        const shouldDelete = window.confirm(
            "Do you really want to delete this live class?"
        );
        if (shouldDelete) {
            try {
                setLoading(true);
                await RemoveLiveClass(record.id);
                dispatch(
                    removeLiveClass({
                        id: record.id
                    })
                );
                toast.success(
                    `Live Class - ${record.title}, Removed successfully`
                );
            } catch (error) {
                const { toast: toastMsg } = ExtractErrorMessage(
                    error?.response || error
                );
                toast.error(toastMsg);
            } finally {
                setLoading(false);
            }
        }
    };

    const handlePagination = () => {
        dispatch(
            setLivePage({
                page: currentPage + 1,
                createdAtFirstItem: items?.data[0]?.createdAt
            })
        );
    };

    return (
        <Fragment>
            <AdminDashboardTitle
                title={t("common.liveClasses")}
                showResult={items?.count > 0}
                count={items?.count || 0}
                btnText={t("modal.liveClass.createNew")}
                onClick={() => toggle(<CreateEditLiveClassModal />)}
            />
            <AdminDashboardTabs />
            <div className="container mt-4 mb-5 scrollableDiv">
                <EventListFilter />
                <InfiniteScroll
                    dataLength={items?.data?.length || 0}
                    next={handlePagination}
                    hasMore={hasNextPage}
                    // hasMore={
                    //     !_.isEmpty(items?.data || 0)
                    //         ? items.data.length % PAGE_LIMIT === 0
                    //         : false
                    // }
                    scrollableTarget="scrollableDiv"
                >
                    <TableUIComponent
                        columns={[
                            {
                                title: t("tableHead.title"),
                                render: (record) => (
                                    <div className="d-flex align-items-center text-nowrap">
                                        <div className="d-flex flex-column ms-2">
                                            <div className="text-gray-600 fw-semibold fs-14">
                                                {record.title}
                                            </div>
                                            <span className="text-gray-300 fs-12 fw-medium">
                                                <a
                                                    href={
                                                        record.externalJoinUrl ||
                                                        ""
                                                    }
                                                    className={
                                                        "text-primary d-flex align-items-center"
                                                    }
                                                    target={"_blank"}
                                                >
                                                    <span>
                                                        <img
                                                            src={`/assets/icons/external_link.svg`}
                                                            className={`img-fluid`}
                                                        />
                                                    </span>
                                                    <span className="ms-1">
                                                        Video Link
                                                    </span>
                                                </a>
                                            </span>
                                        </div>
                                    </div>
                                ),
                                width: 280
                            },
                            {
                                title: t("tableHead.description"),
                                render: (record) => (
                                    <span className="ellipsis text-gray-300 fs-14 fw-medium">
                                        {record.description}
                                    </span>
                                ),
                                width: 280
                            },
                            {
                                title: t("tableHead.course"),
                                render: (record) => (
                                    <span className="text-gray-600 fs-14 fw-semibold">
                                        {_.find(
                                            CourseList || [],
                                            (e) => e.id === record.courseId
                                        )?.title || record.courseId}
                                    </span>
                                ),
                                width: 240
                            },
                            {
                                title: t("tableHead.time"),
                                render: (record) => {
                                    const { start, end } = timeFormatter(
                                        {
                                            start: record.startDateTime,
                                            end: record.endDateTime
                                        },
                                        true
                                    );
                                    return (
                                        <span className="text-gray-300 fs-14 fw-medium">
                                            {start} - {end}
                                        </span>
                                    );
                                },
                                width: 160
                            },
                            {
                                title: t("tableHead.date"),
                                render: (record) => {
                                    return (
                                        <span className="text-gray-300 fs-14 fw-medium">
                                            {dayjs(record.startDateTime).format(
                                                "DD MMM YYYY"
                                            )}
                                        </span>
                                    );
                                },
                                width: 140
                            },
                            {
                                title: "",
                                render: (record) => (
                                    <DropdownUIComponent
                                        className="border-0 rounded-2 d-inline-block lh-1"
                                        btnClassName={`def-color btn-sm rounded-2 border-0 px-2 py-1 bg-transparent mw-22`}
                                        isPrimary={false}
                                        btnText={
                                            <img
                                                src={`/assets/icons/three-dot.svg`}
                                                className={"img-fluid"}
                                                alt={"Three Dot Menu"}
                                            />
                                        }
                                        toggleIcon={false}
                                    >
                                        <DropdownItemUIComponent>
                                            <ButtonUIComponent
                                                className={`pt-1 px-0 pb-2 btn-sm bg-transparent border-0 btn-danger text-dark no-hover`}
                                                buttonInner={
                                                    <div className="d-flex align-items-center">
                                                        <img
                                                            width={14}
                                                            src={`/assets/icons/pencil.svg`}
                                                        />
                                                        <span className="ms-2 fs-12 fw-semibold">
                                                            {t(
                                                                "common.editDetails"
                                                            )}
                                                        </span>
                                                    </div>
                                                }
                                                onClick={() =>
                                                    handleEdit(record)
                                                }
                                            />
                                        </DropdownItemUIComponent>
                                        <DropdownItemUIComponent>
                                            <ButtonUIComponent
                                                className={`pb-0 px-0 btn-sm bg-transparent border-0 btn-danger text-danger no-hover`}
                                                buttonInner={
                                                    <div className="d-flex align-items-center">
                                                        <img
                                                            width={14}
                                                            src={`/assets/icons/red-minus.svg`}
                                                        />
                                                        <span className="ms-2 fs-12 fw-semibold">
                                                            {t(
                                                                "common.deleteClass"
                                                            )}
                                                        </span>
                                                    </div>
                                                }
                                                onClick={() =>
                                                    handleDelete(record)
                                                }
                                            />
                                        </DropdownItemUIComponent>
                                    </DropdownUIComponent>
                                ),
                                width: 40
                            }
                        ]}
                        dataSource={items?.data || []}
                        showEmpty={_.isEmpty(items?.data || [])}
                        isLoading={isLoading}
                        tableKey={"liveclass-list"}
                        onRowClick={handleView}
                    />
                </InfiniteScroll>
            </div>
            <DefaultCardLoader isFixed={true} show={isLoading || loading} />
        </Fragment>
    );
};

export default AdminLiveClassBaseComponent;
