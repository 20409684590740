import { RouterProvider } from "react-router-dom";
import BrowserRouterConfig from "./routes";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./intl/i18n";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
// import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
// import { RECAPTCHA_TOKEN } from "./utils";
import { Fragment } from "react";

dayjs.extend(relativeTime);

const App = () => {
    return (
        <Fragment>
        {/* <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_TOKEN}> */}
            <RouterProvider router={BrowserRouterConfig} />
            <ToastContainer />
        {/* </GoogleReCaptchaProvider> */}
        </Fragment>
    );
};

export default App;
