import _ from "lodash";
import { useTranslation } from "react-i18next";

const LandingMakingDiffComponent = () => {
    const { t } = useTranslation();
    return (
        <div className="container">
            <div className="row">
                <div className="col-12 text-center">
                    <div className="fs-48 fw-bold text-primary head">
                        {t("landing.difference.title")}
                    </div>
                </div>
                <div className="col-12 mt-4 pt-md-4">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="d-flex justify-content-center">
                                <img
                                    alt={"Making a difference"}
                                    src={`/assets/images/making-a-difference.webp`}
                                    className={`img-fluid`}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <ul className="list-unstyled diff-list mt-4 mt-lg-0">
                                {_.times(4).map((index) => {
                                    return (
                                        <li key={index}>
                                            <div className="d-flex align-items-start">
                                                <img
                                                    src={`/assets/icons/checked-circle.svg`}
                                                    alt={"Check"}
                                                    className={`mt-1 img-fluid`}
                                                />
                                                <div className="d-flex flex-column ms-2">
                                                    <strong className="fs-4 fw-semibold text-gray-600">
                                                        {t(
                                                            `landing.difference.n${index + 1}`
                                                        )}
                                                    </strong>
                                                    <span className="text-gray-400 content fs-5">
                                                        {t(
                                                            `landing.difference.t${index + 1}`
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingMakingDiffComponent;
