import dayjs from "dayjs";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import CalendarHeaderComponent from "../../../components/user/calendar/CalendarHeader";
import CalendarViewComponent from "../../../components/user/calendar/CalendarView";
import { FetchCalendarData } from "../../../services/user";
import DefaultCardLoader from "../../../components/common/Loader";

const CalendarComponent = () => {
    const { search } = useLocation();
    const currDate = dayjs();
    const [yearAndMonth, setYearAndMonth] = useState([
        currDate.year(),
        currDate.month() + 1
    ]);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});

    useMemo(async () => {
        const endDate = new Date(yearAndMonth[0], yearAndMonth[1], 1);
        const startDate = new Date(yearAndMonth[0], yearAndMonth[1] - 1, 1);
        try {
            setLoading(true);
            const params = new URLSearchParams(search);
            let obj = {};

            if (params.has("courseId")) {
                obj.listCourseIds = params.get("courseId");
            }

            const response = await FetchCalendarData({
                isReturnAll: true,
                minDateTime: dayjs(startDate).format("YYYY-MM-DD"),
                maxDateTime: dayjs(endDate).format("YYYY-MM-DD"),
                ...obj
            });

            const transformedData = _.reduce(
                response.data?.data || [],
                (previous, current) => {
                    let key = dayjs(current.startDateTime).format("YYYY-MM-DD");
                    let params = { timeStyle: "short", hour12: true };
                    let startDTime = new Date(current.startDateTime);
                    let endDTime = new Date(current.endDateTime);
                    let dataset = {
                        title: current.title,
                        start: startDTime.toLocaleTimeString("en-IN", params),
                        end: endDTime.toLocaleTimeString("en-IN", params),
                        diff: Math.round(
                            (endDTime.getTime() - startDTime.getTime()) /
                                (60000 * 60)
                        ),
                        href: current.externalJoinUrl,
                        id: current.id
                    };

                    if (previous.hasOwnProperty(key)) {
                        previous[key] = [...previous[key], dataset];
                    } else {
                        previous = { ...previous, [key]: [dataset] };
                    }
                    return previous;
                },
                {}
            );

            setData(transformedData);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    }, [search, yearAndMonth]);

    return (
        <div className="card mb-5 border-0 p-0 m-0">
            <div className="card-body calendar-card p-0 m-0 border-0">
                <div className="calendar-header">
                    <CalendarHeaderComponent
                        yearAndMonth={yearAndMonth}
                        onYearAndMonthChange={setYearAndMonth}
                    />
                </div>
                <CalendarViewComponent
                    yearAndMonth={yearAndMonth}
                    data={data || {}}
                />
            </div>
            <DefaultCardLoader isFixed={true} show={loading} />
        </div>
    );
};

export default CalendarComponent;
