import { useTranslation } from "react-i18next";
import ButtonUIComponent from "../UI/Button";

const AdminDashboardTitle = ({
    title = "",
    showResult = false,
    count = 0,
    btnText = "",
    onClick = () => {},
    children
}) => {
    const { t } = useTranslation();
    return (
        <div className="container my-4 pt-md-3">
            <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                    <div className="fs-28 fw-semibold">{title}</div>
                    {showResult && (
                        <small className="mt-2 text-gray-300 ms-md-3 ms-2">{`${count} ${t("common.results")}`}</small>
                    )}
                </div>
                {btnText && (
                    <div className="d-flex">
                        {children}
                        <ButtonUIComponent
                            buttonInner={btnText}
                            onClick={onClick}
                            className={`btn-sm fw-semibold fs-14 ms-3`}
                            isPrimary={true}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default AdminDashboardTitle;
