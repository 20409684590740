import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import CircularProgress from "../reusable/Progress";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { FetchCourseCompletion } from "../../../services/common";

const CourseProgressComponent = () => {
    const { t } = useTranslation();
    const { slug } = useParams();
    const { items, isLoading, isError } = useSelector(
        (state) => state.courseCompletion
    );
    const progressData = items && items[slug] ? items[slug] : {};

    const dispatch = useDispatch();

    const progressPercent = useMemo(() => {
        let totalVolume =
            progressData?.assessmentCount + progressData?.contentCount || 0;
        let totalCompletion =
            progressData?.assessmentCompletionCount +
                progressData?.contentCompletionCount || 0;
        let assessmentProgress =
            Math.floor(
                (progressData?.assessmentCompletionCount /
                    progressData?.assessmentCount) *
                    100
            ) || 0;
        let contentProgress =
            Math.floor(
                (progressData?.contentCompletionCount /
                    progressData?.contentCount) *
                    100
            ) || 0;
        return {
            assessmentProgress,
            contentProgress,
            total: Math.floor((totalCompletion / totalVolume) * 100)
            // total: Math.floor((assessmentProgress + contentProgress) / 2)
        };
    }, [items[slug]]);

    useEffect(() => {
        dispatch(FetchCourseCompletion(slug));
    }, [dispatch, slug]);

    return isLoading ? (
        <></>
    ) : (
        <div className="card bg-white content-card">
            <div className="card-heading py-2 my-2 d-flex justify-content-between align-items-center">
                <div className={classNames("fw-bold text-gray-600 fs-5")}>
                    {t("common.courseProgress")}
                </div>
                <CircularProgress value={progressPercent.total || 0} />
            </div>
            <div className="card-body">
                <div className="row px-2 pb-3">
                    <div className="col-md-6">
                        <div
                            className="progress h-24"
                            role="progressbar"
                            aria-label="Basic example"
                            ariavaluenow="0"
                            ariavaluemin="0"
                            ariavaluemax="100"
                        >
                            <div
                                className="progress-bar bg-progress-1"
                                style={{
                                    width: `${progressPercent.assessmentProgress}%`
                                }}
                            ></div>
                        </div>
                        <div className="fs-20 text-gray-600 fw-600 mt-2">
                            {`${progressData.assessmentCompletionCount || 0}/${progressData.assessmentCount || 0}`}
                        </div>
                        <div className="fs-12 text-gray-300 fw-500 mt-1">
                            {t("common.assessmentCompleted")}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div
                            className="progress h-24"
                            role="progressbar"
                            aria-label="Basic example"
                            ariavaluenow="0"
                            ariavaluemin="0"
                            ariavaluemax="100"
                        >
                            <div
                                className="progress-bar bg-progress-2"
                                style={{
                                    width: `${progressPercent.contentProgress}%`
                                }}
                            ></div>
                        </div>
                        <div className="fs-20 text-gray-600 fw-600 mt-2">
                            {`${progressData.contentCompletionCount || 0}/${progressData.contentCount || 0}`}
                        </div>
                        <div className="fs-12 text-gray-300 fw-500 mt-1">
                            {t("common.contentCompleted")}
                        </div>
                    </div>
                    <div className="col-md-6"></div>
                </div>
            </div>
        </div>
    );
};

export default CourseProgressComponent;
