import _ from "lodash";
import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import DefaultCardLoader from "../../../components/common/Loader";
import ButtonUIComponent from "../../../components/UI/Button";
import { useModalContext } from "../../../context/modal-context";
import {
    SingleInstitutionOperation,
    updateInstitutionList
} from "../../../services/admin/reducer/institution";
import { InstitutionType } from "../../../utils/constants";
import { ExtractErrorMessage } from "../../../utils/fn";

const AddEditInstitutionModal = ({ action = "create", record = {} }) => {
    const isEdit = action !== "create";
    const viewOnly = action === "view";
    const { toggle } = useModalContext();
    const { t } = useTranslation();
    const fields = [
        "uidCode",
        "edit",
        "create",
        "name",
        "view",
        "type",
        "principalName",
        "enterName",
        "selectType",
        "enterPrincipalName"
    ];
    const titles = _.map(fields, (field) => {
        return { value: t(`modal.institution.${field}`), key: field };
    }).reduce((p, c, i) => {
        p[c.key] = c.value;
        return p;
    }, {});

    const [payload, setPayload] = useState({
        uidCode: undefined,
        name: undefined,
        type: undefined,
        principalName: undefined
    });
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (record && !_.isEmpty(record)) {
            setPayload({ ...record });
        }
    }, [record]);

    const handleOnChange = (inputs) => {
        setPayload({
            ...payload,
            [inputs.name]: inputs.value
        });
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const response = await SingleInstitutionOperation(
                payload,
                isEdit ? 1 : 0
            );
            // dispatch update action once this operation is finised!
            dispatch(
                updateInstitutionList({
                    action,
                    data: response.data
                })
            );
            toast.success(
                `Institution ${isEdit ? "updated" : "added"} successfully!`
            );
            toggle();
        } catch (error) {
            const { toast: toastMsg } = ExtractErrorMessage(error.response);
            toast.error(toastMsg);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Form onSubmit={submitHandler}>
            <Modal.Header style={{ padding: "24px 16px" }}>
                <Modal.Title className="fs-20">
                    {viewOnly
                        ? t(titles.view)
                        : !isEdit
                          ? t(titles.create)
                          : t(titles.edit)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                <div className="d-flex flex-column">
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                        <div className="col-md-3">
                            <label
                                htmlFor="uidCode"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.uidCode}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <input
                                autoComplete={"off"}
                                required
                                className="form-control fs-14"
                                placeholder={t("common.enterUidCode")}
                                value={payload.uidCode || ""}
                                onChange={(e) =>
                                    handleOnChange({
                                        name: e.target.name,
                                        value: e.target.value
                                    })
                                }
                                disabled={viewOnly}
                                name={"uidCode"}
                            />
                        </div>
                    </div>
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                        <div className="col-md-3">
                            <label
                                htmlFor="name"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.name}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <input
                                autoComplete={"off"}
                                required
                                className="form-control fs-14"
                                placeholder={titles.enterName}
                                value={payload.name || ""}
                                onChange={(e) =>
                                    handleOnChange({
                                        name: e.target.name,
                                        value: e.target.value
                                    })
                                }
                                name={"name"}
                                disabled={viewOnly}
                            />
                        </div>
                    </div>
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                        <div className="col-md-3">
                            <label
                                htmlFor="type"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.type}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <select
                                required
                                className="form-select fs-14"
                                placeholder={titles.selectType}
                                name={"type"}
                                value={payload.type || ""}
                                onChange={(e) =>
                                    handleOnChange({
                                        name: e.target.name,
                                        value: e.target.value
                                    })
                                }
                                disabled={viewOnly}
                            >
                                <option value="">{titles.selectType}</option>
                                {InstitutionType &&
                                    _.map(InstitutionType, (item, key) => {
                                        return (
                                            <option value={key} key={key}>
                                                {t(`constants.${item}`)}
                                            </option>
                                        );
                                    })}
                            </select>
                        </div>
                    </div>
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center">
                        <div className="col-md-3">
                            <label
                                htmlFor="principalName"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.principalName}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <input
                                autoComplete={"off"}
                                className="form-control fs-14"
                                placeholder={titles.enterPrincipalName}
                                name={"principalName"}
                                required
                                value={payload.principalName || ""}
                                onChange={(e) =>
                                    handleOnChange({
                                        name: e.target.name,
                                        value: e.target.value
                                    })
                                }
                                disabled={viewOnly}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex">
                    <ButtonUIComponent
                        className={`fs-14 fw-semibold`}
                        buttonInner={t("common.cancel")}
                        type={"button"}
                        onClick={() => toggle()}
                    />
                    {!viewOnly && (
                        <ButtonUIComponent
                            isPrimary={true}
                            className={`fs-14 ms-3 fw-semibold`}
                            buttonInner={
                                !isEdit ? t(titles.create) : t(titles.edit)
                            }
                            type={"submit"}
                        />
                    )}
                </div>
            </Modal.Footer>
            <DefaultCardLoader show={loading} />
        </Form>
    );
};

export default AddEditInstitutionModal;
