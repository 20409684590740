import _ from "lodash";
import { FloatingLabel, Form } from "react-bootstrap";

const FloatingSelectUIComponent = ({
    label = "",
    placeholder = "",
    name = "",
    onChange = () => {},
    options = [],
    className = "",
    defaultOptionPlaceholder = ""
}) => {
    return (
        <FloatingLabel className={className} label={`${label || "label"}`}>
            <Form.Select
                name={name}
                placeholder={placeholder}
                onChange={(e) =>
                    onChange({ value: e.target.value, name: e.target.name })
                }
            >
                <option value="">{defaultOptionPlaceholder}</option>
                {_.map(options, (opt) => {
                    return (
                        <option key={opt.value} value={opt.value}>
                            {opt.text}
                        </option>
                    );
                })}
            </Form.Select>
        </FloatingLabel>
    );
};

export default FloatingSelectUIComponent;
