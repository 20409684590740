import _ from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import AdminDashboardTitle from "../../../components/admin/DashboardTitle";
import StudentListFilter from "../../../components/admin/filters/student";
import AdminDashboardTabs from "../../../components/admin/Tabs";
import DefaultCardLoader from "../../../components/common/Loader";
import ButtonUIComponent from "../../../components/UI/Button";
import DropdownUIComponent, {
    DropdownItemUIComponent
} from "../../../components/UI/Dropdown";
import TableUIComponent from "../../../components/UI/Table";
import { useModalContext } from "../../../context/modal-context";
import {
    fetchStudentList,
    setStudentFilters,
    setStudentPage
} from "../../../services/admin/reducer/students";
import AddEditStudentModal from "../modal/CreateEditStudent";
import { PAGE_LIMIT, SortingCriteria } from "../../../utils/constants";
import useGetInstitutionList from "../../../hooks/useGetInstitutionList";
import UserAvatar from "../../../components/user/avatar";
import ImportCSV from "../../../components/admin/import";
import { StudentsCreateMultiple, StudentsUpdateMultiple } from "../../../services/admin";
import { useTranslation } from "react-i18next";
import { checkNextPage } from "../../../utils/fn";
import ChangePasswordModal from "../modal/ChangePassword";

const AdminStudentBaseComponent = () => {
    const { toggle } = useModalContext();
    const { t } = useTranslation();
    const {
        items,
        isLoading,
        isError,
        currentPage,
        createdAtFirstItem,
        filters
    } = useSelector((state) => state.students);
    const dispatch = useDispatch();
    const studentList = items && items["home"] ? items["home"] : {};
    const { list: InstitutionList } = useGetInstitutionList();
    const hasNextPage = checkNextPage(studentList?.data || [], currentPage);

    useEffect(() => {
        dispatch(
            fetchStudentList({
                pageNumber: currentPage || 1,
                createdAtFirstItem: createdAtFirstItem,
                ...filters
            })
        );
    }, [dispatch, currentPage, createdAtFirstItem, filters]);

    useEffect(() => {
        return () => {
            dispatch(setStudentFilters({}));
        };
    }, []);

    const handleEdit = (record) => {
        toggle(<AddEditStudentModal action={"edit"} record={record} />);
    };

    const handleView = (record) => {
        toggle(<AddEditStudentModal action={"view"} record={record} />);
    };

    const handlePagination = () => {
        let shouldbeTime =
            filters?.sortingCriteria === SortingCriteria.ALPHABETICAL ||
            !filters?.sortingCriteria
                ? false
                : true;
        dispatch(
            setStudentPage({
                pageNumber: currentPage + 1 || 1,
                createdAtFirstItem: shouldbeTime
                    ? studentList?.data[0]?.createdAt
                    : studentList?.data[0]?.name
            })
        );
    };

    const importCallback = () => {
        dispatch(setStudentFilters({}));
    };

    const passwordManager = (record) => {
        toggle(<ChangePasswordModal record={record} />, { centered: true, size: "sm" });
    };

    return (
        <Fragment>
            <AdminDashboardTitle
                title={t(`common.students`)}
                showResult={studentList?.count > 0}
                count={studentList?.count || 0}
                btnText={t("modal.student.createNew")}
                onClick={() => toggle(<AddEditStudentModal />)}
            >
                <ImportCSV
                    toastKey={"Students"}
                    ExportFn={StudentsUpdateMultiple}
                    callbackFn={importCallback}
                    isCreate={false}
                />
                <ImportCSV
                    toastKey={"Students"}
                    ExportFn={StudentsCreateMultiple}
                    callbackFn={importCallback}
                />
            </AdminDashboardTitle>
            <AdminDashboardTabs />
            <div className="container mt-4 mb-5 scrollableDiv">
                <StudentListFilter />
                <InfiniteScroll
                    dataLength={studentList?.data?.length || 0}
                    next={handlePagination}
                    hasMore={hasNextPage}
                    // hasMore={
                    //     !_.isEmpty(studentList?.data || 0)
                    //         ? studentList.data.length % PAGE_LIMIT === 0
                    //         : false
                    // }
                    scrollableTarget="scrollableDiv"
                >
                    <TableUIComponent
                        columns={[
                            {
                                title: t("tableHead.name"),
                                render: (record) => {
                                    return (
                                        <div className="d-flex align-items-center text-nowrap">
                                            <UserAvatar
                                                profileImage={
                                                    record?.profileImageUri
                                                }
                                                name={record.name}
                                                width={32}
                                                size="md"
                                            />
                                            <div className="d-flex flex-column ms-2">
                                                <div className="text-gray-600 fs-14 fw-medium">
                                                    {record.name}
                                                </div>
                                                <span className="text-gray-300 fs-12">
                                                    {record.uidCode}
                                                </span>
                                            </div>
                                        </div>
                                    );
                                },
                                width: 160
                            },
                            {
                                title: t("tableHead.email"),
                                render: (record) => (
                                    <span className="text-gray-300 fs-14 fw-medium">
                                        {record.email || "-"}
                                    </span>
                                ),
                                width: 200
                            },
                            {
                                title: t("tableHead.institution"),
                                render: (record) => (
                                    <span className="text-gray-300 fs-14 fw-medium">
                                        {_.find(
                                            InstitutionList || [],
                                            (e) => e.id === record.institutionId
                                        )?.name || record.institutionId}
                                    </span>
                                ),
                                width: 180
                            },
                            {
                                title: t("tableHead.medium"),
                                render: (record) => (
                                    <span className="text-gray-300 fs-14 fw-medium">
                                        {record.languageCode
                                            ? t(
                                                  `constants.${record.languageCode}`
                                              )
                                            : "-"}
                                    </span>
                                ),
                                width: 200
                            },
                            {
                                title: "",
                                render: (record) => (
                                    <DropdownUIComponent
                                        className="border-0 rounded-2 d-inline-block lh-1"
                                        btnClassName={`def-color btn-sm rounded-2 border-0 px-2 py-1 bg-transparent mw-22`}
                                        isPrimary={false}
                                        btnText={
                                            <img
                                                src={`/assets/icons/three-dot.svg`}
                                                className={"img-fluid"}
                                                alt={"Three Dot Menu"}
                                            />
                                        }
                                        toggleIcon={false}
                                    >
                                        <DropdownItemUIComponent>
                                            <ButtonUIComponent
                                                className={`pt-0 px-0 pb-2 btn-sm bg-transparent border-0 btn-danger text-dark no-hover`}
                                                buttonInner={
                                                    <div className="d-flex align-items-center">
                                                        <img
                                                            width={14}
                                                            src={`/assets/icons/pencil.svg`}
                                                        />
                                                        <span className="ms-2 fs-12 fw-semibold">
                                                            {t(
                                                                "common.editDetails"
                                                            )}
                                                        </span>
                                                    </div>
                                                }
                                                onClick={() =>
                                                    handleEdit(record)
                                                }
                                            />
                                        </DropdownItemUIComponent>
                                        <DropdownItemUIComponent>
                                            <ButtonUIComponent
                                                className={`pb-0 px-0 btn-sm bg-transparent border-0 text-dark no-hover`}
                                                buttonInner={
                                                    <div className="d-flex align-items-center">
                                                        {/* <img
                                                            width={14}
                                                            src={`/assets/icons/red-minus.svg`}
                                                        /> */}
                                                        <span className="ms-2 fs-12 fw-semibold">
                                                            {t("modal.changePassword.title")}
                                                        </span>
                                                    </div>
                                                }
                                                onClick={() => {
                                                    passwordManager(record);
                                                }}
                                            />
                                        </DropdownItemUIComponent>
                                    </DropdownUIComponent>
                                ),
                                width: 40
                            }
                        ]}
                        dataSource={studentList?.data || []}
                        showEmpty={
                            !isLoading && _.isEmpty(studentList?.data || [])
                        }
                        isLoading={isLoading}
                        tableKey={"student-list"}
                        onRowClick={handleView}
                    />
                </InfiniteScroll>
            </div>
            <DefaultCardLoader isFixed={true} show={isLoading} />
        </Fragment>
    );
};

export default AdminStudentBaseComponent;
