import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CourseContentItemTemplate from "../../../components/common/courses/ContentItem";
import DefaultCardLoader from "../../../components/common/Loader";
import EmptyBoxComponent from "../../../components/common/reusable/EmptyBox";
import ListDraggableUIComponent from "../../../components/UI/List/ListDraggable";
import { useModalContext } from "../../../context/modal-context";
import {
    deleteCourseAssessment,
    reorderCourseAssessment
} from "../../../services/common/reducer/course";
import {
    CourseAssessmentHandler,
    DeleteCourseAssessmentHandler,
    fetchCourseAssessment
} from "../../../services/common/reducer/course/content";
import { COURSE_CONTENT_TYPE } from "../../../utils/constants";
import { ExtractErrorMessage, KeyGenerator } from "../../../utils/fn";
import AdminCreateEditContentModal from "../modal/CreateEditContent";
import { toast } from "react-toastify";

const AdminCourseAssessmentComponent = () => {
    const navigate = useNavigate();
    const { slug } = useParams();
    const { toggle } = useModalContext();
    const { assessment, isLoading, isError } = useSelector(
        (state) => state.courseDetail
    );
    const [loading, setLoading] = useState(false);

    const cacheKey = KeyGenerator({ id: slug });
    const courseContent =
        assessment && assessment[cacheKey] ? assessment[cacheKey] : undefined;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCourseAssessment({ id: slug }));
    }, [dispatch]);

    const editHandler = (record) => {
        // navigate to quiz edit page
        if (record.type === COURSE_CONTENT_TYPE[4]) {
            navigate(`/courses/${slug}/quiz/${record.id}`);
            return;
        }
        toggle(
            <AdminCreateEditContentModal
                fixed
                data={record}
                selectedTab={record.type || COURSE_CONTENT_TYPE[2]}
            />
        );
    };

    const reorderHandler = async (start, end, items = []) => {
        try {
            if (start === end) return;
            let totalLength = items.length || 0;
            let nextElement = totalLength - 1 === end ? null : end + 1;
            let nextId = nextElement ? items[nextElement].id : null;

            // hit reorder API
            await CourseAssessmentHandler(
                {
                    id: items[end].id,
                    nextId: nextId
                },
                2
            );

            dispatch(
                reorderCourseAssessment({
                    meta: { id: slug },
                    data: {
                        startIndex: start,
                        endIndex: end,
                        nextElement
                    }
                })
            );
        } catch (error) {}
    };

    const deleteHandler = async (record) => {
        try {
            let shouldDelete = window.confirm(
                `Do you really want to delete the selected assessment?`
            );
            if (!shouldDelete) return;
            setLoading(true);
            // hit delete course API
            await DeleteCourseAssessmentHandler(record.id);
            dispatch(
                deleteCourseAssessment({
                    meta: { id: slug },
                    id: record.id
                })
            );
            toast.success(`Assessment deleted successfully!`);
        } catch (error) {
            const { toast: toastMsg } = ExtractErrorMessage(
                error?.response || error
            );
            toast.error(toastMsg);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="row mt-3">
            <div className="col-12">
                {isLoading ||
                (!isLoading && _.isEmpty(courseContent?.data || [])) ? (
                    <EmptyBoxComponent
                        isLoading={isLoading}
                        data={courseContent?.data}
                    />
                ) : (
                    <ListDraggableUIComponent
                        listKey={"admin-course-assessment"}
                        separator={false}
                        render={(record, index) => (
                            <CourseContentItemTemplate
                                record={record}
                                itemIndex={index}
                                handleEdit={editHandler}
                                deleteHandler={deleteHandler}
                            />
                        )}
                        data={courseContent?.data || []}
                        onDragEndChange={reorderHandler}
                    />
                )}
            </div>
            <DefaultCardLoader isFixed show={isLoading || loading} />
        </div>
    );
};

export default AdminCourseAssessmentComponent;
