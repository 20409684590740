import _ from "lodash";
import { Fragment, useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import DefaultCardLoader from "../../../components/common/Loader";
import ButtonUIComponent from "../../../components/UI/Button";
import UploadButton from "../../../components/UI/Button/upload";
import { useModalContext } from "../../../context/modal-context";
import {
    SingleCourseOperation,
    updateCourseList
} from "../../../services/admin/reducer/courses";
import { ExtractErrorMessage } from "../../../utils/fn";
import {
    CourseCategory,
    CourseType,
    FILE_SIZE,
    LanguageCode
} from "../../../utils/constants";
import { resetCourseDetails } from "../../../services/common/reducer/course";
import MultiSelectUIComponent from "../../../components/UI/Input/multi-select";
import useGetInstructorList from "../../../hooks/useGetTeacherList";

/**
 *
 * @param {String} action => create | edit
 * @returns
 */
const CreateEditCourseModal = ({ action = "create", record = {} }) => {
    const isEdit = action === "edit";
    const { toggle } = useModalContext();
    const { t } = useTranslation();
    const fields = [
        "uidCode",
        "lang",
        "editCourse",
        "createCourse",
        "courseTitle",
        "courseThumbnail",
        "courseLayout",
        "courseInstructor",
        "selectCourseInstructor",
        "instructorName",
        "courseDescription",
        "studyMaterial",
        "gradingCriteria",
        "externalSourceUrl",
        "sourceType",
        "category",
        "enterCourseTitle",
        "selectType",
        "selectCategory",
        "selectLang",
        "enterCourseDescription",
        "enterStudyMaterial",
        "enterGradingCriteria",
        "enterUrl"
    ];
    const titles = _.map(fields, (field) => {
        return { value: t(`modal.courses.${field}`), key: field };
    }).reduce((p, c, i) => {
        p[c.key] = c.value;
        return p;
    }, {});

    const [payload, setPayload] = useState({
        uidCode: undefined,
        title: undefined,
        description: undefined,
        sourceType: undefined,
        externalSourceUrl: undefined,
        category: undefined,
        studyMaterial: undefined,
        gradingCriteria: undefined,
        thumbnailImageUri: undefined,
        courseLayoutImageUri: undefined,
        languageCode: undefined,
        listCourseInstructorTeacherIds: []
    });
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { 
        list: InstructorList, 
        isLoading: isLoadingInstructorList, 
        isError: isErrorInstructorList 
    } = useGetInstructorList();

    useEffect(() => {
        if (record && !_.isEmpty(record)) {
            let instructorOptions = [];
            if (InstructorList && !_.isEmpty(InstructorList)) {
                let recordInstructorOptions = _.map(
                    record?.listCourseInstructorTeachers || [],
                    (e) => e.teacherId || e.id
                );
                instructorOptions = _.filter(InstructorList || [], (opts) =>
                    [...recordInstructorOptions].includes(opts.id)
                ).map((item) => ({
                    label: `${item.name} [#${item.uidCode}]`,
                    value: item.id
                }));
            }
            setPayload({
                ...record,
                listCourseInstructorTeacherIds: instructorOptions
            });
        }
    }, [record, InstructorList]);

    useEffect(() => {
        return () => {
            setPayload({
                uidCode: undefined,
                title: undefined,
                description: undefined,
                sourceType: undefined,
                externalSourceUrl: undefined,
                category: undefined,
                studyMaterial: undefined,
                gradingCriteria: undefined,
                thumbnailImageUri: undefined,
                courseLayoutImageUri: undefined,
                languageCode: undefined,
                listCourseInstructorTeacherIds: []
            });
        };
    }, []);

    const handleOnChange = (inputs) => {
        setPayload({
            ...payload,
            [inputs.name]: inputs.value
        });
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            if (!payload.thumbnailImageUri) {
                throw new Error("Please upload thumbnail");
            }
            setLoading(true);
            const response = await SingleCourseOperation(
                payload,
                isEdit ? 1 : 0
            );
            // dispatch update action once this operation is finised!
            dispatch(
                updateCourseList({
                    action,
                    data: {
                        ...response.data,
                        listCourseInstructorTeachers: response?.listCourseInstructorAssociations || []
                    }
                })
            );
            dispatch(resetCourseDetails());
            toast.success(
                `Course ${isEdit ? "updated" : "added"} successfully!`
            );
            toggle();
        } catch (error) {
            const { toast: toastMsg } = ExtractErrorMessage(
                error?.response || error
            );
            toast.error(toastMsg);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Form onSubmit={submitHandler}>
            <Modal.Header style={{ padding: "24px 16px" }}>
                <Modal.Title className="fs-20">
                    {!isEdit ? t(titles.createCourse) : t(titles.editCourse)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                <div className="d-flex flex-column">
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                        <div className="col-md-3">
                            <label
                                htmlFor="name"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.uidCode}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <input
                                autoComplete={"off"}
                                required
                                className="form-control fs-14"
                                placeholder={t("common.enterUidCode")}
                                value={payload.uidCode || ""}
                                onChange={(e) =>
                                    handleOnChange({
                                        name: e.target.name,
                                        value: e.target.value
                                    })
                                }
                                name={"uidCode"}
                            />
                        </div>
                    </div>
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                        <div className="col-md-3">
                            <label
                                htmlFor="name"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.courseTitle}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <input
                                autoComplete={"off"}
                                required
                                className="form-control fs-14"
                                placeholder={titles.enterCourseTitle}
                                value={payload.title || ""}
                                onChange={(e) =>
                                    handleOnChange({
                                        name: e.target.name,
                                        value: e.target.value
                                    })
                                }
                                name={"title"}
                            />
                        </div>
                    </div>
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                        <div className="col-md-3">
                            <label
                                htmlFor="name"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.courseThumbnail}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <UploadButton
                                uploadButtonKey={`course-modal`}
                                btnText={t("common.dragDropImg")}
                                accept={`image/png,image/jpeg,image/jpg`}
                                name={"thumbnailImageUri"}
                                onChange={handleOnChange}
                                value={payload.thumbnailImageUri || undefined}
                                sizeLimit={FILE_SIZE.IMAGE}
                                imgWidth={180}
                                aspect={16 / 9}
                                allowCrop={true}
                            />
                        </div>
                    </div>
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                        <div className="col-md-3">
                            <label
                                htmlFor="sourceType"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.sourceType}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <select
                                disabled={isEdit}
                                required
                                className="form-select fs-14"
                                placeholder={titles.selectType}
                                name={"sourceType"}
                                value={payload.sourceType || ""}
                                onChange={(e) =>
                                    handleOnChange({
                                        name: e.target.name,
                                        value: e.target.value
                                    })
                                }
                            >
                                <option value="">{titles.selectType}</option>
                                {CourseType &&
                                    _.map(CourseType, (item, key) => {
                                        return (
                                            <option
                                                value={item.value}
                                                key={key}
                                            >
                                                {t(`constants.${item.value}`)}
                                            </option>
                                        );
                                    })}
                            </select>
                        </div>
                    </div>
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                        <div className="col-md-3">
                            <label
                                htmlFor="category"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.category}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <select
                                required
                                className="form-select fs-14"
                                placeholder={titles.selectCategory}
                                name={"category"}
                                value={payload.category || ""}
                                onChange={(e) =>
                                    handleOnChange({
                                        name: e.target.name,
                                        value: e.target.value
                                    })
                                }
                            >
                                <option value="">
                                    {titles.selectCategory}
                                </option>
                                {CourseCategory &&
                                    _.map(CourseCategory, (item, key) => {
                                        return (
                                            <option
                                                value={item.value}
                                                key={key}
                                            >
                                                {t(`constants.${item.value}`)}
                                            </option>
                                        );
                                    })}
                            </select>
                        </div>
                    </div>
                    {payload.sourceType &&
                        (payload.sourceType === CourseType.INTERNAL.value ? (
                            <Fragment>
                                <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                                    <div className="col-md-3">
                                        <label
                                            htmlFor="name"
                                            className="fs-14 text-gray-300 fw-semibold"
                                        >
                                            {titles.courseDescription}
                                        </label>
                                    </div>
                                    <div className="col-md-9 mt-2 mt-md-0">
                                        <textarea
                                            className={`form-control fs-14`}
                                            placeholder={
                                                titles.enterCourseDescription
                                            }
                                            required={false}
                                            name={"description"}
                                            onChange={(e) =>
                                                handleOnChange({
                                                    name: e.target.name,
                                                    value: e.target.value
                                                })
                                            }
                                            value={payload.description || ""}
                                        />
                                    </div>
                                </div>
                                <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                                    <div className="col-md-3">
                                        <label
                                            htmlFor="name"
                                            className="fs-14 text-gray-300 fw-semibold"
                                        >
                                            {titles.courseInstructor}
                                        </label>
                                    </div>
                                    <div className="col-md-9 mt-2 mt-md-0">
                                        <MultiSelectUIComponent
                                            name={"listCourseInstructorTeacherIds"}
                                            placeholder={titles.selectCourseInstructor}
                                            value={payload?.listCourseInstructorTeacherIds || []}
                                            isLoading={
                                                isLoadingInstructorList || isErrorInstructorList
                                            }
                                            options={[
                                                ..._.map(InstructorList || [], (item) => {
                                                    return {
                                                        label: `${item.name} [${item.uidCode}]`,
                                                        value: item.id
                                                    };
                                                })
                                            ]}
                                            onChange={(e) => {
                                                handleOnChange({
                                                    name: "listCourseInstructorTeacherIds",
                                                    value: e
                                                });
                                            }}
                                            disabled={false}
                                        />          
                                    </div>
                                </div>
                                <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                                    <div className="col-md-3">
                                        <label
                                            htmlFor="name"
                                            className="fs-14 text-gray-300 fw-semibold"
                                        >
                                            {titles.courseLayout}
                                        </label>
                                    </div>
                                    <div className="col-md-9 mt-2 mt-md-0">
                                        <UploadButton
                                            uploadButtonKey={`course-layout-modal`}
                                            btnText={t("common.dragDropImg")}
                                            accept={`image/png,image/jpeg,image/jpg`}
                                            name={"courseLayoutImageUri"}
                                            onChange={handleOnChange}
                                            value={
                                                payload.courseLayoutImageUri ||
                                                undefined
                                            }
                                            sizeLimit={FILE_SIZE.IMAGE}
                                            imgWidth={180}
                                        />
                                    </div>
                                </div>
                                <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                                    <div className="col-md-3">
                                        <label
                                            htmlFor="name"
                                            className="fs-14 text-gray-300 fw-semibold"
                                        >
                                            {titles.studyMaterial}
                                        </label>
                                    </div>
                                    <div className="col-md-9 mt-2 mt-md-0">
                                        <input
                                            autoComplete={"off"}
                                            className="form-control fs-14"
                                            placeholder={
                                                titles.enterStudyMaterial
                                            }
                                            name={"studyMaterial"}
                                            required={false}
                                            value={payload.studyMaterial || ""}
                                            onChange={(e) =>
                                                handleOnChange({
                                                    name: e.target.name,
                                                    value: e.target.value
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                                    <div className="col-md-3">
                                        <label
                                            htmlFor="name"
                                            className="fs-14 text-gray-300 fw-semibold"
                                        >
                                            {titles.gradingCriteria}
                                        </label>
                                    </div>
                                    <div className="col-md-9 mt-2 mt-md-0">
                                        <textarea
                                            className={`form-control fs-14`}
                                            placeholder={
                                                titles.enterGradingCriteria
                                            }
                                            name={"gradingCriteria"}
                                            required={false}
                                            onChange={(e) =>
                                                handleOnChange({
                                                    name: e.target.name,
                                                    value: e.target.value
                                                })
                                            }
                                            value={
                                                payload.gradingCriteria || ""
                                            }
                                        />
                                    </div>
                                </div>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                                    <div className="col-md-3">
                                        <label
                                            htmlFor="name"
                                            className="fs-14 text-gray-300 fw-semibold"
                                        >
                                            {titles.externalSourceUrl}
                                        </label>
                                    </div>
                                    <div className="col-md-9 mt-2 mt-md-0">
                                        <input
                                            type={"url"}
                                            className={`form-control fs-14`}
                                            placeholder={titles.enterUrl}
                                            name={"externalSourceUrl"}
                                            required
                                            onChange={(e) =>
                                                handleOnChange({
                                                    name: e.target.name,
                                                    value: e.target.value
                                                })
                                            }
                                            value={
                                                payload.externalSourceUrl || ""
                                            }
                                        />
                                    </div>
                                </div>
                            </Fragment>
                        ))}
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center">
                        <div className="col-md-3">
                            <label
                                htmlFor="lang"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.lang}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <select
                                required
                                className="form-select fs-14"
                                placeholder={titles.selectLang}
                                name={"languageCode"}
                                value={payload.languageCode || ""}
                                onChange={(e) =>
                                    handleOnChange({
                                        name: e.target.name,
                                        value: e.target.value
                                    })
                                }
                            >
                                <option value="">{titles.selectLang}</option>
                                {LanguageCode &&
                                    _.map(LanguageCode, (item, key) => {
                                        return (
                                            <option
                                                value={item.value}
                                                key={key}
                                            >
                                                {t(`constants.${item.value}`)}
                                            </option>
                                        );
                                    })}
                            </select>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex">
                    <ButtonUIComponent
                        className={`fs-14 me-3 fw-semibold`}
                        buttonInner={t("common.cancel")}
                        type={"button"}
                        onClick={() => toggle()}
                    />
                    <ButtonUIComponent
                        isPrimary={true}
                        className={`fs-14 fw-semibold`}
                        buttonInner={
                            !isEdit
                                ? t(titles.createCourse)
                                : t(titles.editCourse)
                        }
                        type={"submit"}
                    />
                </div>
            </Modal.Footer>
            <DefaultCardLoader show={loading} />
        </Form>
    );
};

export default CreateEditCourseModal;
