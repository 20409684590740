import _ from "lodash";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useQueryParams from "../../../hooks/useSearchQuery";
import SearchBarComponent from "../../UI/Search";
import { setInstitutionFilters } from "../../../services/admin/reducer/institution";
import { InstitutionType } from "../../../utils/constants";
import { useTranslation } from "react-i18next";

const InstitutionListFilter = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const query = useQueryParams();
    const navigate = useNavigate();

    const handleSearch = (search) => {
        let payload = { ...query, search };
        if (!payload.search || _.isEmpty(payload.search)) {
            delete payload.search;
        }
        navigate({
            search: queryString.stringify(payload)
        });
        dispatch(setInstitutionFilters(payload));
    };

    const handleType = (type) => {
        let payload = { ...query, type: type };
        if (!payload.type) {
            delete payload["type"];
        }
        navigate({
            search: queryString.stringify(payload)
        });
        dispatch(setInstitutionFilters(payload));
    };

    return (
        <div className="row my-3">
            <div className="col-md-4">
                <SearchBarComponent
                    placeholder={t("formFields.searchInstitution")}
                    onChange={handleSearch}
                />
            </div>
            <div className="col-md-2 offset-md-1 ms-auto">
                <div
                    className="d-flex align-items-center justify-content-end"
                    style={{ gap: 20 }}
                >
                    <select
                        onChange={(e) => handleType(e.target.value)}
                        className="form-select fs-14 normal br-60"
                        placeholder={"Select Type"}
                    >
                        <option value={""}>{t("formFields.selectType")}</option>
                        {_.map(InstitutionType, (item) => {
                            return (
                                <option value={item} key={item}>
                                    {t(`constants.${item}`)}
                                </option>
                            );
                        })}
                    </select>
                </div>
            </div>
        </div>
    );
};

export default InstitutionListFilter;
