import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import HeaderComponent from "../../components/common/header";
import { useAuthContext } from "../../context/auth-context";

const UserDashboardPage = () => {
    const { userObject, logout } = useAuthContext();
    return (
        <Fragment>
            <HeaderComponent
                userObject={userObject}
                logout={logout}
                selfUser={true}
            />
            <Outlet />
        </Fragment>
    );
};

export default UserDashboardPage;
