import { useNavigate } from "react-router-dom";
import { URLS } from "../../../utils";
import { dateTimeFormatter } from "../../../utils/fn";
import ButtonUIComponent from "../../UI/Button";
import DropdownUIComponent, {
    DropdownItemUIComponent
} from "../../UI/Dropdown";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const ActivityForumListItemTemplate = ({
    data = {},
    handleOperation = (record, remove = true) => {},
    redirection = true
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isResolved = data?.isResolved || false;
    const isRemoved = data?.isEntityRemoved || false;

    return (
        <div className={"d-flex flex-column w-100"}>
            <div className="d-flex">
                <div
                    className="d-flex align-self-start"
                    style={{ flexBasis: "5%", minWidth: "5%" }}
                >
                    <img
                        style={{ minWidth: 24 }}
                        src={`/assets/icons/default-user.svg`}
                        alt={`User Image`}
                        className={"img-fluid border rounded-circle p-1"}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ flexBasis: "95%", minWidth: "95%" }}
                >
                    <div className="d-flex justify-content-between">
                        <div className="text-gray-600 fw-semibold">
                            {data?.entityTitle || ""}
                        </div>
                        <div
                            className={classNames("", {
                                "d-flex": !isResolved,
                                "d-none": isResolved
                            })}
                            style={{ minWidth: 20 }}
                        >
                            <DropdownUIComponent
                                className="border-0 rounded-2 d-inline-block lh-1"
                                btnClassName={`def-color btn-sm rounded-2 border-0 px-2 py-1 bg-transparent mw-22`}
                                isPrimary={false}
                                btnText={
                                    <img
                                        src={`/assets/icons/three-dot.svg`}
                                        className={"img-fluid"}
                                        alt={"Three Dot Menu"}
                                    />
                                }
                                toggleIcon={false}
                            >
                                <DropdownItemUIComponent>
                                    <ButtonUIComponent
                                        className={`btn-sm btn-text pt-0 pb-2 px-0 text-dark no-hover fw-semibold`}
                                        buttonInner={t("common.remove")}
                                        onClick={() =>
                                            handleOperation(data, true)
                                        }
                                    />
                                </DropdownItemUIComponent>
                                <DropdownItemUIComponent>
                                    <ButtonUIComponent
                                        className={`btn-sm btn-text pt-2 pb-0 px-0 text-danger no-hover fw-semibold`}
                                        buttonInner={t("common.ignore")}
                                        onClick={() =>
                                            handleOperation(data, false)
                                        }
                                    />
                                </DropdownItemUIComponent>
                            </DropdownUIComponent>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                        <div className="fs-14 text-gray-300 fw-semibold">
                            {data.entityCreatorName} •{" "}
                            {data.entityCreatorInstitutionName} •{" "}
                            {data?.reason || ""}
                        </div>
                        <div className="badge rounded-pill text-bg-primary fs-12 text-light fw-regular">
                            {/* {data.isResolved
                                ? "Resolved"
                                : "Ongoing discussion"} */}
                            {data.type === "FORUM_TOPIC"
                                ? t("common.originalQuestion")
                                : ""}
                        </div>
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                        <div
                            className={classNames("badge rounded-pill fs-12", {
                                "bg-light-danger": !isResolved || isRemoved,
                                "text-primary bg-light-primary": isResolved
                            })}
                        >
                            {/* • Reported Question */}
                            {!isResolved
                                ? t("common.unresolved")
                                : `${t("common.resolved")} - ${t(isRemoved ? "common.removed" : "common.ignored")}`}
                        </div>
                        <span className="fs-12 text-gray-300">
                            {dateTimeFormatter(
                                data.updatedAt || data.createdAt,
                                true
                            )}
                        </span>
                    </div>
                </div>
            </div>
            {data?.entityAttachmentUri && (
                <div className="d-flex mt-3 w-100">
                    <img
                        src={`${URLS.MEDIA_URL}${data?.entityAttachmentUri}`}
                        alt={`Forum Image`}
                        width={"100%"}
                        style={{
                            maxHeight: 280,
                            objectFit: "cover",
                            borderRadius: 16
                        }}
                        className={"img-fluid"}
                    />
                </div>
            )}
            {redirection && (
                <div className="d-flex mt-3">
                    <ButtonUIComponent
                        className={`btn-sm fs-14 px-md-4`}
                        buttonInner={`Join the Discussion ->`}
                        onClick={() => navigate(`/forum/${data.id}`)}
                    />
                </div>
            )}
        </div>
    );
};

export default ActivityForumListItemTemplate;
