import classNames from "classnames";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { URLS } from "../../../utils";
import ButtonUIComponent from "../../UI/Button";
import DropdownUIComponent, {
    DropdownItemUIComponent
} from "../../UI/Dropdown";
import UserAvatar from "../../user/avatar";
import { useTranslation } from "react-i18next";
import { parseHTMLContent } from "../../../utils/fn";

export const ForumCommentItemTemplate = ({
    data = {},
    userObject = {},
    handleReport = (record) => {},
    handleRemove = (record) => {}
}) => {
    const { t } = useTranslation();
    const isSelfUser = userObject?.id === data?.creatorStudentId;

    return (
        <div className={"d-flex flex-column w-100"}>
            <div className="d-flex">
                <div
                    className="d-flex align-self-start"
                    style={{ flexBasis: "5%", minWidth: "5%" }}
                >
                    <UserAvatar
                        profileImage={data?.creatorProfileImageUri}
                        name={data?.creatorName}
                        width={24}
                        externalImage={`/assets/icons/default-user.svg`}
                        externalImageClass="p-1 border"
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ flexBasis: "95%", minWidth: "95%" }}
                >
                    <div className="d-flex justify-content-between">
                        <div
                            className="text-gray-600 fw-medium"
                            dangerouslySetInnerHTML={{
                                __html: parseHTMLContent(data?.title, "")
                            }}
                        />
                        <div
                            className={classNames("", {
                                "d-flex": true
                                // "d-none": !data.isOpen && isSelfUser
                            })}
                            style={{ minWidth: 20 }}
                        >
                            <DropdownUIComponent
                                className="border-0 rounded-2 d-inline-block lh-1"
                                btnClassName={`def-color btn-sm rounded-2 border-0 px-2 py-1 bg-transparent mw-22`}
                                isPrimary={false}
                                onToggle={() => {}}
                                btnText={
                                    <img
                                        src={`/assets/icons/three-dot.svg`}
                                        className={"img-fluid"}
                                        alt={"Three Dot Menu"}
                                    />
                                }
                                toggleIcon={false}
                            >
                                {!isSelfUser && (
                                    <DropdownItemUIComponent>
                                        <ButtonUIComponent
                                            className={`btn-sm btn-text pb-${isSelfUser ? 1 : 0} pt-0 px-0 text-dark no-hover`}
                                            buttonInner={
                                                <div className="d-flex align-items-center fw-semibold">
                                                    <div className="d-flex">
                                                        <img
                                                            width={20}
                                                            src={`/assets/icons/report.svg`}
                                                            className={`img-fluid`}
                                                            alt={`Report Icon`}
                                                        />
                                                    </div>
                                                    <span className="ms-2">
                                                        {t("common.report")}
                                                    </span>
                                                </div>
                                            }
                                            onClick={() => handleReport(data)}
                                        />
                                    </DropdownItemUIComponent>
                                )}
                                {isSelfUser && (
                                    <DropdownItemUIComponent>
                                        <ButtonUIComponent
                                            className={`btn-sm btn-text pb-0 pt-0 px-0 text-dark no-hover`}
                                            buttonInner={
                                                <div className="d-flex align-items-center fw-semibold">
                                                    <div className="d-flex">
                                                        <img
                                                            width={20}
                                                            src={`/assets/icons/report.svg`}
                                                            className={`img-fluid`}
                                                            alt={`Report Icon`}
                                                        />
                                                    </div>
                                                    <span className="ms-2">
                                                        {t("common.remove")}
                                                    </span>
                                                </div>
                                            }
                                            onClick={() => handleRemove(data)}
                                        />
                                    </DropdownItemUIComponent>
                                )}
                            </DropdownUIComponent>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                        <div className="fs-14 text-gray-300">
                            {data?.creatorName} • {data?.creatorInstitutionName}
                        </div>
                        <span className="fs-12 text-gray-300">
                            {dayjs(data.updatedAt || data.createdAt).format(
                                "DD MMM YYYY, hh:mm A"
                            )}
                        </span>
                    </div>
                </div>
            </div>
            {data?.attachmentUri && (
                <div className="d-flex mt-3 w-100">
                    <img
                        src={`${URLS.MEDIA_URL}${data?.attachmentUri}`}
                        alt={`Forum Image`}
                        width={"100%"}
                        style={{
                            // maxHeight: 280,
                            // objectFit: "cover",
                            borderRadius: 16
                        }}
                        className={"img-fluid"}
                    />
                </div>
            )}
        </div>
    );
};

const ForumListItemTemplate = ({
    data = {},
    userObject = {},
    isForumPage = false,
    handleReport = (record) => {},
    handleMarkAsClosed = (record) => {},
    handleRemove = (record) => {},
    redirection = true
}) => {
    const { t } = useTranslation();
    const isSelfUser = userObject?.id === data?.creatorStudentId;
    const navigate = useNavigate();

    return (
        <div
            className={classNames("d-flex flex-column w-100", {
                cp: !isForumPage
            })}
            onClick={(e) => {
                e.stopPropagation();
                if (isForumPage) return;
                navigate(`${URLS.DASHBOARD_URL}forum/${data.id}`);
            }}
        >
            <div className="d-flex">
                <div
                    className="d-flex align-self-start"
                    style={{ flexBasis: "5%", minWidth: "5%" }}
                >
                    <UserAvatar
                        profileImage={data?.creatorProfileImageUri}
                        name={data?.creatorName}
                        width={30}
                        size="md"
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ flexBasis: "95%", minWidth: "95%" }}
                >
                    <div className="d-flex justify-content-between">
                        <div
                            className="text-gray-600 fw-bold"
                            dangerouslySetInnerHTML={{
                                __html: parseHTMLContent(data?.title, "")
                            }}
                        />
                        <div
                            className={classNames("", {
                                "d-flex": data.isOpen || !isSelfUser,
                                "d-none": !data.isOpen && isSelfUser
                            })}
                            style={{ minWidth: 20 }}
                        >
                            <DropdownUIComponent
                                className="border-0 rounded-2 d-inline-block lh-1"
                                btnClassName={`def-color btn-sm rounded-2 border-0 px-2 py-1 bg-transparent mw-22`}
                                isPrimary={false}
                                onToggle={() => {}}
                                btnText={
                                    <img
                                        src={`/assets/icons/three-dot.svg`}
                                        className={"img-fluid"}
                                        alt={"Three Dot Menu"}
                                    />
                                }
                                toggleIcon={false}
                            >
                                {!isSelfUser && (
                                    <DropdownItemUIComponent>
                                        <ButtonUIComponent
                                            className={`btn-sm btn-text pb-${isSelfUser ? 1 : 0} pt-0 px-0 text-dark no-hover`}
                                            buttonInner={
                                                <div className="d-flex align-items-center fw-semibold">
                                                    <div className="d-flex">
                                                        <img
                                                            width={20}
                                                            src={`/assets/icons/report.svg`}
                                                            className={`img-fluid`}
                                                            alt={`Report Icon`}
                                                        />
                                                    </div>
                                                    <span className="ms-2">
                                                        {t("common.report")}
                                                    </span>
                                                </div>
                                            }
                                            onClick={() => handleReport(data)}
                                        />
                                    </DropdownItemUIComponent>
                                )}
                                {isSelfUser && (
                                    <DropdownItemUIComponent>
                                        <ButtonUIComponent
                                            className={`btn-sm btn-text pb-${isSelfUser ? 1 : 0} pt-0 px-0 text-dark no-hover`}
                                            buttonInner={
                                                <div className="d-flex align-items-center fw-semibold">
                                                    <div className="d-flex">
                                                        <img
                                                            width={20}
                                                            src={`/assets/icons/report.svg`}
                                                            className={`img-fluid`}
                                                            alt={`Report Icon`}
                                                        />
                                                    </div>
                                                    <span className="ms-2">
                                                        {t("common.remove")}
                                                    </span>
                                                </div>
                                            }
                                            onClick={() => handleRemove(data)}
                                        />
                                    </DropdownItemUIComponent>
                                )}
                                {isSelfUser && (
                                    <DropdownItemUIComponent>
                                        <ButtonUIComponent
                                            className={`btn-sm btn-text pt-2 pb-1 px-0 text-dark no-hover fw-semibold`}
                                            buttonInner={t("common.markClosed")}
                                            onClick={() =>
                                                handleMarkAsClosed(data)
                                            }
                                        />
                                    </DropdownItemUIComponent>
                                )}
                            </DropdownUIComponent>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                        <div className="fs-14 text-gray-300">
                            {data.creatorName} • {data.creatorInstitutionName}
                        </div>
                        <div
                            className={
                                "badge rounded-pill fs-12 fw-regular text-bg-primary text-light"
                            }
                        >
                            {t("common.originalQuestion")}
                        </div>
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                        <div
                            className={classNames("badge rounded-pill fs-14", {
                                "bg-light-primary text-primary": data.isOpen,
                                "bg-light-danger text-red-p": !data.isOpen
                            })}
                        >
                            {!data.isOpen
                                ? `• ${t("common.closedDiscussion")}`
                                : `• ${t("common.ongoingDiscussion")}`}
                        </div>
                        <span className="fs-12 text-gray-300">
                            {dayjs(data.updatedAt || data.createdAt).format(
                                "DD MMM YYYY, hh:mm A"
                            )}
                        </span>
                    </div>
                </div>
            </div>
            {data?.attachmentUri && (
                <div className="d-flex mt-3 w-100">
                    <img
                        src={`${URLS.MEDIA_URL}${data?.attachmentUri}`}
                        alt={`Forum Image`}
                        width={"100%"}
                        style={{
                            // maxHeight: 280,
                            // objectFit: "cover",
                            borderRadius: 16
                        }}
                        className={"img-fluid"}
                    />
                </div>
            )}
            {redirection && (
                <div className="d-flex mt-4">
                    <ButtonUIComponent
                        className={`btn-sm fs-12 px-md-4 fw-semibold`}
                        buttonInner={`${t("common.joinDiscussion")} ->`}
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate(`${URLS.DASHBOARD_URL}forum/${data.id}`);
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default ForumListItemTemplate;
