import _ from "lodash";

const EmptyBoxComponent = ({ isLoading = false, data = [], message = "" }) => {
    return (
        <div
            className="py-5"
            style={{
                minHeight: 300,
                width: "100%",
                backgroundColor: "white",
                borderRadius: 32
            }}
        >
            {!isLoading && _.isEmpty(data) && (
                <div className="d-flex align-items-center w-100 h-100 justify-content-center flex-column">
                    <img
                        src={`/assets/icons/empty.svg`}
                        style={{ maxWidth: 100 }}
                        className={"img-fluid"}
                        alt={"Empty Image"}
                    />
                    <span className="fs-6 mt-4 fw-bold">
                        {message || "No Data"}
                    </span>
                </div>
            )}
        </div>
    );
};

export default EmptyBoxComponent;
