import DefaultCardLoader from "../Loader";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonUIComponent from "../../UI/Button";
import { URLS } from "../../../utils";
import UploadButton from "../../UI/Button/upload";

const CourseLayoutComponent = ({
    content = "",
    isEditable = false,
    onSave = (data, cb) => {}
}) => {
    const { t } = useTranslation();
    const [edit, setEdit] = useState(false);
    const [layoutDetails, setLayoutDetails] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLayoutDetails(content);
    }, [content]);

    const handleOnChange = (e) => {
        setLayoutDetails(e.value);
    };

    const saveInformation = async () => {
        setLoading(true);
        let obj = {
            courseLayoutImageUri: layoutDetails
        };
        if (!layoutDetails) {
            obj.isRemoveCourseLayoutImage = true;
        }
        onSave(obj, (cb) => {
            if (cb) {
                setEdit(false);
            }
            setLoading(false);
        });
    };

    return (
        <div className="card bg-white content-card">
            <div className="card-heading d-flex justify-content-between align-items-center">
                <div className="fs-5 fw-bold text-gray-600">
                    {t("common.courseLayoutDetails")}
                </div>
                {isEditable &&
                    (!edit ? (
                        <ButtonUIComponent
                            buttonInner={
                                <img
                                    src={`/assets/icons/pencil.svg`}
                                    className={`img-fluid`}
                                    alt={`Edit`}
                                />
                            }
                            className={`bg-transparent border-0 px-0 py-1`}
                            onClick={(e) => {
                                e.stopPropagation();
                                setEdit(true);
                            }}
                        />
                    ) : (
                        <ButtonUIComponent
                            buttonInner={`Save Info`}
                            isPrimary={true}
                            className={`btn-sm py-2`}
                            onClick={saveInformation}
                        />
                    ))}
            </div>
            {!edit ? (
                <div className="card-body p-4">
                    <p className="mb-0">
                        {!layoutDetails ? (
                            "No Course Layout Image"
                        ) : (
                            <img
                                className="img-fluid w-100"
                                src={`${URLS.MEDIA_URL}${layoutDetails}`}
                                alt={"Couse Layout Image"}
                                style={{ background: "#dbdbdb" }}
                            />
                        )}
                    </p>
                </div>
            ) : (
                <form className="content-card--form p-4">
                    <div className="row">
                        <div className="col-12">
                            <UploadButton
                                uploadButtonKey={`course-detail-page`}
                                btnText={`Drag and drop or click to upload image (.jpg or .png supported)`}
                                accept={`image/png,image/jpeg,image/jpg`}
                                name={"layoutDetails"}
                                onChange={handleOnChange}
                                value={layoutDetails || undefined}
                                showPreview={false}
                            />
                        </div>
                    </div>
                </form>
            )}
            <DefaultCardLoader show={loading} />
        </div>
    );
};

export default CourseLayoutComponent;
