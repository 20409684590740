import dayjs from "dayjs";
import _ from "lodash";
import { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import CustomPageHeadComponent from "../../components/common/reusable/CustomPageHead";
import { useAuthContext } from "../../context/auth-context";
import useGetCourseList from "../../hooks/useGetCourseList";
import useGetInstitutionList from "../../hooks/useGetInstitutionList";
import { UploadUserAvatar } from "../../services/common";
import { URLS } from "../../utils";
import {
    ExtractErrorMessage,
    GetAvatarColor,
    GetAvatarInitials
} from "../../utils/fn";
import DefaultCardLoader from "../../components/common/Loader";
import RecentForumActivity from "../../components/common/forum/RecentActivity";
import ImageCropper from "../../components/common/cropper";
import { useTranslation } from "react-i18next";
import ButtonUIComponent from "../../components/UI/Button";
import { useModalContext } from "../../context/modal-context";
import EditStudentProfile from "../../modules/user/modal/EditStudentProfile";
import EditTeacherProfile from "../../modules/user/modal/EditTeacherProfile";

const UserProfilePage = () => {
    const { toggle } = useModalContext();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { userObject, updateAvatar, updateProfile } = useAuthContext();
    const { list: InstitutionList } = useGetInstitutionList();
    const { list: UserCourseList } = useGetCourseList();
    const [avatar, setAvatar] = useState(undefined);

    const userAvatar = userObject?.profileImageUri || null;
    const userAvatarInitials =
        !userAvatar && GetAvatarInitials(userObject?.name);
    const userAvatarColor =
        userAvatarInitials && GetAvatarColor(userAvatarInitials);

    const handleAvatarChange = async (fileBlob) => {
        try {
            setAvatar(undefined);
            setLoading(true);
            const response = await UploadUserAvatar(
                userObject?.type == "STUDENT" ? "students" : "teachers",
                fileBlob
            );
            updateAvatar(
                response?.data?.student || response?.data?.teacher || null
            );
            document.getElementById("profileImage").value = null;
        } catch (error) {
            const { toast: toastMsg } = ExtractErrorMessage(error?.response);
            toast.error(toastMsg);
        } finally {
            setLoading(false);
        }
    };

    const handleAvatar = (e) => {
        setAvatar(e.target.files[0]);
    };

    const updateMyProfile = data => {
        updateProfile({...data, type: userObject.type });
    };

    const handleEditButtonClick = e => {
        if (userObject.type === "TEACHER") {
            toggle(<EditTeacherProfile record={userObject} updateCallback={updateMyProfile} />);
            return;
        };
        toggle(<EditStudentProfile record={userObject} type={"student"} updateCallback={updateMyProfile} />)
    };

    return (
        <Fragment>
            <CustomPageHeadComponent
                title={t("common.myProfile")}
                subtitle={""}
            />
            <div className="container mt-4 mb-5">
                <div className="row">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-12 mb-4">
                                <div className="card bg-white content-card">
                                    <div className="card-body p-4">
                                        <div
                                            className="d-flex align-items-center"
                                            style={{ gap: 20 }}
                                        >
                                            <div className="d-flex position-relative userAvatar">
                                                {userAvatar ? (
                                                    <img
                                                        src={`${URLS.MEDIA_URL}${userAvatar}`}
                                                        alt={`User Profile`}
                                                        className={`img-fluid rounded-circle`}
                                                        width={60}
                                                    />
                                                ) : (
                                                    <div
                                                        className="avatar lg"
                                                        data-color={
                                                            userAvatarColor ||
                                                            ""
                                                        }
                                                    >
                                                        {userAvatarInitials}
                                                    </div>
                                                )}
                                                <input
                                                    autoComplete={"off"}
                                                    type={"file"}
                                                    id="profileImage"
                                                    hidden
                                                    accept={`image/jpeg,image/png,image/jpg`}
                                                    name={"profileImage"}
                                                    onChange={handleAvatar}
                                                />
                                                <button
                                                    type={"button"}
                                                    onClick={(e) =>
                                                        document
                                                            .getElementById(
                                                                "profileImage"
                                                            )
                                                            .click()
                                                    }
                                                    className="btn btn-transparent p-0 m-0"
                                                    title={"Update Avatar"}
                                                >
                                                    <img
                                                        width={16}
                                                        className="img-fluid"
                                                        src={`/assets/icons/pencil.svg`}
                                                    />
                                                </button>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <strong className="fw-semibold mb-1">
                                                    {userObject?.name}
                                                </strong>
                                                <div className="d-flex align-items-center text-gray-300">
                                                    <span>
                                                        {userObject?.uidCode}
                                                    </span>
                                                    <span className="mx-2">
                                                        {"•"}
                                                    </span>
                                                    <span>
                                                        {userObject?.email}
                                                    </span>
                                                    <span className="mx-2">
                                                        {"•"}
                                                    </span>
                                                    <span>
                                                        {
                                                            userObject?.phoneNumber
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="card bg-white content-card">
                                    <div className="card-heading d-flex justify-content-between align-items-center">
                                        <div className="fs-5 fw-semibold text-gray-600">
                                            {t("common.studentDetails")}
                                        </div>
                                        <div className="d-flex">
                                            <ButtonUIComponent
                                                className={`d-flex bg-transparent p-0 border-0`}
                                                buttonInner={(
                                                    <img
                                                        src={`/assets/icons/pencil.svg`}
                                                        className={`img-fluid`}
                                                        alt={`Edit`}
                                                    />
                                                )}
                                                onClick={handleEditButtonClick}
                                            />
                                        </div>
                                    </div>
                                    <div className="card-body p-0">
                                        <ul className="list-unstyled flex-column nm-list border-0 mb-0">
                                            {!!userObject?.dateOfBirth && (
                                                <li className="py-3 fs-14">
                                                    <strong className="text-gray-600 fw-semibold">
                                                        {t("tableHead.dob")}
                                                    </strong>
                                                    <span className="ms-3 text-gray-300">
                                                        {dayjs(
                                                            userObject?.dateOfBirth
                                                        ).format("DD MMM YYYY")}
                                                    </span>
                                                </li>
                                            )}
                                            <li className="py-3 fs-14">
                                                <strong className="text-gray-600 fw-semibold">
                                                    {t("tableHead.institution")}
                                                </strong>
                                                <span className="ms-3 text-gray-300">
                                                    {
                                                        _.find(
                                                            InstitutionList ||
                                                                [],
                                                            (item) =>
                                                                item.id ===
                                                                userObject?.institutionId
                                                        )?.name
                                                    }
                                                </span>
                                            </li>
                                            {!!userObject?.address && (
                                                <li className="py-3 fs-14">
                                                    <strong className="text-gray-600 fw-semibold">
                                                        {t("tableHead.address")}
                                                    </strong>
                                                    <span className="ms-3 text-gray-300">
                                                        {userObject?.address}
                                                    </span>
                                                </li>
                                            )}
                                            {!!userObject?.gender && (
                                                <li className="py-3 fs-14">
                                                    <strong className="text-gray-600 fw-semibold">
                                                        {t("tableHead.gender")}
                                                    </strong>
                                                    <span className="ms-3 text-gray-300">
                                                        {t(
                                                            `constants.${userObject?.gender}`
                                                        )}
                                                    </span>
                                                </li>
                                            )}
                                            {!!userObject?.phoneNumber && (
                                                <li className="py-3 fs-14">
                                                    <strong className="text-gray-600 fw-semibold">
                                                        {t("tableHead.phone")}
                                                    </strong>
                                                    <span className="ms-3 text-gray-300">
                                                        {
                                                            userObject?.phoneNumber
                                                        }
                                                    </span>
                                                </li>
                                            )}
                                            {!!userObject?.guardianPhoneNumber && (
                                                <li className="py-3 fs-14">
                                                    <strong className="text-gray-600 fw-semibold">
                                                        {t(
                                                            "tableHead.guardianPhone"
                                                        )}
                                                    </strong>
                                                    <span className="ms-3 text-gray-300">
                                                        {userObject?.guardianPhoneNumber ||
                                                            "-"}
                                                    </span>
                                                </li>
                                            )}
                                            {!!userObject?.guardianName && (
                                                <li className="py-3 fs-14">
                                                    <strong className="text-gray-600 fw-semibold">
                                                        {t(
                                                            "tableHead.guardianName"
                                                        )}
                                                    </strong>
                                                    <span className="ms-3 text-gray-300">
                                                        {userObject?.guardianName ||
                                                            "-"}
                                                    </span>
                                                </li>
                                            )}
                                            {!!userObject?.guardianEmail && (
                                                <li className="py-3 fs-14">
                                                    <strong className="text-gray-600 fw-semibold">
                                                        {t(
                                                            "tableHead.guardianEmail"
                                                        )}
                                                    </strong>
                                                    <span className="ms-3 text-gray-300">
                                                        {userObject?.guardianEmail ||
                                                            "-"}
                                                    </span>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-12">
                                <div className="card bg-white content-card">
                                    <div className="card-heading d-flex justify-content-between align-items-center">
                                        <div className="fs-5 fw-semibold text-gray-600">
                                            {t("common.coursesEnrolledIn")}
                                        </div>
                                    </div>
                                    <div className="card-body p-0">
                                        <ul className="list-unstyled flex-column nm-list border-0 mb-0">
                                            {_.map(
                                                UserCourseList || [],
                                                (item, index) => {
                                                    return (
                                                        <li
                                                            key={index}
                                                            className="py-3 fs-14"
                                                        >
                                                            <strong className="text-gray-600 fw-semibold">
                                                                {index + 1}.{" "}
                                                                {item.title}
                                                            </strong>
                                                            <div className="text-gray-300 mt-1 ms-3">
                                                                {item.uidCode}
                                                            </div>
                                                        </li>
                                                    );
                                                }
                                            )}
                                            <li className="py-3 fs-14">
                                                <NavLink
                                                    className={
                                                        "text-gray-600 fw-semibold"
                                                    }
                                                    to={`${URLS.DASHBOARD_URL}`}
                                                >
                                                    {t("common.viewAllCourses")}
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-4">
                                <RecentForumActivity size="md" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {!!avatar && (
                <ImageCropper
                    imageFile={avatar}
                    aspect={1}
                    handleOnChange={handleAvatarChange}
                    onClose={(e) => setAvatar(undefined)}
                />
            )}
            <DefaultCardLoader isFixed={true} show={loading} />
        </Fragment>
    );
};

export default UserProfilePage;
