import { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import CoursePageTabs from "../../components/common/courses/CoursePageTabs";
import CustomPageHeadComponent from "../../components/common/reusable/CustomPageHead";
import ButtonUIComponent from "../../components/UI/Button";
import DropdownUIComponent, {
    DropdownItemUIComponent
} from "../../components/UI/Dropdown";
import { useModalContext } from "../../context/modal-context";
import AdminCreateEditContentModal from "../../modules/admin/modal/CreateEditContent";
import course, {
    fetchCourseDetails
} from "../../services/common/reducer/course";
import { COURSE_CONTENT_TYPE, CourseType } from "../../utils/constants";
import { KeyGenerator } from "../../utils/fn";
import EmptyBoxComponent from "../../components/common/reusable/EmptyBox";
import BackButton from "../../components/common/BackButton";
import { useTranslation } from "react-i18next";
import _ from "lodash";

const RightOptions = ({ currPath = "" }) => {
    const { toggle } = useModalContext();
    const { t } = useTranslation();
    const navigate = useNavigate();

    return currPath === "content" ? (
        <ButtonUIComponent
            onClick={() => toggle(<AdminCreateEditContentModal />)}
            className={"align-self-center btn-sm fw-semibold"}
            isPrimary={true}
            buttonInner={t("common.uploadContent")}
        />
    ) : (
        <DropdownUIComponent
            className="border-0 rounded-2 d-inline-block lh-1 align-self-center btn-sm"
            btnClassName={`btn-sm`}
            isPrimary={true}
            extraStyles={{
                style: { minWidth: 200 }
            }}
            btnText={
                <div className="d-flex align-items-center fw-semibold">
                    <span>{t("common.createAssessment")}</span>
                    <img
                        src={`/assets/icons/arrow-down-white.svg`}
                        alt={"Caret Down"}
                        className={"img-fluid ms-2"}
                    />
                </div>
            }
            toggleIcon={false}
        >
            <DropdownItemUIComponent>
                <ButtonUIComponent
                    isPrimary={false}
                    className={`bg-transparent btn-text text-dark no-hover btn-sm fs-14 px-0 pt-1`}
                    onClick={() => navigate(`create-quiz`)}
                    buttonInner={
                        <Fragment>
                            <img
                                src={`/assets/icons/quiz.svg`}
                                alt={""}
                                className={"img-fluid"}
                            />
                            <span className="ms-2 fs-12 fw-semibold">
                                {t(`constants.QUIZ`)}
                            </span>
                        </Fragment>
                    }
                />
            </DropdownItemUIComponent>
            <DropdownItemUIComponent>
                <ButtonUIComponent
                    isPrimary={false}
                    className={`bg-transparent btn-text text-dark no-hover btn-sm fs-14 px-0 pt-2 pb-0`}
                    buttonInner={
                        <Fragment>
                            <img
                                src={`/assets/icons/document.svg`}
                                alt={""}
                                className={"img-fluid"}
                            />
                            <span className="ms-2 fs-12 fw-semibold">
                                {t(`constants.WORKSHEET`)}
                            </span>
                        </Fragment>
                    }
                    onClick={() =>
                        toggle(
                            <AdminCreateEditContentModal
                                selectedTab={COURSE_CONTENT_TYPE[2]}
                            />
                        )
                    }
                />
            </DropdownItemUIComponent>
        </DropdownUIComponent>
    );
};

const CourseAdminContentComponent = () => {
    const { pathname } = useLocation();
    const regex = /\/(.*)\/(.*)\/(content|assessment)/.exec(pathname);
    const currPath = regex && regex.length > 3 && regex[3];

    const { t } = useTranslation();
    const { slug } = useParams();
    const { items, isLoading, isError } = useSelector(
        (state) => state.courseDetail
    );
    const cacheKey = KeyGenerator({ id: slug });
    const courseDetails =
        items && items[cacheKey] && items[cacheKey]?.data
            ? items[cacheKey].data
            : {};

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCourseDetails({ id: slug }));
    }, [dispatch]);

    useEffect(() => {
        if (!_.isEmpty(items) && courseDetails) {
            if (courseDetails.sourceType === CourseType.EXTERNAL.value) {
                window.open(`${courseDetails.externalSourceUrl}`, "_self");
                return;
            }
        }
    }, [items]);

    if (isError) {
        return (
            <div className="container mt-5">
                <BackButton redirectionKey="dashboard" />
                <div className="mt-3">
                    <EmptyBoxComponent message="Some error occurred, try again later." />
                </div>
            </div>
        );
    }

    return (
        <Fragment>
            <CustomPageHeadComponent
                title={courseDetails?.title || "-"}
                subtitle={`${t("common.courseId")} : ${courseDetails?.uidCode || "-"}`}
            >
                {currPath ? <RightOptions currPath={currPath} /> : ""}
            </CustomPageHeadComponent>
            <div className="mt-4">
                <CoursePageTabs />
            </div>
            <div className="container mt-4 mb-5 pb-5">
                <Outlet />
            </div>
        </Fragment>
    );
};

export default CourseAdminContentComponent;
