import _ from "lodash";
import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import DefaultCardLoader from "../../../components/common/Loader";
import ButtonUIComponent from "../../../components/UI/Button";
import UploadButton from "../../../components/UI/Button/upload";
import { useModalContext } from "../../../context/modal-context";
import { ExtractErrorMessage } from "../../../utils/fn";
import {
    CourseCategory,
    FILE_SIZE,
    LanguageCode
} from "../../../utils/constants";
import {
    CourseBundleOperation,
    updateCourseBundleList
} from "../../../services/admin/reducer/courses/bundle";
import useGetCourseList from "../../../hooks/useGetCourseList";
import MultiSelectUIComponent from "../../../components/UI/Input/multi-select";
import useGetCourseBundleList from "../../../hooks/useGetCourseBundleList";

/**
 *
 * @param {String} action => create | edit
 * @returns
 */
const CreateEditCourseBundleModal = ({ action = "create", record = {} }) => {
    const isEdit = action === "edit";
    const viewOnly = action === "view";
    const { toggle } = useModalContext();
    const { t } = useTranslation();
    const fields = [
        "uidCode",
        "view",
        "courses",
        "courseBundle",
        "lang",
        "edit",
        "create",
        "courseTitle",
        "courseThumbnail",
        "category",
        "enterCourseBundleTitle",
        "selectCategory",
        "selectCourses",
        "selectCourseBundle",
        "selectLang"
    ];
    const titles = _.map(fields, (field) => {
        return { value: t(`modal.coursesBundle.${field}`), key: field };
    }).reduce((p, c, i) => {
        p[c.key] = c.value;
        return p;
    }, {});

    const [payload, setPayload] = useState({
        uidCode: undefined,
        title: undefined,
        category: undefined,
        thumbnailImageUri: undefined,
        languageCode: undefined,
        listCourseIds: [],
        listCourseBundleIds: []
    });
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const {
        list: CourseList,
        isLoading: isLoadingCourseList,
        isError: isErrorCourseList
    } = useGetCourseList();
    const {
        list: CourseBundleList,
        isLoading: isLoadingCourseBundleList,
        isError: isErrorCourseBundleList
    } = useGetCourseBundleList();

    useEffect(() => {
        if (record && !_.isEmpty(record)) {
            let courseOptions = [];
            let courseBundleOptions = [];
            if (CourseList && !_.isEmpty(CourseList)) {
                let recordCourses = _.map(
                    record?.listAssociatedCourses || [],
                    (e) => e.courseId
                );
                courseOptions = _.filter(CourseList || [], (opts) =>
                    [...recordCourses].includes(opts.id)
                ).map((item) => ({
                    label: `${item.title} [#${item.uidCode}]`,
                    value: item.id
                }));
            }
            if (CourseBundleList && !_.isEmpty(CourseBundleList)) {
                let recordCourses = _.map(
                    record?.listAssociatedChildCourseBundles || [],
                    (e) => e.childCourseBundleId || e
                );
                courseBundleOptions = _.filter(CourseBundleList || [], (opts) =>
                    [...recordCourses].includes(opts.id)
                ).map((item) => ({
                    label: `${item.title} [#${item.uidCode}]`,
                    value: item.id
                }));
            }
            setPayload({
                ...record,
                listCourseIds: [...courseOptions],
                listCourseBundleIds: [...courseBundleOptions]
            });
        }
    }, [record, CourseList]);

    const handleOnChange = (inputs) => {
        setPayload({
            ...payload,
            [inputs.name]: inputs.value
        });
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            if (!payload.thumbnailImageUri) {
                throw new Error("Please upload thumbnail");
            }
            // if (!payload.listCourseIds || _.isEmpty(payload.listCourseIds)) {
            //     throw new Error("Select atleast one course");
            // }

            setLoading(true);
            const response = await CourseBundleOperation(
                payload,
                isEdit ? 1 : 0
            );
            // dispatch update action once this operation is finised!
            let transformedResponse = {
                ...response.data.courseBundle,
                listAssociatedCourses:
                    response?.data?.listCourseBundleAssociatedCourses || [],
                listAssociatedChildCourseBundles: response?.data?.listCourseBundleAssociatedCourseBundles || []
            };
            dispatch(
                updateCourseBundleList({
                    action,
                    data: transformedResponse
                })
            );
            toast.success(
                `Course Bundle ${isEdit ? "updated" : "added"} successfully!`
            );
            toggle();
        } catch (error) {
            const { toast: toastMsg } = ExtractErrorMessage(
                error?.response || error
            );
            toast.error(toastMsg);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Form onSubmit={submitHandler}>
            <Modal.Header style={{ padding: "24px 16px" }}>
                <Modal.Title className="fs-20">
                    {viewOnly
                        ? t(titles.view)
                        : !isEdit
                          ? t(titles.create)
                          : t(titles.edit)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                <div className="d-flex flex-column">
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                        <div className="col-md-3">
                            <label
                                htmlFor="name"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.uidCode}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <input
                                autoComplete={"off"}
                                required
                                className="form-control fs-14"
                                placeholder={t("common.enterUidCode")}
                                value={payload.uidCode || ""}
                                onChange={(e) =>
                                    handleOnChange({
                                        name: e.target.name,
                                        value: e.target.value
                                    })
                                }
                                name={"uidCode"}
                                disabled={viewOnly}
                            />
                        </div>
                    </div>
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                        <div className="col-md-3">
                            <label
                                htmlFor="name"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.courseTitle}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <input
                                autoComplete={"off"}
                                required
                                className="form-control fs-14"
                                placeholder={titles.enterCourseBundleTitle}
                                value={payload.title || ""}
                                onChange={(e) =>
                                    handleOnChange({
                                        name: e.target.name,
                                        value: e.target.value
                                    })
                                }
                                name={"title"}
                                disabled={viewOnly}
                            />
                        </div>
                    </div>
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                        <div className="col-md-3">
                            <label
                                htmlFor="name"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.courseThumbnail}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <UploadButton
                                uploadButtonKey={`course-modal`}
                                btnText={t("common.dragDropImg")}
                                accept={`image/png,image/jpeg,image/jpg`}
                                name={"thumbnailImageUri"}
                                onChange={handleOnChange}
                                value={payload.thumbnailImageUri || undefined}
                                sizeLimit={FILE_SIZE.IMAGE}
                                imgWidth={180}
                                disabled={viewOnly}
                                aspect={16 / 9}
                                allowCrop={true}
                            />
                        </div>
                    </div>
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                        <div className="col-md-3">
                            <label
                                htmlFor="category"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.category}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <select
                                required
                                className="form-select fs-14"
                                placeholder={titles.selectCategory}
                                name={"category"}
                                value={payload.category || ""}
                                onChange={(e) =>
                                    handleOnChange({
                                        name: e.target.name,
                                        value: e.target.value
                                    })
                                }
                                disabled={viewOnly}
                            >
                                <option value="">
                                    {titles.selectCategory}
                                </option>
                                {CourseCategory &&
                                    _.map(CourseCategory, (item, key) => {
                                        return (
                                            <option
                                                value={item.value}
                                                key={key}
                                            >
                                                {t(`constants.${item.value}`)}
                                            </option>
                                        );
                                    })}
                            </select>
                        </div>
                    </div>
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                        <div className="col-md-3">
                            <label
                                htmlFor="courses"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.courses}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <MultiSelectUIComponent
                                name={"listCourseIds"}
                                placeholder={titles.selectCourses}
                                value={payload.listCourseIds || []}
                                isLoading={
                                    isLoadingCourseList || isErrorCourseList
                                }
                                options={[
                                    ..._.map(CourseList || [], (item) => {
                                        return {
                                            label: `${item.title} [${item.uidCode}]`,
                                            value: item.id
                                        };
                                    })
                                ]}
                                onChange={(e) => {
                                    handleOnChange({
                                        name: "listCourseIds",
                                        value: e
                                    });
                                }}
                                disabled={viewOnly}
                            />
                        </div>
                    </div>
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                        <div className="col-md-3">
                            <label
                                htmlFor="courseBundles"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.courseBundle}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <MultiSelectUIComponent
                                name={"listCourseBundleIds"}
                                placeholder={titles.selectCourseBundle}
                                value={payload?.listCourseBundleIds || []}
                                isLoading={
                                    isLoadingCourseBundleList || isErrorCourseBundleList
                                }
                                options={[
                                    ..._.filter(CourseBundleList || [], item => (_.isEmpty(record) || record?.id !== item.id))
                                    .map((item) => {
                                        return {
                                            label: `${item.title} [${item.uidCode}]`,
                                            value: item.id
                                        };
                                    })
                                ]}
                                onChange={(e) => {
                                    handleOnChange({
                                        name: "listCourseBundleIds",
                                        value: e
                                    });
                                }}
                                disabled={viewOnly}
                            />
                        </div>
                    </div>
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center">
                        <div className="col-md-3">
                            <label
                                htmlFor="lang"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.lang}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <select
                                required
                                className="form-select fs-14"
                                placeholder={titles.selectLang}
                                name={"languageCode"}
                                value={payload.languageCode || ""}
                                onChange={(e) =>
                                    handleOnChange({
                                        name: e.target.name,
                                        value: e.target.value
                                    })
                                }
                            >
                                <option value="">{titles.selectLang}</option>
                                {LanguageCode &&
                                    _.map(LanguageCode, (item, key) => {
                                        return (
                                            <option
                                                value={item.value}
                                                key={key}
                                            >
                                                {t(`constants.${item.value}`)}
                                            </option>
                                        );
                                    })}
                            </select>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex">
                    <ButtonUIComponent
                        className={`fs-14 me-3 fw-semibold`}
                        buttonInner={t("common.cancel")}
                        type={"button"}
                        onClick={() => toggle()}
                    />
                    {!viewOnly && (
                        <ButtonUIComponent
                            isPrimary={true}
                            className={`fs-14 fw-semibold`}
                            buttonInner={
                                !isEdit ? t(titles.create) : t(titles.edit)
                            }
                            type={"submit"}
                        />
                    )}
                </div>
            </Modal.Footer>
            <DefaultCardLoader show={loading} />
        </Form>
    );
};

export default CreateEditCourseBundleModal;
