import { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import useQueryParams from "../../hooks/useSearchQuery";
import LanguageDropdown from "../../components/UI/Dropdown/language";
import { useTranslation } from "react-i18next";
import JoinRequestDropdown from "../../components/UI/Dropdown/JoinRequest";

const LandingHeader = () => {
    const { t } = useTranslation();
    const [sidebar, setSidebar] = useState(false);
    const [activeSection, setActiveSection] = useState(null);
    const observer = useRef(null);
    const hash = useQueryParams({ hash: true });
    const navigate = useNavigate();

    useEffect(() => {
        if (hash) {
            try {
                const el = document.getElementById(hash);
                setActiveSection(hash);
                el.scrollIntoView({
                    behavior: "smooth"
                });
            } catch (error) {
                setActiveSection(null);
            }
        }
    }, [hash]);

    useEffect(() => {
        //create new instance and pass a callback function
        observer.current = new IntersectionObserver(
            (entries) => {
                const visibleSection = entries.find(
                    (entry) => entry.isIntersecting
                )?.target;
                if (
                    visibleSection &&
                    visibleSection.id &&
                    [
                        "home",
                        "features",
                        "roadmap",
                        "initiative",
                        "gallery",
                        "contact-us"
                    ].includes(visibleSection.id)
                ) {
                    setActiveSection(visibleSection.id);
                }
            },
            {
                threshold: 0.2
            }
        );

        //Get custom attribute data-section from all sections
        const sections = document.querySelectorAll("[id]");
        sections.forEach((section) => {
            observer.current.observe(section);
        });

        //Cleanup function to remove observer
        return () => {
            sections.forEach((section) => {
                observer.current.unobserve(section);
            });
        };
    }, []);

    const hashHandler = (e) => {
        try {
            let clickedHash = e.target.href.split("#")?.[1];
            if (clickedHash === hash) {
                document.getElementById(clickedHash).scrollIntoView({
                    behavior: "smooth"
                });
            }
        } catch (error) {}
    };

    return (
        <header className="sticky-top">
            <div className="header-menu d-flex align-items-center">
                <div className="landing-sidebar w-100">
                    <div className="d-flex d-md-none">
                        <button
                            onClick={() => setSidebar((e) => !e)}
                            className="btn btn-sm bg-transparent border-0"
                        >
                            <img
                                src={`/assets/icons/menu.svg`}
                                className="img-fluid"
                                alt={"Menu Icon"}
                            />
                        </button>
                    </div>
                    <ul
                        className="list-unstyled d-flex flex-md-row flex-column mb-0 justify-content-center w-100"
                        data-expanded={sidebar}
                    >
                        <li>
                            <NavLink
                                onClick={hashHandler}
                                to={"/#home"}
                                className={({ isActive }) =>
                                    isActive &&
                                    (activeSection === null ||
                                        activeSection === "home")
                                        ? "active"
                                        : ""
                                }
                            >
                                {t("landing.navi.home")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                onClick={hashHandler}
                                to={"#features"}
                                className={({ isActive }) =>
                                    isActive && activeSection === "features"
                                        ? "active"
                                        : ""
                                }
                            >
                                {t("landing.navi.features")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                onClick={hashHandler}
                                to={"/#roadmap"}
                                className={({ isActive }) =>
                                    isActive && activeSection === "roadmap"
                                        ? "active"
                                        : ""
                                }
                            >
                                {t("landing.navi.roadmap")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                onClick={hashHandler}
                                to={"/#initiative"}
                                className={({ isActive }) =>
                                    isActive && activeSection === "initiative"
                                        ? "active"
                                        : ""
                                }
                            >
                                {t("landing.navi.initiative")}
                            </NavLink>
                        </li>
                        {/* <li>
                            <NavLink
                                onClick={hashHandler}
                                to={"/#gallery"}
                                className={({ isActive }) =>
                                    isActive && activeSection === "gallery"
                                        ? "active"
                                        : ""
                                }
                            >
                                {t("landing.navi.gallery")}
                            </NavLink>
                        </li> */}
                        <li>
                            <NavLink
                                onClick={hashHandler}
                                to={"/#contact-us"}
                                className={({ isActive }) =>
                                    isActive && activeSection === "contact-us"
                                        ? "active"
                                        : ""
                                }
                            >
                                {t("landing.navi.contact")}
                            </NavLink>
                        </li>
                    </ul>
                </div>
                <div className="d-flex pe-2">
                    <div className="d-flex d-md-none">
                        <LanguageDropdown />
                    </div>
                    <div className="d-flex d-md-none ms-2">
                        <JoinRequestDropdown />
                    </div>
                    <div className="d-flex d-md-none align-self-center ms-2">
                        <NavLink
                            type={"button"}
                            to={`/user/login`}
                            className={
                                "btn btn-primary btn-sm px-4 fw-semibold"
                            }
                        >
                            Login
                        </NavLink>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default LandingHeader;
