import { useState } from "react";
import ModalUIComponent from "../../components/UI/Modal";
import PrivacyModal from "./modal/privacy";
import CookieModal from "./modal/cookie";
import { useTranslation } from "react-i18next";

const LandingFooterComponent = () => {
    const { t } = useTranslation();
    const [privacy, setPrivacy] = useState(false);
    const [cookie, setCookie] = useState(false);

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <div className="d-flex flex-column">
                        <div className="fs-1 fw-bold text-light">
                            {t("landing.hero.b1.title")}
                        </div>
                        <div
                            className="fs-5 text-gray-300 mt-3"
                            dangerouslySetInnerHTML={{
                                __html: t("landing.hero.b1.subtitle").replace(
                                    /\n/,
                                    "<br/>"
                                )
                            }}
                        />
                    </div>
                </div>
                <div className="col-md-3 mt-4 mt-md-0">
                    {/* <div className="d-flex flex-column">
                        <div className="fs-4 fw-bold text-light">
                            {t("landing.footer.important_links")}
                        </div>
                        <div className="mt-2">
                            <a
                                href="javascript:void(0)"
                                className="text-gray-300 fw-bold text-decoration-none"
                                onClick={(e) => setPrivacy(true)}
                            >
                                {t("landing.footer.privacy")}
                            </a>
                        </div>
                        <div className="mt-2">
                            <a
                                href="javascript:void(0)"
                                className="text-gray-300 fw-bold text-decoration-none"
                                onClick={(e) => setCookie(true)}
                            >
                                {t("landing.footer.cookie")}
                            </a>
                        </div>
                    </div> */}
                </div>
                <div className="col-md-3 mt-4 mt-md-0">
                    <div className="d-flex flex-column">
                        <div className="fs-4 fw-bold text-light">
                            {t("landing.footer.contact")}
                        </div>
                        <div className="fs-5 mt-3 fw-bold text-light">
                            {t("landing.footer.address")}
                        </div>
                        <div
                            className="mt-2 text-gray-300"
                            dangerouslySetInnerHTML={{
                                __html: t(
                                    "landing.footer.address_value"
                                ).replace(/\n/, "<br/>")
                            }}
                        />
                        <div className="fs-5 mt-3 fw-bold text-light">
                            {t("landing.contact.email")}
                        </div>
                        <div className="mt-2 text-gray-300">
                            <a
                                href={`mailto:dprczpudupi@gmail.com`}
                                className="text-decoration-none text-gray-300"
                            >
                                dprczpudupi@gmail.com
                            </a>
                        </div>
                        <div className="fs-5 mt-3 fw-bold text-light">
                            {t("landing.footer.phone")}
                        </div>
                        <div className="mt-2 text-gray-300">
                            <a
                                href={`tel:0820-2002100`}
                                className="text-decoration-none text-gray-300"
                            >
                                0820-2002100
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-12 text-center mt-4 mt-md-0">
                    <span className="fs-12 text-gray-300">
                        Copyright © 2024 Namma Shaale. All Rights Reserved<br/>Powered by <a style={{color: "#079561"}} href="https://www.aphelia.co" target="_blank">Aphelia Innovations</a>
                    </span>
                </div>
            </div>
            <ModalUIComponent show={privacy} size="xl">
                <PrivacyModal close={() => setPrivacy(false)} />
            </ModalUIComponent>
            <ModalUIComponent show={cookie} size="xl">
                <CookieModal close={() => setCookie(false)} />
            </ModalUIComponent>
        </div>
    );
};

export default LandingFooterComponent;
