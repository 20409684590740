import classNames from "classnames";
import _ from "lodash";

const ListUIComponent = ({
    data = [],
    separator = false,
    userCheckButton = false,
    isCompleted = [],
    render = (record) => {}
}) => {
    return (
        <ul
            className={classNames(`list-unstyled flex-column nm-list`, {
                separator: separator
            })}
        >
            {_.map(data, (item, index) => {
                const isRecordCompleted =
                    !_.isEmpty(isCompleted) &&
                    userCheckButton &&
                    isCompleted.includes(item.id);
                return (
                    <li key={index}>
                        {
                            <div className="d-flex">
                                {userCheckButton && (
                                    <span className="me-3 mt-1 lh-1">
                                        <img
                                            alt={`Content check button`}
                                            width={20}
                                            src={`/assets/icons/checked-${isRecordCompleted ? "mark" : "white"}-circle.svg `}
                                        />
                                    </span>
                                )}
                                <div className="d-flex w-100">
                                    {render(
                                        item,
                                        userCheckButton,
                                        isRecordCompleted,
                                        index
                                    )}
                                </div>
                            </div>
                        }
                    </li>
                );
            })}
        </ul>
    );
};

export default ListUIComponent;
