import { useTranslation } from "react-i18next";

const LandingRoadmapComponent = () => {
    const { t } = useTranslation();

    return (
        <div className="container-lg container-fluid">
            <div className="row px-md-5">
                <div className="col-12 text-center">
                    <div className="fs-48 fw-bold text-primary head">
                        {t("landing.roadmap.title")}
                    </div>
                </div>
                <div className="col-12 mt-4 pt-4">
                    <div className="row">
                        <div className="col-12">
                            <ul className="timeline">
                                <li>
                                    <div className="d-flex flex-column align-items-center">
                                        <span className="fs-14 fw-semibold text-gray-300">
                                            {t("landing.roadmap.d1")}
                                        </span>
                                        <span
                                            className="fw-semibold text-gray-600 mt-2"
                                            dangerouslySetInnerHTML={{
                                                __html: t(
                                                    "landing.roadmap.t1"
                                                ).replace(/\n/, "<br/>")
                                            }}
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex flex-column align-items-center">
                                        <span className="fs-14 fw-semibold text-gray-300">
                                            {t("landing.roadmap.d2")}
                                        </span>
                                        <span
                                            className="fw-semibold text-gray-600 mt-2"
                                            dangerouslySetInnerHTML={{
                                                __html: t(
                                                    "landing.roadmap.t2"
                                                ).replace(/\n/, "<br/>")
                                            }}
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex flex-column align-items-center">
                                        <span className="fs-14 fw-semibold text-gray-300">
                                            {t("landing.roadmap.d3")}
                                        </span>
                                        <span
                                            className="fw-semibold text-gray-600 mt-2"
                                            dangerouslySetInnerHTML={{
                                                __html: t(
                                                    "landing.roadmap.t3"
                                                ).replace(/\n/, "<br/>")
                                            }}
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex flex-column align-items-center">
                                        <span className="fs-14 fw-semibold text-gray-300">
                                            {t("landing.roadmap.d4")}
                                        </span>
                                        <span
                                            className="fw-semibold text-gray-600 mt-2"
                                            dangerouslySetInnerHTML={{
                                                __html: t(
                                                    "landing.roadmap.t4"
                                                ).replace(/\n/, "<br/>")
                                            }}
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingRoadmapComponent;
