import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import UserCourseSectionComponent from "../../../components/user/course/Section";
import CustomPageHeadComponent from "../../../components/common/reusable/CustomPageHead";
import DefaultCardLoader from "../../../components/common/Loader";
import { fetchCourseBundleById } from "../../../services/admin/reducer/courses/bundle";
import { useTranslation } from "react-i18next";
import { CourseCategory } from "../../../utils/constants";

const UserCourseBundlePageComponent = () => {
    const { t } = useTranslation();
    const {
        bundle: courseBundleList,
        isLoading,
        isError
    } = useSelector((state) => state.coursesBundle);
    const { id } = useParams();
    const data = courseBundleList[id];
    const dispatch = useDispatch();
    const [transformedCourses, setTransformedCourses] = useState([]);

    useEffect(() => {
        dispatch(fetchCourseBundleById(id));
    }, [id, dispatch]);

    useEffect(() => {
        let listObj = {};
        if (data && !_.isEmpty(data)) {
            listObj = dataReducer(data?.listCourses, data?.listChildCourseBundles);
            setTransformedCourses(listObj);
        }

        return () => {
            setTransformedCourses({});
        };
    }, [data]);

    const dataReducer = (data = [], bundle = []) => {
        let fuser = [];
        if (Array.isArray(data)) { 
            fuser = fuser.concat(data);
        };
        if (Array.isArray(bundle)) {
            fuser = fuser.concat(bundle);
        }
        return _.reduce(
            fuser,
            (p, c) => {
                if (c.category in p) {
                    p[c.category].push(c);
                } else {
                    p[c.category] = [c];
                }
                return p;
            },
            {}
        );
    }

    return (
        <div className="container pt-md-3 mb-5 pb-4 pb-md-5">
            <CustomPageHeadComponent
                bundle={true}
                title={data?.title || "-"}
                subtitle={`${t("common.courseBundleId")} : ${data?.uidCode || "-"}`}
            />
            <div className="container">
                {isLoading && (
                    <UserCourseSectionComponent
                        title={""}
                        items={[]}
                        loading={true}
                        fromBundle={true}
                    />
                )}
                {CourseCategory.ACADEMIC.value in transformedCourses && (
                    <UserCourseSectionComponent
                        title={t(`constants.${CourseCategory.ACADEMIC.value}`)}
                        items={
                            transformedCourses[CourseCategory.ACADEMIC.value] ||
                            []
                        }
                        loading={isLoading}
                        fromBundle={true}
                    />
                )}
                {CourseCategory.COMPETITIVE_EXAM.value in
                    transformedCourses && (
                    <UserCourseSectionComponent
                        title={t(
                            `constants.${CourseCategory.COMPETITIVE_EXAM.value}`
                        )}
                        items={
                            transformedCourses[
                                CourseCategory.COMPETITIVE_EXAM.value
                            ] || []
                        }
                        loading={isLoading}
                        fromBundle={true}
                    />
                )}
                {CourseCategory.SKILL_DEVELOPMENT.value in
                    transformedCourses && (
                    <UserCourseSectionComponent
                        title={t(
                            `constants.${CourseCategory.SKILL_DEVELOPMENT.value}`
                        )}
                        items={
                            transformedCourses[
                                CourseCategory.SKILL_DEVELOPMENT.value
                            ] || []
                        }
                        loading={isLoading}
                        fromBundle={true}
                    />
                )}
                {CourseCategory.E_LIBRARY.value in transformedCourses && (
                    <UserCourseSectionComponent
                        title={t(`constants.${CourseCategory.E_LIBRARY.value}`)}
                        items={
                            transformedCourses[
                                CourseCategory.E_LIBRARY.value
                            ] || []
                        }
                        loading={isLoading}
                        fromBundle={true}
                    />
                )}
            </div>
            <DefaultCardLoader isFixed={true} show={isLoading} />
        </div>
    );
};

export default UserCourseBundlePageComponent;
