import _ from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ForumListItemTemplate from "../../../components/common/forum/ListItem";
import EmptyBoxComponent from "../../../components/common/reusable/EmptyBox";
import ListUIComponent from "../../../components/UI/List";
import ForumPostBarComponent from "../../../components/user/forum/Post";
import {
    fetchCourseForumData,
    MarkAsClosedForumTopic,
    removeForumTopic,
    RemoveForumTopic,
    setForumPageParams,
    unmountForumData,
    updateForumTopic
} from "../../../services/common/reducer/forum";
import {
    checkNextPage,
    ExtractErrorMessage,
    KeyGenerator
} from "../../../utils/fn";
import { PAGE_LIMIT } from "../../../utils/constants";
import DefaultCardLoader from "../../../components/common/Loader";
import { useAuthContext } from "../../../context/auth-context";
import { toast } from "react-toastify";
import { useModalContext } from "../../../context/modal-context";
import ReportModal from "../../admin/modal/Report";
import RecentForumActivity from "../../../components/common/forum/RecentActivity";
import { useTranslation } from "react-i18next";

const UserCourseForumComponent = () => {
    const { t } = useTranslation();
    const { toggle } = useModalContext();
    const { userObject } = useAuthContext();
    const { slug } = useParams();
    const [loading, setLoading] = useState(false);
    const { items, isLoading, isError, pageNumber, createdAtFirstItem } =
        useSelector((state) => state.forumData);
    const dispatch = useDispatch();

    const cacheKey = KeyGenerator({ id: slug });
    const courseForumData = items && items[cacheKey] ? items[cacheKey] : {};
    const hasNextPage = checkNextPage(courseForumData?.data || [], pageNumber);

    useEffect(() => {
        dispatch(
            fetchCourseForumData({
                id: slug,
                pageNumber: pageNumber || 1,
                createdAtFirstItem: createdAtFirstItem || ""
            })
        );
    }, [dispatch, pageNumber, createdAtFirstItem]);

    useEffect(() => {
        return () => {
            dispatch(unmountForumData());
        };
    }, [dispatch]);

    const handleReport = (record) => {
        toggle(<ReportModal record={record} />);
    };

    const handlePagination = () => {
        dispatch(
            setForumPageParams({
                id: slug,
                pageNumber: (pageNumber || 1) + 1,
                createdAtFirstItem: courseForumData?.data[0]?.createdAt
            })
        );
    };

    const handleForumTopicRemoval = useCallback(
        async (record) => {
            const sure = window.confirm(t("common.removeForumConfirmTopic"));
            if (!sure) return;
            try {
                setLoading(true);
                await RemoveForumTopic(record.id);
                dispatch(
                    removeForumTopic({
                        meta: { id: slug },
                        id: record.id
                    })
                );
                toast.success(`Forum - ${record.title}, Removed successfully!`);
            } catch (error) {
                const { toast: toastMsg } = ExtractErrorMessage(
                    error?.response
                );
                toast.error(toastMsg);
            } finally {
                setLoading(false);
            }
        },
        [slug]
    );

    const handleMarkAsClosed = useCallback(
        async (record) => {
            try {
                setLoading(true);
                const response = await MarkAsClosedForumTopic(
                    record.id,
                    record.title
                );
                dispatch(
                    updateForumTopic({
                        meta: { id: slug },
                        data: response.data
                    })
                );
                toast.success(`Forum - ${record.title}, Closed successfully!`);
            } catch (error) {
                const { toast: toastMsg } = ExtractErrorMessage(
                    error?.response
                );
                toast.error(toastMsg);
            } finally {
                setLoading(false);
            }
        },
        [slug]
    );

    return (
        <div className="row mt-3">
            <div className="col-md-9">
                <ForumPostBarComponent />
            </div>
            {!isLoading && _.isEmpty(courseForumData?.data) ? (
                <div className="col-md-9">
                    <EmptyBoxComponent isLoading={false} data={[]} />
                </div>
            ) : (
                <div className="col-md-9 scrollableDiv">
                    <InfiniteScroll
                        dataLength={courseForumData?.data?.length || 0}
                        next={handlePagination}
                        hasMore={hasNextPage}
                        // hasMore={
                        //     !_.isEmpty(courseForumData?.data || 0)
                        //         ? courseForumData.data.length % PAGE_LIMIT === 0
                        //         : false
                        // }
                        scrollableTarget="scrollableDiv"
                    >
                        <ListUIComponent
                            data={courseForumData?.data || []}
                            separator={true}
                            render={(record) => (
                                <ForumListItemTemplate
                                    data={record}
                                    redirection={true}
                                    userObject={userObject}
                                    handleMarkAsClosed={handleMarkAsClosed}
                                    handleReport={handleReport}
                                    handleRemove={handleForumTopicRemoval}
                                />
                            )}
                        />
                    </InfiniteScroll>
                </div>
            )}
            <div className="col-md-3">
                <RecentForumActivity />
            </div>
            <DefaultCardLoader isFixed={true} show={isLoading || loading} />
        </div>
    );
};

export default UserCourseForumComponent;
