import _ from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BackButton from "../../../components/common/BackButton";
import DefaultCardLoader from "../../../components/common/Loader";
import QuizTemplate from "../../../components/common/quiz/Quiz";
import ButtonUIComponent from "../../../components/UI/Button";
import { updateCourseAssessment } from "../../../services/common/reducer/course";
import {
    CourseAssessmentHandler,
    FetchSingleAssessment
} from "../../../services/common/reducer/course/content";
import { COURSE_CONTENT_TYPE } from "../../../utils/constants";
import { ExtractErrorMessage, TransformQuizData } from "../../../utils/fn";
import { useTranslation } from "react-i18next";

const AdminQuizComponent = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [questions, setQuestions] = useState([]);
    const [createCtr, setCreateCtr] = useState(0);
    const [loading, setLoading] = useState(false);
    const { slug, id } = useParams();
    const [payload, setPayload] = useState({
        title: "",
        description: ""
    });
    const dispatch = useDispatch();
    const isEdit = !!id;

    useEffect(() => {
        if (id) {
            fetchAssessment();
        }
    }, [dispatch, id]);

    const fetchAssessment = async () => {
        try {
            setLoading(true);
            const response = await FetchSingleAssessment(
                COURSE_CONTENT_TYPE[4],
                id
            );
            setPayload(response?.data?.data?.courseAssessment || {});
            let quizQuestions = TransformQuizData(
                response?.data?.data?.courseAssessment
            );
            setQuestions(quizQuestions.listQuizQuestions);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    const handleOnChange = (inputs) => {
        setPayload({
            ...payload,
            [inputs.name]: inputs.value
        });
    };

    const onSubmit = async (items) => {
        try {
            const action = isEdit ? 1 : 0;
            setLoading(true);
            await new Promise((resolve) =>
                setTimeout(() => resolve(true), 500)
            );
            let data = TransformQuizData(
                {
                    ...payload,
                    listQuizQuestions: items
                },
                true
            );

            const response = await CourseAssessmentHandler(
                {
                    ...data,
                    courseId: slug,
                    id
                },
                action
            );

            dispatch(
                updateCourseAssessment({
                    meta: { id: slug },
                    data: isEdit
                        ? response?.data
                        : response?.data?.courseAssessment,
                    action
                })
            );

            navigate(`/courses/${slug}/assessment`);
            toast.success(
                isEdit ? `Quiz updated successfully` : `Quiz added successfully`
            );
        } catch (error) {
            const { toast: toastMsg } = ExtractErrorMessage(error?.response);
            toast.error(toastMsg);
        } finally {
            setLoading(false);
        }
    };

    const onSubmitHandler = (e) => {
        e.preventDefault();
        setCreateCtr((e) => e + 1);
    };

    return (
        <div className="container mt-4 mb-5">
            <Form onSubmit={onSubmitHandler}>
                <div className="row">
                    <div className="col-lg-7 col-md-10">
                        <div className="d-flex w-100 justify-content-between">
                            <BackButton
                                btnText={t("common.backToAssessment")}
                            />
                            <ButtonUIComponent
                                className={`btn-sm`}
                                isPrimary={true}
                                type={"submit"}
                                buttonInner={t(
                                    isEdit
                                        ? "common.updateQuiz"
                                        : "common.createQuiz"
                                )}
                                // onClick={() => setCreateCtr((e) => e + 1)}
                            />
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-lg-7 col-md-10 order-2 order-lg-1 mb-4">
                        <div
                            className="bg-white card"
                            style={{
                                borderRadius: 32,
                                border: `1px solid #efefef`
                            }}
                        >
                            <div
                                className="d-flex flex-column pb-0 px-3 pt-4"
                                style={{ paddingBottom: 0 }}
                            >
                                <div className="col-12 mt-2 mt-md-0">
                                    <input
                                        autoComplete={"off"}
                                        required
                                        className="form-control fs-14"
                                        placeholder={t("common.enterQuizTitle")}
                                        value={payload.title || ""}
                                        onChange={(e) =>
                                            handleOnChange({
                                                name: e.target.name,
                                                value: e.target.value
                                            })
                                        }
                                        name={"title"}
                                    />
                                </div>
                            </div>
                            <div className="form-row d-flex flex-column">
                                <div className="col-12 mt-2 mt-md-0">
                                    <textarea
                                        rows={2}
                                        className={`form-control fs-14`}
                                        placeholder={t(
                                            "common.enterQuizDescription"
                                        )}
                                        required
                                        name={"description"}
                                        onChange={(e) =>
                                            handleOnChange({
                                                name: e.target.name,
                                                value: e.target.value
                                            })
                                        }
                                        value={payload.description || ""}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-10 order-2 order-lg-1">
                        <QuizTemplate
                            questions={questions}
                            isEditable={!isEdit}
                            isAdmin={true}
                            createCtr={createCtr}
                            onSubmit={onSubmit}
                            onChange={(items) => setQuestions(items)}
                        />
                    </div>
                    {/* <div
                        className="d-none col-lg-4 offset-lg-1 col-md-10 bg-white align-self-start border-ef order-1 order-lg-2 mb-4 mb-lg-0 px-0"
                        style={{ borderRadius: 32 }}
                    >
                        <div className="form-row d-flex flex-column border-bottom">
                            <div className="col-12 mb-2">
                                <label
                                    htmlFor="name"
                                    className="fs-14 text-gray-300 fw-semibold"
                                >
                                    {"Title"}
                                </label>
                            </div>
                            <div className="col-12 mt-2 mt-md-0">
                                <input
autoComplete={"off"}
                                    required
                                    className="form-control fs-14"
                                    placeholder={`Enter Quiz Title`}
                                    value={payload.title || ""}
                                    onChange={(e) =>
                                        handleOnChange({
                                            name: e.target.name,
                                            value: e.target.value
                                        })
                                    }
                                    name={"title"}
                                />
                            </div>
                        </div>
                        <div className="form-row d-flex flex-column">
                            <div className="col-12 mb-2">
                                <label
                                    htmlFor="name"
                                    className="fs-14 text-gray-300 fw-semibold"
                                >
                                    {"Description"}
                                </label>
                            </div>
                            <div className="col-12 mt-2 mt-md-0">
                                <textarea
                                    rows={2}
                                    className={`form-control fs-14`}
                                    placeholder={`Enter Description`}
                                    required
                                    name={"description"}
                                    onChange={(e) =>
                                        handleOnChange({
                                            name: e.target.name,
                                            value: e.target.value
                                        })
                                    }
                                    value={payload.description || ""}
                                />
                            </div>
                        </div>
                    </div> */}
                </div>
            </Form>
            <DefaultCardLoader isFixed show={loading} />
        </div>
    );
};

export default AdminQuizComponent;
