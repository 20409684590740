import ModalUIComponent from "../components/UI/Modal";
import { createContext, useState, useContext } from "react";

const ModalContext = createContext({
    show: false,
    toggle: (child, options = {}) => {}
});

export const ModalContextProvider = (props) => {
    const [show, setShow] = useState(false);
    const [options, setOptions] = useState({});
    const [children, setChildren] = useState(undefined);

    const toggle = (child = null, options = {}) => {
        if (show) {
            setShow(false);
            return;
        }
        setChildren(child);
        setShow(true);
        setOptions({...options});
    };

    return (
        <ModalContext.Provider
            value={{
                show,
                toggle
            }}
        >
            {props.children}
            <ModalUIComponent
                onClose={toggle}
                show={show}
                {...options}
            >
                {children}
            </ModalUIComponent>
        </ModalContext.Provider>
    );
};

export const useModalContext = () => useContext(ModalContext);
