import _ from "lodash";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useQueryParams from "../../../hooks/useSearchQuery";
import { setLiveFilters } from "../../../services/admin/reducer/liveClass";
import SearchBarComponent from "../../UI/Search";
import { useTranslation } from "react-i18next";

const EventListFilter = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const query = useQueryParams();
    const navigate = useNavigate();

    const handleSearch = (search) => {
        let payload = { ...query, search };
        if (!payload.search || _.isEmpty(payload.search)) {
            delete payload.search;
        }
        navigate({
            search: queryString.stringify(payload)
        });
        dispatch(setLiveFilters(payload));
    };

    return (
        <div className="row my-3">
            <div className="col-md-4">
                <SearchBarComponent
                    placeholder={t("formFields.searchLiveClasses")}
                    onChange={handleSearch}
                />
            </div>
        </div>
    );
};

export default EventListFilter;
