import classNames from "classnames";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DefaultCardLoader from "../../../components/common/Loader";
import ButtonUIComponent from "../../../components/UI/Button";
import UploadButton from "../../../components/UI/Button/upload";
import { useModalContext } from "../../../context/modal-context";
import {
    updateCourseAssessment,
    updateCourseContent
} from "../../../services/common/reducer/course";
import {
    CourseAssessmentHandler,
    CourseContentHandler
} from "../../../services/common/reducer/course/content";
import { COURSE_CONTENT_TYPE, FILE_SIZE } from "../../../utils/constants";
import { ExtractErrorMessage } from "../../../utils/fn";

const DocumentFormComponent = ({
    payload = {
        title: undefined,
        description: undefined,
        documentUri: undefined
    },
    updatePayload = (data) => {}
}) => {
    const { t } = useTranslation();
    const fields = [
        "documentTitle",
        "documentDescription",
        "documentFile",
        "enterDocumentTitle",
        "enterDocumentDescription"
    ];
    const titles = _.map(fields, (field) => {
        return { value: t(`modal.content.${field}`), key: field };
    }).reduce((p, c, i) => {
        p[c.key] = c.value;
        return p;
    }, {});

    const handleOnChange = (inputs) => {
        updatePayload({
            ...payload,
            [inputs.name]: inputs.value
        });
    };

    return (
        <div className="d-flex flex-column">
            <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                <div className="col-md-3">
                    <label
                        htmlFor="name"
                        className="fs-14 text-gray-300 fw-semibold"
                    >
                        {titles.documentTitle}
                    </label>
                </div>
                <div className="col-md-9 mt-2 mt-md-0">
                    <input
                        autoComplete={"off"}
                        required
                        className="form-control fs-14"
                        placeholder={titles.enterDocumentTitle}
                        value={payload.title || ""}
                        onChange={(e) =>
                            handleOnChange({
                                name: e.target.name,
                                value: e.target.value
                            })
                        }
                        name={"title"}
                    />
                </div>
            </div>
            <div className="form-row d-flex flex-column flex-md-row border-bottom">
                <div className="col-md-3">
                    <label
                        htmlFor="name"
                        className="fs-14 text-gray-300 fw-semibold"
                    >
                        {titles.documentDescription}
                    </label>
                </div>
                <div className="col-md-9 mt-2 mt-md-0">
                    <textarea
                        rows={4}
                        className={`form-control fs-14`}
                        placeholder={titles.enterDocumentDescription}
                        required
                        name={"description"}
                        onChange={(e) =>
                            handleOnChange({
                                name: e.target.name,
                                value: e.target.value
                            })
                        }
                        value={payload.description || ""}
                    />
                </div>
            </div>
            <div className="form-row d-flex flex-column flex-md-row">
                <div className="col-md-3">
                    <label
                        htmlFor="name"
                        className="fs-14 text-gray-300 fw-semibold"
                    >
                        {titles.documentFile}
                    </label>
                </div>
                <div className="col-md-9 mt-2 mt-md-0">
                    <UploadButton
                        uploadButtonKey={100}
                        className={`py-5 px-md-5 w-100`}
                        btnText={
                            <div className="d-flex flex-column align-items-center justify-content-center">
                                <img
                                    src={`/assets/icons/export.svg`}
                                    alt={"Export"}
                                    className={"img-fluid"}
                                />
                                <span className="text-primary mt-3">
                                    {t("common.dragDrop")}
                                </span>
                            </div>
                        }
                        imgWidth={100}
                        accept={`application/pdf`}
                        name={"documentUri"}
                        onChange={handleOnChange}
                        value={payload.documentUri || undefined}
                        sizeLimit={FILE_SIZE.DOCUMENT}
                    />
                </div>
            </div>
        </div>
    );
};

const VideoFormComponent = ({
    payload = {
        title: "",
        description: "",
        videoUri: ""
    },
    updatePayload = (data) => {}
}) => {
    const [yid, setYID] = useState(undefined);
    const { t } = useTranslation();
    const fields = [
        "videoTitle",
        "videoDescription",
        "videoLink",
        "enterVideoTitle",
        "enterVideoDescription",
        "enterVideoLink"
    ];
    const titles = _.map(fields, (field) => {
        return { value: t(`modal.video.${field}`), key: field };
    }).reduce((p, c, i) => {
        p[c.key] = c.value;
        return p;
    }, {});

    useEffect(() => {
        if (payload?.videoUri) {
            let url = payload.videoUri.split("?");
            let v = url.length > 1 ? url[1] : null;
            const keys = new URLSearchParams(v);
            let vid = keys.get("v");
            setYID(vid || "");
        }
    }, [payload.videoUri]);

    const handleOnChange = (inputs) => {
        updatePayload({
            ...payload,
            [inputs.name]: inputs.value
        });
    };

    return (
        <div className="d-flex flex-column">
            <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                <div className="col-md-3">
                    <label
                        htmlFor="name"
                        className="fs-14 text-gray-300 fw-semibold"
                    >
                        {titles.videoTitle}
                    </label>
                </div>
                <div className="col-md-9 mt-2 mt-md-0">
                    <input
                        autoComplete={"off"}
                        required
                        className="form-control fs-14"
                        placeholder={titles.enterVideoTitle}
                        value={payload.title || ""}
                        onChange={(e) =>
                            handleOnChange({
                                name: e.target.name,
                                value: e.target.value
                            })
                        }
                        name={"title"}
                    />
                </div>
            </div>
            <div className="form-row d-flex flex-column flex-md-row border-bottom">
                <div className="col-md-3">
                    <label
                        htmlFor="name"
                        className="fs-14 text-gray-300 fw-semibold"
                    >
                        {titles.videoDescription}
                    </label>
                </div>
                <div className="col-md-9 mt-2 mt-md-0">
                    <textarea
                        rows={4}
                        className={`form-control fs-14`}
                        placeholder={titles.enterVideoDescription}
                        required
                        name={"description"}
                        onChange={(e) =>
                            handleOnChange({
                                name: e.target.name,
                                value: e.target.value
                            })
                        }
                        value={payload.description || ""}
                    />
                </div>
            </div>
            <div className="form-row d-flex flex-column flex-md-row">
                <div className="col-md-3">
                    <label
                        htmlFor="name"
                        className="fs-14 text-gray-300 fw-semibold"
                    >
                        {titles.videoLink}
                    </label>
                </div>
                <div className="col-md-9 mt-2 mt-md-0">
                    <input
                        autoComplete={"off"}
                        required
                        className="form-control fs-14"
                        placeholder={titles.enterVideoLink}
                        value={payload.videoUri || ""}
                        onChange={(e) =>
                            handleOnChange({
                                name: e.target.name,
                                value: e.target.value
                            })
                        }
                        type={"url"}
                        name={"videoUri"}
                    />
                    {yid && (
                        <iframe
                            width={"100%"}
                            height="200"
                            className={"mt-4"}
                            // src={`https://www.youtube.com/embed/${yid};controls=0`}
                            src={`https://www.youtube.com/embed/${yid}?rel=0`}
                            title="YouTube Video Player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

const WorkSheetComponent = ({
    payload = {
        description: undefined,
        title: undefined,
        worksheetDocumentUri: undefined,
        answerKeyDocumentUri: ""
    },
    updatePayload = (data) => {}
}) => {
    const { t } = useTranslation();
    const fields = [
        "title",
        "document",
        "answerKey",
        "description",
        "enterWorksheetTitle",
        "enterWorksheetDescription"
    ];
    const titles = _.map(fields, (field) => {
        return { value: t(`modal.worksheet.${field}`), key: field };
    }).reduce((p, c, i) => {
        p[c.key] = c.value;
        return p;
    }, {});

    const handleOnChange = (inputs) => {
        updatePayload({
            ...payload,
            [inputs.name]: inputs.value
        });
    };

    return (
        <div className="d-flex flex-column">
            <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                <div className="col-md-3">
                    <label
                        htmlFor="name"
                        className="fs-14 text-gray-300 fw-semibold"
                    >
                        {titles.title}
                    </label>
                </div>
                <div className="col-md-9 mt-2 mt-md-0">
                    <input
                        autoComplete={"off"}
                        required
                        className="form-control fs-14"
                        placeholder={titles.enterWorksheetTitle}
                        value={payload.title || ""}
                        onChange={(e) =>
                            handleOnChange({
                                name: e.target.name,
                                value: e.target.value
                            })
                        }
                        name={"title"}
                    />
                </div>
            </div>
            <div className="form-row d-flex flex-column flex-md-row border-bottom">
                <div className="col-md-3">
                    <label
                        htmlFor="name"
                        className="fs-14 text-gray-300 fw-semibold"
                    >
                        {titles.description}
                    </label>
                </div>
                <div className="col-md-9 mt-2 mt-md-0">
                    <textarea
                        rows={4}
                        className={`form-control fs-14`}
                        placeholder={titles.enterWorksheetDescription}
                        required
                        name={"description"}
                        onChange={(e) =>
                            handleOnChange({
                                name: e.target.name,
                                value: e.target.value
                            })
                        }
                        value={payload.description || ""}
                    />
                </div>
            </div>
            <div className="form-row d-flex flex-column flex-md-row border-bottom">
                <div className="col-md-3">
                    <label
                        htmlFor="name"
                        className="fs-14 text-gray-300 fw-semibold"
                    >
                        {titles.document}
                    </label>
                </div>
                <div className="col-md-9 mt-2 mt-md-0">
                    <UploadButton
                        uploadButtonKey={100}
                        className={`py-5 px-md-5 w-100`}
                        btnText={
                            <div className="d-flex flex-column align-items-center justify-content-center">
                                <img
                                    src={`/assets/icons/export.svg`}
                                    alt={"Export"}
                                    className={"img-fluid"}
                                />
                                <span className="text-primary mt-3">
                                    {t("common.dragDrop")}
                                </span>
                            </div>
                        }
                        imgWidth={100}
                        accept={`application/pdf`}
                        name={"worksheetDocumentUri"}
                        onChange={handleOnChange}
                        value={payload?.worksheetDocumentUri || undefined}
                        sizeLimit={FILE_SIZE.DOCUMENT}
                    />
                </div>
            </div>
            <div className="form-row d-flex flex-column flex-md-row">
                <div className="col-md-3">
                    <label
                        htmlFor="name"
                        className="fs-14 text-gray-300 fw-semibold"
                    >
                        {titles.answerKey}
                    </label>
                </div>
                <div className="col-md-9 mt-2 mt-md-0">
                    <UploadButton
                        uploadButtonKey={101}
                        className={`py-5 px-md-5 w-100`}
                        btnText={
                            <div className="d-flex flex-column align-items-center justify-content-center">
                                <img
                                    src={`/assets/icons/export.svg`}
                                    alt={"Export"}
                                    className={"img-fluid"}
                                />
                                <span className="text-primary mt-3">
                                    {t("common.dragDrop")}
                                </span>
                            </div>
                        }
                        imgWidth={100}
                        accept={`application/pdf`}
                        name={"answerKeyDocumentUri"}
                        onChange={handleOnChange}
                        value={payload?.answerKeyDocumentUri || undefined}
                        sizeLimit={FILE_SIZE.DOCUMENT}
                    />
                </div>
            </div>
        </div>
    );
};

const AdminCreateEditContentModal = ({
    courseInfo = {
        title: "",
        uidCode: ""
    },
    data = {
        title: "",
        description: "",
        videoUri: "",
        documentUri: "",
        answerKeyDocumentUri: "",
        worksheetDocumentUri: ""
    },
    fixed = false,
    selectedTab = COURSE_CONTENT_TYPE[0]
}) => {
    const isEdit = !!data?.id;
    const [loading, setLoading] = useState(false);
    const { toggle } = useModalContext();
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(
        selectedTab || COURSE_CONTENT_TYPE[0]
    );
    const typeOfContent =
        activeTab === COURSE_CONTENT_TYPE[2]
            ? t("common.courseAssessment")
            : t("common.content");
    const [payload, setPayload] = useState({
        title: "",
        description: "",
        videoUri: "",
        documentUri: "",
        answerKeyDocumentUri: "",
        worksheetDocumentUri: ""
    });
    const { slug } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!_.isEmpty(data)) {
            setPayload(data);
        }
    }, []);

    const handleTab = (key) => {
        if (fixed) return;
        setActiveTab(key);
    };

    const submissionHandler = async (e) => {
        e.preventDefault();
        try {
            const action = data?.id ? 1 : 0;
            setLoading(true);

            if (
                [COURSE_CONTENT_TYPE[0], COURSE_CONTENT_TYPE[1]].includes(
                    activeTab
                )
            ) {
                // create | update content
                const response = await CourseContentHandler(
                    {
                        ...payload,
                        courseId: slug,
                        type: activeTab
                    },
                    action
                );

                // update store
                dispatch(
                    updateCourseContent({
                        meta: { id: slug },
                        data: response.data,
                        action
                    })
                );
            } else {
                const response = await CourseAssessmentHandler(
                    {
                        ...payload,
                        courseId: slug,
                        type: activeTab
                    },
                    action
                );

                // update store
                dispatch(
                    updateCourseAssessment({
                        meta: { id: slug },
                        data: data?.id
                            ? response?.data
                            : response?.data?.courseAssessment,
                        action
                    })
                );
            }

            // close the modal
            toggle();
        } catch (error) {
            const { toast: toastMsg } = ExtractErrorMessage(error.response);
            toast.error(toastMsg);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Form onSubmit={submissionHandler}>
            <Modal.Header style={{ padding: "24px 16px" }}>
                <Modal.Title>
                    <div className="d-flex flex-column">
                        <div className="fs-20 text-gray-600">
                            {courseInfo.title}
                        </div>
                        <div className="fs-14 text-gray-300">
                            {t("common.courseId")}: {courseInfo.uidCode}
                        </div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                <ul className="nav nav-tabs w-100 bg-white">
                    <li className="nav-item w-50 text-center">
                        <a
                            className={classNames(`nav-link`, {
                                active: activeTab === COURSE_CONTENT_TYPE[0]
                            })}
                            href={`javascript:void(0)`}
                            onClick={() => handleTab(COURSE_CONTENT_TYPE[0])}
                        >
                            {t("common.document")}
                        </a>
                    </li>
                    <li className="nav-item w-50 text-center">
                        <a
                            className={classNames(`nav-link`, {
                                active: activeTab === COURSE_CONTENT_TYPE[1]
                            })}
                            href={`javascript:void(0)`}
                            onClick={() => handleTab(COURSE_CONTENT_TYPE[1])}
                        >
                            {t("common.video")}
                        </a>
                    </li>
                    <li className="nav-item w-50 text-center">
                        <a
                            className={classNames(`nav-link`, {
                                active: activeTab === COURSE_CONTENT_TYPE[2]
                            })}
                            href={`javascript:void(0)`}
                            onClick={() => handleTab(COURSE_CONTENT_TYPE[2])}
                        >
                            {t("common.worksheet")}
                        </a>
                    </li>
                </ul>
                {activeTab === COURSE_CONTENT_TYPE[0] ? (
                    <DocumentFormComponent
                        payload={payload}
                        updatePayload={setPayload}
                    />
                ) : activeTab === COURSE_CONTENT_TYPE[1] ? (
                    <VideoFormComponent
                        payload={payload}
                        updatePayload={setPayload}
                    />
                ) : (
                    <WorkSheetComponent
                        payload={payload}
                        updatePayload={setPayload}
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex">
                    <ButtonUIComponent
                        className={`fs-14 fw-semibold me-3`}
                        buttonInner={t("common.cancel")}
                        type={"button"}
                        onClick={() => toggle()}
                    />
                    <ButtonUIComponent
                        isPrimary={true}
                        className={`fs-14 fw-semibold`}
                        buttonInner={`${t(isEdit ? "common.update" : "common.add")} ${typeOfContent}`}
                        type={"submit"}
                    />
                </div>
            </Modal.Footer>
            <DefaultCardLoader show={loading} />
        </Form>
    );
};

export default AdminCreateEditContentModal;
