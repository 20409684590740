import Axios from "axios";
import { RECENT_FORUM_KEY, URLS } from "../../utils";
import { getTokenFromStorage } from "../../utils/fn";
import _ from "lodash";
import { UserType } from "../../utils/constants";

export const FetchCalendarData = async (params) => {
    let qp = new URLSearchParams(params).toString();
    qp = qp ? `?${qp}` : "";
    return Axios.get(`${URLS.API_URL}v1/events${qp}`, {
        headers: {
            Authorization: getTokenFromStorage()
        }
    });
};

export const RetrieveRecentActivity = (callback) => {
    let key = RECENT_FORUM_KEY,
        cache = new Map();
    try {
        let items = JSON.parse(localStorage.getItem(key));
        if (items && !_.isEmpty(items)) {
            _.forEach(items, (item) => {
                cache.set(item.id, item);
            });
        }
    } catch (error) {}
    return callback(cache);
};

export const SaveRecentActivity = (items = new Map()) => {
    if (items.size) {
        let key = RECENT_FORUM_KEY;
        localStorage.setItem(
            key,
            JSON.stringify(Array.from(items, ([name, value]) => value))
        );
    }
};

export const UpdateUserEmail = async (email, userType) => {
    const formData = new FormData();
    const endpointUrl = userType === UserType.STUDENT ? `${URLS.API_URL}v1/students/me` : `${URLS.API_URL}v1/teachers/me`;

    formData.append("data", JSON.stringify({ email }));
    const response = await Axios.patch(
        endpointUrl,
        formData,
        {
            headers: {
                Authorization: getTokenFromStorage()
            }
        }
    );

    return response.data;
};

/**
 * 
 * @param {string} type => student | teacher | staff 
 * @param {object} payload => associated and valid fields
*/
export const JoinRequestAPI = async (type = "student", payload = {}) => {
    let baseURL = "students/join-request";
    if (type === "teacher") {
        baseURL = "teachers/join-request";
    }
    else if (type === "staff") {
        baseURL = "teachers/join-request/non-teaching-staff";
    }
    return Axios.post(`${URLS.API_URL}v1/${baseURL}`, payload);
};