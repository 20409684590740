import queryString from "query-string";
import { useLocation } from "react-router-dom";

const useQueryParams = (props) => {
    const { search, hash } = useLocation();
    const { query } = queryString.parseUrl(search, {
        parseBooleans: true,
        parseNumbers: true
    });

    if (props && props.hash) {
        return hash.replace("#", "");
    }

    return query;
};

export default useQueryParams;
