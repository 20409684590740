// src/studentListSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { URLS } from "../../../utils";
import Axios from "axios";
import { getTokenFromStorage, KeyGenerator } from "../../../utils/fn";
import dayjs from "dayjs";
import { SortingCriteria } from "../../../utils/constants";

// Define async thunk
export const fetchStudentList = createAsyncThunk(
    "studentList/fetchData",
    async (params, { getState }) => {
        try {
            const { pageNumber, createdAtFirstItem, override, ...rest } = params;
            const payload = {
                ...rest
            };
            const key = KeyGenerator(payload);
            const { items } = getState().students;

            if (!payload?.sortingCriteria) {
                payload.sortingCriteria = SortingCriteria.ALPHABETICAL;
            }

            if (pageNumber > 1 && createdAtFirstItem) {
                payload.pageNumber = pageNumber;
                let sortKey =
                    payload?.sortingCriteria === SortingCriteria.ALPHABETICAL ||
                    !payload?.sortingCriteria
                        ? "nameFirstItem"
                        : "createdAtFirstItem";
                payload[sortKey] = createdAtFirstItem;
            }

            let qp = new URLSearchParams(payload).toString();
            qp = qp ? `?${qp}` : "";

            if (
                payload?.courseId &&
                !_.isEmpty(items) &&
                !_.isEmpty(items[key] || []) &&
                !override
            ) {
                return items[key];
            }

            const response = await Axios.get(
                `${URLS.API_URL}v1/students/${qp}`,
                {
                    headers: {
                        Authorization: getTokenFromStorage()
                    }
                }
            );
            return response.data;
        } catch (error) {
            // console.log(error);
            throw new Error(error.response.data.error);
        }
    }
);

const formatStudentData = (data) => {
    let params = {
        ...data,
        listCourseIds: _.map(data?.listCourseIds || [], (item) => item.value),
        listCourseBundleIds:
            _.map(data?.listCourseBundleIds || [], (item) => item.value) || []
    };
    const payload = _.omitBy(
        _.pick(params, [
            "uidCode",
            "name",
            "email",
            "phoneNumber",
            "dateOfBirth",
            "gender",
            "listCourseIds",
            "languageCode",
            "institutionId",
            "guardianName",
            "guardianEmail",
            "guardianPhoneNumber",
            "address",
            "listCourseBundleIds"
        ]),
        _.isNil
    );
    payload.phoneNumber = payload.phoneNumber
        ? `+91${payload.phoneNumber}`
        : null;
    payload.guardianPhoneNumber = payload.guardianPhoneNumber
        ? `+91${payload.guardianPhoneNumber}`
        : null;
    payload.guardianName = payload.guardianName ? payload.guardianName : null;
    payload.guardianEmail = payload.guardianEmail
        ? payload.guardianEmail
        : null;
    payload.email = payload.email ? payload.email : null;
    payload.dateOfBirth = payload.dateOfBirth ? payload.dateOfBirth : null;
    payload.gender = payload.gender ? payload.gender : null;
    payload.languageCode = payload.languageCode ? payload.languageCode : null;
    payload.address = payload.address ? payload.address : null;

    return payload;
};

// action => 0: POST, 1: PUT
export const SingleStudentOperation = async (payload, action = 0) => {
    let config = {
        url: `${URLS.API_URL}v1/students`,
        method: "POST"
    };
    if (action !== 0) {
        config.url = `${config.url}/${payload.id}`;
        config.method = "PATCH";
    }

    const response = await Axios({
        ...config,
        data: formatStudentData(payload),
        headers: {
            Authorization: getTokenFromStorage()
        }
    });
    return response.data;
};

const studentCacheKey = (data) => {
    let { createdAtFirstItem, pageNumber, ...rest } = data;
    return !!data?.courseId ? KeyGenerator(rest) : "home";
};

// Create slice
export const studentListSlice = createSlice({
    name: "students",
    initialState: {
        items: {},
        currentPage: 1,
        createdAtFirstItem: null,
        filters: {},
        isLoading: false,
        isError: false,
        status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null
    },
    reducers: {
        setStudentPage: (state, action) => {
            state.currentPage = action.payload.pageNumber || 1;
            state.createdAtFirstItem =
                action.payload.createdAtFirstItem || null;
        },
        setStudentFilters: (state, action) => {
            state.currentPage = 1;
            state.createdAtFirstItem = null;
            state.items = {};
            state.filters = action.payload || {};
        },
        updateStudentList: (state, action) => {
            try {
                let { payload } = action;
                const key = studentCacheKey(payload?.meta);
                let existingData = _.cloneDeep(
                    state.items[key] || {
                        data: [],
                        count: 0
                    }
                );

                if (payload.action === "create") {
                    existingData.data = [
                        { ...payload.data },
                        ...existingData.data
                    ];
                    existingData.count = (existingData.count || 0) + 1;
                    state.items = {
                        [key]: existingData
                    };
                } else {
                    let index = _.findIndex(
                        existingData?.data,
                        (e) => e.id === payload.data.id
                    );
                    if (index > -1) {
                        existingData.data[index] = payload.data;
                        state.items = {
                            [key]: existingData
                        };
                    }
                }
            } catch (er) {
                // console.log(er);
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchStudentList.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
                state.isError = false;
            })
            .addCase(fetchStudentList.fulfilled, (state, action) => {
                state.status = "succeeded";
                let { override, ...args } = action.meta.arg;
                const key = studentCacheKey(args);

                if (key === "home") {
                    const existingData = _.cloneDeep(state.items[key]);
                    if (
                        !_.isEmpty(existingData) &&
                        !_.isEmpty(existingData?.data)
                    ) {
                        state.items[key] = {
                            count: action.payload?.count || 0,
                            data: [
                                ...existingData.data,
                                ...action?.payload?.data
                            ]
                        };
                    } else {
                        state.items[key] = {
                            count: action.payload?.count || 0,
                            data: action.payload?.data || []
                        };
                    }
                } else {
                    const existingData = _.cloneDeep(state.items[key]);
                    if (
                        !_.isEmpty(existingData) &&
                        !_.isEmpty(existingData?.data)
                    ) {
                        state.items[key] = {
                            count: action.payload?.count || 0,
                            data: [
                                ...existingData.data,
                                ...action?.payload?.data
                            ]
                        };
                    } else {
                        state.items[key] = {
                            count: action.payload?.count || 0,
                            data: action.payload?.data || []
                        };
                    }
                }
                state.isLoading = false;
                state.isError = false;
            })
            .addCase(fetchStudentList.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.isLoading = false;
                state.isError = true;
            });
    }
});

export const { updateStudentList, setStudentFilters, setStudentPage } =
    studentListSlice.actions;

export default studentListSlice.reducer;
