import useQueryParams from "../../hooks/useSearchQuery";
import UserLoginComponent from "../../modules/auth/user";
import UserResetPasswordComponent from "../../modules/auth/user/reset-password";
import LanguageDropdown from "../../components/UI/Dropdown/language";
import { Fragment } from "react";

const AuthPage = () => {
    const isResetPassword = useQueryParams({ hash: true }) == "reset-password";

    return (
        <Fragment>
            <div className="position-absolute" style={{ top: 20, left: 20 }}>
                <LanguageDropdown />
            </div>
            <div className="container min-vw-100 min-vh-100">
                <div className="d-flex align-items-center justify-content-center min-vh-100">
                    {isResetPassword ? (
                        <UserResetPasswordComponent
                            title={"Reset your password"}
                        />
                    ) : (
                        <UserLoginComponent title={"Welcome to Namma Shaale"} />
                    )}
                </div>
            </div>
        </Fragment>
    );
};

export default AuthPage;
