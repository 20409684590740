import classNames from "classnames";

const DefaultCardLoader = ({ show = false, isFixed = false }) => {
    return show ? (
        <div
            className={classNames("default-loader", {
                fixed: isFixed
            })}
        >
            <div className="loader">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    ) : (
        <></>
    );
};

export default DefaultCardLoader;
