import { useState } from "react";
import { FloatingLabel, Form } from "react-bootstrap";

const FloatingInputUIComponent = ({
    label = "",
    type = "text",
    name = "",
    placeholder = "",
    icon = "",
    value = "",
    required = false,
    disabled = false,
    iconClick = () => {},
    onChange = () => {}
}) => {
    return (
        <FloatingLabel
            label={`${label || "label"}`}
            className="fs-14 fw-medium"
        >
            <Form.Control
                value={value}
                name={name}
                type={`${type}`}
                required={required || false}
                placeholder={placeholder}
                disabled={disabled}
                className={"fs-16 fw-semibold"}
                onChange={(e) =>
                    onChange({ value: e.target.value, name: e.target.name })
                }
            />
            {icon && (
                <button
                    type={"button"}
                    onClick={iconClick}
                    className={`btn btn-text p-0 m-0 border-0 icon`}
                >
                    <img src={icon} alt={"icon"} width={20} />
                </button>
            )}
        </FloatingLabel>
    );
};

export default FloatingInputUIComponent;
