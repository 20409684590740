import dayjs from "dayjs";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import DefaultCardLoader from "../../../components/common/Loader";
import ButtonUIComponent from "../../../components/UI/Button";
import { useModalContext } from "../../../context/modal-context";
import useGetCourseList from "../../../hooks/useGetCourseList";
import {
    SingleLiveClassOperation,
    updateLiveClass
} from "../../../services/admin/reducer/liveClass";
import { ExtractErrorMessage } from "../../../utils/fn";
import { resetLiveClasses } from "../../../services/common/reducer/course";

/**
 *
 * @param {String} action => create | edit
 * @returns
 */
const CreateEditLiveClassModal = ({
    record = undefined,
    action = "create",
    course = null
}) => {
    const isEdit = action === "edit";
    const viewOnly = action === "view";
    const { toggle } = useModalContext();
    const { t } = useTranslation();
    const fields = [
        "view",
        "createLiveClass",
        "editLiveClass",
        "title",
        "description",
        "classLink",
        "course",
        "date",
        "startTime",
        "endTime",
        "enterTitle",
        "enterDescription",
        "enterLink",
        "selectCourse"
    ];
    const titles = _.map(fields, (field) => {
        return { value: t(`modal.liveClass.${field}`), key: field };
    }).reduce((p, c, i) => {
        p[c.key] = c.value;
        return p;
    }, {});

    const { list: courseList } = useGetCourseList({ params: { isDisabled: false } });
    const [payload, setPayload] = useState({
        title: undefined,
        description: undefined,
        externalJoinUrl: undefined,
        courseId: undefined,
        date: undefined,
        startTime: undefined,
        endTime: undefined
    });
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        let extraObj = {};
        if (course) {
            extraObj.courseId = course;
        }
        if (record && !_.isEmpty(record)) {
            setPayload({
                ...record,
                ...extraObj,
                date: dayjs(record.startDateTime).format("YYYY-MM-DD"),
                startTime: dayjs(record.startDateTime).format("HH:mm"),
                endTime: dayjs(record.endDateTime).format("HH:mm")
            });
        } else if (extraObj.courseId) {
            setPayload({ ...payload, ...extraObj });
        }

        return () => {
            setPayload({
                title: undefined,
                description: undefined,
                classLink: undefined,
                courseId: undefined,
                date: undefined,
                startTime: undefined,
                endTime: undefined
            });
        };
    }, [record, course]);

    useEffect(() => {
        if (!isEdit && payload.startTime) {
            let totalSeconds = +payload.startTime
                .split(":")
                .reduce((acc, time) => 60 * acc + +time);
            let newDate = new Date(null);
            newDate.setSeconds(totalSeconds + 60);
            setPayload((d) => {
                return {
                    ...d,
                    endTime: newDate.toISOString().substr(14, 5)
                };
            });
        }
    }, [payload.startTime]);

    const handleOnChange = (inputs) => {
        setPayload({
            ...payload,
            [inputs.name]: inputs.value
        });
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            if (!payload.courseId) {
                throw new Error("Please select a course.");
            }
            let startTimeSeconds = +payload.startTime
                .split(":")
                .reduce((acc, time) => 60 * acc + +time);
            let endTimeSeconds = +payload.endTime
                .split(":")
                .reduce((acc, time) => 60 * acc + +time);

            if (endTimeSeconds < startTimeSeconds) {
                throw new Error("Start time should be greater than End time");
            }
            const selectedYear = new Date(payload.date).getFullYear();
            const currentYear = new Date().getFullYear();
            const isBeforeCurrent = dayjs(payload.date).isBefore(
                dayjs().subtract("1", "day")
            );
            if (isBeforeCurrent || selectedYear < currentYear) {
                throw new Error(
                    "Selected Date should be greater than current date"
                );
            }

            setLoading(true);
            const response = await SingleLiveClassOperation(
                payload,
                isEdit ? 1 : 0
            );
            dispatch(
                updateLiveClass({
                    action,
                    data: response.data
                })
            );
            dispatch(resetLiveClasses());
            toast.success(`Added successfully`);
            toggle();
        } catch (error) {
            const { toast: toastMsg } = ExtractErrorMessage(
                error.response || error
            );
            toast.error(toastMsg);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Form onSubmit={submitHandler}>
            <Modal.Header style={{ padding: "24px 16px" }}>
                <Modal.Title className="fs-20">
                    {viewOnly
                        ? t(titles.view)
                        : !isEdit
                          ? t(titles.createLiveClass)
                          : t(titles.editLiveClass)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                <div className="d-flex flex-column">
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                        <div className="col-md-3">
                            <label
                                htmlFor="name"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.title}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <input
                                autoComplete={"off"}
                                required
                                className="form-control fs-14"
                                placeholder={titles.enterTitle}
                                value={payload.title || ""}
                                onChange={(e) =>
                                    handleOnChange({
                                        name: e.target.name,
                                        value: e.target.value
                                    })
                                }
                                name={"title"}
                                disabled={viewOnly}
                            />
                        </div>
                    </div>
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                        <div className="col-md-3">
                            <label
                                htmlFor="name"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.description}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <textarea
                                className={`form-control fs-14`}
                                placeholder={titles.enterDescription}
                                required
                                name={"description"}
                                onChange={(e) =>
                                    handleOnChange({
                                        name: e.target.name,
                                        value: e.target.value
                                    })
                                }
                                value={payload.description || ""}
                                disabled={viewOnly}
                            />
                        </div>
                    </div>
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                        <div className="col-md-3">
                            <label
                                htmlFor="name"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.classLink}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <input
                                autoComplete={"off"}
                                type={"url"}
                                className="form-control fs-14"
                                placeholder={titles.enterLink}
                                name={"externalJoinUrl"}
                                required
                                value={payload.externalJoinUrl || ""}
                                onChange={(e) =>
                                    handleOnChange({
                                        name: e.target.name,
                                        value: e.target.value
                                    })
                                }
                                disabled={viewOnly}
                            />
                        </div>
                    </div>
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                        <div className="col-md-3">
                            <label
                                htmlFor="name"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.course}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <select
                                disabled={course || viewOnly}
                                className="form-select fs-14"
                                placeholder={titles.selectCourse}
                                name={"courseId"}
                                required={false}
                                value={payload.courseId || ""}
                                onChange={(e) =>
                                    handleOnChange({
                                        name: e.target.name,
                                        value: e.target.value
                                    })
                                }
                            >
                                <option value="">{titles.selectCourse}</option>
                                {courseList &&
                                    !_.isEmpty(courseList) &&
                                    _.map(courseList, (item) => {
                                        return (
                                            <option
                                                key={item.id}
                                                value={item.id}
                                            >
                                                {item.title} [{item.uidCode}]
                                            </option>
                                        );
                                    })}
                            </select>
                        </div>
                    </div>
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                        <div className="col-md-3">
                            <label
                                htmlFor="name"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.date}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <input
                                autoComplete={"off"}
                                type={"date"}
                                className="form-control fs-14"
                                placeholder={`Select Date`}
                                name={"date"}
                                required
                                value={payload.date || ""}
                                onChange={(e) =>
                                    handleOnChange({
                                        name: e.target.name,
                                        value: e.target.value
                                    })
                                }
                                disabled={viewOnly}
                            />
                        </div>
                    </div>
                    <div className="form-row split d-flex flex-column flex-md-row align-items-md-center border-0">
                        <div
                            className="d-flex flex-column flex-md-row align-items-md-center border-end"
                            style={{ flexBasis: "50%" }}
                        >
                            <div className="col-md-4">
                                <label
                                    htmlFor="name"
                                    className="fs-14 text-gray-300 fw-semibold text-nowrap"
                                >
                                    {titles.startTime}
                                </label>
                            </div>
                            <div className="col-md-8 col-lg-6 offset-lg-2 mt-2 mt-md-0 ps-lg-2">
                                <input
                                    autoComplete={"off"}
                                    type={"time"}
                                    className="form-control fs-14"
                                    placeholder={`Select Start Time`}
                                    name={"startTime"}
                                    required
                                    value={payload.startTime || ""}
                                    onChange={(e) =>
                                        handleOnChange({
                                            name: e.target.name,
                                            value: e.target.value
                                        })
                                    }
                                    disabled={viewOnly}
                                />
                            </div>
                        </div>
                        <div
                            className="d-flex flex-column flex-md-row align-items-md-center ms-md-auto"
                            style={{ flexBasis: "50%" }}
                        >
                            <div className="col-md-4">
                                <label
                                    htmlFor="name"
                                    className="fs-14 text-gray-300 fw-semibold text-nowrap"
                                >
                                    {titles.endTime}
                                </label>
                            </div>
                            <div className="col-md-8 col-lg-6 offset-lg-2 mt-2 mt-md-0">
                                <input
                                    autoComplete={"off"}
                                    type={"time"}
                                    className="form-control fs-14"
                                    placeholder={`Select End Time`}
                                    name={"endTime"}
                                    required
                                    value={payload.endTime || ""}
                                    onChange={(e) =>
                                        handleOnChange({
                                            name: e.target.name,
                                            value: e.target.value
                                        })
                                    }
                                    disabled={viewOnly}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex">
                    <ButtonUIComponent
                        className={`fs-14 fw-semibold`}
                        buttonInner={t("common.cancel")}
                        type={"button"}
                        onClick={() => toggle()}
                    />
                    {!viewOnly && (
                        <ButtonUIComponent
                            isPrimary={true}
                            className={`fs-14 ms-3 fw-semibold`}
                            buttonInner={
                                !isEdit
                                    ? t(titles.createLiveClass)
                                    : t(titles.editLiveClass)
                            }
                            type={"submit"}
                        />
                    )}
                </div>
            </Modal.Footer>
            <DefaultCardLoader show={loading} />
        </Form>
    );
};

export default CreateEditLiveClassModal;
