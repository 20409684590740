import classNames from "classnames";
import _ from "lodash";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import DefaultCardLoader from "../../../components/common/Loader";
import ButtonUIComponent from "../../../components/UI/Button";
import { useModalContext } from "../../../context/modal-context";
import useGetCourseList from "../../../hooks/useGetCourseList";
import {
    SingleAnnouncementOperation,
    updateAnnouncement
} from "../../../services/admin/reducer/announcements";

const CreateAnnouncementModal = () => {
    const { toggle } = useModalContext();
    const { t } = useTranslation();
    const fields = [
        "create",
        "edit",
        "title",
        "description",
        "course",
        "publicAnnouncement",
        "enterTitle",
        "enterDescription",
        "selectCourse"
    ];
    const titles = _.map(fields, (field) => {
        return { value: t(`modal.announcement.${field}`), key: field };
    }).reduce((p, c, i) => {
        p[c.key] = c.value;
        return p;
    }, {});

    const [payload, setPayload] = useState({
        title: undefined,
        description: undefined,
        courseId: undefined,
        publicAnnouncement: true
    });
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { list: CourseList } = useGetCourseList({ params: { isDisabled: false } });

    const handleOnChange = (inputs) => {
        setPayload({
            ...payload,
            [inputs.name]: inputs.value
        });
    };

    const submitHandler = async (e) => {
        e.preventDefault();

        try {
            setLoading(true);
            const response = await SingleAnnouncementOperation(payload);
            dispatch(
                updateAnnouncement({
                    data: response?.data?.announcement
                })
            );
            toggle();
            toast.success(`Added successfully`);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    return (
        <Form onSubmit={submitHandler}>
            <Modal.Header style={{ padding: "24px 16px" }}>
                <Modal.Title className="fs-20">{t(titles.create)}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                <div className="d-flex flex-column">
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                        <div className="col-md-3">
                            <label
                                htmlFor="name"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.title}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <input
                                autoComplete={"off"}
                                required
                                className="form-control fs-14"
                                placeholder={titles.enterTitle}
                                value={payload.title || ""}
                                onChange={(e) =>
                                    handleOnChange({
                                        name: e.target.name,
                                        value: e.target.value
                                    })
                                }
                                name={"title"}
                            />
                        </div>
                    </div>
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                        <div className="col-md-3">
                            <label
                                htmlFor="name"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.description}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <textarea
                                className={`form-control fs-14`}
                                placeholder={titles.enterDescription}
                                required
                                name={"description"}
                                onChange={(e) =>
                                    handleOnChange({
                                        name: e.target.name,
                                        value: e.target.value
                                    })
                                }
                                value={payload.description || ""}
                            />
                        </div>
                    </div>
                    <div
                        className={classNames(
                            `form-row d-flex flex-column flex-md-row align-items-md-center`,
                            {
                                "border-bottom": !payload.publicAnnouncement,
                                "border-0": payload.publicAnnouncement
                            }
                        )}
                    >
                        <div className="col-md-3">
                            <label
                                htmlFor="name"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.publicAnnouncement}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <Form.Check
                                type={"switch"}
                                checked={payload.publicAnnouncement || false}
                                onChange={(e) =>
                                    handleOnChange({
                                        name: "publicAnnouncement",
                                        value: e.target.checked
                                    })
                                }
                            />
                        </div>
                    </div>
                    {!payload.publicAnnouncement && (
                        <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-0">
                            <div className="col-md-3">
                                <label
                                    htmlFor="name"
                                    className="fs-14 text-gray-300 fw-semibold"
                                >
                                    {titles.course}
                                </label>
                            </div>
                            <div className="col-md-9 mt-2 mt-md-0">
                                <select
                                    className="form-select fs-14"
                                    placeholder={titles.selectCourse}
                                    name={"courseId"}
                                    required
                                    value={payload.courseId || ""}
                                    onChange={(e) =>
                                        handleOnChange({
                                            name: e.target.name,
                                            value: e.target.value
                                        })
                                    }
                                >
                                    <option value="">
                                        {titles.selectCourse}
                                    </option>
                                    {CourseList &&
                                        _.map(CourseList, (item) => {
                                            return (
                                                <option value={item.id}>
                                                    {item.title} [{item.uidCode}
                                                    ]
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex">
                    <ButtonUIComponent
                        className={`fs-14 fw-semibold me-3`}
                        buttonInner={t("common.cancel")}
                        type={"button"}
                        onClick={() => toggle()}
                    />
                    <ButtonUIComponent
                        isPrimary={true}
                        className={`fs-14 fw-semibold`}
                        buttonInner={t(titles.create)}
                        type={"submit"}
                    />
                </div>
            </Modal.Footer>
            <DefaultCardLoader show={loading} />
        </Form>
    );
};

export default CreateAnnouncementModal;
