import { useTranslation } from "react-i18next";
import Slider from "react-slick";

const LandingHeroComponent = () => {
    const { t } = useTranslation();
    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        padding: 20
    };

    return (
        <div className="container-fluid px-0" id="hero">
            <div className="">
                <Slider {...settings}>
                    <div className="item bg-1 py-5">
                        <div className="row my-md-5">
                            <div className="col-lg-6 col-md-7 col-sm-7">
                                <div className="d-flex flex-column justify-content-center h-100 ms-md-5 ps-lg-5 ps-md-4 ps-5">
                                    <div className="fw-semibold text-gray-600 fs-32">
                                        {t("landing.hero.b1.intro")}
                                    </div>
                                    <div className="fw-semibold fs-54 text-dark-head mt-2">
                                        {t("landing.hero.b1.title")}
                                    </div>
                                    <div
                                        className="fw-semibold text-gray-400 fs-32 mt-1"
                                        dangerouslySetInnerHTML={{
                                            __html: t(
                                                "landing.hero.b1.subtitle"
                                            ).replace(/\n/, "<br/>")
                                        }}
                                    />
                                    <div className="row hero-list d-md-flex d-none">
                                        <div className="col-md-6">
                                            <div className="item c1">
                                                <img
                                                    src={`/assets/icons/checked-circle.svg`}
                                                    alt={""}
                                                    className={"img-fluid"}
                                                />
                                                <span className="ms-2">
                                                    {t(
                                                        "landing.hero.b1.tiles.0"
                                                    )}
                                                </span>
                                            </div>
                                            <div className="item c1">
                                                <img
                                                    src={`/assets/icons/checked-circle.svg`}
                                                    alt={""}
                                                    className={"img-fluid"}
                                                />
                                                <span className="ms-2">
                                                    {t(
                                                        "landing.hero.b1.tiles.1"
                                                    )}
                                                </span>
                                            </div>
                                            <div className="item c1">
                                                <img
                                                    src={`/assets/icons/checked-circle.svg`}
                                                    alt={""}
                                                    className={"img-fluid"}
                                                />
                                                <span className="ms-2">
                                                    {t(
                                                        "landing.hero.b1.tiles.2"
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="item c1">
                                                <img
                                                    src={`/assets/icons/checked-circle.svg`}
                                                    alt={""}
                                                    className={"img-fluid"}
                                                />
                                                <span className="ms-2">
                                                    {t(
                                                        "landing.hero.b1.tiles.3"
                                                    )}
                                                </span>
                                            </div>
                                            <div className="item c1">
                                                <img
                                                    src={`/assets/icons/checked-circle.svg`}
                                                    alt={""}
                                                    className={"img-fluid"}
                                                />
                                                <span className="ms-2">
                                                    {t(
                                                        "landing.hero.b1.tiles.4"
                                                    )}
                                                </span>
                                            </div>
                                            <div className="item c1">
                                                <img
                                                    src={`/assets/icons/checked-circle.svg`}
                                                    alt={""}
                                                    className={"img-fluid"}
                                                />
                                                <span className="ms-2">
                                                    {t(
                                                        "landing.hero.b1.tiles.5"
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-5 mt-5 mt-md-0 col-sm-5 col-10 offset-sm-0 offset-1">
                                <div className="d-flex position-relative justify-content-end w-100">
                                    <img
                                        src={`/assets/images/hero-image.webp`}
                                        alt={"Image 1"}
                                        className={"img-fluid"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item bg-2 py-5">
                        <div className="row my-md-5">
                            <div className="col-lg-6 col-md-7 col-sm-7">
                                <div className="d-flex flex-column justify-content-center h-100 ms-md-5 ps-lg-5 ps-md-4 ps-5">
                                    <div className="fw-semibold fs-48 text-red-p mt-0">
                                        {t("landing.hero.b2.intro")}
                                    </div>
                                    <div className="fw-semibold fs-28 text-gray-400 mt-2">
                                        {t("landing.hero.b2.subtitle")}
                                    </div>
                                    <div className="row hero-list d-md-flex d-none">
                                        <div className="col-md-6">
                                            <div className="item c1">
                                                <img
                                                    src={`/assets/icons/checked-circle-red.svg`}
                                                    alt={""}
                                                    className={"img-fluid"}
                                                />
                                                <span className="ms-2">
                                                    {t(
                                                        "landing.hero.b2.tiles.0"
                                                    )}
                                                </span>
                                            </div>
                                            <div className="item c1">
                                                <img
                                                    src={`/assets/icons/checked-circle-red.svg`}
                                                    alt={""}
                                                    className={"img-fluid"}
                                                />
                                                <span className="ms-2">
                                                    {t(
                                                        "landing.hero.b2.tiles.1"
                                                    )}
                                                </span>
                                            </div>
                                            <div className="item c1">
                                                <img
                                                    src={`/assets/icons/checked-circle-red.svg`}
                                                    alt={""}
                                                    className={"img-fluid"}
                                                />
                                                <span className="ms-2">
                                                    {t(
                                                        "landing.hero.b2.tiles.2"
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-5 mt-5 mt-md-0 col-sm-5 col-10 offset-sm-0 offset-1">
                                <div className="d-flex position-relative justify-content-end w-100">
                                    <img
                                        src={`/assets/images/contact-us.webp`}
                                        alt={"Image 2"}
                                        className={"img-fluid"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="item bg-2 py-5">
                        <div className="row my-md-5">
                            <div className="col-lg-6 col-md-7 col-sm-7">
                                <div className="d-flex flex-column justify-content-center h-100 ms-md-5 ps-lg-5 ps-md-4 ps-5">
                                    <div className="fw-semibold fs-48 text-red-p mt-0">
                                        {t("landing.hero.b3.intro")}
                                    </div>
                                    <div className="fw-semibold fs-28 text-red-p mt-2">
                                        {t("landing.topBar.siddara_title")}
                                    </div>
                                    <div
                                        style={{ opacity: 0.5 }}
                                        className="fw-semibold fs-20 text-red-p mt-2"
                                        dangerouslySetInnerHTML={{
                                            __html: t(
                                                "landing.topBar.siddara_subtitle"
                                            ).replace(/\n/, "<br/>")
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-5 mt-5 mt-md-0 col-sm-5 col-10 offset-sm-0 offset-1">
                                <div className="d-flex position-relative justify-content-end w-100">
                                    <img
                                        src={`/assets/images/b2.webp`}
                                        alt={"Image 2"}
                                        className={"img-fluid"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div> */}
                </Slider>
            </div>
        </div>
    );
};

export default LandingHeroComponent;
