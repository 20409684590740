import _ from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import queryString from "query-string";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import AdminDashboardTitle from "../../../components/admin/DashboardTitle";
import AdminDashboardTabs from "../../../components/admin/Tabs";
import ActivityForumListItemTemplate from "../../../components/common/forum/ReportedActivity";
import DefaultCardLoader from "../../../components/common/Loader";
import EmptyBoxComponent from "../../../components/common/reusable/EmptyBox";
import ListUIComponent from "../../../components/UI/List";
// import SearchBarComponent from "../../../components/UI/Search";
import {
    fetchReportedAcitivity,
    refetchActivity,
    ReportedForumOperation,
    setReportedPageParams,
    updateReportedActivity
} from "../../../services/admin/reducer/activity";
import {
    checkNextPage,
    ExtractErrorMessage,
    KeyGenerator
} from "../../../utils/fn";
import { PAGE_LIMIT } from "../../../utils/constants";
import ForumActivityFilter from "../../../components/admin/filters/forumActivity";
import { useTranslation } from "react-i18next";

const AdminForumBaseComponent = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { search } = useLocation();
    const {
        items,
        isLoading,
        isError,
        pageNumber,
        createdAtFirstItem,
        filters
    } = useSelector((state) => state.reportedActivity);
    const options = {
        ...queryString.parseUrl(search, {
            parseBooleans: true,
            parseNumbers: true
        }).query
    };
    const cacheKey = KeyGenerator({
        isResolved: options.isResolved
    });
    const forumActivity = items; // && items[cacheKey] ? items[cacheKey] : {};
    const hasNextPage = checkNextPage(forumActivity?.data || [], pageNumber);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isError) {
            dispatch(
                fetchReportedAcitivity({
                    ...options,
                    pageNumber,
                    createdAtFirstItem
                })
            );
        }
    }, [dispatch, search, pageNumber, createdAtFirstItem, isError]);

    useEffect(() => {
        return () => {
            dispatch(refetchActivity());
        };
    }, [dispatch]);

    const handleOperation = async (record, remove = true) => {
        try {
            setLoading(true);
            await ReportedForumOperation(
                {
                    id: record.id,
                    isRemoveEntity: remove
                },
                1
            );
            dispatch(
                updateReportedActivity({
                    meta: {
                        isResolved: filters.isResolved
                    },
                    id: record.id
                })
            );
            toast.success(`${remove ? "Removed" : "Ignored"} successfully`);
        } catch (error) {
            const { toast: toastMsg } = ExtractErrorMessage(error?.response);
            toast.error(toastMsg);
        } finally {
            setLoading(false);
        }
    };

    const handlePagination = () => {
        if (isError) return;
        dispatch(
            setReportedPageParams({
                pageNumber: (pageNumber || 0) + 1,
                createdAtFirstItem: forumActivity?.data[0]?.createdAt
            })
        );
    };

    return (
        <Fragment>
            <AdminDashboardTitle
                title={t(`common.forumActivity`)}
                showResult={forumActivity?.count > 0}
                count={forumActivity?.count || 0}
            />
            <AdminDashboardTabs />
            <div className="container mt-4 mb-5">
                <div className="row mb-4">
                    <div className="col-lg-10">
                        <ForumActivityFilter />
                    </div>
                </div>
                <div className="row">
                    {!isLoading && _.isEmpty(forumActivity?.data || []) ? (
                        <div className="col-lg-10">
                            <EmptyBoxComponent
                                isLoading={isLoading}
                                data={forumActivity?.data || []}
                            />
                        </div>
                    ) : (
                        <div className="col-lg-10 scrollableDiv">
                            <InfiniteScroll
                                dataLength={forumActivity?.data?.length || 0}
                                next={handlePagination}
                                hasMore={!isError && hasNextPage}
                                // hasMore={
                                //     !isError &&
                                //     !_.isEmpty(forumActivity?.data || 0)
                                //         ? forumActivity?.data?.length %
                                //               PAGE_LIMIT ===
                                //           0
                                //         : false
                                // }
                                scrollableTarget="scrollableDiv"
                            >
                                <ListUIComponent
                                    data={forumActivity?.data || []}
                                    separator={true}
                                    render={(record) => (
                                        <ActivityForumListItemTemplate
                                            redirection={false}
                                            data={record}
                                            handleOperation={handleOperation}
                                        />
                                    )}
                                />
                            </InfiniteScroll>
                        </div>
                    )}
                </div>
            </div>
            <DefaultCardLoader show={loading || isLoading} isFixed={true} />
        </Fragment>
    );
};

export default AdminForumBaseComponent;
