import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getTokenFromStorage, KeyGenerator } from "../../../../utils/fn";
import Axios from "axios";
import { URLS } from "../../../../utils";
import _ from "lodash";

export const fetchCourseForumData = createAsyncThunk(
    `forumCourseData/fetchData`,
    async ({ id, ...params }, { getState }) => {
        try {
            // const { items } = getState().forumData;
            // const key = KeyGenerator({ id });
            let qs = { courseId: id };

            // if (items && !_.isEmpty(items) && items[key] && !params.pageNumber) {
            //     return items[key];
            // }

            if (params.pageNumber > 1 && params.createdAtFirstItem) {
                qs.pageNumber = params.pageNumber;
                qs.createdAtFirstItem = params.createdAtFirstItem;
            }

            let qp = new URLSearchParams(qs).toString();
            qp = qp ? `?${qp}` : "";

            const response = await Axios.get(
                `${URLS.API_URL}v1/forum-topics${qp}`,
                {
                    headers: {
                        Authorization: getTokenFromStorage()
                    }
                }
            );
            return response.data;
        } catch (error) {
            throw new Error(error.response.data.error);
        }
    }
);

const formatForumData = (payload, action = 0) => {
    const fd = new FormData();
    fd.append(
        `data`,
        JSON.stringify({
            courseId: payload.courseId,
            title: payload.title
        })
    );
    if (payload?.attachment) {
        fd.append("attachment", payload.attachment);
    }

    return fd;
};

export const RemoveForumTopic = async (id) => {
    let config = {
        url: `${URLS.API_URL}v1/forum-topics/remove/${id}`,
        method: "PATCH"
    };

    const response = await Axios({
        url: config.url,
        method: config.method,
        headers: {
            Authorization: getTokenFromStorage()
        }
    });

    return response.data;
};

export const MarkAsClosedForumTopic = async (id, title) => {
    let config = {
        url: `${URLS.API_URL}v1/forum-topics/${id}`,
        method: "PATCH"
    };
    let formData = new FormData();
    formData.append("data", JSON.stringify({ isOpen: false, title: title }));

    const response = await Axios({
        url: config.url,
        method: config.method,
        data: formData,
        headers: {
            Authorization: getTokenFromStorage()
        }
    });

    return response.data;
};

export const PostForumTopic = async (payload, action = 0) => {
    let config = {
        url: `${URLS.API_URL}v1/forum-topics`,
        method: "POST"
    };

    const response = await Axios({
        url: config.url,
        method: config.method,
        data: formatForumData(payload),
        headers: {
            Authorization: getTokenFromStorage()
        }
    });

    return response.data;
};

export const ReportForumTopic = async ({ id: forumTopicId, reason }) => {
    let config = {
        url: `${URLS.API_URL}v1/removal-requests`,
        method: "POST"
    };

    const response = await Axios({
        url: config.url,
        method: config.method,
        data: {
            forumTopicId,
            reason
        },
        headers: {
            Authorization: getTokenFromStorage()
        }
    });

    return response.data;
};

const forumSlice = createSlice({
    name: `forumData`,
    initialState: {
        items: {},
        pageNumber: 1,
        createdAtFirstItem: null,
        isLoading: false,
        isError: false,
        status: "idle",
        error: null
    },
    reducers: {
        unmountForumData: (state, action) => {
            state.items = {};
            state.pageNumber = 1;
            state.createdAtFirstItem = null;
        },
        setForumPageParams: (state, action) => {
            let { payload } = action;
            state.pageNumber = payload.pageNumber;
            state.createdAtFirstItem = payload.createdAtFirstItem;
        },
        updateForumTopic: (state, action) => {
            try {
                let { payload } = action;
                let key = KeyGenerator(payload.meta);
                let existingData = state.items[key] || {
                    count: 0,
                    data: []
                };
                let isFound = true;
                if (
                    existingData &&
                    !_.isEmpty(existingData.data) &&
                    payload.data?.id
                ) {
                    let foundIndex = _.findIndex(existingData.data, (e) => {
                        return e.id === payload.data.id;
                    });
                    if (foundIndex > -1) {
                        existingData.data[foundIndex] = payload.data;
                    } else {
                        isFound = false;
                    }
                }
                if (!isFound) {
                    existingData.data = [
                        { ...payload.data },
                        ...existingData.data
                    ];
                    existingData.count = (existingData.count || 0) + 1;
                }
                Object.assign(state.items, { [key]: existingData });
            } catch (er) {
                // console.log(er);
            }
        },
        removeForumTopic: (state, action) => {
            try {
                let { payload } = action;
                let key = KeyGenerator(payload.meta);
                let existingData = state.items[key] || {
                    count: 0,
                    data: []
                };
                existingData.data = _.filter(
                    existingData.data,
                    (record) => record.id !== payload.id
                );
                existingData.count = (existingData.count || 1) - 1;
                Object.assign(state.items, { [key]: existingData });
            } catch (error) {
                // console.log(error);
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCourseForumData.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
                state.isError = false;
            })
            .addCase(fetchCourseForumData.fulfilled, (state, action) => {
                state.status = "succeeded";
                const key = KeyGenerator({ id: action.meta.arg.id });
                if (state.items.hasOwnProperty(key)) {
                    let existingData = _.cloneDeep(state.items[key]) || [];
                    existingData.data = [
                        ...existingData.data,
                        ...action.payload.data
                    ];
                    Object.assign(state.items, { [key]: existingData });
                } else {
                    Object.assign(state.items, { [key]: action.payload });
                }
                state.isLoading = false;
                state.isError = false;
            })
            .addCase(fetchCourseForumData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.isLoading = false;
                state.isError = true;
            });
    }
});

export const {
    updateForumTopic,
    setForumPageParams,
    unmountForumData,
    removeForumTopic
} = forumSlice.actions;

export default forumSlice.reducer;
