import { Fragment } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { URLS } from "../../../utils";
import { GetAvatarInitials } from "../../../utils/fn";
import ButtonUIComponent from "../../UI/Button";
import DropdownUIComponent, {
    DropdownItemUIComponent
} from "../../UI/Dropdown";
import LanguageDropdown from "../../UI/Dropdown/language";
import NotificationDropdown from "../../user/notification";
import EventNotificationDropdown from "../../user/notification/event";
import { useTranslation } from "react-i18next";

const HeaderComponent = ({
    userObject = {},
    logout = () => {},
    selfUser = false
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className="dashboard-header">
            <div className="container">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex">
                        <NavLink to={"/"}>
                            <img
                                src={`/assets/images/logo.webp`}
                                alt={`Namma Shaale Logo`}
                                className={`img-fluid`}
                                style={{ minWidth: 40 }}
                            />
                        </NavLink>
                    </div>
                    <div className="d-flex action-buttons">
                        <div className="d-sm-flex d-none">
                            <LanguageDropdown />
                        </div>
                        {selfUser && (
                            <Fragment>
                                <div className="d-flex ms-3">
                                    <ButtonUIComponent
                                        className={`btn-sm rounded-circle px-2 py-2`}
                                        buttonInner={
                                            <img
                                                src={`/assets/icons/calendar.svg`}
                                                alt={`Calendar Events`}
                                                className={`img-fluid`}
                                                style={{ minWidth: 20 }}
                                            />
                                        }
                                        onClick={() =>
                                            navigate(`/user/dashboard/calendar`)
                                        }
                                    />
                                </div>
                                <div className="d-flex ms-3">
                                    <NotificationDropdown />
                                </div>
                                <div className="d-flex ms-3">
                                    <EventNotificationDropdown />
                                </div>
                            </Fragment>
                        )}
                        <div className="d-flex ms-3">
                            <DropdownUIComponent
                                btnText={
                                    <div
                                        className={`d-flex align-items-center text-nowrap`}
                                    >
                                        {userObject?.profileImageUri ? (
                                            <img
                                                className="img-fluid rounded-circle"
                                                width={24}
                                                src={`${URLS.MEDIA_URL}${userObject?.profileImageUri}`}
                                                alt={
                                                    userObject?.name ||
                                                    "User Avatar"
                                                }
                                            />
                                        ) : (
                                            <div
                                                className="avatar sm"
                                                data-color={"brown" || ""}
                                            >
                                                {GetAvatarInitials(
                                                    userObject?.name || "",
                                                    true
                                                )}
                                            </div>
                                        )}
                                        <div className="text-dark fs-14 ms-2 d-none d-sm-block">
                                            {userObject?.name || ""}
                                        </div>
                                    </div>
                                }
                                btnClassName={`btn-sm text-dark d-flex align-items-center fw-semibold`}
                                style={{ paddingBlock: "6px" }}
                                placement={"end"}
                            >
                                {selfUser && (
                                    <DropdownItemUIComponent>
                                        <NavLink
                                            className={
                                                "text-gray-600 pb-1 d-block px-2 text-decoration-none fw-semibold fs-12"
                                            }
                                            to={`${URLS.DASHBOARD_URL}profile`}
                                        >
                                            {t("common.myProfile")}
                                        </NavLink>
                                    </DropdownItemUIComponent>
                                )}
                                <DropdownItemUIComponent>
                                    <ButtonUIComponent
                                        className={`px-0 btn-sm bg-transparent border-0 pt-2 pb-1 text-danger no-hover`}
                                        buttonInner={
                                            <div className="d-flex">
                                                <span className="ms-2 fs-12 fw-semibold">
                                                    {t("common.logout")}
                                                </span>
                                            </div>
                                        }
                                        onClick={() => logout()}
                                    />
                                </DropdownItemUIComponent>
                            </DropdownUIComponent>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeaderComponent;
