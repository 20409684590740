import _ from "lodash";

const TableUIComponent = ({
    tableKey = "",
    showEmpty = false,
    isLoading = false,
    columns = [],
    dataSource = [],
    onRowClick = (record) => {}
}) => {
    return (
        <div
            key={tableKey || "unique-key"}
            className="table-responsive nm-table w-100"
            data-empty={showEmpty && !isLoading}
        >
            <table className="table w-100">
                <thead>
                    <tr>
                        {_.map(columns, (column, index) => {
                            let opts = {};
                            if (column?.width) {
                                opts = { width: column.width };
                            }
                            return (
                                <th key={index} style={{ ...opts }}>
                                    {column.title}
                                </th>
                            );
                        })}
                        {/* <th>Course Name</th>
                        <th>Description</th>
                        <th>Instructor(s)</th>
                        <th>Students</th>
                        <th>Last Updated On</th>
                        <th></th> */}
                    </tr>
                </thead>
                <tbody>
                    {_.map(dataSource, (data, index) => {
                        return (
                            <tr
                                key={index}
                                onClick={() => onRowClick(data)}
                                data-ptr={true}
                                data-disabled={data?.isDisabled}
                            >
                                {_.map(columns, (cell, indice) => {
                                    let opts = {};
                                    if (cell?.width) {
                                        opts = { width: cell.width };
                                    }
                                    return (
                                        <td
                                            key={`${index}-${indice}`}
                                            style={{ ...opts }}
                                        >
                                            {cell.render(data)}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default TableUIComponent;
