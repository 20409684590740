import Select from "react-select";

const MultiSelectUIComponent = ({
    disabled = false,
    name = "",
    options = [],
    placeholder = "",
    defaultOptions = [],
    value = [],
    onChange = () => {},
    isLoading = false
}) => {
    return (
        <Select
            key={name}
            className={`nm-multi-select fw-semibold text-gray-600`}
            defaultValue={defaultOptions}
            value={value || []}
            isMulti={true}
            name={name}
            options={options}
            placeholder={placeholder || "Select"}
            classNamePrefix={`nm-multi`}
            onChange={onChange}
            isLoading={isLoading}
            isDisabled={isLoading || disabled}
        />
    );
};

export default MultiSelectUIComponent;
