import _ from "lodash";
import { useTranslation } from "react-i18next";

const LandingPlatformFeaturesComponent = () => {
    const { t } = useTranslation();
    const icons = [
        "live.svg",
        "outcome.svg",
        "on-demand.svg",
        "student-community.svg",
        "forum.svg",
        "feedback.svg",
        "progress.svg",
        "domain.svg",
        "skill.svg"
    ];
    const features = _.map(icons, (e, i) => {
        return {
            name: `landing.features.n${i + 1}`,
            text: `landing.features.t${i + 1}`,
            icon: e
        };
    });
    const features1 = [
        {
            name: "Live Online Classes",
            text: "Interactive sessions with live instructor-led guidance",
            icon: "live.svg"
        },
        {
            name: "Outcome-Based Learning",
            text: "Real-world outcomes drive personalized educational",
            icon: "outcome.svg"
        },
        {
            name: "On-Demand Resources",
            text: "Access educational content anytime, anywhere.",
            icon: "on-demand.svg"
        },
        {
            name: "Student Community",
            text: "Engage with peers in a collaborative environment",
            icon: "student-community.svg"
        },
        {
            name: "Forum & Discussion",
            text: "Share insights and seek guidance from peers.",
            icon: "forum.svg"
        },
        {
            name: "Feedback & Assessment",
            text: "Actionable feedback for continuous improvement",
            icon: "feedback.svg"
        },
        {
            name: "Progress Management",
            text: "Monitor progress through learning milestones",
            icon: "progress.svg"
        },
        {
            name: "Domain-Expert Instructors",
            text: "Learn from the leading faculty of each curriculum",
            icon: "domain.svg"
        },
        {
            name: "Skill Development",
            text: "Cultivate expertise through targeted training programs",
            icon: "skill.svg"
        }
    ];

    return (
        <div className="container">
            <div className="row px-md-5">
                <div className="col-12 text-center">
                    <div className="text-light fs-1 fw-bold head">
                        {t("landing.features.title")}
                    </div>
                </div>
                <div className="col-12 mt-4 pt-4">
                    <div className="row">
                        {_.map(features, (feature, index) => {
                            return (
                                <div
                                    key={index}
                                    className="col-md-4 col-sm-6 col-12 mb-4"
                                >
                                    <div className="card bg-transparent">
                                        <div className="card-body bg-transparent pb-lg-5 pb-4">
                                            <div className="d-flex text-center flex-column align-items-center justify-content-center">
                                                <div className="d-flex">
                                                    <img
                                                        src={`/assets/images/icons/${feature.icon}`}
                                                        alt={t(feature.name)}
                                                        className={`img-fluid`}
                                                    />
                                                </div>
                                                <strong className="text-gray-100 fs-5">
                                                    {t(feature.name)}
                                                </strong>
                                                <span className="fs-6 text-gray-300 px-md-4">
                                                    {t(feature.text)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingPlatformFeaturesComponent;
