import { useNavigate, useLocation } from "react-router-dom";
import ButtonUIComponent from "../UI/Button";
import { useEffect, useState } from "react";
import { URLS } from "../../utils";
import { useTranslation } from "react-i18next";

const BackButton = ({
    btnText = "",
    redirectionKey = "",
    redirectionConfig = {}
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [url, setUrl] = useState("");

    useEffect(() => {
        if (redirectionKey === "dashboard") {
            setUrl(`${URLS.DASHBOARD_URL}`);
        } else if (redirectionKey === "courses" && redirectionConfig?.slug) {
            let build = `${URLS.COURSE_BASE_URL}/${redirectionConfig?.slug}/${redirectionConfig?.path || "overview"}`;
            setUrl(build);
        }
    }, [redirectionConfig, redirectionKey]);

    return (
        <ButtonUIComponent
            buttonInner={
                <div className="d-flex align-items-center">
                    <img
                        src={`/assets/icons/arrow-left.svg`}
                        alt={`Back`}
                        className={`img-fluid`}
                    />
                    <span className="ms-2">{btnText || t("common.back")}</span>
                </div>
            }
            className={`btn-sm bg-white border text-dark px-3 py-2`}
            onClick={() => navigate(url || -1)}
        />
    );
};

export default BackButton;
