import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchInstitutionList } from "../services/admin/reducer/institution";

const useGetInstitutionList = (props) => {
    const { items, isLoading, isError } = useSelector(
        (state) => state.institution
    );
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchInstitutionList(props?.params || {}));
    }, [dispatch]);

    return {
        list: items?.data || [],
        count: items?.count || 0,
        isLoading,
        isError
    };
};

export default useGetInstitutionList;
