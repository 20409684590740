import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { Fragment } from "react";
import { NavLink } from "react-router-dom";

import LanguageDropdown from "../../components/UI/Dropdown/language";
import LandingAboutSection from "../../modules/landing/about";
import LandingContactComponent from "../../modules/landing/contact";
import LandingMakingDiffComponent from "../../modules/landing/difference";
import LandingPlatformFeaturesComponent from "../../modules/landing/features";
import LandingFooterComponent from "../../modules/landing/footer";
import LandingGalleryComponent from "../../modules/landing/gallery";
import LandingHeroComponent from "../../modules/landing/hero";
import LandingInspirationComponent from "../../modules/landing/inspiration";
import LandingHeader from "../../modules/landing/header";
import LandingRoadmapComponent from "../../modules/landing/roadmap";
import { useTranslation } from "react-i18next";
import TeamLandingComponent from "../../modules/landing/team";
import JoinRequestDropdown from "../../components/UI/Dropdown/JoinRequest";
import { ModalContextProvider } from "../../context/modal-context";
import institutionReducer from "../../services/admin/reducer/institution";
import { Provider } from "react-redux";

const store = configureStore({
    reducer: {
        institution: institutionReducer
    },
    middleware: (gDm) => gDm()
});

setupListeners(store.dispatch);

const LandingPage = () => {
    const { t } = useTranslation();
    return (
        <Provider store={store}>
            <ModalContextProvider>
                <div className="top-menu px-md-4">
                    <div className="d-flex align-items-center justify-content-between w-100 position-relative py-3">
                        <div className="d-md-flex d-none">
                            <LanguageDropdown />
                        </div>
                        <div className="d-flex box-centered info">
                            {/* <div className="d-flex align-items-center box">
                                <div className="d-block">
                                    <img
                                        src={`/assets/images/nav1.webp`}
                                        alt={`Shri Siddaramaiah`}
                                        className={"img-fluid"}
                                        width={48}
                                        style={{ minWidth: 48 }}
                                    />
                                </div>
                                <div className="d-flex flex-column fs-14 ms-2">
                                    <div className="text-gray-600 fw-semibold text-nowrap">
                                        {t("landing.topBar.siddara_title")}
                                    </div>
                                    <span
                                        className="text-gray-300 fs-12 text-nowrap"
                                        dangerouslySetInnerHTML={{
                                            __html: t(
                                                "landing.topBar.siddara_subtitle"
                                            ).replace(/\n/, "<br/>")
                                        }}
                                    ></span>
                                </div>
                            </div> */}
                            <div className="d-flex align-items-center box">
                                <div className="d-block">
                                    <img
                                        src={`/assets/images/nav2.webp`}
                                        alt={`Initiative`}
                                        className={"img-fluid"}
                                        width={48}
                                        style={{ minWidth: 48 }}
                                    />
                                </div>
                                <div className="d-flex flex-column fs-14 ms-2">
                                    <div
                                        className="text-gray-600 fw-semibold text-nowrap"
                                        dangerouslySetInnerHTML={{
                                            __html: t(
                                                "landing.topBar.initiative_title"
                                            ).replace(/\n/, "<br/>")
                                        }}
                                    ></div>
                                </div>
                            </div>
                            {/* <div className="d-flex align-items-center box">
                                <div className="d-block">
                                    <img
                                        src={`/assets/images/nav3.webp`}
                                        alt={`Lakshmi Hebbalkar`}
                                        className={"img-fluid"}
                                        width={48}
                                        style={{ minWidth: 48 }}
                                    />
                                </div>
                                <div className="d-flex flex-column fs-14 ms-2">
                                    <div className="text-gray-600 fw-semibold text-nowrap">
                                        {t("landing.topBar.lakshmi_title")}
                                    </div>
                                    <span
                                        className="text-gray-300 fs-12 text-nowrap"
                                        dangerouslySetInnerHTML={{
                                            __html: t(
                                                "landing.topBar.lakshmi_subtitle"
                                            ).replace(/\n/, "<br/>")
                                        }}
                                    ></span>
                                </div>
                            </div> */}
                        </div>
                        <div className="d-md-flex d-none align-self-center">
                            <JoinRequestDropdown/>
                            <NavLink
                                type={"button"}
                                to={`/user/login`}
                                className={
                                    "btn btn-primary btn-sm px-4 fw-semibold"
                                }
                            >
                                {t("common.login")}
                            </NavLink>
                        </div>
                    </div>
                </div>
                <LandingHeader />
                <section className="hero-section" id="home">
                    <LandingHeroComponent />
                </section>
                <section className="about-section">
                    <LandingAboutSection />
                </section>
                <section className="platform-features" id="features">
                    <LandingPlatformFeaturesComponent />
                </section>
                <section className="our-roadmap" id="roadmap">
                    <LandingRoadmapComponent />
                </section>
                <section className="making-difference" id="initiative">
                    <LandingMakingDiffComponent />
                </section>
                {/* <section className="our-inspiration" id="inspirations">
                    <LandingInspirationComponent />
                </section> */}
                <section className="our-team" id="team">
                    <TeamLandingComponent />
                </section>
                {/* <section className="gallery" id="gallery">
                    <LandingGalleryComponent />
                </section> */}
                <section className="contact-us" id="contact-us">
                    <LandingContactComponent />
                </section>
                <footer>
                    <LandingFooterComponent />
                </footer>
            </ModalContextProvider>
        </Provider>
    );
};

export default LandingPage;
