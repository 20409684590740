import classNames from "classnames";
import _ from "lodash";
import { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? "#f5f5f5" : "white",
    ...draggableStyle
});

const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#f5f5f5" : "#fefefe"
});

const ListDraggableUIComponent = ({
    data = [],
    separator = false,
    listKey = "1",
    render = (record) => {},
    onDragEndChange = () => {}
}) => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        setItems(data);
    }, [data]);

    function onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const newItems = reorder(
            items,
            result.source.index,
            result.destination.index
        );

        setItems(newItems);
        onDragEndChange(
            result.source.index,
            result.destination.index,
            newItems
        );
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={`droppableId-${listKey || 1}`}>
                {(provided, snapshot) => (
                    <ul
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={classNames(
                            `list-unstyled flex-column nm-list`,
                            {
                                separator: separator
                            }
                        )}
                        style={getListStyle(snapshot.isDraggingOver)}
                    >
                        {_.map(items, (item, index) => {
                            return (
                                <Draggable
                                    key={item.id}
                                    draggableId={item?.id?.toString()}
                                    index={index}
                                >
                                    {(provided, snapshot) => (
                                        <li
                                            ref={provided.innerRef}
                                            {...provided.dragHandleProps}
                                            {...provided.draggableProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >
                                            <div className="d-flex">
                                                <span className="me-3 mt-1 lh-1">
                                                    <img
                                                        src={`/assets/icons/drag.svg `}
                                                    />
                                                </span>
                                                <div className="d-flex w-100">
                                                    {render(item, index)}
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                </Draggable>
                            );
                        })}
                        {provided.placeholder}
                    </ul>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default ListDraggableUIComponent;
