import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchTeacherList } from "../services/admin/reducer/teachers";
import { KeyGenerator } from "../utils/fn";

const useGetInstructorList = (props) => {
    const { items, isLoading, isError } = useSelector(
        (state) => state.teachers
    );
    const isReturnCourseInstructorsOnly = props?.isReturnCourseInstructorsOnly ?? false;
    let options = {};

    if (props?.courseId) {
        options.courseId = props.courseId;
    }
    if (isReturnCourseInstructorsOnly) {
        options.isReturnCourseInstructorsOnly = isReturnCourseInstructorsOnly;
    }

    const key = KeyGenerator({...options}) || "home";
    const dispatch = useDispatch();

    useEffect(() => {
        if (!items?.[key]?.data) {
            dispatch(fetchTeacherList({...options}));
        }
    }, [dispatch, items]);

    return {
        list: items[key]?.data || [],
        count: items[key]?.count || 0,
        isLoading,
        isError
    };
};

export default useGetInstructorList;
