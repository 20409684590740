import { useTranslation } from "react-i18next";

const LandingAboutSection = () => {
    const { t } = useTranslation();
    return (
        <div className="container-fluid px-4 px-md-0">
            <div className="row align-items-center w-100">
                <div className="col-md-6 offset-md-0 col-10 offset-1 px-md-0">
                    <div className="d-flex">
                        <img
                            src={`/assets/images/about-us.webp`}
                            className={`img-fluid`}
                            alt={`About Namma Shaale`}
                        />
                    </div>
                </div>
                <div className="col-lg-5 col-md-6 ps-md-4">
                    <div className="d-flex flex-column justify-content-center h-100 mt-4 mt-md-0">
                        <div className="text-dark-head fs-48 fw-bold">
                            {t("landing.about.title")}
                        </div>
                        <div className="d-flex flex-column mt-4 fs-20">
                            <p>{t("landing.about.textA")}</p>
                            <p>{t("landing.about.textB")}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingAboutSection;
