export const PAGE_LIMIT = 10;

export const Genders = {
    MALE: "MALE",
    FEMALE: "FEMALE",
    OTHER: "OTHER"
};

export const InstitutionType = {
    URBAN: "URBAN",
    RURAL: "RURAL"
};

export const UserType = {
    TEACHER: "TEACHER",
    STUDENT: "STUDENT"
};

export const CourseContentTypes = {
    VIDEO: "VIDEO",
    DOCUMENT: "DOCUMENT"
};

export const CourseAssessmentTypes = {
    QUIZ: "QUIZ",
    WORKSHEET: "WORKSHEET"
};

export const COURSE_CONTENT_TYPE = {
    0: "DOCUMENT",
    1: "VIDEO",
    2: "WORKSHEET",
    4: "QUIZ"
};

export const COURSE_CONTENT_GROUP = {
    content: ["document", "video"],
    assessment: ["quiz", "worksheet"]
};

export const SortingCriteria = {
    ALPHABETICAL: "ALPHABETICAL",
    LATEST: "LATEST"
};

export const CourseEnableOptions = {
    ALL: {
        text: "All",
        value: "null"
    },
    ENABLED: {
        text: "Enabled",
        value: "false"
    },
    DISABLED: {
        text: "Disabled",
        value: "true"
    }
};

export const LanguageCode = {
    ENGLISH: {
        short: "EN",
        value: "ENGLISH"
    },
    KANNADA: {
        short: "KA",
        value: "KANNADA"
    }
};

export const CourseCategory = {
    ACADEMIC: {
        text: "Academic",
        value: "ACADEMIC"
    },
    COMPETITIVE_EXAM: {
        text: "Competitive Exam",
        value: "COMPETITIVE_EXAM"
    },
    SKILL_DEVELOPMENT: {
        text: "Skill Development",
        value: "SKILL_DEVELOPMENT"
    },
    E_LIBRARY: {
        text: "E Library",
        value: "E_LIBRARY"
    }
};

export const CourseType = {
    INTERNAL: {
        text: "Internal",
        value: "INTERNAL"
    },
    EXTERNAL: {
        text: "External",
        value: "EXTERNAL"
    }
};

export const RegExps = {
    PASSWORD: {
        exp: /^(?=.*\d)(?=.*[@$!%*#?&])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
        errorMessage:
            "Password should be minimum eight characters, containing at least one capital letter, one small letter, one number and one special character"
    },
    PHONE_NUMBER: {
        exp: /^\d{10}$/,
        errorMessage: "Invalid phone number. Note: Do not add the extension."
    },
    COLOR_HEX_CODE: {
        exp: /^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/,
        errorMessage: "Invalid Hex Color Code"
    },
    PINCOODE: {
        exp: /^[1-9][0-9]{5}$/,
        errorMessage: "Invalid Indian PIN Code"
    }
};

export const FILE_SIZE = {
    IMAGE: {
        size: 2000,
        message: "File size should be less than 2MB"
    },
    DOCUMENT: {
        size: 5000,
        message: "File size should be less than 5MB"
    }
};
