import _, { has } from "lodash";
import { useNavigate } from "react-router-dom";
import { URLS } from "../../../utils";
import {
    COURSE_CONTENT_GROUP,
    COURSE_CONTENT_TYPE
} from "../../../utils/constants";
import ButtonUIComponent from "../../UI/Button";
import DropdownUIComponent, {
    DropdownItemUIComponent
} from "../../UI/Dropdown";
import { useTranslation } from "react-i18next";

const iconSelector = (
    type,
    isUser = false,
    isCompleted = false,
    hasAnswerSheet = false,
    titles = {}
) => {
    // console.log(titles);
    let name = "video.svg";
    let buttons = [];
    switch (type) {
        case COURSE_CONTENT_TYPE[1]: {
            name = "video.svg";
            buttons.push({
                name: titles.watchVideo,
                path: `video/:id`
            });
            break;
        }
        case COURSE_CONTENT_TYPE[0]: {
            name = "article.svg";
            buttons.push({
                name: titles.viewDocument,
                path: `document/:id`
            });
            break;
        }
        case COURSE_CONTENT_TYPE[4]: {
            name = "quiz.svg";
            buttons.push({
                name: isUser
                    ? !isCompleted
                        ? titles.startQuiz
                        : titles.viewResult
                    : titles.viewQuiz,
                path: `quiz/:id`
            });
            break;
        }
        case COURSE_CONTENT_TYPE[2]: {
            name = "document.svg";
            let view = {
                name: titles.viewAssessment,
                path: `worksheet/:id`
            };
            let answer = {
                name: titles.viewAnswerKey,
                path: `worksheet/:id/#key`
            };
            buttons.push(view);
            if (hasAnswerSheet && (!isUser || (isUser && isCompleted))) {
                buttons.push(answer);
            }
            break;
        }
        default: {
        }
    }

    return { icon: name, buttons };
};

const CourseContentItemTemplate = ({
    record,
    itemIndex,
    isUser = false,
    isCompleted = false,
    handleEdit = (record) => {},
    deleteHandler = (record) => {}
}) => {
    const fields = [
        "watchVideo",
        "viewAssessment",
        "viewDocument",
        "startQuiz",
        "viewQuiz",
        "viewAnswerKey",
        "viewResult"
    ];
    const { t } = useTranslation();
    const titles = _.map(fields, (field) => {
        return { value: t(`common.${field}`), key: field };
    }).reduce((p, c, i) => {
        p[c.key] = c.value;
        return p;
    }, {});

    const navigate = useNavigate();
    const { icon, buttons } = iconSelector(
        record?.type,
        isUser,
        isCompleted,
        record?.answerKeyDocumentUri || false,
        titles
    );
    const maxCharLength = Math.ceil((window.innerWidth / 10) * 2);
    const currentLength = record?.description?.length || 0;

    return (
        <div className={"d-flex justify-content-between w-100"}>
            <div className="d-flex align-items-start">
                <div className="d-flex mt-1">
                    <img
                        src={`/assets/icons/${icon}`}
                        alt={"Icon"}
                        className={`img-fluid mw-22`}
                    />
                </div>
                <div className="d-flex flex-column ms-2">
                    <div className="fs-16 text-gray-600 fw-bold">
                        {record.title}
                    </div>
                    <div className="fs-12 text-gray-300 mt-1">
                        {record.description?.substr(0, maxCharLength)}
                        {currentLength > maxCharLength ? "..." : ""}
                    </div>
                </div>
            </div>
            <div className="d-flex align-self-start">
                <div
                    className="d-flex align-self-start flex-column flex-md-row"
                    style={{ gap: 12 }}
                >
                    {_.map(buttons, (button, index) => {
                        return (
                            <ButtonUIComponent
                                key={index}
                                className={`btn-sm py-1 fs-12 text-nowrap fw-semibold`}
                                buttonInner={button.name}
                                onClick={() =>
                                    navigate(
                                        `${URLS.COURSE_BASE_URL}/${record?.courseId}/${button.path.replace(":id", record?.id)}`,
                                        {
                                            state: {
                                                previous:
                                                    itemIndex === 0
                                                        ? false
                                                        : true
                                            }
                                        }
                                    )
                                }
                            />
                        );
                    })}
                </div>
                {!isUser && (
                    <div className="d-flex ms-2" style={{ minWidth: 20 }}>
                        <DropdownUIComponent
                            className="border-0 rounded-2 d-inline-block lh-1"
                            btnClassName={`def-color btn-sm rounded-2 border-0 px-2 py-1 bg-transparent mw-22`}
                            isPrimary={false}
                            btnText={
                                <img
                                    src={`/assets/icons/three-dot.svg`}
                                    className={"img-fluid"}
                                    alt={"Three Dot Menu"}
                                />
                            }
                            toggleIcon={false}
                        >
                            <DropdownItemUIComponent>
                                <ButtonUIComponent
                                    className={`pt-0 px-0 btn-sm bg-transparent border-0 btn-danger text-dark no-hover`}
                                    buttonInner={
                                        <div className="d-flex align-items-center">
                                            <img
                                                width={14}
                                                src={`/assets/icons/pencil.svg`}
                                            />
                                            <span className="ms-2 fs-12 fw-semibold">
                                                {t("common.editDetails")}
                                            </span>
                                        </div>
                                    }
                                    onClick={(e) => handleEdit(record)}
                                />
                            </DropdownItemUIComponent>
                            <DropdownItemUIComponent>
                                <ButtonUIComponent
                                    className={`pb-0 px-0 btn-sm bg-transparent border-0 btn-danger text-danger no-hover`}
                                    buttonInner={
                                        <div className="d-flex align-items-center">
                                            <img
                                                width={14}
                                                src={`/assets/icons/red-minus.svg`}
                                            />
                                            <span className="ms-2 fs-12 fw-semibold text-nowrap">
                                                {COURSE_CONTENT_GROUP.content.includes(
                                                    record?.type?.toLowerCase()
                                                )
                                                    ? t("common.deleteContent")
                                                    : t(
                                                          "common.deleteAssessment"
                                                      )}
                                            </span>
                                        </div>
                                    }
                                    onClick={(e) => deleteHandler(record)}
                                />
                            </DropdownItemUIComponent>
                        </DropdownUIComponent>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CourseContentItemTemplate;
