import { useEffect, useState } from "react";
import { PAGE_LIMIT } from "../../../utils/constants";
import DefaultCardLoader from "../../common/Loader";
import Axios from "axios";
import DropdownUIComponent from "../../UI/Dropdown";
import { URLS } from "../../../utils";
import { ExtractErrorMessage, getTokenFromStorage } from "../../../utils/fn";
import _ from "lodash";
import ButtonUIComponent from "../../UI/Button";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useAuthContext } from "../../../context/auth-context";

const NotificationDropdown = () => {
    const { userObject, latestNotifications } = useAuthContext();
    const lastReadAt = userObject?.lastReadNotificationsAt || null;
    const [loading, setLoading] = useState(true);
    const [trigger, setTrigger] = useState(1);
    const [data, setData] = useState([]);
    const [error, setError] = useState(false);
    const [config, setConfig] = useState({
        pageNumber: 1,
        createdAtFirstItem: null
    });
    const [haveLatestNotification, setHaveLatestNotification] = useState(false);

    useEffect(() => {
        if (latestNotifications.notification) {
            setHaveLatestNotification(
                dayjs(lastReadAt).isBefore(
                    dayjs(latestNotifications.notification)
                )
            );
        }
    }, [latestNotifications]);

    useEffect(() => {
        if (trigger > 1) {
            fetchNotifications();
        }
    }, [trigger, config]);

    const fetchNotifications = async () => {
        try {
            setLoading(true);
            let qp = {};
            if (config.pageNumber > 1 && config.createdAtFirstItem) {
                qp = { ...config };
            }
            let queryString = new URLSearchParams(qp).toString();
            queryString = queryString ? `?${queryString}` : "";

            const response = await Axios.get(
                `${URLS.API_URL}v1/notifications${queryString}`,
                {
                    headers: {
                        Authorization: getTokenFromStorage()
                    }
                }
            );
            setData([...data, ...response?.data?.data]);
            setHaveLatestNotification(false);
        } catch (error) {
            setError(true);
            const { toast: toastMsg } = ExtractErrorMessage(
                error?.response?.data
            );
            toast.error(toastMsg);
        } finally {
            setLoading(false);
        }
    };

    const handlePagination = (e) => {
        e.stopPropagation();
        setConfig({
            pageNumber: config.pageNumber + 1,
            createdAtFirstItem: data && data[0]?.createdAt
        });
    };

    return (
        <DropdownUIComponent
            btnText={
                <img
                    src={`/assets/icons/notification.svg`}
                    alt={`Notification`}
                    className={`img-fluid`}
                    style={{ minWidth: 20 }}
                />
            }
            toggleIcon={false}
            btnClassName={`btn-sm btn-sm rounded-circle px-2 py-2 ${haveLatestNotification ? "noti" : ""}`}
            extraStyles={{
                className: `notification-drop`
            }}
            onClick={(e) => setTrigger(2)}
        >
            <div className="scrollableDivNotificationDrop">
                <ul className="list-unstyled p-0 m-0 drop-list">
                    {_.map(data || [], (item, index) => {
                        const isRead = dayjs(item.createdAt).isBefore(
                            dayjs(lastReadAt)
                        );
                        return (
                            <li key={item.id} data-read={isRead}>
                                <div
                                    className="d-flex align-items-center"
                                    style={{ gap: 16 }}
                                >
                                    <div className="d-flex">
                                        <img
                                            src={`/assets/icons/events.svg`}
                                            className={`img-fluid mw-22`}
                                            alt={`Icon`}
                                        />
                                    </div>
                                    <div className="d-flex flex-column">
                                        <span className="text-gray-600 fw-semibold fs-12">
                                            {item.forumTopicTitle}
                                        </span>
                                        <span className="text-gray-300 fs-12">
                                            Sent{" "}
                                            {dayjs(item.createdAt).fromNow()}
                                        </span>
                                    </div>
                                </div>
                            </li>
                        );
                    })}
                    {!loading && _.isEmpty(data) && (
                        <li>
                            <span className="text-gray-600 fw-semibold fs-12">
                                No Notifications!
                            </span>
                        </li>
                    )}
                    {!_.isEmpty(data) && data.length % PAGE_LIMIT === 0 && (
                        <li>
                            <div className="d-flex justify-content-center">
                                <ButtonUIComponent
                                    onClick={handlePagination}
                                    buttonInner={`Load more..`}
                                    className={`btn btn-sm border-0 p-0 text-blue bg-white`}
                                />
                            </div>
                        </li>
                    )}
                </ul>
            </div>
            <DefaultCardLoader show={loading} />
        </DropdownUIComponent>
    );
};

export default NotificationDropdown;
