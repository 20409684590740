import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { Provider } from "react-redux";
import { Outlet } from "react-router-dom";
import { AuthContextProvider } from "../../context/auth-context";
import { ModalContextProvider } from "../../context/modal-context";
import teacherListReducer from "../../services/admin/reducer/teachers";
import institutionReducer from "../../services/admin/reducer/institution";
import courseDetailReducer from "../../services/common/reducer/course";
import courseListReducer from "../../services/admin/reducer/courses";
import studentListReducer from "../../services/admin/reducer/students";
import forumDataReducer from "../../services/common/reducer/forum";
import forumPageReducer from "../../services/common/reducer/forum/single";
import courseCompletionReducer from "../../services/common/reducer/course/activity";
import coursesBundleReducer from "../../services/admin/reducer/courses/bundle";

const store = configureStore({
    reducer: {
        courses: courseListReducer,
        courseDetail: courseDetailReducer,
        institution: institutionReducer,
        teachers: teacherListReducer,
        students: studentListReducer,
        forumData: forumDataReducer,
        forumDetail: forumPageReducer,
        courseCompletion: courseCompletionReducer,
        coursesBundle: coursesBundleReducer
    },
    middleware: (gDm) => gDm()
});

setupListeners(store.dispatch);

const UserMasterLayout = (props) => {
    return (
        <Provider store={store}>
            <ModalContextProvider>
                <AuthContextProvider>
                    <Outlet />
                </AuthContextProvider>
            </ModalContextProvider>
        </Provider>
    );
};

export default UserMasterLayout;
