// src/reportedActivitySlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { URLS } from "../../../../utils";
import Axios from "axios";
import { getTokenFromStorage, KeyGenerator } from "../../../../utils/fn";

// Define async thunk
export const fetchReportedAcitivity = createAsyncThunk(
    "reportedActivity/fetchData",
    async ({ ...params }) => {
        try {
            const qs = {};

            if (params.hasOwnProperty("isResolved")) {
                qs.isResolved = params.isResolved || false;
            }
            if (params.hasOwnProperty("search")) {
                qs.search = params.search;
            }

            if (params.pageNumber > 1 && params.createdAtFirstItem) {
                qs.pageNumber = params.pageNumber;
                qs.createdAtFirstItem = params.createdAtFirstItem;
            }

            let qp = new URLSearchParams(qs).toString();
            qp = qp ? `?${qp}` : "";

            const response = await Axios.get(
                `${URLS.API_URL}v1/removal-requests${qp}`,
                {
                    headers: {
                        Authorization: getTokenFromStorage()
                    }
                }
            );
            return response.data;
        } catch (error) {
            throw new Error(error.response?.data?.error || error);
        }
    }
);

const formatReportedActivityData = (data, action = 0) => {
    if (action === 0) {
        return {
            reason: data?.reason || "",
            forumTopicId: data.id
        };
    } else {
        return {
            isRemoveEntity: data.isRemoveEntity || false
        };
    }
};

// action => 0: POST, 1: PUT
export const ReportedForumOperation = async (payload, action = 0) => {
    let config = {
        url: `${URLS.API_URL}v1/removal-requests`,
        method: "POST"
    };

    if (action === 1) {
        config.url = `${config.url}/resolve/${payload.id}`;
        config.method = `PATCH`;
    }

    const response = await Axios({
        ...config,
        data: formatReportedActivityData(payload, action),
        headers: {
            Authorization: getTokenFromStorage()
        }
    });
    return response.data;
};

// Create slice
export const reportedActivitySlice = createSlice({
    name: "reportedActivity",
    initialState: {
        items: {},
        filters: {},
        pageNumber: 1,
        createdAtFirstItem: null,
        isLoading: false,
        isError: false,
        status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null
    },
    reducers: {
        refetchActivity: (state, action) => {
            state.items = {};
            state.isLoading = false;
            state.isError = false;
            state.error = null;
            state.status = "idle";
            state.pageNumber = 1;
            state.createdAtFirstItem = null;
        },
        updateReportedActivity: (state, action) => {
            let { payload } = action;
            const existingData = state.items;
            if (existingData && existingData.data) {
                existingData.data = _.filter(
                    existingData?.data || [],
                    (items) => items.id !== payload.id
                );
                existingData.count = (existingData?.count || 1) - 1;
                Object.assign(state.items, existingData);
            }
        },
        setReportedPageParams: (state, action) => {
            let { payload } = action;
            state.pageNumber = payload.pageNumber || 1;
            state.createdAtFirstItem = payload.createdAtFirstItem || null;
        },
        setReportedFilters: (state, action) => {
            state.isError = false;
            state.pageNumber = 1;
            state.createdAtFirstItem = null;
            state.filters = action.payload;
            state.items = {
                data: [],
                count: 0
            };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchReportedAcitivity.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
                state.isError = false;
            })
            .addCase(fetchReportedAcitivity.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.isLoading = false;
                state.isError = false;
                if (_.isEmpty(action.payload.data)) {
                    return;
                }
                let existingData = _.cloneDeep(state.items) || { data: [] };
                existingData.data = [
                    ...(existingData?.data || []),
                    ...action.payload.data
                ];
                Object.assign(state.items, existingData);
            })
            .addCase(fetchReportedAcitivity.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.isLoading = false;
                state.isError = true;
            });
    }
});

export const {
    refetchActivity,
    setReportedPageParams,
    setReportedFilters,
    updateReportedActivity
} = reportedActivitySlice.actions;

export default reportedActivitySlice.reducer;
