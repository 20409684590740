import { NavLink } from "react-router-dom";
import { URLS } from "../../../utils";
import { CourseType } from "../../../utils/constants";
import { Card } from "react-bootstrap";

const UserCourseCardComponent = ({
    data = {},
    loading = false,
    isBundle = false,
    fromBundle = false
}) => {
    let isExternal = data?.sourceType === CourseType.EXTERNAL.value;
    let { target, url } = {
        url: isBundle
            ? `${URLS.DASHBOARD_URL}course-bundle/${data?.id}`
            : isExternal
              ? data?.externalSourceUrl
              : `/user/dashboard/courses/${data?.id}/overview`,
        target: isExternal ? "_blank" : "_self"
    };

    if (loading) {
        return (
            <div className="col-md-4 mt-4">
                <div className="card card-skeleton"></div>
            </div>
        );
    }

    return (
        <div className="col-md-4 mt-4">
            <NavLink
                to={`${url}`}
                className={`text-decoration-none cp`}
                target={target}
                state={{ bundle: fromBundle }}
            >
                <Card className="course-card cp">
                    <Card.Img
                        alt={`${data.title}`}
                        className={`img-fluid`}
                        src={`${URLS.MEDIA_URL}${data.thumbnailImageUri}`}
                        style={{
                            minHeight: 200,
                            backgroundColor: "#dbdbdb"
                        }}
                    />
                    <Card.Body>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex flex-column">
                                <div className="fw-600">{data.title}</div>
                                <div className="text-gray-300 fs-14 mt-1">
                                    {data.uidCode}
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </NavLink>
        </div>
    );
};

export default UserCourseCardComponent;
