import { URLS } from "../../../utils";
import { GetAvatarColor, GetAvatarInitials } from "../../../utils/fn";

const UserAvatar = ({
    externalImage = "",
    profileImage,
    width = 24,
    name,
    size = "sm",
    externalImageClass = ""
}) => {
    const astr = GetAvatarInitials(name);
    const acolor = GetAvatarColor(astr);

    return profileImage || externalImage ? (
        <img
            width={width || 24}
            className={`img-fluid rounded-circle ${!profileImage ? externalImageClass : ""}`}
            src={
                profileImage
                    ? `${URLS.MEDIA_URL}${profileImage}`
                    : externalImage
            }
            alt={name || "User Avatar"}
        />
    ) : (
        <div className="d-flex">
            <div className={`avatar ${size}`} data-color={acolor || ""}>
                {astr}
            </div>
        </div>
    );
};

export default UserAvatar;
