import DefaultCardLoader from "../Loader";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonUIComponent from "../../UI/Button";
import { parseHTMLContent } from "../../../utils/fn";

const CourseAssessmentComponent = ({
    content = "",
    isEditable = false,
    onSave = (data, cb) => {}
}) => {
    const { t } = useTranslation();
    const [edit, setEdit] = useState(false);
    const [assessmentDetails, setAssessmentDetails] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setAssessmentDetails(content);
    }, [content]);

    const handleOnChange = (e) => {
        setAssessmentDetails(e.target.value);
    };

    const saveInformation = async () => {
        setLoading(true);
        onSave({ gradingCriteria: assessmentDetails }, (cb) => {
            if (cb) {
                setEdit(false);
            }
            setLoading(false);
        });
    };

    return (
        <div className="card bg-white content-card">
            <div className="card-heading d-flex justify-content-between align-items-center">
                <div className="fs-5 fw-bold text-gray-600">
                    {t("common.courseAssessmentDetails")}
                </div>
                {isEditable &&
                    (!edit ? (
                        <ButtonUIComponent
                            buttonInner={
                                <img
                                    src={`/assets/icons/pencil.svg`}
                                    className={`img-fluid`}
                                    alt={`Edit`}
                                />
                            }
                            className={`bg-transparent border-0 px-0 py-1`}
                            onClick={(e) => {
                                e.stopPropagation();
                                setEdit(true);
                            }}
                        />
                    ) : (
                        <ButtonUIComponent
                            buttonInner={`Save Info`}
                            isPrimary={true}
                            className={`btn-sm py-2`}
                            onClick={saveInformation}
                        />
                    ))}
            </div>
            {!edit ? (
                <div className="card-body p-4">
                    <p
                        className="mb-0"
                        dangerouslySetInnerHTML={{
                            __html: parseHTMLContent(
                                assessmentDetails,
                                "No Assessment and Grading Details present."
                            )
                        }}
                    />
                </div>
            ) : (
                <form className="content-card--form p-4">
                    <div className="row">
                        <div className="col-12">
                            <textarea
                                className="form-control"
                                onChange={handleOnChange}
                            >
                                {assessmentDetails}
                            </textarea>
                        </div>
                    </div>
                </form>
            )}
            <DefaultCardLoader show={loading} />
        </div>
    );
};

export default CourseAssessmentComponent;
