import _ from "lodash";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import CourseContentItemTemplate from "../../../components/common/courses/ContentItem";
import DefaultCardLoader from "../../../components/common/Loader";
import EmptyBoxComponent from "../../../components/common/reusable/EmptyBox";
import ListUIComponent from "../../../components/UI/List";
import { useAuthContext } from "../../../context/auth-context";
import { FetchCourseCompletion } from "../../../services/common";
import { reorderCourseContent } from "../../../services/common/reducer/course";
import {
    CourseContentHandler,
    fetchCourseContent
} from "../../../services/common/reducer/course/content";
import { UserType } from "../../../utils/constants";
import { KeyGenerator } from "../../../utils/fn";

const UserCourseContentComponent = () => {
    const { slug } = useParams();
    const { userObject } = useAuthContext();
    const { content, isLoading, isError } = useSelector(
        (state) => state.courseDetail
    );
    const { items: completionItem } = useSelector(
        (state) => state.courseCompletion
    );
    const courseCompletion =
        completionItem && completionItem[slug] ? completionItem[slug] : {};

    const cacheKey = KeyGenerator({ id: slug });
    const courseContent =
        content && content[cacheKey] ? content[cacheKey] : undefined;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCourseContent({ id: slug }));
        dispatch(FetchCourseCompletion(slug));
    }, [dispatch]);

    const reorderHandler = async (start, end, items = []) => {
        try {
            if (start === end) return;
            let totalLength = items.length;
            let nextElement = totalLength - 1 === end ? null : end + 1;
            let nextId = nextElement ? items[nextElement].id : null;

            // hit reorder API
            await CourseContentHandler(
                {
                    id: items[end].id,
                    nextId: nextId
                },
                2
            );

            dispatch(
                reorderCourseContent({
                    meta: { id: slug },
                    data: {
                        startIndex: start,
                        endIndex: end,
                        nextElement
                    }
                })
            );
        } catch (error) {}
    };

    return (
        <div className="row mt-3">
            <div className="col-12">
                {isLoading ||
                (!isLoading && _.isEmpty(courseContent?.data || [])) ? (
                    <EmptyBoxComponent
                        isLoading={isLoading}
                        data={courseContent?.data}
                    />
                ) : (
                    <ListUIComponent
                        listKey={"admin-course-content"}
                        separator={false}
                        render={(
                            record,
                            isUser,
                            isCompleted = false,
                            index
                        ) => (
                            <CourseContentItemTemplate
                                itemIndex={index}
                                record={record}
                                isUser={isUser}
                                isCompleted={isCompleted}
                            />
                        )}
                        data={courseContent?.data || []}
                        onDragEndChange={reorderHandler}
                        userCheckButton={userObject?.type === UserType.STUDENT}
                        isCompleted={courseCompletion?.contentId || []}
                    />
                )}
            </div>
            <DefaultCardLoader isFixed show={isLoading} />
        </div>
    );
};

export default UserCourseContentComponent;
