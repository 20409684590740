import { Fragment, useEffect, useRef } from "react";
import { useState } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: "%",
                width: 90
            },
            aspect,
            mediaWidth,
            mediaHeight
        ),
        mediaWidth,
        mediaHeight
    );
}

const ImageCropper = ({
    imageFile = "",
    aspect = "",
    handleOnChange = () => {},
    onClose = () => {}
}) => {
    const imgRef = useRef(null);
    const [crop, setCrop] = useState();
    const [completedCrop, setCompletedCrop] = useState();
    const [image, setImage] = useState(null);

    useEffect(() => {
        if (imageFile) {
            setCrop(undefined);
            const reader = new FileReader();
            reader.addEventListener("load", () =>
                setImage(reader.result?.toString() || "")
            );
            reader.readAsDataURL(imageFile);
        }
    }, [imageFile]);

    function onImageLoad(e) {
        if (aspect) {
            const { width, height } = e.currentTarget;
            setCrop(centerAspectCrop(width, height, aspect));
        }
    }

    const getCroppedImage = () => {
        if (!completedCrop || !imgRef.current) {
            return;
        }

        const canvas = document.createElement("canvas");
        const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
        const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
        canvas.width = completedCrop.width;
        canvas.height = completedCrop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            imgRef.current,
            completedCrop.x * scaleX,
            completedCrop.y * scaleY,
            completedCrop.width * scaleX,
            completedCrop.height * scaleY,
            0,
            0,
            completedCrop.width,
            completedCrop.height
        );

        canvas.toBlob((blob) => {
            handleOnChange(blob);
        }, "image/jpeg");
    };

    return (
        !!image && (
            <Fragment>
                <div className="crop-overlay">
                    <div className="crop-section">
                        <div className="crop-section--layout flex-column">
                            <ReactCrop
                                src
                                crop={crop}
                                onChange={(_, percentCrop) =>
                                    setCrop(percentCrop)
                                }
                                onComplete={(c) => setCompletedCrop(c)}
                                aspect={aspect || 1}
                                style={{ maxHeight: "inherit" }}
                            >
                                <img
                                    ref={imgRef}
                                    alt="Crop Me"
                                    src={image}
                                    className={`img-fluid`}
                                    onLoad={onImageLoad}
                                />
                            </ReactCrop>
                            <div className="d-flex mt-3" style={{ gap: 12 }}>
                                <button
                                    className="btn btn-sm btn-primary rounded-pill fw-semibold"
                                    type={"button"}
                                    onClick={getCroppedImage}
                                >
                                    Crop
                                </button>
                                <button
                                    className="btn btn-sm btn-secondary rounded-pill fw-semibold"
                                    type={"button"}
                                    onClick={onClose}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    );
};

export default ImageCropper;
