import _ from "lodash";
import useQueryParams from "../../../hooks/useSearchQuery";

const SearchBarComponent = ({ placeholder = "", onChange = () => {} }) => {
    const qp = useQueryParams();

    const handleChange = (e) => {
        onChange(e.target.value);
    };

    return (
        <div className="position-relative">
            <div className="d-flex search-icon">
                <img
                    src={`/assets/icons/search.svg`}
                    alt={`Search Icon`}
                    className={`img-fluid`}
                />
            </div>
            <input
                autoComplete={"off"}
                style={{
                    borderRadius: 60
                }}
                className="form-control fs-14"
                name={`search`}
                defaultValue={qp.search || ""}
                placeholder={placeholder || `Search`}
                onChange={_.debounce(handleChange, 500)}
            />
        </div>
    );
};

export default SearchBarComponent;
