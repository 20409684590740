import _ from "lodash";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useModalContext } from "../../../context/modal-context";
import CreateEditLiveClassModal from "../../../modules/admin/modal/CreateEditLiveClass";
import { URLS } from "../../../utils";
import {
    dateTimeFormatter,
    KeyGenerator,
    timeFormatter
} from "../../../utils/fn";
import ButtonUIComponent from "../../UI/Button";

const CourseLiveClassComponent = ({
    getLiveClasses = () => {},
    data = [],
    isAdmin = true
}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { slug } = useParams();
    const { toggle } = useModalContext();
    const cacheKey = KeyGenerator({ id: slug });
    const liveClassData = data && data[cacheKey] ? data[cacheKey] : {};

    useEffect(() => {
        getLiveClasses();
    }, [slug]);

    const handleAddLiveClass = () => {
        toggle(<CreateEditLiveClassModal course={slug} />);
    };

    return (
        liveClassData && (
            <div className="card bg-white content-card">
                <div className="card-heading d-flex justify-content-between align-items-center">
                    <div className="fs-5 fw-bold text-gray-600">
                        {t("common.courseUpcomingLiveClasses")}
                    </div>
                </div>
                <div className="card-body p-4">
                    <ul
                        className="list-unstyled d-flex flex-column"
                        style={{ gap: 16 }}
                    >
                        {_.map(liveClassData?.data || [], (item, index) => {
                            let dateObj = timeFormatter(
                                {
                                    start: item.startDateTime,
                                    end: item.endDateTime
                                },
                                true
                            );
                            return (
                                <li key={item.id}>
                                    <div className="d-flex align-items-center">
                                        <span className="text-gray-400 fs-14 text-nowrap">
                                            {dateTimeFormatter(
                                                item.startDateTime,
                                                false,
                                                {
                                                    month: "short",
                                                    day: "numeric"
                                                }
                                            )}
                                        </span>
                                        <div className="d-flex bg-primary rounded-3 mx-3 w-100">
                                            <div className="d-flex flex-column px-3 py-2">
                                                <span className="fs-14 text-white fw-bold">
                                                    {item.title}
                                                </span>
                                                <div className="mt-0">
                                                    <img
                                                        src={`/assets/icons/link.svg`}
                                                        className={`img-fluid`}
                                                        alt={"Link icon"}
                                                    />
                                                    <a
                                                        href={
                                                            item.externalJoinUrl
                                                        }
                                                        target={"_blank"}
                                                        className={
                                                            "text-white fs-12 ms-2"
                                                        }
                                                    >
                                                        {t(
                                                            "common.meetingLink"
                                                        )}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column text-nowrap">
                                            <span className="text-gray-400 fs-14">
                                                {dateObj.start}
                                            </span>
                                            <span className="text-gray-300 fs-12">
                                                {dateObj.diff}hr
                                            </span>
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                        {_.isEmpty(liveClassData?.data || []) && (
                            <li>No Live Classes</li>
                        )}
                    </ul>
                    <div className="mt-4 pt-md-2">
                        {isAdmin ? (
                            <ButtonUIComponent
                                className={`btn-text text-dark fs-14 p-0 me-2 bg-transparent`}
                                buttonInner={
                                    <u>{t("common.addAnotherClass")}</u>
                                }
                                onClick={handleAddLiveClass}
                            />
                        ) : (
                            <ButtonUIComponent
                                className={`btn-text text-dark fs-14 p-0 me-2 bg-transparent`}
                                buttonInner={
                                    <u>{t("common.seeEntireSchedule")}</u>
                                }
                                onClick={() =>
                                    navigate(
                                        `${URLS.DASHBOARD_URL}calendar?courseId=${slug}`
                                    )
                                }
                            />
                        )}
                        <img
                            src={`/assets/icons/arrow-right.svg`}
                            style={{ width: 12 }}
                            className={"img-fluid"}
                            alt={`Right Icon`}
                        />
                    </div>
                </div>
            </div>
        )
    );
};

export default CourseLiveClassComponent;
