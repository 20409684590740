// src/courseListSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { URLS } from "../../../../utils";
import Axios from "axios";
import { getTokenFromStorage } from "../../../../utils/fn";
import { CourseType, SortingCriteria } from "../../../../utils/constants";

// Define async thunk
export const fetchCourseList = createAsyncThunk(
    "courseList/fetchData",
    async (params, { getState }) => {
        try {
            if (!params?.sortingCriteria) {
                params.sortingCriteria = SortingCriteria.ALPHABETICAL;
            }

            let qp = new URLSearchParams(params).toString();
            qp = qp ? `?${qp}` : "";

            const response = await Axios.get(`${URLS.API_URL}v1/courses${qp}`, {
                headers: {
                    Authorization: getTokenFromStorage()
                }
            });
            return response.data;
        } catch (error) {
            throw new Error(error.response.data.error);
        }
    }
);

const formatCourseData = (data, action = 0) => {
    let fd = new FormData();
    let commonFields = [
        "uidCode",
        "title",
        "languageCode",
        "sourceType",
        "category"
    ];
    let internalFields = ["description", "studyMaterial", "gradingCriteria", "listCourseInstructorTeacherIds"];
    let externalFields = ["externalSourceUrl"];
    let isExternal = data.sourceType === CourseType.EXTERNAL.value;

    if (data.thumbnailImageUri) {
        fd.append(
            `thumbnail`,
            _.isObject(data.thumbnailImageUri) &&
                data.thumbnailImageUri instanceof File
                ? data.thumbnailImageUri[0]
                : data.thumbnailImageUri
        );
    }
    if (!isExternal && data.courseLayoutImageUri) {
        fd.append(
            `courseLayout`,
            _.isObject(data.courseLayoutImageUri)
                ? data.courseLayoutImageUri[0]
                : data.courseLayoutImageUri
        );
    }

    let payload = _.omitBy(
        _.pick(
            data,
            commonFields.concat(!isExternal ? internalFields : externalFields)
        ),
        _.isNil
    );
    if (action === 1) {
        delete payload.sourceType;
    }
    if (!isExternal) {
        internalFields.forEach((key) => {
            if (!payload[key]) {
                payload[key] = null;
            }
        });
        payload.listCourseInstructorTeacherIds = _.map(
            payload?.listCourseInstructorTeacherIds || [],
            (item) => item.value
        );
        if (_.isEmpty(payload.listCourseInstructorTeacherIds)) {
            payload.listCourseInstructorTeacherIds = [];
        }
    }
    fd.append(`data`, JSON.stringify(payload));
    return fd;
};

// action => 0: POST, 1: PUT
export const SingleCourseOperation = async (payload, action = 0) => {
    let config = {
        url: `${URLS.API_URL}v1/courses`,
        method: "POST"
    };
    if (action !== 0) {
        config.url = `${config.url}/${payload.id}`;
        config.method = "PATCH";
    }

    const response = await Axios({
        ...config,
        data: formatCourseData(payload, action),
        headers: {
            Authorization: getTokenFromStorage()
        }
    });
    return response.data;
};

// Create slice
export const courseListSlice = createSlice({
    name: "courses",
    initialState: {
        items: {
            count: null,
            data: []
        },
        filters: {},
        isLoading: false,
        isError: false,
        status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null
    },
    reducers: {
        setCourseFilter: (state, action) => {
            state.items = {
                count: null,
                data: []
            };
            state.filters = action.payload || {};
        },
        updateCourseList: (state, action) => {
            try {
                let { payload } = action;
                // // console.log("Updating the course list item state: ", {...payload.data});
                if (payload.action === "create") {
                    let existingData = _.cloneDeep(state.items?.data) || [];
                    state.items.data = [{ ...payload.data }, ...existingData];
                    state.items.count = state.items.count || 1;
                } else {
                    let existingData = _.cloneDeep(state.items.data);
                    let index = _.findIndex(
                        existingData,
                        (e) => e.id === payload.data.id
                    );
                    state.items.data[index] = payload.data;
                }
            } catch (er) {
                // console.log(er);
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCourseList.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
                state.isError = false;
            })
            .addCase(fetchCourseList.fulfilled, (state, action) => {
                state.status = "succeeded";
                // Add fetched data to the state array
                state.items = action.payload;
                state.isLoading = false;
                state.isError = false;
            })
            .addCase(fetchCourseList.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.isLoading = false;
                state.isError = true;
            });
    }
});

export const { updateCourseList, setCourseFilter } = courseListSlice.actions;

export default courseListSlice.reducer;
