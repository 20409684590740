import _ from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import AdminDashboardTitle from "../../../components/admin/DashboardTitle";
import TeacherListFilter from "../../../components/admin/filters/teacher";
import AdminDashboardTabs from "../../../components/admin/Tabs";
import DefaultCardLoader from "../../../components/common/Loader";
import ButtonUIComponent from "../../../components/UI/Button";
import DropdownUIComponent, {
    DropdownItemUIComponent
} from "../../../components/UI/Dropdown";
import TableUIComponent from "../../../components/UI/Table";
import { useModalContext } from "../../../context/modal-context";
import useGetInstitutionList from "../../../hooks/useGetInstitutionList";
import {
    fetchTeacherList,
    setTeachersFilters,
    setTeachersPage
} from "../../../services/admin/reducer/teachers";
import {
    checkNextPage,
    GetAvatarColor,
    GetAvatarInitials
} from "../../../utils/fn";
import AddEditTeacherModal from "../modal/CreateEditTeacher";
import { PAGE_LIMIT, SortingCriteria } from "../../../utils/constants";
import ImportCSV from "../../../components/admin/import";
import { TeachersImport } from "../../../services/admin";
import { useTranslation } from "react-i18next";
import ChangePasswordModal from "../modal/ChangePassword";

const AdminTeacherBaseComponent = () => {
    const { toggle } = useModalContext();
    const { t } = useTranslation();
    const {
        items,
        isLoading,
        isError,
        currentPage,
        filters,
        createdAtFirstItem
    } = useSelector((state) => state.teachers);
    const dispatch = useDispatch();
    const teacherList = items && items["home"] ? items["home"] : {};
    const { list: InstitutionList } = useGetInstitutionList();
    const hasNextPage = checkNextPage(teacherList?.data || [], currentPage);

    useEffect(() => {
        dispatch(
            fetchTeacherList({
                pageNumber: currentPage || 1,
                createdAtFirstItem: createdAtFirstItem,
                ...filters
            })
        );
    }, [dispatch, currentPage, createdAtFirstItem, filters]);

    useEffect(() => {
        return () => {
            dispatch(setTeachersFilters({}));
        };
    }, []);

    const handleEdit = (record) => {
        toggle(<AddEditTeacherModal action={"edit"} record={record} />);
    };

    const handleView = (record) => {
        toggle(<AddEditTeacherModal action={"view"} record={record} />);
    };

    const handlePagination = () => {
        let shouldbeTime =
            filters?.sortingCriteria === SortingCriteria.ALPHABETICAL ||
            !filters?.sortingCriteria
                ? false
                : true;
        dispatch(
            setTeachersPage({
                page: currentPage + 1,
                createdAtFirstItem: shouldbeTime
                    ? teacherList?.data[0]?.createdAt
                    : teacherList?.data[0]?.name
            })
        );
    };

    const importCallback = () => {
        dispatch(setTeachersFilters({}));
    };

    const passwordManager = (record) => {
        toggle(<ChangePasswordModal type={"teacher"} record={record} />, { centered: true, size: "sm" });
    };

    return (
        <Fragment>
            <AdminDashboardTitle
                title={t(`common.teachers`)}
                showResult={teacherList?.count > 0}
                count={teacherList?.count || 0}
                btnText={t("modal.teacher.createNew")}
                onClick={() => toggle(<AddEditTeacherModal />)}
            >
                <ImportCSV
                    toastKey={"Teachers"}
                    ExportFn={TeachersImport}
                    callbackFn={importCallback}
                />
            </AdminDashboardTitle>
            <AdminDashboardTabs />
            <div className="container mt-4 mb-5 scrollableDiv">
                <TeacherListFilter />
                <InfiniteScroll
                    dataLength={teacherList?.data?.length || 0}
                    next={handlePagination}
                    hasMore={hasNextPage}
                    // hasMore={
                    //     !_.isEmpty(teacherList?.data || 0)
                    //         ? teacherList.data.length % PAGE_LIMIT === 0
                    //         : false
                    // }
                    scrollableTarget="scrollableDiv"
                >
                    <TableUIComponent
                        showEmpty={_.isEmpty(teacherList?.data || [])}
                        isLoading={isLoading}
                        columns={[
                            {
                                title: t("tableHead.name"),
                                render: (record) => {
                                    const astr = GetAvatarInitials(record.name);
                                    const acolor = GetAvatarColor(astr);
                                    return (
                                        <div className="d-flex align-items-center text-nowrap">
                                            <div className="d-flex">
                                                <div
                                                    className="avatar"
                                                    data-color={acolor || ""}
                                                >
                                                    {astr}
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column ms-2">
                                                <div className="text-gray-600 fw-semibold fs-14">
                                                    {record.name}
                                                </div>
                                                <span className="text-gray-300 fs-12">
                                                    {record.uidCode}
                                                </span>
                                            </div>
                                        </div>
                                    );
                                },
                                width: 280
                            },
                            {
                                title: t("tableHead.email"),
                                render: (record) => (
                                    <span className="ellipsis text-gray-300 fs-14 fw-medium">
                                        {record.email || "-"}
                                    </span>
                                ),
                                width: 280
                            },
                            {
                                title: t("tableHead.institution"),
                                render: (record) => (
                                    <span className="text-gray-300 fs-14 fw-medium">
                                        {_.find(
                                            InstitutionList || [],
                                            (e) => e.id === record.institutionId
                                        )?.name || record.institutionId}
                                    </span>
                                ),
                                width: 240
                            },
                            {
                                title: t("tableHead.medium"),
                                render: (record) => (
                                    <span className="text-gray-300 fs-14 fw-medium">
                                        {record.languageCode
                                            ? t(
                                                  `constants.${record.languageCode}`
                                              )
                                            : "-"}
                                    </span>
                                ),
                                width: 200
                            },
                            {
                                title: "",
                                render: (record) => (
                                    <DropdownUIComponent
                                        className="border-0 rounded-2 d-inline-block lh-1"
                                        btnClassName={`def-color btn-sm rounded-2 border-0 px-2 py-1 bg-transparent mw-22`}
                                        isPrimary={false}
                                        onToggle={() => {}}
                                        btnText={
                                            <img
                                                src={`/assets/icons/three-dot.svg`}
                                                className={"img-fluid"}
                                                alt={"Three Dot Menu"}
                                            />
                                        }
                                        toggleIcon={false}
                                    >
                                        <DropdownItemUIComponent>
                                            <ButtonUIComponent
                                                className={`pt-0 px-0 pb-2 btn-sm bg-transparent border-0 btn-danger text-dark no-hover`}
                                                buttonInner={
                                                    <div className="d-flex align-items-center">
                                                        <img
                                                            width={14}
                                                            src={`/assets/icons/pencil.svg`}
                                                        />
                                                        <span className="ms-2 fs-12 fw-semibold">
                                                            {t(
                                                                "common.editDetails"
                                                            )}
                                                        </span>
                                                    </div>
                                                }
                                                onClick={() =>
                                                    handleEdit(record)
                                                }
                                            />
                                        </DropdownItemUIComponent>
                                        <DropdownItemUIComponent>
                                            <ButtonUIComponent
                                                className={`pb-0 px-0 btn-sm bg-transparent border-0 text-dark no-hover`}
                                                buttonInner={
                                                    <div className="d-flex align-items-center">
                                                        {/* <img
                                                            width={14}
                                                            src={`/assets/icons/red-minus.svg`}
                                                        /> */}
                                                        <span className="ms-2 fs-12 fw-semibold">
                                                            {t("modal.changePassword.title")}
                                                        </span>
                                                    </div>
                                                }
                                                onClick={() => {
                                                    passwordManager(record);
                                                }}
                                            />
                                        </DropdownItemUIComponent>
                                        {/* <DropdownItemUIComponent>
                                            <ButtonUIComponent
                                                className={`pb-0 px-0 btn-sm bg-transparent border-0 btn-danger text-danger no-hover`}
                                                buttonInner={
                                                    <div className="d-flex align-items-center">
                                                        <img
                                                            width={14}
                                                            src={`/assets/icons/red-minus.svg`}
                                                        />
                                                        <span className="ms-2 fs-12 fw-semibold">
                                                            Delete Class
                                                        </span>
                                                    </div>
                                                }
                                            />
                                        </DropdownItemUIComponent> */}
                                    </DropdownUIComponent>
                                ),
                                width: 40
                            }
                        ]}
                        dataSource={teacherList?.data || []}
                        tableKey={"teachers-list"}
                        onRowClick={handleView}
                    />
                </InfiniteScroll>
            </div>
            <DefaultCardLoader isFixed={true} show={isLoading} />
        </Fragment>
    );
};

export default AdminTeacherBaseComponent;
