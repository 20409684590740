import classNames from "classnames";

const ButtonUIComponent = ({
    disabled = false,
    type = "button",
    onClick = () => {},
    buttonInner,
    isBlock = false,
    isPrimary = false,
    className = "",
    title = ""
}) => {
    return (
        <button
            title={title || ""}
            disabled={disabled}
            type={type || "button"}
            onClick={onClick || null}
            className={classNames(`btn ${className}`, {
                "btn-primary": isPrimary,
                "btn-block": isBlock
            })}
        >
            {buttonInner}
        </button>
    );
};

export default ButtonUIComponent;
