import { Fragment, useEffect, useState } from "react";
import ButtonUIComponent from ".";
import { URLS } from "../../../utils";
import { toast } from "react-toastify";
import ImageCropper from "../../common/cropper";

const UploadButton = ({
    uploadButtonKey = 1001,
    showPreview = true,
    className = "",
    accept = "",
    btnText = "",
    name = "",
    imgWidth = 24,
    value = undefined,
    disabled = false,
    allowCrop = false,
    aspect = 1,
    sizeLimit = {
        size: 5000,
        message: ""
    },
    onChange = () => {}
}) => {
    const [media, setMedia] = useState(value || undefined);
    const [cropMedia, setCropMedia] = useState(null);
    const isFileInstance = media instanceof File;
    const fileId = `file-${uploadButtonKey}`;

    useEffect(() => {
        if (value && !isFileInstance && !(value instanceof Blob)) {
            setMedia(`${URLS.MEDIA_URL}${value}`);
        }
    }, [value, isFileInstance]);

    const fileExtension = (filename) => filename?.split(".").pop();
    const fileName = (filename) => filename?.split("/").pop();

    const fileValidation = (file) => {
        if (file.size / 1000 > sizeLimit.size) {
            document.getElementById(`${fileId}`).value = "";
            onChange({
                name: name,
                type: "file",
                value: undefined
            });
            setMedia(undefined);
            toast.error(sizeLimit.message);
            return;
        }
        return true;
    };

    const handleMediaChange = (e) => {
        let file = e.target.files[0];
        fileValidation(file);
        onChange({
            name: name,
            type: "file",
            value: e.target.files
        });
        setMedia(e.target.files[0]);

        // const file = e.target.files[0];
        const fileExtn = fileExtension(file.name);
        var reader = new FileReader();
        reader.onload = function () {
            if (fileExtn === "pdf") {
                const pdfBlob = new Blob([reader.result], {
                    type: "application/pdf"
                });
                const pdfUrl = URL.createObjectURL(pdfBlob);
                document
                    .getElementById(`img-${fileId}`)
                    .setAttribute("src", pdfUrl);
            } else {
                document.getElementById(`img-${fileId}`).src = reader.result;
            }
            // var blob = window.dataURLtoBlob(reader.result);
        };
        if (fileExtn === "pdf") {
            reader.readAsArrayBuffer(file);
        } else {
            reader.readAsDataURL(file);
        }
    };

    const removeMedia = () => {
        document.getElementById(`${fileId}`).value = "";
        onChange({
            name: name,
            type: "file",
            value: undefined
        });
        setMedia(undefined);
    };

    const handleImageCropper = (e) => {
        let file = e.target.files[0];
        fileValidation(file);
        setCropMedia(e.target.files[0]);
    };

    const handleImageCropOperation = (fileBlob) => {
        setMedia(URL.createObjectURL(fileBlob));
        setCropMedia(null);
        onChange({
            name: name,
            type: "file",
            value: fileBlob
        });
        document.getElementById(`img-${fileId}`).src =
            URL.createObjectURL(fileBlob);
        return;
    };

    return (
        <Fragment>
            {media === undefined ? (
                <ButtonUIComponent
                    isBlock={true}
                    className={`btn-sm btn-upload fs-14 ${className}`}
                    buttonInner={btnText}
                    onClick={() => document.getElementById(fileId).click()}
                />
            ) : (
                <div className="d-flex align-items-center">
                    {showPreview ? (
                        <Fragment>
                            {fileExtension(media?.name) === "pdf" ||
                            (media && !isFileInstance) ? (
                                <iframe
                                    id={`img-${fileId}`}
                                    frameBorder={0}
                                    style={{ maxWidth: "100%" }}
                                    src={!isFileInstance ? media : ""}
                                />
                            ) : (
                                <img
                                    src={""}
                                    id={`img-${fileId}`}
                                    className={`img-fluid border`}
                                    style={{ width: imgWidth || 24 }}
                                />
                            )}
                            <span
                                className={"text-link fs-12 ms-2 text-gray-400"}
                            >
                                {media.name}
                            </span>
                        </Fragment>
                    ) : (
                        <ButtonUIComponent
                            isBlock={true}
                            className={`btn-sm btn-upload fs-14 ${className}`}
                            buttonInner={
                                media?.name || fileName(media) || "file"
                            }
                            onClick={() =>
                                !isFileInstance
                                    ? window.open(media, "_blank")
                                    : {}
                            }
                        />
                    )}
                    <ButtonUIComponent
                        className={`ms-2 btn-sm text-danger btn-danger no-hover`}
                        buttonInner={
                            // <img src={`/assets/icons/red-minus.svg`} />
                            "Remove"
                        }
                        onClick={removeMedia}
                        disabled={disabled}
                    />
                </div>
            )}
            {!!cropMedia && (
                <ImageCropper
                    aspect={aspect}
                    imageFile={cropMedia}
                    onClose={(e) => setCropMedia(undefined)}
                    handleOnChange={handleImageCropOperation}
                />
            )}
            <input
                autoComplete={"off"}
                type={"file"}
                hidden
                id={fileId}
                name={`name`}
                accept={accept || "*"}
                onChange={(e) => {
                    if (allowCrop) {
                        handleImageCropper(e);
                    } else {
                        handleMediaChange(e);
                    }
                }}
            />
        </Fragment>
    );
};

export default UploadButton;
