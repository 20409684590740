import { Modal } from "react-bootstrap";

const ModalUIComponent = ({
    show,
    size = "md",
    onClose = () => {},
    centered = false,
    ...props
}) => {
    return (
        <Modal centered={centered} show={show} size={size || "md"}>
            {props.children}
        </Modal>
    );
};

export default ModalUIComponent;
