import "./progress.scss";

const CircularProgress = ({ value }) => {
    return (
        <div
            className="progress-c"
            data-percentage={Math.ceil(value / 10) * 10 || 0}
        >
            <span className="progress-c-left">
                <span className="progress-c-bar"></span>
            </span>
            <span className="progress-c-right">
                <span className="progress-c-bar"></span>
            </span>
            <div className="progress-c-value">
                <div>{value || 0}%</div>
            </div>
        </div>
    );
};

export default CircularProgress;
