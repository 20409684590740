import _ from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BackButton from "../../../components/common/BackButton";
import DefaultCardLoader from "../../../components/common/Loader";
import QuizTemplate from "../../../components/common/quiz/Quiz";
import ButtonUIComponent from "../../../components/UI/Button";
import {
    PostCourseCompletion,
    updateCompletionData
} from "../../../services/common/reducer/course/activity";
import { FetchSingleAssessment } from "../../../services/common/reducer/course/content";
import { URLS } from "../../../utils";
import {
    COURSE_CONTENT_GROUP,
    COURSE_CONTENT_TYPE,
    UserType
} from "../../../utils/constants";
import {
    ExtractErrorMessage,
    TransformQuizData,
    TransformQuizSubmissionData
} from "../../../utils/fn";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../../context/auth-context";

const UserQuizComponent = () => {
    const { t } = useTranslation();
    const { userObject } = useAuthContext();
    const navigate = useNavigate();
    const [submitted, setSubmitted] = useState(true);
    const [questions, setQuestions] = useState([]);
    const [createCtr, setCreateCtr] = useState(0);
    const [loading, setLoading] = useState(false);
    const { slug, id } = useParams();
    const [payload, setPayload] = useState({
        title: "",
        description: ""
    });
    const dispatch = useDispatch();

    useEffect(() => {
        if (id) {
            fetchAssessment();
        }
    }, [dispatch, id]);

    const fetchAssessment = async () => {
        try {
            setLoading(true);
            const response = await FetchSingleAssessment(
                COURSE_CONTENT_TYPE[4],
                id
            );
            let quizQuestions = TransformQuizData(
                response?.data?.data?.courseAssessment,
                false,
                response?.data?.data?.courseComponentCompletion
            );

            setPayload({
                id: quizQuestions.id,
                title: quizQuestions.title,
                description: quizQuestions.description
            });
            setSubmitted(
                !_.isEmpty(response?.data?.data?.courseComponentCompletion)
            );
            setQuestions(quizQuestions.listQuizQuestions);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    const onSubmit = async (items) => {
        try {
            setLoading(true);
            let data = TransformQuizSubmissionData({ questions: items, id });
            const contentType = COURSE_CONTENT_TYPE[4].toLowerCase();
            await PostCourseCompletion(id, contentType, data);

            dispatch(
                updateCompletionData({ key: slug, id, type: contentType })
            );

            fetchAssessment();
            toast.success(`Quiz submitted successfully!`);
            // navigate(`${URLS.COURSE_BASE_URL}/${slug}/assessment`);
        } catch (error) {
            const { toast: toastMsg } = ExtractErrorMessage(error?.response);
            toast.error(toastMsg);
        } finally {
            setLoading(false);
        }
    };

    const onSubmitHandler = (e) => {
        e.preventDefault();
        setCreateCtr((e) => e + 1);
    };

    return (
        <div className="container mt-4 mb-5">
            <Form onSubmit={onSubmitHandler}>
                <div className="row">
                    <div className="col-lg-7 col-md-10">
                        <div className="d-flex w-100 justify-content-between">
                            <BackButton
                                btnText={t("common.backToAssessment")}
                                redirectionKey={"courses"}
                                redirectionConfig={{
                                    slug: slug,
                                    path: "assessment"
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-lg-7 col-md-10 mb-4">
                        <div className="card bg-white content-card">
                            <div
                                className="d-flex fs-14 text-gray-300 align-items-center pt-3 px-3"
                                style={{ gap: 8 }}
                            >
                                <span>{t("tableHead.course")}</span>
                                <span>
                                    <img
                                        src={`/assets/icons/caret-right.svg`}
                                        alt={"Right arrow"}
                                        className={"img-fluid"}
                                    />
                                </span>
                                <span>{t("common.courseAssessments")}</span>
                            </div>
                            <div className="card-body mt-0 pt-2">
                                <div className="fs-5 fw-semibold text-gray-600">
                                    {payload.title}
                                </div>
                                <div className="text-gray-300 fs-14 mt-2">
                                    {payload.description}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-10 order-2 order-lg-1">
                        <QuizTemplate
                            questions={questions}
                            isEditable={false}
                            isSubmitted={submitted}
                            createCtr={createCtr}
                            onSubmit={onSubmit}
                            isAdmin={false}
                            onChange={(items) => setQuestions(items)}
                        />
                    </div>
                </div>
                {userObject.type === UserType.STUDENT && !submitted && (
                    <div className="row mt-0">
                        <div className="col-lg-7 col-md-10 d-flex">
                            <ButtonUIComponent
                                className={`btn-sm px-5 ms-auto`}
                                isPrimary={true}
                                type={"submit"}
                                buttonInner={t("common.saveQuiz")}
                            />
                        </div>
                    </div>
                )}
            </Form>
            <DefaultCardLoader isFixed show={loading} />
        </div>
    );
};

export default UserQuizComponent;
