import { useParams } from "react-router-dom";
import BackButton from "../../../components/common/BackButton";
import ForumListItemTemplate, {
    ForumCommentItemTemplate
} from "../../../components/common/forum/ListItem";
import ListUIComponent from "../../../components/UI/List";
import { useTranslation } from "react-i18next";

const SingleForumComponent = () => {
    const { t } = useTranslation();
    const { id } = useParams();

    return (
        <div className="container mt-4 mb-5">
            <div className="row">
                <div className="d-flex">
                    <BackButton btnText={t("common.backToForum")} />
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-md-9">
                    <div className="card content-card bg-white forumCardShadow pb-4">
                        <div className="card-body">
                            <ForumListItemTemplate
                                redirection={false}
                                data={{
                                    imageUri: "/assets/images/forum-1.png"
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-9">
                    <ListUIComponent
                        data={[{}, {}]}
                        separator={true}
                        render={(record) => <ForumCommentItemTemplate />}
                    />
                </div>
            </div>
        </div>
    );
};

export default SingleForumComponent;
