import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import { URLS } from "../../utils";
import { COURSE_CONTENT_TYPE } from "../../utils/constants";
import { KeyGenerator, getTokenFromStorage } from "../../utils/fn";
import { setCourseAssessment, setCourseContent } from "./reducer/course";
import _ from "lodash";

export const FetchPreviousContent = async (type, id, previous = true) => {
    const slug = [COURSE_CONTENT_TYPE[0], COURSE_CONTENT_TYPE[1]].includes(type)
        ? "course-contents"
        : "course-assessments";
    let qp = previous ? `?isGetPrevious=true` : "";

    return await Axios.get(`${URLS.API_URL}v1/${slug}/${id}${qp}`, {
        headers: {
            Authorization: getTokenFromStorage()
        }
    });
};

export const FetchCourseCompletion = createAsyncThunk(
    "courseCompletion/fetch",
    async (id, { getState, dispatch }) => {
        let courseCompletion = getState().courseCompletion;
        if (
            courseCompletion &&
            courseCompletion.items &&
            courseCompletion.items.hasOwnProperty(id)
        ) {
            return courseCompletion.items[id];
        }

        const response = await Axios.get(
            `${URLS.API_URL}v1/course-component-completions?courseId=${id}`,
            {
                headers: {
                    Authorization: getTokenFromStorage()
                }
            }
        );
        if (response.status == 200) {
            let currentState = getState();
            let {
                courseDetail: { assessment, content }
            } = currentState;

            // optimisation to preload the state with course content & assessment.
            if (
                _.isEmpty(assessment) &&
                !_.isEmpty(response?.data?.listCourseAssessments)
            ) {
                dispatch(
                    setCourseAssessment({
                        id: id,
                        data: {
                            count:
                                response.data.listCourseAssessments.length || 0,
                            data: response.data.listCourseAssessments || []
                        }
                    })
                );
            }
            if (
                _.isEmpty(content) &&
                !_.isEmpty(response?.data?.listCourseContents)
            ) {
                dispatch(
                    setCourseContent({
                        id: id,
                        data: {
                            count: response.data.listCourseContents.length || 0,
                            data: response.data.listCourseContents || []
                        }
                    })
                );
            }

            let responseData = response.data;
            let ids = [
                "listCourseContentCompletions",
                "listCourseAssessmentCompletions"
            ]
                .map((key) => {
                    return responseData[key];
                })
                .flat()
                .map((e) => e.courseAssessmentId || e.courseContentId);

            return {
                contentId: ids,
                contentCompletionCount:
                    responseData.countCourseContentCompletions,
                contentCount: responseData.countCourseContents,
                assessmentCompletionCount:
                    responseData.countCourseAssessmentCompletions,
                assessmentCount: responseData.countCourseAssessments
            };
        }
        return response.data;
    }
);

export const UploadUserAvatar = async (type, file) => {
    if (!type) return;
    const userType = type.toLowerCase();
    const formData = new FormData();
    formData.append(`profileImage`, file);
    const response = await Axios.patch(
        `${URLS.API_URL}v1/${userType}/me`,
        formData,
        {
            headers: {
                Authorization: getTokenFromStorage()
            }
        }
    );
    return response.data;
};

export const ContactUsAPI = async (payload) => {
    let data = payload;
    data.phoneNumber = `+91${data.phoneNumber}`;
    const response = await Axios.post(`${URLS.API_URL}v1/contact-us`, data, {
        headers: {
            Authorization: getTokenFromStorage()
        }
    });
    return response.data;
};
