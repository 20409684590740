import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DefaultCardLoader from "../../../components/common/Loader";
import ButtonUIComponent from "../../../components/UI/Button";
import { useModalContext } from "../../../context/modal-context";
import { ReportForumTopic } from "../../../services/common/reducer/forum";
import { ExtractErrorMessage } from "../../../utils/fn";

const ReportModal = ({ record }) => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const { toggle } = useModalContext();
    const { t } = useTranslation();
    const [reason, setReason] = useState("");

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const response = await ReportForumTopic({
                id: id || record?.id,
                reason: reason
            });
            toast.success(`Reported successfully!`);
            toggle();
        } catch (error) {
            const { toast: toastMsg } = ExtractErrorMessage(error?.response);
            toast.error(toastMsg);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Form onSubmit={onSubmitHandler}>
            <Modal.Header style={{ padding: "24px 16px" }}>
                <Modal.Title className="fs-20">
                    {t(`common.reportReason`)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pb-2">
                <div className="form-row d-flex flex-column flex-md-row">
                    <div className="col-md-3">
                        <label
                            htmlFor="name"
                            className="fs-14 text-gray-300 fw-semibold"
                        >
                            {t(`common.reason`)}
                        </label>
                    </div>
                    <div className="col-md-9 mt-2 mt-md-0">
                        <textarea
                            className={`form-control fs-14`}
                            placeholder={t("common.enterReason")}
                            required
                            name={"reason"}
                            rows={5}
                            onChange={(e) => setReason(e.target.value)}
                            value={reason || ""}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex">
                    <ButtonUIComponent
                        className={`fs-14 me-3 fw-semibold`}
                        buttonInner={t(`common.cancel`)}
                        type={"button"}
                        onClick={() => toggle()}
                    />
                    <ButtonUIComponent
                        isPrimary={true}
                        className={`fs-14 fw-semibold`}
                        buttonInner={t(`common.report`)}
                        type={"submit"}
                    />
                </div>
            </Modal.Footer>
            <DefaultCardLoader show={loading} />
        </Form>
    );
};

export default ReportModal;
