import { Fragment, useState } from "react";
import ButtonUIComponent from "../../UI/Button";
import DefaultCardLoader from "../../common/Loader";
import { ExtractErrorMessage } from "../../../utils/fn";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const ImportCSV = ({
    toastKey = "Data",
    ExportFn = async (id) => {},
    callbackFn = () => {},
    isCreate = true
}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const handleCSVUpload = async (e) => {
        try {
            setLoading(true);
            await ExportFn(e.target.files[0]);
            toast.success(`${toastKey} ${isCreate? 'imported' : 'updated'} successfully`);
            callbackFn();
        } catch (error) {
            const { toast: toastMsg } = ExtractErrorMessage(error?.response);
            toast.error(toastMsg);
        } finally {
            setLoading(false);
            document.getElementById("upload_csv").value = null;
        }
    };

    return (
        <Fragment>
            <ButtonUIComponent
                buttonInner={isCreate ? t("common.createMultiple") : t("common.updateMultiple") }
                className={`btn-sm fw-semibold fs-14 px-4`}
                isPrimary={false}
                onClick={() => document.getElementById("upload_csv").click()}
            />
            <input
                accept={
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                }
                type="file"
                hidden
                id={"upload_csv"}
                onChange={handleCSVUpload}
            />
            <DefaultCardLoader isFixed={true} show={loading} />
        </Fragment>
    );
};

export default ImportCSV;
