import _ from "lodash";
import { Form } from "react-bootstrap";

const SelectUIComponent = ({
    placeholder = "",
    name = "",
    options = [],
    value = "",
    defaultOptionPlaceholder = "",
    className = "",
    onChange = () => {}
}) => {
    return (
        <Form.Select
            className={className}
            name={name}
            value={value || undefined}
            placeholder={placeholder}
            onChange={(e) =>
                onChange({ value: e.target.value, name: e.target.name })
            }
        >
            <option value="">{defaultOptionPlaceholder}</option>
            {_.map(options, (opt) => {
                return (
                    <option key={opt.value} value={opt.value}>
                        {opt.text}
                    </option>
                );
            })}
        </Form.Select>
    );
};

export default SelectUIComponent;
