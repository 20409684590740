import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DefaultCardLoader from "../../../components/common/Loader";
import ButtonUIComponent from "../../../components/UI/Button";
import FloatingInputUIComponent from "../../../components/UI/Input";
import { useAdminAuthContext } from "../../../context/admin-context";
import { formValidations } from "../../../utils/fn";
// import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
import ReCaptcha from "../../../components/Recaptcha";

const AdminLoginComponent = ({ title = "" }) => {
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showPass, setShowPass] = useState(false);
    const { loginInitiate, loginVerify, isLoading, sessionId } =
        useAdminAuthContext();
    const [payload, setPayload] = useState({
        email: "",
        password: "",
        oneTimePassword: ""
    });
    const [enableSubmit, setEnableSubmit] = useState(false);
    // const { executeRecaptcha } = useGoogleReCaptcha();
    // const [recaptchaToken, setRecaptchaToken] = useState("");
    // const [ctr, setCtr] = useState(0);

    useEffect(() => {
        return () => {
            setPayload({
                email: "",
                password: ""
            });
            // setCtr(0);
            // setRecaptchaToken("");
        };
    }, []);

    const inputHandler = ({ name, value }) => {
        setPayload({ ...payload, [name]: value });
    };

    // useEffect(() => {
    //     if (ctr >= 5) {
    //         window.location.reload();
    //         return;
    //     }
    //     if (!executeRecaptcha && ctr < 5) {
    //         setCtr((e) => e + 1);
    //         return;
    //     }

    //     const verifyRecaptcha = async () => {
    //         const recaptchaToken = await executeRecaptcha("login_page");
    //         setRecaptchaToken(recaptchaToken);
    //         setLoading(false);
    //     };

    //     verifyRecaptcha();
    // }, [executeRecaptcha, recaptchaToken]);

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            // if (!executeRecaptcha) {
            //     throw new Error("Verification failed, refresh or try again!");
            // }
            // const recaptchaToken = await executeRecaptcha();

            formValidations("password", payload.password);
            if (!sessionId) {
                // await loginInitiate({ ...payload, recaptchaToken });
                await loginInitiate({ ...payload });
            } else {
                // await loginVerify({ ...payload, recaptchaToken });
                await loginVerify({ ...payload });
            }
            // navigate user once data is received
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(false);
            // setRefreshReCaptcha((e) => !e);
        }
    };

    return (
        <div className="card px-3 py-3 auth-card">
            <div className="card-body">
                <div className="d-flex flex-column">
                    <h4 className="fs-24 fw-semibold">{t("auth.welcome")}</h4>
                    <form onSubmit={submitHandler} className="mt-4">
                        <div className="row">
                            <div className="col-12">
                                <FloatingInputUIComponent
                                    name={"email"}
                                    type={"email"}
                                    label={t("auth.enterEmail")}
                                    value={payload.email}
                                    onChange={inputHandler}
                                    required={true}
                                    disabled={sessionId}
                                />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-12">
                                <FloatingInputUIComponent
                                    type={showPass ? "text" : "password"}
                                    name={"password"}
                                    label={t("auth.enterPass")}
                                    icon={`/assets/icons/eye-close.svg`}
                                    value={payload.password}
                                    onChange={inputHandler}
                                    required={true}
                                    disabled={sessionId}
                                    iconClick={() => setShowPass((e) => !e)}
                                />
                            </div>
                        </div>
                        {sessionId && (
                            <div className="row mt-4">
                                <div className="col-12">
                                    <FloatingInputUIComponent
                                        type={"text"}
                                        name={"oneTimePassword"}
                                        label={t("auth.enterOtp")}
                                        value={payload.oneTimePassword || ""}
                                        onChange={inputHandler}
                                        required={true}
                                    />
                                </div>
                            </div>
                        )}
                        {/* <div className="row">
                            <div className="col-12">
                                <GoogleReCaptcha
                                    onVerify={setRecaptchaToken}
                                    refreshReCaptcha={refreshReCaptcha}
                                />
                            </div>
                        </div> */}
                        <div className="row mt-4">
                            <div className="col-12">
                                <div className="d-flex w-100 justify-content-center">
                                    <ReCaptcha callback={() => setEnableSubmit(true)}/>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column w-100 mt-4">
                            <ButtonUIComponent
                                isBlock={true}
                                isPrimary={true}
                                disabled={!enableSubmit}
                                type={"submit"}
                                buttonInner={t("common.login")}
                                className={`w-100 fw-semibold`}
                            />
                            <ButtonUIComponent
                                isBlock={true}
                                isPrimary={false}
                                type={"button"}
                                buttonInner={`${t("auth.forgotPass")}?`}
                                className={`w-100 btn-text mt-2 fs-14 fw-semibold`}
                                onClick={() => navigate(`#reset-password`)}
                            />
                        </div>
                    </form>
                </div>
            </div>
            <DefaultCardLoader show={isLoading || loading} />
        </div>
    );
};

export default AdminLoginComponent;
