import _ from "lodash";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";

const TeamLandingComponent = () => {
    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        padding: 0,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            }
        ]
    };
    const { t } = useTranslation();
    const baseData = [
        "t1.webp",
        "t2.webp",
        "t3.png",
        "t4.webp",
        "t5.webp",
        "t6.webp",
        "t7.webp",
        "t8.webp",
        "t9.webp",
        "t10.webp"
    ];
    const data = _.map(baseData, (e, i) => {
        return {
            img: e,
            name: `landing.team.n${i + 1}`,
            subtitle: `landing.team.s${i + 1}`
        };
    });

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="fs-48 text-dark-primary text-center fw-bold head">
                        {t("landing.team.title")}
                    </div>
                </div>
                <div className="col-10 offset-1 mt-md-5 mt-4">
                    <Slider {...settings}>
                        {_.map(data, (item, index) => {
                            return (
                                <div className="" key={index}>
                                    <div className="d-flex flex-column position-relative insp-wrapper">
                                        <div className="d-flex">
                                            <img
                                                src={`/assets/images/team/${item.img}`}
                                                alt={`${t(item.name)}`}
                                                className={`img-fluid`}
                                                style={{
                                                    aspectRatio: "1/1",
                                                    objectFit: "cover",
                                                    objectPosition: "top",
                                                    border: "1px solid #e1e1e1",
                                                    minWidth: 377
                                                }}
                                            />
                                        </div>
                                        <div
                                            className={`d-flex flex-column insp-box`}
                                        >
                                            <span className="text-primary fw-semibold mt-3">
                                                {t(item.name)}
                                            </span>
                                            <span
                                                className="text-gray-400 fw-semibold"
                                                dangerouslySetInnerHTML={{
                                                    __html: t(item.subtitle)
                                                }}
                                            ></span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default TeamLandingComponent;
