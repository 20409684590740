import _ from "lodash";
import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import AdminDashboardTitle from "../../../components/admin/DashboardTitle";
import AdminDashboardTabs from "../../../components/admin/Tabs";
import DefaultCardLoader from "../../../components/common/Loader";
import ButtonUIComponent from "../../../components/UI/Button";
import DropdownUIComponent, {
    DropdownItemUIComponent
} from "../../../components/UI/Dropdown";
import TableUIComponent from "../../../components/UI/Table";
import { useModalContext } from "../../../context/modal-context";
import { URLS } from "../../../utils";
import CoursesBundleFilter from "../../../components/admin/filters/coursesBundle";
import {
    fetchCourseBundleList,
    setCourseBundleFilter
} from "../../../services/admin/reducer/courses/bundle";
import CreateEditCourseBundleModal from "../modal/CreateEditCourseBundle";

const AdminCourseBundleComponent = () => {
    const { toggle } = useModalContext();
    const { t } = useTranslation();
    const { items, isLoading, isError, filters } = useSelector(
        (state) => state.coursesBundle
    );
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCourseBundleList({ ...filters }));
    }, [dispatch, filters]);

    useEffect(() => {
        return () => {
            dispatch(setCourseBundleFilter({}));
        };
    }, []);

    const handleCourseEdit = (record) => {
        toggle(<CreateEditCourseBundleModal action={"edit"} record={record} />);
    };

    return (
        <Fragment>
            <AdminDashboardTitle
                title={t("common.coursesBundle")}
                showResult={items?.count > 0}
                count={items?.count || 0}
                btnText={t("admin.home.coursesBundle.headerBtn.title")}
                onClick={() => toggle(<CreateEditCourseBundleModal />)}
            />
            <AdminDashboardTabs />
            <div className="container mt-4 mb-5">
                <CoursesBundleFilter placeholder="Search Course Bundle" />
                <TableUIComponent
                    columns={[
                        {
                            title: t("tableHead.courseBundleName"),
                            render: (record) => (
                                <div className="d-flex align-items-center text-nowrap">
                                    <div className="d-flex">
                                        <img
                                            style={{
                                                width: 36,
                                                height: 36,
                                                minWidth: 36
                                            }}
                                            src={`${URLS.MEDIA_URL}${record.thumbnailImageUri}`}
                                            alt={`Thumbnail`}
                                            className={`img-fluid rounded-circle`}
                                        />
                                    </div>
                                    <div className="d-flex flex-column ms-2">
                                        <div className="text-gray-600 fs-14 fw-semibold">
                                            {record.title}
                                        </div>
                                        <span className="text-gray-300 fs-12">
                                            {record.uidCode}
                                        </span>
                                    </div>
                                </div>
                            ),
                            width: 280
                        },
                        {
                            title: t("tableHead.category"),
                            render: (record) => (
                                <span
                                    className="text-gray-300 fs-14 fw-medium"
                                    style={{ textTransform: "capitalize" }}
                                >
                                    {t(`constants.${record.category}`)}
                                </span>
                            ),
                            width: 140
                        },
                        {
                            title: t("tableHead.language"),
                            render: (record) => (
                                <span className="text-gray-300 fs-14 fw-medium">
                                    {t(`constants.${record.languageCode}`)}
                                </span>
                            ),
                            width: 200
                        },
                        {
                            title: "",
                            render: (record) => (
                                <DropdownUIComponent
                                    className="border-0 rounded-2 d-inline-block lh-1"
                                    btnClassName={`def-color btn-sm rounded-2 border-0 px-2 py-1 bg-transparent mw-22`}
                                    isPrimary={false}
                                    onToggle={() => {}}
                                    btnText={
                                        <img
                                            src={`/assets/icons/three-dot.svg`}
                                            className={"img-fluid"}
                                            alt={"Three Dot Menu"}
                                        />
                                    }
                                    toggleIcon={false}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <DropdownItemUIComponent>
                                        <ButtonUIComponent
                                            className={`btn-text p-0 pb-0 fs-12 fw-semibold text-dark`}
                                            onClick={() =>
                                                handleCourseEdit(record)
                                            }
                                            buttonInner={
                                                <div className="d-flex align-items-center">
                                                    <img
                                                        width={14}
                                                        src={`/assets/icons/pencil.svg`}
                                                    />
                                                    <span className="ms-2 fs-12 fw-semibold">
                                                        {t(
                                                            "tableHead.editBundle"
                                                        )}
                                                    </span>
                                                </div>
                                            }
                                        />
                                    </DropdownItemUIComponent>
                                </DropdownUIComponent>
                            ),
                            width: 40
                        }
                    ]}
                    onRowClick={(record) =>
                        toggle(
                            <CreateEditCourseBundleModal
                                record={record}
                                action={"view"}
                            />
                        )
                    }
                    isLoading={isLoading}
                    dataSource={items?.data || []}
                    showEmpty={
                        !isLoading &&
                        Array.isArray(items?.data) &&
                        items?.data.length === 0
                    }
                    tableKey={"coursesBundle-list"}
                />
            </div>
            <DefaultCardLoader isFixed={true} show={isLoading} />
        </Fragment>
    );
};

export default AdminCourseBundleComponent;
