import { useTranslation } from "react-i18next";
import { Fragment, useState } from "react";
import { Modal } from "react-bootstrap";

import { useModalContext } from "../../../context/modal-context";
import ButtonUIComponent from "../../../components/UI/Button";
import { ExtractErrorMessage } from "../../../utils/fn";
import { toast } from "react-toastify";
import { UpdateStudentPassword } from "../../../services/admin";
import DefaultCardLoader from "../../../components/common/Loader";

const ChangePasswordModal = ({ record, type }) => {
    const { toggle } = useModalContext();
    const { t } = useTranslation();
    const [passGenerated, setPassGenerated] = useState(false);
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const generatePassword = async () => {
        try {
            setLoading(true);
            const response = await UpdateStudentPassword(record.id, type || "user");
            setPassword(response?.data?.newPassword);
            setPassGenerated(true);
        } catch (error) {
            const { toast: toastMsg } = ExtractErrorMessage(error?.response);
            toast.error(toastMsg);

            setPassGenerated(false);
            setPassword("");
        } finally {
            setLoading(false);
        }
    };

    const copyPasswordToClipboard = () => {
        navigator.clipboard.writeText(password);
        toast.info('Copied to the clipboard.');
        toggle();
    };

    return (
        <Fragment>
            <Modal.Header style={{ padding: "24px 16px" }}>
                <Modal.Title className="fs-20">
                    {t("modal.changePassword.title")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                <div>
                    {!passGenerated && (
                        <p
                            className="changePass-desc"
                            dangerouslySetInnerHTML={{
                                __html: `${t("modal.changePassword.message")} <br><br><b>${record.name} [${record.uidCode}]</b>`
                            }}
                        />
                    )}
                    {passGenerated && (
                        <p
                            className="changePass-desc"
                            dangerouslySetInnerHTML={{
                                __html: `${t("modal.changePassword.successMessage")} <b>${password}</b>`
                            }}
                        />
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex justify-content-between w-100">
                    <div className="d-flex ms-auto">
                        <ButtonUIComponent
                            className={`fs-14 fw-semibold`}
                            buttonInner={passGenerated ? t("common.close") : t("common.cancel")}
                            type={"button"}
                            onClick={() => toggle()}
                        />
                        {!passGenerated && (
                            <ButtonUIComponent
                                isPrimary={true}
                                className={`fs-14 ms-3 fw-semibold`}
                                buttonInner={t("common.confirm")}
                                type={"submit"}
                                onClick={generatePassword}
                            />
                        )}
                        {passGenerated && (
                            <ButtonUIComponent
                                isPrimary={true}
                                className={`fs-14 ms-3 fw-semibold`}
                                buttonInner={t("common.copy")}
                                onClick={copyPasswordToClipboard}
                            />
                        )}
                    </div>
                </div>
            </Modal.Footer>
            <DefaultCardLoader show={loading} />
        </Fragment>
    );
};

export default ChangePasswordModal;
