// src/announcementSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { URLS } from "../../../utils";
import Axios from "axios";
import { getTokenFromStorage, KeyGenerator } from "../../../utils/fn";

// Define async thunk
export const fetchAnnouncementList = createAsyncThunk(
    "announcementList/fetchData",
    async (params, { getState }) => {
        try {
            const { pageNumber, createdAtFirstItem, ...rest } = params;
            const payload = {
                ...rest
            };
            if (pageNumber > 1 && createdAtFirstItem) {
                payload.pageNumber = pageNumber;
                payload.createdAtFirstItem = createdAtFirstItem;
            }

            let qp = new URLSearchParams(payload).toString();
            qp = qp ? `?${qp}` : "";

            const response = await Axios.get(
                `${URLS.API_URL}v1/announcements${qp}`,
                {
                    headers: {
                        Authorization: getTokenFromStorage()
                    }
                }
            );
            return response.data;
        } catch (error) {
            throw new Error(error.response.data.error);
        }
    }
);

const formatAnnouncementData = (data) => {
    const payload = _.omitBy(
        _.pick(data, ["title", "description", "courseId"]),
        _.isNil
    );

    if (!payload.courseId) {
        payload.courseId = null;
    }

    return payload;
};

// action => 0: POST, 1: PUT
export const SingleAnnouncementOperation = async (payload) => {
    let config = {
        url: `${URLS.API_URL}v1/announcements`,
        method: "POST"
    };

    const response = await Axios({
        ...config,
        data: formatAnnouncementData(payload),
        headers: {
            Authorization: getTokenFromStorage()
        }
    });
    return response.data;
};

// Create slice
export const announcementSlice = createSlice({
    name: "announcement",
    initialState: {
        items: {
            count: null,
            data: []
        },
        currentPage: 1,
        createdAtFirstItem: null,
        filters: {},
        isLoading: false,
        isError: false,
        status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null
    },
    reducers: {
        setAnnouncementPage: (state, action) => {
            state.currentPage = action.payload.page || 1;
            state.createdAtFirstItem =
                action.payload.createdAtFirstItem || null;
        },
        setAnnouncementFilters: (state, action) => {
            state.currentPage = 1;
            state.createdAtFirstItem = null;
            state.items = {
                count: null,
                data: []
            };
            state.filters = action.payload || {};
        },
        updateAnnouncement: (state, action) => {
            try {
                let { payload } = action;
                let existingData = _.cloneDeep(state.items?.data) || [];
                state.items.data = [{ ...payload.data }, ...existingData];
                state.items.count = state.items.count || 1;
            } catch (er) {
                // console.log(er);
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAnnouncementList.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
                state.isError = false;
            })
            .addCase(fetchAnnouncementList.fulfilled, (state, action) => {
                state.status = "succeeded";
                const existingData = _.cloneDeep(state.items);
                if (
                    !_.isEmpty(existingData) &&
                    !_.isEmpty(existingData?.data)
                ) {
                    state.items = {
                        count: action.payload?.count || 0,
                        data: [...existingData.data, ...action?.payload?.data]
                    };
                } else {
                    state.items = {
                        count: action.payload?.count || 0,
                        data: action.payload?.data || []
                    };
                }
                state.isLoading = false;
                state.isError = false;
            })
            .addCase(fetchAnnouncementList.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.isLoading = false;
                state.isError = true;
            });
    }
});

export const {
    updateAnnouncement,
    setAnnouncementFilters,
    setAnnouncementPage
} = announcementSlice.actions;

export default announcementSlice.reducer;
