// src/liveClassSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { URLS } from "../../../utils";
import Axios from "axios";
import { getTokenFromStorage, KeyGenerator } from "../../../utils/fn";
import dayjs from "dayjs";

// Define async thunk
export const fetchLiveClassList = createAsyncThunk(
    "liveClassList/fetchData",
    async (params, { getState }) => {
        try {
            const { pageNumber, createdAtFirstItem, ...rest } = params;
            const payload = {
                ...rest
            };

            if (pageNumber > 1 && createdAtFirstItem) {
                payload.pageNumber = pageNumber;
                payload.createdAtFirstItem = createdAtFirstItem;
            }
            let qp = new URLSearchParams(payload).toString();
            qp = qp ? `?${qp}` : "";

            const response = await Axios.get(`${URLS.API_URL}v1/events${qp}`, {
                headers: {
                    Authorization: getTokenFromStorage()
                }
            });
            return response.data;
        } catch (error) {
            throw new Error(error.response.data.error);
        }
    }
);

const formatLiveClassData = (data) => {
    let params = {
        ...data,
        startDateTime: dayjs(`${data.date} ${data.startTime}`).format(),
        endDateTime: dayjs(`${data.date} ${data.endTime}`).format()
    };
    const payload = _.omitBy(
        _.pick(params, [
            "courseId",
            "title",
            "description",
            "startDateTime",
            "endDateTime",
            "externalJoinUrl"
        ]),
        _.isNil
    );
    return payload;
};

// action => 0: POST, 1: PUT
export const SingleLiveClassOperation = async (payload, action = 0) => {
    let config = {
        url: `${URLS.API_URL}v1/events`,
        method: "POST",
        data: formatLiveClassData(payload)
    };

    if (action !== 0) {
        config.url = `${config.url}/${payload.id}`;
        config.method = "PATCH";
        let { courseId, ...body } = config.data;
        config.data = body;
    }

    const response = await Axios({
        ...config,
        headers: {
            Authorization: getTokenFromStorage()
        }
    });
    return response.data;
};

export const RemoveLiveClass = async (id) => {
    const response = await Axios({
        url: `${URLS.API_URL}v1/events/${id}`,
        method: "DELETE",
        headers: {
            Authorization: getTokenFromStorage()
        }
    });
    return response.data;
};

// Create slice
export const liveClassSlice = createSlice({
    name: "liveClass",
    initialState: {
        items: {
            count: null,
            data: []
        },
        currentPage: 1,
        createdAtFirstItem: null,
        filters: {},
        isLoading: false,
        isError: false,
        status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null
    },
    reducers: {
        setLivePage: (state, action) => {
            state.currentPage = action.payload.page || 1;
            state.createdAtFirstItem =
                action.payload.createdAtFirstItem || null;
        },
        setLiveFilters: (state, action) => {
            state.currentPage = 1;
            state.createdAtFirstItem = null;
            state.items = {
                count: null,
                data: []
            };
            state.filters = action.payload || {};
        },
        updateLiveClass: (state, action) => {
            try {
                let { payload } = action;
                if (payload.action === "create") {
                    let existingData = _.cloneDeep(state.items?.data) || [];
                    state.items.data = [{ ...payload.data }, ...existingData];
                    state.items.count = state.items.count || 1;
                } else {
                    let existingData = _.cloneDeep(state.items.data);
                    let index = _.findIndex(
                        existingData,
                        (e) => e.id === payload.data.id
                    );
                    state.items.data[index] = payload.data;
                }
            } catch (er) {
                // console.log(er);
            }
        },
        removeLiveClass: (state, action) => {
            let { payload } = action;
            let modifiedData = _.cloneDeep(state.items);
            modifiedData.data = _.filter(
                modifiedData.data,
                (record) => record.id !== payload.id
            );
            modifiedData.count = (modifiedData.count || 1) - 1;
            Object.assign(state.items, modifiedData);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLiveClassList.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
                state.isError = false;
            })
            .addCase(fetchLiveClassList.fulfilled, (state, action) => {
                state.status = "succeeded";
                const existingData = _.cloneDeep(state.items);
                if (
                    !_.isEmpty(existingData) &&
                    !_.isEmpty(existingData?.data)
                ) {
                    state.items = {
                        count: action.payload?.count || 0,
                        data: [...existingData.data, ...action?.payload?.data]
                    };
                } else {
                    state.items = {
                        count: action.payload?.count || 0,
                        data: action.payload?.data || []
                    };
                }
                state.isLoading = false;
                state.isError = false;
            })
            .addCase(fetchLiveClassList.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.isLoading = false;
                state.isError = true;
            });
    }
});

export const { updateLiveClass, setLivePage, setLiveFilters, removeLiveClass } =
    liveClassSlice.actions;

export default liveClassSlice.reducer;
