import { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Outlet, useParams, useLocation } from "react-router-dom";
import CoursePageTabs from "../../components/common/courses/CoursePageTabs";
import CustomPageHeadComponent from "../../components/common/reusable/CustomPageHead";
import { fetchCourseDetails } from "../../services/common/reducer/course";
import { KeyGenerator } from "../../utils/fn";
import { useTranslation } from "react-i18next";

const CourseUserContentPage = () => {
    const { t } = useTranslation();
    const { slug } = useParams();
    const { state } = useLocation();
    const { items, isLoading, isError } = useSelector(
        (state) => state.courseDetail
    );
    const cacheKey = KeyGenerator({ id: slug });
    const courseDetails =
        items && items[cacheKey] && items[cacheKey]?.data
            ? items[cacheKey].data
            : {};

    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        dispatch(fetchCourseDetails({ id: slug }));
    }, [dispatch, slug]);

    return (
        <Fragment>
            <CustomPageHeadComponent
                title={courseDetails?.title || "-"}
                subtitle={`${t("common.courseId")} : ${courseDetails?.uidCode || "-"}`}
                bundle={state?.bundle}
            />
            <div className="mt-4">
                <CoursePageTabs />
            </div>
            <div className="container mt-4 mb-5">
                <Outlet />
            </div>
        </Fragment>
    );
};

export default CourseUserContentPage;
