// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslation from "./locales/en.json";
import knTranslation from "./locales/kn.json";

// the translations
const resources = {
    en: {
        translation: enTranslation
    },
    kn: {
        translation: knTranslation
    }
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en", // default language
        fallbackLng: "en", // fallback language
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
