import classNames from "classnames";
import { useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";

export const DropdownItemUIComponent = (props) => {
    return <div className="customDrop-item">{props.children}</div>;
};

const DropdownUIComponent = ({
    isPrimary = false,
    btnClassName = "",
    btnVariant = "default",
    children,
    btnText = "",
    toggleIcon = true,
    className = "",
    placement = "",
    style = {},
    onToggle = () => {},
    onClick = () => {},
    extraStyles = {}
}) => {
    const [show, setShow] = useState(false);
    const handleDropClick = (e) => {
        e.stopPropagation();
        if (onClick) {
            onClick();
        }
    };

    const handleToggle = (value) => {
        setShow(value);
        if (onToggle) {
            onToggle();
        }
    };

    const handleBehaviour = () => {
        setShow((e) => !e);
    };

    return (
        <Dropdown
            className={className}
            // align={placement || "end"}
            show={show}
            onClick={handleDropClick}
            onToggle={handleToggle}
            autoClose={true}
        >
            <Dropdown.Toggle
                data-toggle-icon={toggleIcon}
                className={classNames(`btn dropdown-toggle ${btnClassName}`, {
                    "btn-primary": isPrimary
                })}
                type={"button"}
                variant={btnVariant || "default"}
                style={style || {}}
            >
                {btnText}
            </Dropdown.Toggle>
            <Dropdown.Menu
                {...extraStyles}
                onClick={handleBehaviour}
                rootCloseEvent={"mousedown"}
            >
                {children}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default DropdownUIComponent;
