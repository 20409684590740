import _ from "lodash";
import { useEffect, useState } from "react";
import ButtonUIComponent from "../../UI/Button";
import QuizQuestionTemplate from "./Question";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? "#f5f5f5" : "white",
    borderRadius: 24,
    marginBottom: 24,
    ...draggableStyle
});

const getListStyle = (isDraggingOver) => ({
    background: "transparent"
});

const QuestionTemplate = {
    question: "",
    options: [
        {
            value: "",
            correct: false
        },
        {
            value: "",
            correct: false
        },
        {
            value: "",
            correct: false
        },
        {
            value: "",
            correct: false
        }
    ]
};

const QuizTemplate = ({
    questions = [],
    isSubmitted = false,
    createCtr = 0,
    isEditable = false,
    isAdmin = false,
    onSubmit = (items) => {}
}) => {
    const { t } = useTranslation();
    const [quiz, setQuiz] = useState([{ id: 1, ...QuestionTemplate }]);
    const [error, setError] = useState([]);

    const isValidQuestion = (item) => {
        let isError = false;
        if (!item.question) {
            isError = true;
        } else {
            let ctr = 0;
            _.forEach(item.options, (opt, index) => {
                if (!opt.value) {
                    isError = true;
                }
                ctr = Math.max(ctr, opt.correct ? 1 : 0);
            });
            if (ctr === 0) {
                isError = true;
            }
        }

        return !isError;
    };

    const addQuestion = () => {
        if (!isEditable) return;

        let existing = [...quiz];
        let noError = true;
        const e = _.map(existing, (e) => {
            const i = isValidQuestion(e);
            noError = noError && i;
            return i;
        });
        setError([...e]);
        if (noError) {
            setQuiz([...quiz, { id: quiz.length + 1, ...QuestionTemplate }]);
        } else {
            toast.error(
                "Please provide all the options and mark the correct option for the current question."
            );
        }
    };

    const handleOnChange = (template, index) => {
        let t = [...quiz];
        t[index] = template;
        setQuiz(t);
    };

    const handleOnRemove = (index) => {
        let q = [...quiz];
        q.splice(index, 1);
        setQuiz([...q]);
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const newItems = reorder(
            quiz,
            result.source.index,
            result.destination.index
        );

        // shuffle error list
        if (
            error &&
            Math.max(result.source.index, result.destination.index) <=
                error.length
        ) {
            const err = reorder(
                error,
                result.source.index,
                result.destination.index
            );
            setError([...err]);
        }

        setQuiz(newItems);
    };

    useEffect(() => {
        let quizTemplate = _.isEmpty(questions)
            ? [{ id: 1, ...QuestionTemplate }]
            : [...questions];
        setQuiz(quizTemplate);
    }, [questions]);

    useEffect(() => {
        if (createCtr) {
            let existing = [...quiz];
            let noError = true;
            const e = _.map(existing, (e) => {
                const i = isValidQuestion(e);
                noError = noError && i;
                return i;
            });
            setError([...e]);
            if (noError) {
                onSubmit([...quiz]);
            } else {
                toast.error(
                    "Please provide all the options and mark the correct option for the current question."
                );
            }
        } else if (createCtr > 0 && !isEditable) {
            onSubmit([]);
        }
    }, [createCtr, isEditable]);

    if (!isEditable) {
        return (
            <div
                className="d-flex flex-column position-relative"
                style={{ gap: 24 }}
            >
                {_.map(quiz, (q, index) => {
                    return (
                        <QuizQuestionTemplate
                            isEditable={false}
                            isSubmitted={isSubmitted}
                            isAdmin={isAdmin}
                            key={index}
                            error={error.length > index ? !error[index] : false}
                            index={index}
                            data={q}
                            onChange={(e) => handleOnChange(e, index)}
                        />
                    );
                })}
                <div className="d-flex justify-content-end"></div>
            </div>
        );
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={`droppableId-quiz`}>
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        className="d-flex flex-column"
                        ref={provided.innerRef}
                        style={{ ...getListStyle(snapshot.isDraggingOver) }}
                    >
                        {_.map(quiz, (q, index) => (
                            <Draggable
                                key={q.id}
                                draggableId={q.id.toString()}
                                index={index}
                            >
                                {(provided, snapshot) => (
                                    <div
                                        className="quiz-card"
                                        ref={provided.innerRef}
                                        {...provided.dragHandleProps}
                                        {...provided.draggableProps}
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                        )}
                                    >
                                        <QuizQuestionTemplate
                                            totalQues={quiz.length}
                                            isEditable={isEditable}
                                            key={index}
                                            error={
                                                error.length > index
                                                    ? !error[index]
                                                    : false
                                            }
                                            index={index}
                                            data={q}
                                            onChange={(e) =>
                                                handleOnChange(e, index)
                                            }
                                            onRemove={(e) =>
                                                handleOnRemove(index)
                                            }
                                        />
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {isEditable && isAdmin && (
                            <div className="d-flex justify-content-end">
                                <ButtonUIComponent
                                    onClick={addQuestion}
                                    className={`btn-sm`}
                                    buttonInner={`+ ${t("common.addAnotherQuestion")}`}
                                />
                            </div>
                        )}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default QuizTemplate;
