import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { URLS } from "../../utils";
import DefaultCardLoader from "../../components/common/Loader";
import Axios from "axios";
import { ExtractErrorMessage, getTokenFromStorage } from "../../utils/fn";
import { toast } from "react-toastify";
import {
    GoogleReCaptcha,
    GoogleReCaptchaProvider
} from "react-google-recaptcha-v3";
import ReCaptcha from "../../components/Recaptcha";

const EmailAlertPage = () => {
    const { type, subscriptionId } = useParams();
    const navigate = useNavigate();
    const apiEndpoint = type === "student" ? "students" : "teachers";
    const [unsubscribe, setUnsubscribe] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState("");
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
    const [enableSubmit, setEnableSubmit] = useState(false);

    useEffect(() => {
        if (!["teacher", "student"].includes(type)) {
            navigate(`${URLS.DASHBOARD_URL}`, { replace: true });
        }
    }, [type]);

    const handleOperation = async (resubscribe = false) => {
        try {
            setLoading(true);
            let payload = {
                isSubscribedToEmailAlerts: resubscribe,
                // recaptchaToken
            };
            await Axios.patch(
                `${URLS.API_URL}v1/${apiEndpoint}/email-alerts-subscription/${subscriptionId}`,
                payload,
                {
                    headers: {
                        Authorization: getTokenFromStorage()
                    }
                }
            );
            setUnsubscribe((e) => !e);
            toast.success(
                `${resubscribe ? "Resubscribed" : "Unsubscribed"} successfully!`
            );
        } catch (error) {
            const { toast: toastMsg } = ExtractErrorMessage(
                error?.response || error
            );
            toast.error(toastMsg);
            if (error?.response?.status == 403) {
                setUnsubscribe((e) => !e);
            }
        } finally {
            setLoading(false);
            setRefreshReCaptcha((e) => !e);
        }
    };

    return (
        // <GoogleReCaptchaProvider
        //     reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
        // >
            <div className="position-relative min-vw-100 min-vh-100">
                {unsubscribe ? (
                    <div className="d-flex flex-column justify-content-center align-items-center my-5 py-5">
                        <div className="d-flex">
                            <img
                                src={`/assets/icons/unsub.svg`}
                                width={220}
                                className="img-fluid"
                                alt={`Icon`}
                            />
                        </div>
                        <h2 className="mt-md-4 mt-3">
                            We are sorry to see you go!
                        </h2>
                        <p className="mt-2">
                            Please click the button below to confirm.
                        </p>
                        <div className="row mt-4 mb-3">
                            <div className="col-12">
                                <div className="d-flex w-100 justify-content-center">
                                    <ReCaptcha callback={() => setEnableSubmit(true)}/>
                                </div>
                            </div>
                        </div>
                        <button
                            disabled={!enableSubmit}
                            type={"button"}
                            onClick={() => handleOperation()}
                            className="btn btn-primary fw-semibold"
                        >
                            Unsubscribe
                        </button>
                        {/* <GoogleReCaptcha
                            onVerify={setRecaptchaToken}
                            refreshReCaptcha={refreshReCaptcha}
                        /> */}
                    </div>
                ) : (
                    <div className="d-flex flex-column justify-content-center align-items-center my-5 py-5">
                        <div className="d-flex">
                            <img
                                style={{ border: `4px solid #0F7650` }}
                                src={`/assets/icons/sub.png`}
                                width={180}
                                className="img-fluid rounded-circle"
                                alt={`Icon`}
                            />
                        </div>
                        <h2 className="mt-md-4 mt-3">Success!</h2>
                        <p className="mt-2 mb-0">
                            You have been successfully unsubscribed from our
                            mailing list.
                        </p>
                        <p className="mt-1">
                            To resubscribe click on the button below.
                        </p>
                        <div className="row mt-4 mb-3">
                            <div className="col-12">
                                <div className="d-flex w-100 justify-content-center">
                                    <ReCaptcha callback={() => setEnableSubmit(true)}/>
                                </div>
                            </div>
                        </div>
                        <button
                            type={"button"}
                            disabled={!enableSubmit}
                            onClick={() => handleOperation(true)}
                            className="btn btn-primary fw-semibold"
                        >
                            Resubscribe
                        </button>
                        {/* <GoogleReCaptcha
                            onVerify={setRecaptchaToken}
                            refreshReCaptcha={refreshReCaptcha}
                        /> */}
                    </div>
                )}
                <DefaultCardLoader isFixed={true} show={isLoading} />
            </div>
        // </GoogleReCaptchaProvider>
    );
};

export default EmailAlertPage;
