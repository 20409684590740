// src/institutionSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { URLS } from "../../../../utils";
import Axios from "axios";
import { getTokenFromStorage } from "../../../../utils/fn";

// Define async thunk
export const fetchInstitutionList = createAsyncThunk(
    "institutionList/fetchData",
    async (params, { getState }) => {
        try {
            let qp = new URLSearchParams(params).toString();
            qp = qp ? `?${qp}` : "";

            // const { items } = getState().institution;
            // if (!_.isEmpty(items) && !_.isEmpty(items?.data || [])) {
            //     return items;
            // }

            const response = await Axios.get(
                `${URLS.API_URL}v1/institutions${qp}`,
                {
                    headers: {
                        Authorization: getTokenFromStorage()
                    }
                }
            );
            return response.data;
        } catch (error) {
            throw new Error(error.response.data.error);
        }
    }
);

const formatInstitutionData = (data) => {
    const payload = _.omitBy(
        _.pick(data, ["uidCode", "name", "type", "principalName"]),
        _.isNil
    );
    return payload;
};

// action => 0: POST, 1: PUT
export const SingleInstitutionOperation = async (payload, action = 0) => {
    let config = {
        url: `${URLS.API_URL}v1/institutions`,
        method: "POST"
    };
    if (action !== 0) {
        config.url = `${config.url}/${payload.id}`;
        config.method = "PATCH";
    }

    const response = await Axios({
        ...config,
        data: formatInstitutionData(payload),
        headers: {
            Authorization: getTokenFromStorage()
        }
    });
    return response.data;
};

// Create slice
export const institutionSlice = createSlice({
    name: "institution",
    initialState: {
        items: {
            count: null,
            data: []
        },
        filters: {},
        isLoading: false,
        isError: false,
        status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null
    },
    reducers: {
        setInstitutionFilters: (state, action) => {
            state.items = {
                count: null,
                data: []
            };
            state.filters = action.payload || {};
        },
        updateInstitutionList: (state, action) => {
            try {
                let { payload } = action;
                if (payload.action === "create") {
                    let existingData = _.cloneDeep(state.items?.data) || [];
                    state.items.data = [{ ...payload.data }, ...existingData];
                    state.items.count = state.items.count;
                } else {
                    let existingData = _.cloneDeep(state.items.data);
                    let index = _.findIndex(
                        existingData,
                        (e) => e.id === payload.data.id
                    );
                    state.items.data[index] = payload.data;
                }
            } catch (er) {
                // console.log(er);
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchInstitutionList.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
                state.isError = false;
            })
            .addCase(fetchInstitutionList.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.items = {
                    count: action.payload?.count,
                    data: action.payload?.data || []
                };
                state.isLoading = false;
                state.isError = false;
            })
            .addCase(fetchInstitutionList.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.isLoading = false;
                state.isError = true;
            });
    }
});

export const { updateInstitutionList, setInstitutionFilters } =
    institutionSlice.actions;

export default institutionSlice.reducer;
