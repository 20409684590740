import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchCourseList } from "../services/admin/reducer/courses";

const useGetCourseList = (props) => {
    const { items, isLoading, isError } = useSelector((state) => state.courses);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCourseList(props?.params || {}));
    }, [dispatch]);

    return {
        list: items?.data || [],
        count: items?.count || 0,
        isLoading,
        isError
    };
};

export default useGetCourseList;
