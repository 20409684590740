import DropdownUIComponent, { DropdownItemUIComponent } from "..";
import { useModalContext } from "../../../../context/modal-context";
import StudentJoinModal from "../../../user/modal/StudentJoin";
import TeacherJoinModal from "../../../user/modal/TeacherJoin";
import StaffJoinModal from "../../../user/modal/TeacherStaffJoin";

const JoinRequestDropdown = () => {
    const { toggle } = useModalContext();

    const toggleModal = (type = "student") => {
        if (type === "student") {
            toggle(<StudentJoinModal />, { size: "md" });
        }
        else if (type === "teacher") {
            toggle(<TeacherJoinModal />, { size: "md" });
        }
        else if (type === "staff") {
            toggle(<StaffJoinModal />, { size: "md" });
        }
    };

    return (
        <DropdownUIComponent
            btnText={`Request to Join`}
            btnClassName={`btn-sm text-dark fs-14 fw-semibold btn-transparent btn-nobg`}
            extraStyles={{
                style: { zIndex: 1021 }
            }}
        >
            <DropdownItemUIComponent>
                <button
                    onClick={() => toggleModal("student")}
                    className="btn btn-text text-nowrap btn-transparent p-0 pb-1 pt-1 m-0 text-dark fs-12"
                >
                    As a Student
                </button>
            </DropdownItemUIComponent>
            <DropdownItemUIComponent>
                <button
                    onClick={() => toggleModal("teacher")}
                    className="btn btn-text text-nowrap btn-transparent p-0 pb-1 pt-1 m-0 text-dark fs-12"
                >
                    As a Teacher
                </button>
            </DropdownItemUIComponent>
            <DropdownItemUIComponent>
                <button
                    onClick={() => toggleModal("staff")}
                    className="btn btn-text text-nowrap btn-transparent p-0 pb-0 pt-1 m-0 text-dark fs-12"
                >
                    As a Non-Teaching Staff
                </button>
            </DropdownItemUIComponent>
        </DropdownUIComponent>
    );
};

export default JoinRequestDropdown;