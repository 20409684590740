import _ from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import TableUIComponent from "../../../components/UI/Table";
import {
    fetchStudentList,
    setStudentFilters,
    setStudentPage
} from "../../../services/admin/reducer/students";
import { checkNextPage, KeyGenerator } from "../../../utils/fn";
import { PAGE_LIMIT } from "../../../utils/constants";
import AddEditStudentModal from "../modal/CreateEditStudent";
import { useModalContext } from "../../../context/modal-context";
import UserAvatar from "../../../components/user/avatar";
import { useTranslation } from "react-i18next";

const AdminCourseStudentsComponent = () => {
    const { toggle } = useModalContext();
    const { t } = useTranslation();
    const { slug } = useParams();
    const { items, isLoading, isError, createdAtFirstItem, currentPage } =
        useSelector((state) => state.students);
    const dispatch = useDispatch();
    const cacheKey = KeyGenerator({ 
        courseId: slug,
        sortingCriteria: "ALPHABETICAL"
    });
    const studentData = items && items[cacheKey] ? items[cacheKey] : [];

    const hasNextPage = checkNextPage(studentData?.data || [], currentPage);

    useEffect(() => {
        dispatch(
            fetchStudentList({
                courseId: slug,
                createdAtFirstItem,
                sortingCriteria: "ALPHABETICAL",
                pageNumber: currentPage,
                override: true
            })
        );
    }, [dispatch, createdAtFirstItem, currentPage]);

    useEffect(() => {
        return () => {
            dispatch(setStudentFilters({}));
        };
    }, []);

    const handleView = (record) => {
        toggle(<AddEditStudentModal action={"view"} record={record} />);
    };

    const handlePagination = () => {
        dispatch(
            setStudentPage({
                pageNumber: currentPage + 1,
                createdAtFirstItem: studentData?.data[0]?.name
            })
        );
    };

    return (
        <div className="row mt-3">
            <div className="col-12">
                <InfiniteScroll
                    dataLength={studentData?.data?.length || 0}
                    next={handlePagination}
                    hasMore={hasNextPage}
                    scrollableTarget="scrollableDiv"
                >
                    <TableUIComponent
                        columns={[
                            {
                                title: t("tableHead.name"),
                                render: (record) => {
                                    return (
                                        <div className="d-flex align-items-center text-nowrap">
                                            <div className="d-flex">
                                                <UserAvatar
                                                    profileImage={
                                                        record?.profileImageUri
                                                    }
                                                    name={record.name}
                                                    width={32}
                                                    size="md"
                                                />
                                            </div>
                                            <div className="d-flex flex-column ms-2">
                                                <div className="text-dark fs-14">
                                                    {record.name}
                                                </div>
                                                <span className="text-gray-300 fs-12">
                                                    {record.uidCode}
                                                </span>
                                            </div>
                                        </div>
                                    );
                                },
                                width: 160
                            },
                            {
                                title: t("tableHead.email"),
                                render: (record) => (
                                    <span className="text-gray-300 fs-14">
                                        {record.email || "-"}
                                    </span>
                                ),
                                width: 200
                            },
                            {
                                title: t("tableHead.phone"),
                                render: (record) => (
                                    <span className="text-gray-300 fs-14">
                                        {record?.phoneNumber?.replace(
                                            "+91",
                                            ""
                                        ) || "-"}
                                    </span>
                                ),
                                width: 200
                            },
                            {
                                title: t("tableHead.medium"),
                                render: (record) => (
                                    <span className="text-gray-300 fs-14">
                                        {record.languageCode
                                            ? t(
                                                  `constants.${record.languageCode}`
                                              )
                                            : "-"}
                                    </span>
                                ),
                                width: 200
                            }
                        ]}
                        dataSource={studentData?.data || []}
                        showEmpty={
                            !isLoading && _.isEmpty(studentData?.data || [])
                        }
                        isLoading={isLoading}
                        tableKey={"student-list"}
                        onRowClick={handleView}
                    />
                </InfiniteScroll>
            </div>
        </div>
    );
};

export default AdminCourseStudentsComponent;
