import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SortingCriteria } from "../../utils/constants";

const AdminDashboardTabs = () => {
    const { t } = useTranslation();

    return (
        <div className="container mb-3">
            <ul className="nav nav-tabs fw-semibold">
                <li className="nav-item">
                    <NavLink className={`nav-link`} to={`/courses`}>
                        {t("common.courses")}
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className={`nav-link`} to={`/courses-bundle`}>
                        {t("common.coursesBundle")}
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className={`nav-link`} to={`/institution`}>
                        {t("common.institution")}
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink
                        className={`nav-link`}
                        to={`/students?sortingCriteria=${SortingCriteria.ALPHABETICAL}`}
                    >
                        {t("common.students")}
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink
                        className={`nav-link`}
                        to={`/teachers?sortingCriteria=${SortingCriteria.ALPHABETICAL}`}
                    >
                        {t("common.teachers")}
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className={`nav-link`} to={`/live-classes`}>
                        {t("common.liveClasses")}
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className={`nav-link`} to={`/announcements`}>
                        {t("common.announcements")}
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink
                        className={`nav-link`}
                        to={`/forum?isResolved=false`}
                    >
                        {t("common.forumActivity")}
                    </NavLink>
                </li>
            </ul>
        </div>
    );
};

export default AdminDashboardTabs;
