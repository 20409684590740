import _ from "lodash";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useQueryParams from "../../../hooks/useSearchQuery";
import { setCourseFilter } from "../../../services/admin/reducer/courses";
import { CourseEnableOptions, LanguageCode, SortingCriteria } from "../../../utils/constants";
import SortDropdownComponent from "../../UI/Dropdown/sort";
import SearchBarComponent from "../../UI/Search";
import { useTranslation } from "react-i18next";

const CoursesFilter = ({ placeholder = "" }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const query = useQueryParams();
    const navigate = useNavigate();

    const handleSearch = (search) => {
        let payload = { ...query, search };
        if (!payload.search || _.isEmpty(payload.search)) {
            delete payload.search;
        }
        navigate({
            search: queryString.stringify(payload)
        });
        dispatch(setCourseFilter(payload));
    };

    const handleSort = (sort) => {
        let payload = { ...query, sortingCriteria: sort };
        if (!payload.sortingCriteria || _.isEmpty(payload.sortingCriteria)) {
            delete payload.sortingCriteria;
        }
        navigate({
            search: queryString.stringify(payload)
        });
        dispatch(setCourseFilter(payload));
    };

    const handleLanguageFilter = (e) => {
        let value = e.target.value;
        let payload = { ...query, languageCode: value };
        if (!value) {
            delete payload.languageCode;
        }
        navigate({
            search: queryString.stringify(payload)
        });
        dispatch(setCourseFilter(payload));
    };

    const handleEnableFilter = (e) => {
        let value = e.target.value;
        let payload = { ...query, isDisabled: value };
        if (!value || value == "null") {
            delete payload.isDisabled;
        }
        navigate({
            search: queryString.stringify(payload)
        });
        dispatch(setCourseFilter(payload));
    };

    return (
        <div className="row my-3">
            <div className="col-md-4">
                <SearchBarComponent
                    placeholder={placeholder || t(`formFields.search`)}
                    onChange={handleSearch}
                />
            </div>
            <div className="col-md-6 ms-auto">
                <div
                    className="d-flex align-items-center justify-content-end"
                    style={{ gap: 20 }}
                >
                    <select
                        className="form-select normal fs-14 br-60"
                        onChange={handleLanguageFilter}
                    >
                        <option value={""}>{t("formFields.language")}</option>
                        {_.map(LanguageCode, (item) => (
                            <option key={item.value} value={item.value}>
                                {t(`constants.${item.value}`)}
                            </option>
                        ))}
                    </select>
                    <select
                        className="form-select normal fs-14 br-60"
                        onChange={handleEnableFilter}
                        defaultValue={"null"}
                    >
                        {/* <option value={""}>{t("formFields.")}</option> */}
                        {_.map(CourseEnableOptions, (item) => (
                            <option key={item.text} value={item.value || null}>
                                {t(`constants.${item.text.toUpperCase()}`)}
                            </option>
                        ))}
                    </select>
                    <SortDropdownComponent
                        options={_.map(SortingCriteria)}
                        onChange={handleSort}
                    />
                </div>
            </div>
        </div>
    );
};

export default CoursesFilter;
