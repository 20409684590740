import classNames from "classnames";
import _ from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useGetInstitutionList from "../../../hooks/useGetInstitutionList";
import useGetInstructorList from "../../../hooks/useGetTeacherList";
import { GetAvatarColor, GetAvatarInitials } from "../../../utils/fn";
import ButtonUIComponent from "../../UI/Button";
import SelectUIComponent from "../../UI/Input/basic-select";
// import DefaultCardLoader from "../Loader";

const CourseInstructorsComponent = ({ isEditable = false, size = "md" }) => {
    const { t } = useTranslation();
    const [edit, setEdit] = useState(false);
    const [instructor, setInstructor] = useState([undefined]);
    const [loading, setLoading] = useState(false);
    const { slug } = useParams();
    const { list: InstitutionList } = useGetInstitutionList();
    const {
        list: InstructorList,
        isLoading,
        isError
    } = useGetInstructorList({
        courseId: slug,
        isReturnCourseInstructorsOnly: true
    });

    const handleOnChange = (e, index) => {
        let ins = [...instructor];
        if (instructor.length >= index) {
            ins[index] = e.value;
            setInstructor([...ins]);
        }
    };

    const addInstructor = () => {
        if (
            instructor &&
            instructor.length &&
            instructor[instructor.length - 1]
        ) {
            setInstructor((i) => [...i, undefined]);
        }
    };

    const removeInstructor = (index) => {
        let data = [...instructor];
        data.splice(index, 1);
        setInstructor([...data]);
    };

    const saveInformation = async () => {
        try {
            setLoading(true);
            let payload = _.filter(
                [...instructor],
                (e) => !_.isUndefined(e) && !_.isNil(e) && !_.isEmpty(e)
            );

            await new Promise((resolve) =>
                setTimeout(() => {
                    resolve(true);
                }, 2000)
            );

            setEdit(false);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="card bg-white content-card">
            <div className="card-heading d-flex justify-content-between align-items-center">
                <div
                    className={classNames("fw-bold text-gray-600", {
                        "fs-5": !size || size === "md",
                        "fs-16": size === "sm"
                    })}
                >
                    {t("common.courseInstructor")}
                </div>
                {isEditable &&
                    (!edit ? (
                        <ButtonUIComponent
                            buttonInner={
                                <img
                                    src={`/assets/icons/pencil.svg`}
                                    className={`img-fluid`}
                                    alt={`Edit`}
                                />
                            }
                            className={`bg-transparent border-0 px-0 py-1`}
                            onClick={(e) => {
                                e.stopPropagation();
                                setEdit(true);
                            }}
                        />
                    ) : (
                        <ButtonUIComponent
                            buttonInner={`Save Info`}
                            isPrimary={true}
                            className={`btn-sm py-2`}
                            onClick={saveInformation}
                        />
                    ))}
            </div>
            {!edit ? (
                <div className="card-body p-0">
                    <ul className="list-unstyled content-card--list">
                        {_.map(InstructorList || [], (item, index) => {
                            const astr = GetAvatarInitials(item.name);
                            const acolor = GetAvatarColor(astr);
                            return (
                                <li key={item.id}>
                                    <div className="d-flex align-items-center">
                                        <div className="d-flex">
                                            <div
                                                className="avatar lg"
                                                data-color={acolor || ""}
                                            >
                                                {astr}
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column ms-3">
                                            <span className="fs-14 text-gray-600 fw-semibold">
                                                {item.name}
                                            </span>
                                            <span className="fs-12 text-gray-400 mt-1 fw-medium">
                                                {_.find(
                                                    InstitutionList || [],
                                                    (e) =>
                                                        e.id ===
                                                        item.institutionId
                                                )?.name || item.institutionId}
                                            </span>
                                            <span className="fs-12 text-gray-400 mt-1 fw-medium">
                                                {item?.highestQualification}
                                            </span>
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                        {_.isEmpty(InstructorList) && <li>No Instructors</li>}
                    </ul>
                </div>
            ) : (
                <form className="content-card--form p-4">
                    <div className="row">
                        <div className="col-md-3">
                            <label
                                className="fs-14 text-gray-300 fw-semibold mt-2"
                                htmlFor="courseInstructor"
                            >
                                Instructor Name
                            </label>
                        </div>
                        <div className="col-md-8 mt-3 mt-md-0">
                            <div className="d-flex flex-column">
                                {_.map(instructor, (ins, index) => {
                                    return (
                                        <div
                                            key={`${index}-${ins || index}`}
                                            className={classNames(`d-flex`, {
                                                "mt-3": index !== 0
                                            })}
                                        >
                                            <SelectUIComponent
                                                defaultOptionPlaceholder={`Select Instructor`}
                                                options={[
                                                    {
                                                        value: "234",
                                                        text: "Rahul Goyal"
                                                    }
                                                ]}
                                                name={`instructor`}
                                                onChange={(d) =>
                                                    handleOnChange(d, index)
                                                }
                                                value={ins || undefined}
                                            />
                                            {instructor.length > 1 && (
                                                <div className="d-flex align-self-center ms-3 text-nowrap">
                                                    <ButtonUIComponent
                                                        className={`bg-transparent p-0 border-0`}
                                                        buttonInner={
                                                            <div className="d-flex">
                                                                <img
                                                                    src={`/assets/icons/red-minus.svg`}
                                                                    style={{
                                                                        minWidth: 16
                                                                    }}
                                                                    alt={""}
                                                                    className={
                                                                        "img-fluid"
                                                                    }
                                                                />
                                                                <span className="ms-2 fs-12 text-danger">
                                                                    Remove
                                                                    Instructor
                                                                </span>
                                                            </div>
                                                        }
                                                        onClick={() =>
                                                            removeInstructor(
                                                                index
                                                            )
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                                {instructor.length > 0 && instructor[0] && (
                                    <div className="d-flex mt-2">
                                        <ButtonUIComponent
                                            type={"button"}
                                            className={`btn-sm bg-transparent border-0 btn-text text-decoration-none px-0`}
                                            buttonInner={
                                                "+ Add another instructor"
                                            }
                                            onClick={addInstructor}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </form>
            )}
            {/* <DefaultCardLoader show={loading || isLoading} /> */}
        </div>
    );
};

export default CourseInstructorsComponent;
