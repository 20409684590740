import _ from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ForumListItemTemplate from "../../../components/common/forum/ListItem";
import EmptyBoxComponent from "../../../components/common/reusable/EmptyBox";
import ListUIComponent from "../../../components/UI/List";
import ForumPostBarComponent from "../../../components/user/forum/Post";
import { useModalContext } from "../../../context/modal-context";
import {
    fetchCourseForumData,
    setForumPageParams,
    unmountForumData
} from "../../../services/common/reducer/forum";
import { PAGE_LIMIT } from "../../../utils/constants";
import { checkNextPage, KeyGenerator } from "../../../utils/fn";
import ReportModal from "../modal/Report";
import DefaultCardLoader from "../../../components/common/Loader";

const AdminCourseForumComponent = () => {
    const { slug } = useParams();
    const { items, isLoading, isError, pageNumber, createdAtFirstItem } =
        useSelector((state) => state.forumData);
    const dispatch = useDispatch();
    const { toggle } = useModalContext();

    const cacheKey = KeyGenerator({ id: slug });
    const courseForumData = items && items[cacheKey] ? items[cacheKey] : {};
    const hasNextPage = checkNextPage(courseForumData?.data || [], pageNumber);

    useEffect(() => {
        dispatch(
            fetchCourseForumData({
                id: slug,
                pageNumber,
                createdAtFirstItem
            })
        );
    }, [dispatch, pageNumber, createdAtFirstItem]);

    useEffect(() => {
        return () => {
            dispatch(unmountForumData());
        };
    }, []);

    const handleReport = (record) => {
        toggle(<ReportModal record={record} />);
    };

    const handlePagination = () => {
        dispatch(
            setForumPageParams({
                pageNumber: (pageNumber || 0) + 1,
                createdAtFirstItem: courseForumData?.data[0]?.createdAt
            })
        );
    };

    return (
        <div className="row mt-3">
            {/* <ForumPostBarComponent /> */}
            {_.isEmpty(courseForumData?.data) ? (
                <div className="col-md-9">
                    <EmptyBoxComponent isLoading={isLoading} data={[]} />
                </div>
            ) : (
                <div className="col-md-9">
                    <InfiniteScroll
                        dataLength={courseForumData?.data?.length || 0}
                        next={handlePagination}
                        hasMore={hasNextPage}
                        // hasMore={
                        //     !_.isEmpty(courseForumData?.data || 0)
                        //         ? courseForumData.data.length % PAGE_LIMIT === 0
                        //         : false
                        // }
                        scrollableTarget="scrollableDiv"
                    >
                        <ListUIComponent
                            data={courseForumData?.data || []}
                            separator={true}
                            render={(record) => (
                                <ForumListItemTemplate
                                    data={record}
                                    redirection={false}
                                    handleReport={handleReport}
                                    isForumPage={true}
                                />
                            )}
                        />
                    </InfiniteScroll>
                </div>
            )}
            {/* <DefaultCardLoader isFixed show={isLoading}/> */}
        </div>
    );
};

export default AdminCourseForumComponent;
