import { NavLink, useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isAdminSubDomain } from "../../../utils";

const CoursePageTabs = () => {
    const { t } = useTranslation();
    const { slug } = useParams();
    const { state } = useLocation();

    const basePath = isAdminSubDomain
        ? `/courses/${slug}`
        : `/user/dashboard/courses/${slug}`;

    return (
        <div className="container mb-3">
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <NavLink
                        replace
                        className={"nav-link"}
                        state={{ ...state }}
                        to={`${basePath}/overview`}
                    >
                        {t("common.courseOverview")}
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink
                        replace
                        className={"nav-link"}
                        state={{ ...state }}
                        to={`${basePath}/content`}
                    >
                        {t("common.courseContent")}
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink
                        replace
                        className={"nav-link"}
                        state={{ ...state }}
                        to={`${basePath}/assessment`}
                    >
                        {t("common.courseAssessment")}
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink
                        replace
                        className={"nav-link"}
                        state={{ ...state }}
                        to={`${basePath}/forum`}
                    >
                        {t("common.courseForum")}
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink
                        replace
                        className={"nav-link"}
                        state={{ ...state }}
                        to={`${basePath}/students`}
                    >
                        {t("common.courseStudents")}
                    </NavLink>
                </li>
            </ul>
        </div>
    );
};

export default CoursePageTabs;
