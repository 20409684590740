import _ from "lodash";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import AdminDashboardTitle from "../../../components/admin/DashboardTitle";
import CommonFilter from "../../../components/admin/filters/courses";
import AdminDashboardTabs from "../../../components/admin/Tabs";
import DefaultCardLoader from "../../../components/common/Loader";
import ButtonUIComponent from "../../../components/UI/Button";
import DropdownUIComponent, {
    DropdownItemUIComponent
} from "../../../components/UI/Dropdown";
import TableUIComponent from "../../../components/UI/Table";
import { useModalContext } from "../../../context/modal-context";
import {
    fetchCourseList,
    setCourseFilter,
    SingleCourseOperation,
    updateCourseList
} from "../../../services/admin/reducer/courses";
import { URLS } from "../../../utils";
import { dateTimeFormatter, ExtractErrorMessage } from "../../../utils/fn";
import CreateEditCourseModal from "../modal/CreateEditCourse";
import { CourseType } from "../../../utils/constants";
import { toast } from "react-toastify";
import { CourseDisableHandler } from "../../../services/common/reducer/course/content";

const AdminCourseBaseComponent = () => {
    const { toggle } = useModalContext();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { items, isLoading, isError, filters } = useSelector(
        (state) => state.courses
    );
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCourseList({ ...filters }));
    }, [dispatch, filters]);

    useEffect(() => {
        return () => {
            dispatch(setCourseFilter({}));
        };
    }, []);

    const handleCourseEdit = (record) => {
        toggle(<CreateEditCourseModal action={"edit"} record={record} />);
    };

    const handleCourseDisable = async (record) => {
        try {
            const shouldOperate = window.confirm(`Are you sure you want to ${record?.isDisabled ? "Enable" : "Disable"} this course?`);
            if (!shouldOperate) return;
            setLoading(true);
            // hit delete course API
            const response = await CourseDisableHandler(record.id, record?.isDisabled ? false : true);
            dispatch(
                updateCourseList({
                    action: "edit",
                    data: {
                        ...response.data,
                        listCourseInstructorTeachers: response?.listCourseInstructorAssociations || []
                    }
                })
            );
            toast.success(`Course ${record?.isDisabled ? "Enabled" : "Disabled"} successfully!`);
        } catch (error) {
            const { toast: toastMsg } = ExtractErrorMessage(
                error?.response || error
            );
            toast.error(toastMsg);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Fragment>
            <AdminDashboardTitle
                title={t("common.courses")}
                showResult={items?.count > 0}
                count={items?.count || 0}
                btnText={t("admin.home.courses.headerBtn.title")}
                onClick={() => toggle(<CreateEditCourseModal />)}
            />
            <AdminDashboardTabs />
            <div className="container mt-4 mb-5">
                <CommonFilter placeholder={t("formFields.searchCourses")} />
                <TableUIComponent
                    columns={[
                        {
                            title: t("tableHead.courseName"),
                            render: (record) => (
                                <div className="d-flex align-items-center text-nowrap">
                                    <div className="d-flex">
                                        <img
                                            style={{
                                                width: 36,
                                                height: 36,
                                                minWidth: 36
                                            }}
                                            src={`${URLS.MEDIA_URL}${record.thumbnailImageUri}`}
                                            alt={`Thumbnail`}
                                            className={`img-fluid rounded-circle`}
                                        />
                                    </div>
                                    <div className="d-flex flex-column ms-2">
                                        <div className="text-gray-600 fs-14 fw-semibold">
                                            {record.title}
                                        </div>
                                        <span className="text-gray-300 fs-12">
                                            {record.uidCode}
                                        </span>
                                    </div>
                                </div>
                            ),
                            width: 280
                        },
                        {
                            title: t("tableHead.description"),
                            render: (record) => (
                                <span className="ellipsis text-gray-300 fs-14 fw-medium">
                                    {record.description || "-"}
                                </span>
                            ),
                            width: 420
                        },
                        {
                            title: t("tableHead.type"),
                            render: (record) => (
                                <span
                                    className="text-gray-300 fs-14 fw-medium"
                                    style={{ textTransform: "capitalize" }}
                                >
                                    {record.sourceType.toLowerCase()}
                                </span>
                            ),
                            width: 140
                        },
                        {
                            title: t("tableHead.lastUpdatedOn"),
                            render: (record) => (
                                <span className="text-gray-300 fs-14 fw-medium">
                                    {dateTimeFormatter(
                                        record.lastDataAddedAt || "",
                                        false
                                    ) || "-"}
                                </span>
                            ),
                            width: 200
                        },
                        {
                            title: "",
                            render: (record) => (
                                <DropdownUIComponent
                                    className="border-0 rounded-2 d-inline-block lh-1"
                                    btnClassName={`def-color btn-sm rounded-2 border-0 px-2 py-1 bg-transparent mw-22`}
                                    isPrimary={false}
                                    onToggle={() => {}}
                                    btnText={
                                        <img
                                            src={`/assets/icons/three-dot.svg`}
                                            className={"img-fluid"}
                                            alt={"Three Dot Menu"}
                                        />
                                    }
                                    toggleIcon={false}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <DropdownItemUIComponent>
                                        <ButtonUIComponent
                                            className={`btn-text p-0 pb-1 fs-12 fw-semibold text-dark`}
                                            onClick={() =>
                                                handleCourseEdit(record)
                                            }
                                            buttonInner={
                                                <div className="d-flex align-items-center">
                                                    <img
                                                        width={14}
                                                        src={`/assets/icons/pencil.svg`}
                                                    />
                                                    <span className="ms-2 fs-12 fw-semibold">
                                                        {t(
                                                            "tableHead.editCourse"
                                                        )}
                                                    </span>
                                                </div>
                                            }
                                        />
                                    </DropdownItemUIComponent>
                                    <DropdownItemUIComponent>
                                        <ButtonUIComponent
                                            className={`btn-text p-0 pt-1 pb-0 fs-12 fw-semibold text-dark`}
                                            onClick={() =>
                                                handleCourseDisable(record)
                                            }
                                            buttonInner={
                                                <div className="d-flex align-items-center">
                                                    <img
                                                        width={14}
                                                        src={!record?.isDisabled ? `/assets/icons/enable.svg` : `/assets/icons/play.svg`}
                                                    />
                                                    <span className={`ms-2 fs-12 fw-semibold ${!record?.isDisabled ? "text-danger" : ""}`}>
                                                        {t(
                                                            !record?.isDisabled ? "tableHead.disableCourse" : "tableHead.enableCourse"
                                                        )}
                                                    </span>
                                                </div>
                                            }
                                        />
                                    </DropdownItemUIComponent>
                                </DropdownUIComponent>
                            ),
                            width: 40
                        }
                    ]}
                    onRowClick={(record) => {
                        if (record.sourceType === CourseType.EXTERNAL.value) {
                            window.open(record.externalSourceUrl, "_blank");
                            return;
                        }
                        navigate(`/courses/${record.id}/overview`);
                    }}
                    isLoading={isLoading}
                    dataSource={items?.data || []}
                    showEmpty={
                        !isLoading &&
                        Array.isArray(items?.data) &&
                        items?.data?.length === 0
                    }
                    tableKey={"courses-list"}
                />
            </div>
            <DefaultCardLoader isFixed={true} show={isLoading} />
        </Fragment>
    );
};

export default AdminCourseBaseComponent;
