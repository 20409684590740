import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getTokenFromStorage, KeyGenerator } from "../../../../utils/fn";
import Axios from "axios";
import { URLS } from "../../../../utils";
import _ from "lodash";

export const fetchForumTopicCommentData = createAsyncThunk(
    `forumTopicCommentData/fetchData`,
    async ({ id, ...params }, { getState }) => {
        try {
            let qs = { forumTopicId: id };
            if (params.pageNumber > 1 && params.createdAtFirstItem) {
                qs.pageNumber = params.pageNumber;
                qs.createdAtFirstItem = params.createdAtFirstItem;
            }

            let qp = new URLSearchParams(qs).toString();
            qp = qp ? `?${qp}` : "";

            const response = await Axios.get(
                `${URLS.API_URL}v1/forum-messages${qp}`,
                {
                    headers: {
                        Authorization: getTokenFromStorage()
                    }
                }
            );
            return response.data;
        } catch (error) {
            throw new Error(error.response.data.error);
        }
    }
);

export const fetchForumTopicData = createAsyncThunk(
    `forumTopicData/GET`,
    async ({ id, ...params }, { getState }) => {
        try {
            let items = getState().forumDetail;
            let key = KeyGenerator({ id });
            if (items && items.forum && items.forum.hasOwnProperty(key)) {
                return items.forum[key];
            }

            const response = await Axios.get(
                `${URLS.API_URL}v1/forum-topics/${id}`,
                {
                    headers: {
                        Authorization: getTokenFromStorage()
                    }
                }
            );
            return response.data;
        } catch (error) {
            throw new Error(error.response.data.error);
        }
    }
);

const formatForumData = (payload) => {
    const fd = new FormData();
    fd.append(
        `data`,
        JSON.stringify({
            forumTopicId: payload.forumTopicId,
            title: payload.title
        })
    );
    if (payload?.attachment) {
        fd.append("attachment", payload.attachment);
    }

    return fd;
};

export const RemoveForumComment = async (id) => {
    let config = {
        url: `${URLS.API_URL}v1/forum-messages/remove/${id}`,
        method: "PATCH"
    };

    const response = await Axios({
        url: config.url,
        method: config.method,
        headers: {
            Authorization: getTokenFromStorage()
        }
    });

    return response.data;
};

export const PostForumComment = async (payload) => {
    let config = {
        url: `${URLS.API_URL}v1/forum-messages`,
        method: "POST"
    };

    const response = await Axios({
        url: config.url,
        method: config.method,
        data: formatForumData(payload),
        headers: {
            Authorization: getTokenFromStorage()
        }
    });

    return response.data;
};

const forumPageSlice = createSlice({
    name: `forumDetail`,
    initialState: {
        items: {},
        forum: {},
        pageNumber: 1,
        createdAtFirstItem: null,
        isLoading: false,
        isError: false,
        status: "idle",
        error: null
    },
    reducers: {
        unmountForumCommentData: (state, action) => {
            state.items = {};
            state.pageNumber = 1;
            state.createdAtFirstItem = null;
        },
        setForumCommentPageParams: (state, action) => {
            let { payload } = action;
            state.pageNumber = payload.pageNumber;
            state.createdAtFirstItem = payload.createdAtFirstItem;
        },
        updateSingleForumTopic: (state, action) => {
            try {
                let { payload } = action;
                let key = KeyGenerator(payload.meta);
                let existingData = state.forum[key];
                if (existingData && existingData.data) {
                    existingData.data = payload.data;
                    Object.assign(state.forum, { [key]: existingData });
                }
            } catch (error) {}
        },
        updateForumComment: (state, action) => {
            try {
                let { payload } = action;
                let key = KeyGenerator(payload.meta);
                let existingData = state.items[key] || {
                    count: 0,
                    data: []
                };
                existingData.data = [...existingData.data, { ...payload.data }];
                existingData.count = (existingData.count || 0) + 1;
                Object.assign(state.items, { [key]: existingData });
            } catch (er) {
                // console.log(er);
            }
        },
        removeForumComment: (state, action) => {
            try {
                let { payload } = action;
                let key = KeyGenerator(payload.meta);
                let existingData = state.items[key] || {
                    count: 0,
                    data: []
                };
                existingData.data = _.filter(
                    existingData.data,
                    (record) => record.id !== payload.id
                );
                existingData.count = (existingData.count || 1) - 1;
                Object.assign(state.items, { [key]: existingData });
            } catch (error) {
                // console.log(error);
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchForumTopicCommentData.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
                state.isError = false;
            })
            .addCase(fetchForumTopicCommentData.fulfilled, (state, action) => {
                state.status = "succeeded";
                const key = KeyGenerator({ id: action.meta.arg.id });
                if (state.items.hasOwnProperty(key)) {
                    let existingData = _.cloneDeep(state.items[key]) || [];
                    existingData.data = [
                        ...existingData.data,
                        ...action.payload.data
                    ];
                    Object.assign(state.items, { [key]: existingData });
                } else {
                    Object.assign(state.items, { [key]: action.payload });
                }
                state.isLoading = false;
                state.isError = false;
            })
            .addCase(fetchForumTopicCommentData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.isLoading = false;
                state.isError = true;
            })
            // .addCase(fetchForumTopicData.pending, (state) => {
            //     state.status = "loading";
            //     state.isLoading = true;
            //     state.isError = false;
            // })
            .addCase(fetchForumTopicData.fulfilled, (state, action) => {
                // state.status = "succeeded";
                const key = KeyGenerator({ id: action.meta.arg.id });
                if (state.forum.hasOwnProperty(key)) {
                    let existingData = _.cloneDeep(state.forum[key]) || [];
                    existingData.data = action.payload.data;
                    Object.assign(state.forum, { [key]: existingData });
                } else {
                    Object.assign(state.forum, { [key]: action.payload });
                }
                // state.isLoading = false;
                // state.isError = false;
            })
            .addCase(fetchForumTopicData.rejected, (state, action) => {
                // state.status = "failed";
                // state.error = action.error.message;
                // state.isLoading = false;
                // state.isError = true;
            });
    }
});

export const {
    updateForumComment,
    setForumCommentPageParams,
    unmountForumCommentData,
    removeForumComment,
    updateSingleForumTopic
} = forumPageSlice.actions;

export default forumPageSlice.reducer;
