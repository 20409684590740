import { Fragment, useEffect, useState } from "react";
import { useModalContext } from "../../../context/modal-context";
import { Modal } from "react-bootstrap";
import ButtonUIComponent from "../../UI/Button";
import DefaultCardLoader from "../../common/Loader";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { toast } from "react-toastify";
import { ExtractErrorMessage } from "../../../utils/fn";
import { UpdateUserEmail } from "../../../services/user";
import { USER_EMAIL_STORAGE_KEY } from "../../../utils";

const UpdateEmail = ({ record }) => {
    const { toggle } = useModalContext();
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);

    const handleEmailUpdate = async () => {
        if (!email) {
            toast.error("Please enter email");
            return;
        }
        try {
            setLoading(true);
            await UpdateUserEmail(email, record?.type);
            toast.success(`Email updated: ${email}`);
            toggle();
        } catch (error) {
            const { toast: toastMsg } = ExtractErrorMessage(error?.response);
            toast.error(toastMsg);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Fragment>
            <Modal.Header style={{ padding: "24px 16px" }}>
                <Modal.Title className="fs-20">
                    {t("common.updateEmailTitle")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                <div className={classNames("flex-column")}>
                    <div className="form-row d-flex flex-column py-0">
                        <div className="col-12">
                            <label
                                htmlFor="lang"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {t("common.updateEmailLabel")}
                            </label>
                        </div>
                        <div className="mt-3">
                            <input
                                autoComplete={"off"}
                                required
                                className="form-control fs-14"
                                placeholder={t("common.updateEmailInputHint")}
                                value={email || ""}
                                onChange={(e) => setEmail(e.target.value)}
                                name={"name"}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex justify-content-between w-100">
                    <div className="d-flex ms-auto">
                        <ButtonUIComponent
                            className={`fs-14 fw-semibold`}
                            buttonInner={t("common.skip")}
                            type={"button"}
                            onClick={() => toggle()}
                        />
                        <ButtonUIComponent
                            isPrimary={true}
                            className={`fs-14 ms-3 fw-semibold`}
                            buttonInner={t("common.confirm")}
                            type={"submit"}
                            onClick={handleEmailUpdate}
                        />
                    </div>
                </div>
            </Modal.Footer>
            <DefaultCardLoader show={loading} />
        </Fragment>
    );
};

const UpdateEmailModal = ({ record }) => {
    const { show, toggle } = useModalContext();

    useEffect(() => {
        if (!show && !record?.email) {
            const lastShownTime = localStorage.getItem(USER_EMAIL_STORAGE_KEY);
            let showModal = true;

            if (lastShownTime) {
                const daysInterval = 3;
                const millisecondsInADay = 24 * 60 * 60 * 1000;

                const lastShownTimestamp = parseInt(lastShownTime, 10);
                const currentTimestamp = Date.now();
                const diffTime = currentTimestamp - lastShownTimestamp;

                showModal = diffTime >= daysInterval * millisecondsInADay;
            }

            if (showModal) {
                let timer = setTimeout(() => {
                    toggle(<UpdateEmail record={record} />, { centered: true, size: "sm" });
                    localStorage.setItem(
                        USER_EMAIL_STORAGE_KEY,
                        Date.now().toString()
                    );
                }, 1000);

                return () => {
                    clearTimeout(timer);
                };
            }
        }
    }, [record]);

    return <></>;
};

export default UpdateEmailModal;
