import { Navigate, Outlet } from "react-router-dom";
import UserMasterLayout from "../../layout/user";
import UserCoursePageComponent from "../../modules/user";
import UserCourseBaseComponent from "../../modules/user/course";
import UserCourseAssessmentComponent from "../../modules/user/course/assessment";
import UserCourseContentComponent from "../../modules/user/course/content";
import UserCourseForumComponent from "../../modules/user/course/forum";
import UserCourseStudentsComponent from "../../modules/user/course/students";
import UserQuizComponent from "../../modules/user/quiz";
import AdminDocumentPage from "../../pages/common/document";
import SingleForumComponent from "../../pages/common/singleForum";
import LandingPage from "../../pages/landing";
import UserDashboardPage from "../../pages/user";
import CalendarPage from "../../pages/user/calendar";
import CourseUserContentPage from "../../pages/user/courses";
import UserProfilePage from "../../pages/user/profile";
import UserCourseBundlePageComponent from "../../modules/user/courseBundle";
import EmailAlertPage from "../../pages/common/email";

const UserRoutes = [
    {
        path: "/",
        element: <LandingPage />
    },
    {
        path: "/user",
        element: <UserMasterLayout />,
        children: [
            {
                index: true,
                element: <Navigate to={`/user/dashboard`} replace />
            },
            {
                path: "login",
                element: <>Login page</>
            },
            {
                path: "dashboard",
                element: <UserDashboardPage />,
                children: [
                    {
                        path: "",
                        element: <UserCoursePageComponent />
                    },
                    {
                        path: "courses",
                        element: <Outlet />,
                        children: [
                            {
                                index: true,
                                element: (
                                    <Navigate to={`/user/dashboard`} replace />
                                )
                            },
                            {
                                path: ":slug",
                                element: <CourseUserContentPage />,
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <Navigate to={`overview`} replace />
                                        )
                                    },
                                    {
                                        path: "overview",
                                        element: <UserCourseBaseComponent />
                                    },
                                    {
                                        path: "content",
                                        element: <UserCourseContentComponent />
                                    },
                                    {
                                        path: "assessment",
                                        element: (
                                            <UserCourseAssessmentComponent />
                                        )
                                    },
                                    {
                                        path: "forum",
                                        element: <UserCourseForumComponent />
                                    },
                                    {
                                        path: "students",
                                        element: <UserCourseStudentsComponent />
                                    }
                                ]
                            },
                            {
                                path: ":slug/quiz/:id",
                                element: <UserQuizComponent />
                            },
                            {
                                path: ":slug/:type/:id",
                                element: <AdminDocumentPage isAdmin={false} />
                            }
                        ]
                    },
                    {
                        path: "course-bundle/:id",
                        element: <UserCourseBundlePageComponent />
                    },
                    {
                        path: "calendar",
                        element: <CalendarPage />
                    },
                    {
                        path: "forum/:id",
                        element: <SingleForumComponent />
                    },
                    {
                        path: "profile",
                        element: <UserProfilePage />
                    }
                ]
            }
        ]
    },
    {
        path: "/:type/email-alerts-subscription/:subscriptionId",
        element: <EmailAlertPage />
    },
    {
        path: "*",
        element: <Navigate to={`/user/dashboard`} replace />
    }
];

export default UserRoutes;
