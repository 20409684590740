export const devEnvironment = ["development", "dev", "local"].includes(
    process.env.NODE_ENV
);
export const JWT_TOKEN_KEY = {
    ADMIN: devEnvironment ? "nms-dev-admin" : "nms-admin",
    USER: devEnvironment ? "nms-dev-token" : "nms-token"
};

export const USER_EMAIL_STORAGE_KEY = devEnvironment
    ? "nms-usermail-dev"
    : "nms-usermail";

export const RECENT_FORUM_KEY = devEnvironment ? "nms-dev-forum" : "nms-forum";

export const LANG_SESSION = devEnvironment ? "nms-dev-locale" : "nms-locale";

export const isAdminSubDomain =
    window.location.href.includes('admin.localhost') ||
    /^https?:\/\/([a-zA-Z0-9-]+\.)*admin\.[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/.*)?$/.test(window.location.href);

// console.log("isAdminSubdomain: ", isAdminSubDomain);
// console.log("window.location.href: ", window.location.href);
// console.log(
//     "matching admin subdomain: ",
//     /^https?:\/\/([a-zA-Z0-9-]+\.)*admin\.[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/.test(
//         window.location.href
//     )
// );

const URLS_LIST = {
    COURSE_BASE_URL: isAdminSubDomain ? `/courses` : `/user/dashboard/courses`,
    DASHBOARD_URL: isAdminSubDomain ? `/` : `/user/dashboard/`,
    API_URL: `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_BASE_PATH}`,
    MEDIA_URL: process.env.REACT_APP_MEDIA_URL
};

export const RECAPTCHA_TOKEN = process.env.REACT_APP_RECAPTCHA_KEY;

export const URLS = URLS_LIST;
