import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DefaultCardLoader from "../../../components/common/Loader";
import ButtonUIComponent from "../../../components/UI/Button";
import FloatingInputUIComponent from "../../../components/UI/Input";
import { useAuthContext } from "../../../context/auth-context";
import { formValidations } from "../../../utils/fn";
// import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
import ReCaptcha from "../../../components/Recaptcha";

const UserLoginComponent = ({ title = "" }) => {
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showPass, setShowPass] = useState(false);
    const { login, isLoading } = useAuthContext();
    const [payload, setPayload] = useState({
        identifier: "",
        password: ""
    });
    const [enableSubmit, setEnableSubmit] = useState(false);
    // const { executeRecaptcha } = useGoogleReCaptcha();
    // const [recaptchaToken, setRecaptchaToken] = useState("");
    // const [ctr, setCtr] = useState(0);

    useEffect(() => {
        return () => {
            setPayload({
                identifier: "",
                password: ""
            });
            // setCtr(0);
            // setRecaptchaToken("");
        };
    }, []);

    // useEffect(() => {
    //     if (ctr >= 5) {
    //         window.location.reload();
    //         return;
    //     }
    //     if (!executeRecaptcha && ctr < 5) {
    //         setCtr((e) => e + 1);
    //         return;
    //     }

    //     const verifyRecaptcha = async () => {
    //         const recaptchaToken = await executeRecaptcha("login_page");
    //         setRecaptchaToken(recaptchaToken);
    //         setLoading(false);
    //     };

    //     verifyRecaptcha();
    // }, [executeRecaptcha, setRecaptchaToken]);

    const inputHandler = ({ name, value }) => {
        setPayload({ ...payload, [name]: value });
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            // if (!executeRecaptcha) {
            //     throw new Error("Verification failed, refresh or try again!");
            // }
            // const recaptchaToken = await executeRecaptcha();
            formValidations("password", payload.password);
            // await login({ ...payload, recaptchaToken });
            await login({ ...payload });
            // navigate user once data is received
            navigate(`/user/dashboard`);
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="card px-3 py-3 auth-card">
            <div className="card-body">
                <div className="d-flex flex-column">
                    <h4 className="fs-24 fw-semibold">{t("auth.welcome")}</h4>
                    <form onSubmit={submitHandler} className="mt-4">
                        <div className="row">
                            <div className="col-12">
                                <FloatingInputUIComponent
                                    name={"identifier"}
                                    type={"text"}
                                    label={t("auth.enterIdentifier")}
                                    value={payload.identifier}
                                    onChange={inputHandler}
                                    required={true}
                                    disabled={false}
                                />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-12">
                                <FloatingInputUIComponent
                                    type={showPass ? "text" : "password"}
                                    name={"password"}
                                    label={t("auth.enterPass")}
                                    icon={`/assets/icons/eye-close.svg`}
                                    value={payload.password}
                                    onChange={inputHandler}
                                    required={true}
                                    iconClick={() => setShowPass((e) => !e)}
                                />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-12">
                                <div className="d-flex w-100 justify-content-center">
                                    <ReCaptcha callback={() => setEnableSubmit(true)}/>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column w-100 mt-4">
                            <ButtonUIComponent
                                isBlock={true}
                                isPrimary={true}
                                type={"submit"}
                                disabled={!enableSubmit}
                                buttonInner={t("common.login")}
                                className={`w-100 fw-semibold`}
                            />
                            <ButtonUIComponent
                                isBlock={true}
                                isPrimary={false}
                                type={"button"}
                                buttonInner={`${t("auth.forgotPass")}?`}
                                className={`w-100 btn-text mt-2 fs-14 fw-semibold`}
                                onClick={() =>
                                    navigate(`/user/login#reset-password`)
                                }
                            />
                        </div>
                    </form>
                </div>
            </div>
            <DefaultCardLoader show={isLoading || loading} />
        </div>
    );
};

export default UserLoginComponent;
