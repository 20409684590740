import { useEffect, useState } from "react";
import ButtonUIComponent from "../../components/UI/Button";
import { ExtractErrorMessage, formValidations } from "../../utils/fn";
import { toast } from "react-toastify";
import { ContactUsAPI } from "../../services/common";
import DefaultCardLoader from "../../components/common/Loader";
import { useTranslation } from "react-i18next";
import ReCaptcha from "../../components/Recaptcha";
// import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const LandingContactComponent = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [payload, setPayload] = useState({
        name: "",
        email: "",
        phoneNumber: "",
        message: ""
    });
    const [enableSubmit, setEnableSubmit] = useState(false);
    // const { executeRecaptcha } = useGoogleReCaptcha();
    // const [recaptchaToken, setRecaptchaToken] = useState("");

    // useEffect(() => {
    //     if (!executeRecaptcha) {
    //         return;
    //     }

    //     const verifyRecaptcha = async () => {
    //         const recaptchaToken = await executeRecaptcha("contact_us");
    //         setRecaptchaToken(recaptchaToken);
    //     };

    //     verifyRecaptcha();
    // }, [executeRecaptcha, setRecaptchaToken]);

    const handleInput = (e) => {
        setPayload({
            ...payload,
            [e.target.name]: e.target.value
        });
    };

    const onFinish = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            // if (!recaptchaToken) {
            //     throw new Error(
            //         "Token verification failed, refresh & try again."
            //     );
            // }
            formValidations("phoneNumber", payload.phoneNumber);
            // await ContactUsAPI({ ...payload, recaptchaToken });
            await ContactUsAPI({ ...payload });
            setPayload({
                name: "",
                phoneNumber: "",
                email: "",
                message: ""
            });
            toast.success(`Message submitted successfully!`);
        } catch (error) {
            const { toast: toastMsg } = ExtractErrorMessage(error);
            toast.error(toastMsg);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container-fluid px-4 px-lg-0">
            <div className="row align-items-center w-lg-100 ms-lg-0">
                <div className="col-lg-5 offset-lg-1">
                    <div className="d-flex flex-column">
                        <div className="fs-48 text-light fw-bold head">
                            {t("landing.contact.title")}
                        </div>
                        <div className="fs-4 text-gray-200 mt-2 fw-semibold">
                            {t("landing.contact.subtitle")}
                        </div>
                        <form className="mt-3 contactForm" onSubmit={onFinish}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-12 mb-3">
                                            <input
                                                autoComplete={"off"}
                                                className="form-control fw-medium"
                                                name="name"
                                                placeholder={t(
                                                    "landing.contact.name"
                                                )}
                                                value={payload.name}
                                                onChange={handleInput}
                                                required
                                            />
                                        </div>
                                        <div className="col-12 mb-3">
                                            <input
                                                autoComplete={"off"}
                                                className="form-control fw-medium"
                                                name="email"
                                                placeholder={t(
                                                    "landing.contact.email"
                                                )}
                                                type={"email"}
                                                value={payload.email}
                                                onChange={handleInput}
                                                required
                                            />
                                        </div>
                                        <div className="col-12 mb-3 mb-md-0">
                                            <input
                                                autoComplete={"off"}
                                                maxLength={10}
                                                max={10}
                                                min={10}
                                                className="form-control fw-medium"
                                                name="phoneNumber"
                                                placeholder={t(
                                                    "landing.contact.phone"
                                                )}
                                                value={payload.phoneNumber}
                                                onChange={handleInput}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <textarea
                                        className="form-control fw-medium"
                                        name={"message"}
                                        placeholder={t(
                                            "landing.contact.message"
                                        )}
                                        value={payload.message}
                                        onChange={handleInput}
                                    ></textarea>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-12">
                                    <div className="d-flex w-100 justify-content-center">
                                        <ReCaptcha callback={() => setEnableSubmit(true)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex w-100">
                                <ButtonUIComponent
                                    type={"submit"}
                                    disabled={!enableSubmit}
                                    buttonInner={t("landing.contact.submit")}
                                    isBlock={true}
                                    isPrimary={false}
                                    className={`w-100 mt-4 btn-lg py-3 rounded-pill fw-medium text-dark-primary`}
                                />
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-lg-5 offset-lg-1 col-12 px-md-0 mt-5 mt-lg-0">
                    <div className="d-flex">
                        <img
                            src={`/assets/images/contact-us.webp`}
                            alt={"Contact Us"}
                            className={`img-fluid w-100`}
                        />
                    </div>
                </div>
            </div>
            <DefaultCardLoader isFixed show={loading} />
        </div>
    );
};

export default LandingContactComponent;
