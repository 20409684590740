import BackButton from "../BackButton";

const CustomPageHeadComponent = (props) => {
    return (
        <div className="container mt-4">
            <div className="d-flex">
                <BackButton redirectionKey={props.bundle ? "" : "dashboard"} />
            </div>
            <div className="d-flex justify-content-between">
                <div className="d-flex flex-column mt-3">
                    <div className="fs-4 fw-semibold text-gray-600">
                        {props.title}
                    </div>
                    {props.subtitle && (
                        <div className="text-gray-300 mt-1">
                            {props.subtitle}
                        </div>
                    )}
                </div>
                <div className="d-flex">{props.children}</div>
            </div>
        </div>
    );
};

export default CustomPageHeadComponent;
