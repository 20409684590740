import _ from "lodash";
import queryString from "query-string";
import { isAdminSubDomain, JWT_TOKEN_KEY } from ".";
import { COURSE_CONTENT_TYPE, Genders, PAGE_LIMIT, RegExps } from "./constants";

export const getTokenFromStorage = () => {
    return (
        localStorage.getItem(
            isAdminSubDomain ? JWT_TOKEN_KEY.ADMIN : JWT_TOKEN_KEY.USER
        ) || null
    );
};

export const dateTimeFormatter = (date = "", time = true, options = {}) => {
    if (!date) return;
    let params = {
        dateStyle: "medium"
    };
    if (options && !_.isEmpty(options)) {
        params = { ...options };
    }
    if (time) {
        params = { ...params, timeStyle: "medium", hour12: true };
    }
    return new Date(date).toLocaleString("en-IN", params);
};

export const timeFormatter = (d, range = false) => {
    if (_.isString(d)) {
        return new Date(d).toLocaleTimeString("en-IN");
    }

    let params = { timeStyle: "short", hour12: true };
    if (range) {
        let { start, end } = d;
        let startTime = new Date(start);
        let endTime = new Date(end);
        return {
            start: startTime.toLocaleTimeString("en-IN", params),
            end: endTime.toLocaleTimeString("en-IN", params),
            diff: Math.round(
                (endTime.getTime() - startTime.getTime()) / (60000 * 60)
            )
        };
    }
    return "";
};

export const ExtractErrorMessage = (error) => {
    let errorPayload = {
        toast: error?.message || "Some error occurred, try again",
        html: ""
    };

    if (error && error.data && error.data.error) {
        let errorData = error.data.error;
        if (typeof errorData === "string") {
            errorPayload.toast = errorData;
            errorPayload.html = errorData;
        } else if (typeof (errorData === "object")) {
            // errorPayload.toast = Object.values(errorData).join(", ")
            errorPayload.toast = Object.keys(errorData)
                .map((key) =>
                    key === "non_field_errors"
                        ? errorData[key]
                        : `${key.toString().toUpperCase().replace("_", " ")}: ${
                              errorData[key]
                          }`
                )
                .join(", ");
            let errorFieldValues = Object.values(errorData);

            if (errorFieldValues.length === 1) {
                errorPayload.html = errorFieldValues[0];
            } else {
                errorPayload.html = `<ul style="padding-left:12px">${Object.values(
                    errorData
                )
                    .map((val) => `<li>${val}</li>`)
                    .join("")}</ul>`;
            }
        }
        errorPayload.error_code =
            error.data.detail?.error_code || error.status || 400;
    }
    return errorPayload;
};

export const GenderOptions = () => {
    return _.map(Genders, (gender, index) => {
        return {
            key: index,
            label: gender,
            value: gender
        };
    });
};

export const GetAvatarInitials = (str = "", single = false) => {
    return str
        .split(" ")
        .filter((e, i) => i < (single ? 1 : 2))
        .map((e) => e.charAt(0).toUpperCase())
        .join("");
};

export const GetAvatarColor = (str = "") => {
    if (/^[A-E]/.test(str[0])) {
        return "primary";
    } else if (/^[F-J]/.test(str[0])) {
        return "gold";
    } else if (/^[K-O]/.test(str[0])) {
        return "gray";
    } else if (/^[P-T]/.test(str[0])) {
        return "brown";
    } else if (/^[U-Y]/.test(str[0])) {
        return "purple";
    }
    return "";
};

export const KeyGenerator = (params = {}) => {
    let obj = Object.keys(params).sort((a, b) => a.localeCompare(b));
    return obj
        .map((e) => {
            return `${e}=${params[e]}`;
        })
        .join("&");
};

export const TransformQuizData = (
    data = {},
    api = false,
    submittedPayload = {}
) => {
    if (api) {
        let listQuizQuestions = _.map(
            data?.listQuizQuestions || [],
            (question) => {
                let isCorrectOption = _.findIndex(
                    question.options,
                    (e) => e.correct === true
                );
                let template = {
                    questionTitle: question.question,
                    optionFirst: question.options[0].value,
                    optionSecond: question.options[1].value,
                    optionThird: question.options[2].value,
                    optionFourth: question.options[3].value,
                    indexCorrectOption: isCorrectOption
                };
                return template;
            }
        );

        return {
            title: data?.title,
            type: COURSE_CONTENT_TYPE[4],
            description: data?.description,
            listQuizQuestions
        };
    } else {
        let submitMap = new Map();
        _.map(submittedPayload?.listQuizQuestionAttempts || [], (item) => {
            submitMap.set(item.quizQuestionId, {
                correct: item.indexCorrectOption,
                selected: item.indexSelectedOption,
                isCorrect: item.isCorrectOptionSelected
            });
        });
        let listQuizQuestions = _.map(
            data?.listQuizQuestions || [],
            (question, index) => {
                return {
                    id: question.id,
                    question: question?.questionTitle,
                    options: [
                        {
                            value: question.optionFirst,
                            correct: question.indexCorrectOption === 0
                        },
                        {
                            value: question.optionSecond,
                            correct: question.indexCorrectOption === 1
                        },
                        {
                            value: question.optionThird,
                            correct: question.indexCorrectOption === 2
                        },
                        {
                            value: question.optionFourth,
                            correct: question.indexCorrectOption === 3
                        }
                    ],
                    config: submitMap.get(question.id) || {}
                };
            }
        );
        return {
            id: data?.id,
            title: data?.title,
            type: COURSE_CONTENT_TYPE[4],
            description: data?.description,
            listQuizQuestions
        };
    }
};

export const TransformQuizSubmissionData = (data) => {
    if (_.isEmpty(data)) return {};
    return {
        courseAssessmentId: data.id,
        listQuizQuestionAttempts: _.map(data?.questions || [], (items) => {
            let index = _.findIndex(
                items?.options || [],
                (el, index) => el.correct
            );
            return {
                quizQuestionId: items.id,
                indexSelectedOption: index
            };
        })
    };
};

export const isUserAdmin = (object) =>
    ["STUDENT", "TEACHER"].indexOf(object.type) <= -1;

export const formValidations = (key, value = "") => {
    switch (key) {
        case "phoneNumber":
        case "guardianPhoneNumber": {
            let isValid = RegExps.PHONE_NUMBER.exp.test(value);
            if (!isValid) {
                throw new Error(RegExps.PHONE_NUMBER.errorMessage);
            }
            return true;
        }
        case "password": {
            let isValid = RegExps.PASSWORD.exp.test(value);
            if (!isValid) {
                throw new Error(RegExps.PASSWORD.errorMessage);
            }
            return true;
        }
        case "pincode": {
            return RegExps.PINCOODE.exp.test(value);
        }
        default: {
            return true;
        }
    }
};

export const Capitalize = (str = "") => {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
};

export const checkNextPage = (data, pageNumber = 1) => {
    if (Array.isArray(data) && data.length) {
        let length = data.length;
        let totalIntendedCount = pageNumber * PAGE_LIMIT;
        return totalIntendedCount === length;
    }
    return false;
};

export const parseHTMLContent = (content = "", fallback = "") => {
    if (!content || content === "") {
        return fallback;
    }
    return content.replace(/\n/g, "<br/>");
};


export const formatStudentProfileData = (data) => {
    const formdata = new FormData();
    let payload = {
        "email": !!data.email ? data.email : null,
        "phoneNumber": !!data.phoneNumber ? `+91${data.phoneNumber}` : null,
        "dateOfBirth": !!data.dateOfBirth ? data.dateOfBirth : null,
        "gender": !!data.gender ? data.gender : null,
        "guardianName": !!data.guardianName ? data.guardianName : null,
        "guardianEmail": !!data.guardianEmail ? data.guardianEmail : null,
        "guardianPhoneNumber": !!data.guardianPhoneNumber ? `+91${data.guardianPhoneNumber}` : null,
        "address": !!data.address ? data.address : null
    }
    formdata.append("data", JSON.stringify(payload));
    return formdata;
}

export const formatTeacherProfileData = (data) => {
    const formdata = new FormData();
    let payload = {
        "email": !!data.email ? data.email : null,
        "phoneNumber": !!data.phoneNumber ? `+91${data.phoneNumber}` : null,
        "dateOfBirth": !!data.dateOfBirth ? data.dateOfBirth : null,
        "gender": !!data.gender ? data.gender : null,
        "highestQualification": !!data.highestQualification ? data.highestQualification : null,
        "experienceYears": !!data.experienceYears ? parseInt(data.experienceYears) : null,
        "institutionId": !!data.institutionId ? data.institutionId : null,
        "languageCode": !!data.languageCode ? data.languageCode : null
    }
    formdata.append("data", JSON.stringify(payload));
    return formdata;
}