import _ from "lodash";
import Calendar, { CalendarDayHeader } from "./utils";

const CalendarViewComponent = ({ yearAndMonth = [], data = {} }) => {
    return (
        <Calendar
            data={data || {}}
            yearAndMonth={yearAndMonth}
            renderDay={(calendarDayObject) => {
                if (!calendarDayObject.isCurrentMonth) {
                    return <></>;
                }
                return (
                    <div className="day-renderer">
                        <CalendarDayHeader
                            calendarDayObject={calendarDayObject}
                        />
                    </div>
                );
            }}
        />
    );
};

export default CalendarViewComponent;
