import { Navigate, Outlet } from "react-router-dom";
import AdminAnnouncementBaseComponent from "../../modules/admin/announcements";
import AdminCourseBaseComponent from "../../modules/admin/courses";
import CourseAdminContentComponent from "../../pages/admin/courses";
import AdminForumBaseComponent from "../../modules/admin/forum";
import AdminLiveClassBaseComponent from "../../modules/admin/live";
import AdminStudentBaseComponent from "../../modules/admin/students";
import AdminTeacherBaseComponent from "../../modules/admin/teachers";
import AdminDashboardPage from "../../pages/admin";
import CourseAdminOverviewComponent from "../../modules/admin/courses/course";
import AdminCourseContentComponent from "../../modules/admin/courses/content";
import AdminCourseAssessmentComponent from "../../modules/admin/courses/assessment";
import AdminCourseForumComponent from "../../modules/admin/courses/forum";
import AdminCourseStudentsComponent from "../../modules/admin/courses/students";
import AdminQuizComponent from "../../modules/admin/courses/quiz";
import AdminDocumentPage from "../../pages/common/document";
import SingleForumComponent from "../../modules/admin/forum/single";
import AdminInstitutionComponent from "../../modules/admin/institution";
import AdminMasterLayout from "../../layout/admin";
import AdminCourseBundleComponent from "../../modules/admin/courseBundle";

const AdminRoutes = [
    {
        path: "/",
        element: (
            <AdminMasterLayout>
                <AdminDashboardPage />
            </AdminMasterLayout>
        ),
        children: [
            {
                path: "courses-bundle",
                element: <AdminCourseBundleComponent />
            },
            {
                path: "courses",
                element: <Outlet />,
                children: [
                    {
                        path: "",
                        element: <AdminCourseBaseComponent />
                    },
                    {
                        path: ":slug",
                        element: <CourseAdminContentComponent />,
                        children: [
                            {
                                index: true,
                                element: <Navigate to={`overview`} replace />
                            },
                            {
                                path: "overview",
                                element: <CourseAdminOverviewComponent />
                            },
                            {
                                path: "content",
                                element: <AdminCourseContentComponent />
                            },
                            {
                                path: "assessment",
                                element: <AdminCourseAssessmentComponent />
                            },
                            {
                                path: "forum",
                                element: <AdminCourseForumComponent />
                            },
                            {
                                path: "students",
                                element: <AdminCourseStudentsComponent />
                            }
                        ]
                    },
                    {
                        path: ":slug/create-quiz",
                        element: <AdminQuizComponent />
                    },
                    {
                        path: ":slug/quiz/:id",
                        element: <AdminQuizComponent />
                    },
                    {
                        path: ":slug/:type/:id",
                        element: <AdminDocumentPage />
                    }
                ]
            },
            {
                path: "institution",
                element: <Outlet />,
                children: [
                    {
                        path: "",
                        element: <AdminInstitutionComponent />
                    }
                ]
            },
            {
                path: "students",
                element: <Outlet />,
                children: [
                    {
                        path: "",
                        element: <AdminStudentBaseComponent />
                    }
                ]
            },
            {
                path: "teachers",
                element: <Outlet />,
                children: [
                    {
                        path: "",
                        element: <AdminTeacherBaseComponent />
                    }
                ]
            },
            {
                path: "live-classes",
                element: <Outlet />,
                children: [
                    {
                        path: "",
                        element: <AdminLiveClassBaseComponent />
                    }
                ]
            },
            {
                path: "announcements",
                element: <Outlet />,
                children: [
                    {
                        path: "",
                        element: <AdminAnnouncementBaseComponent />
                    }
                ]
            },
            {
                path: "forum",
                element: <Outlet />,
                children: [
                    {
                        path: "",
                        element: <AdminForumBaseComponent />
                    },
                    {
                        path: ":id",
                        element: <SingleForumComponent />
                    }
                ]
            }
        ]
    },
    {
        path: "*",
        element: <Navigate to={`/courses`} replace />
    }
];

export default AdminRoutes;
