import { createBrowserRouter } from "react-router-dom";
import UIComponentDevelopmentPage from "../components/UI";
import { devEnvironment, isAdminSubDomain } from "../utils";
import AdminRoutes from "./admin";
import UserRoutes from "./user";

const currentRouterConfig = isAdminSubDomain ? AdminRoutes : UserRoutes;
const uiComponentPath = devEnvironment
    ? [
          {
              path: "/ui",
              element: <UIComponentDevelopmentPage />
          }
      ]
    : [];

const BrowserRouterConfig = createBrowserRouter([
    ...currentRouterConfig,
    ...uiComponentPath
]);

export default BrowserRouterConfig;
