import { Fragment } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const CookieModal = ({ close = () => {} }) => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <Modal.Header
                style={{ padding: "24px 16px" }}
                closeButton={true}
                onHide={close}
            >
                <Modal.Title className="fs-20 fw-bold">
                    {t("landing.footer.cookie")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <iframe
                    src={`https://s3.ap-south-1.amazonaws.com/development-apps-storage/604a9ec3-10c5-467e-8c1d-0d6b549cf7d6.pdf#toolbar=0&navpanes=0`}
                    frameBorder={0}
                    allowFullScreen={false}
                    width={"100%"}
                    height={"100vh"}
                    style={{ minHeight: "calc(80vh)" }}
                ></iframe>
            </Modal.Body>
        </Fragment>
    );
};

export default CookieModal;
