import _ from "lodash";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CourseContentItemTemplate from "../../../components/common/courses/ContentItem";
import DefaultCardLoader from "../../../components/common/Loader";
import EmptyBoxComponent from "../../../components/common/reusable/EmptyBox";
import ListUIComponent from "../../../components/UI/List";
import { useAuthContext } from "../../../context/auth-context";
import { FetchCourseCompletion } from "../../../services/common";
import { fetchCourseAssessment } from "../../../services/common/reducer/course/content";
import { UserType } from "../../../utils/constants";
import { KeyGenerator } from "../../../utils/fn";

const UserCourseAssessmentComponent = () => {
    const { userObject } = useAuthContext();
    const { slug } = useParams();
    const { assessment, isLoading, isError } = useSelector(
        (state) => state.courseDetail
    );
    const { items: completionItem } = useSelector(
        (state) => state.courseCompletion
    );
    const courseCompletion =
        completionItem && completionItem[slug] ? completionItem[slug] : {};

    const cacheKey = KeyGenerator({ id: slug });
    const courseContent =
        assessment && assessment[cacheKey] ? assessment[cacheKey] : undefined;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCourseAssessment({ id: slug }));
        dispatch(FetchCourseCompletion(slug));
    }, [dispatch]);

    return (
        <div className="row mt-3">
            <div className="col-12">
                {isLoading ||
                (!isLoading && _.isEmpty(courseContent?.data || [])) ? (
                    <EmptyBoxComponent
                        isLoading={isLoading}
                        data={courseContent?.data}
                    />
                ) : (
                    <ListUIComponent
                        listKey={"admin-course-assessment"}
                        separator={false}
                        render={(record, type, isCompleted = false, index) => (
                            <CourseContentItemTemplate
                                itemIndex={index}
                                record={record}
                                isUser={type}
                                isCompleted={isCompleted}
                            />
                        )}
                        data={courseContent?.data || []}
                        userCheckButton={userObject?.type === UserType.STUDENT}
                        isCompleted={courseCompletion?.contentId || []}
                    />
                )}
            </div>
            <DefaultCardLoader isFixed show={isLoading} />
        </div>
    );
};

export default UserCourseAssessmentComponent;
