import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DefaultCardLoader from "../../../components/common/Loader";
import ButtonUIComponent from "../../../components/UI/Button";
import FloatingInputUIComponent from "../../../components/UI/Input";
import { useAdminAuthContext } from "../../../context/admin-context";
import { ExtractErrorMessage, formValidations } from "../../../utils/fn";
// import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
import ReCaptcha from "../../../components/Recaptcha";

const AdminResetPasswordComponent = ({ title = "" }) => {
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [otpSent, setOTPSent] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const { getOTP, resetPassword, isLoading } = useAdminAuthContext();
    const [payload, setPayload] = useState({
        email: "",
        confirm_password: "",
        password: "",
        otp: ""
    });
    const [enableSubmit, setEnableSubmit] = useState(false);
    // const { executeRecaptcha } = useGoogleReCaptcha();
    // const [recaptchaToken, setRecaptchaToken] = useState("");
    // const [ctr, setCtr] = useState(0);

    useEffect(() => {
        // setRefreshReCaptcha((e) => !e);
        return () => {
            setOTPSent(false);
            setPayload({
                email: "",
                confirm_password: "",
                password: "",
                otp: ""
            });
            // setRecaptchaToken("");
            // setCtr(0);
        };
    }, []);

    // useEffect(() => {
    //     if (ctr >= 5) {
    //         window.location.reload();
    //         return;
    //     }
    //     if (!executeRecaptcha && ctr < 5) {
    //         setCtr((e) => e + 1);
    //         return;
    //     }

    //     const verifyRecaptcha = async () => {
    //         const recaptchaToken = await executeRecaptcha("login_page");
    //         setRecaptchaToken(recaptchaToken);
    //         setLoading(false);
    //     };

    //     verifyRecaptcha();
    // }, [executeRecaptcha, setRecaptchaToken]);

    const inputHandler = ({ name, value }) => {
        setPayload({ ...payload, [name]: value });
    };

    const validations = async (data) => {
        let message = "";
        if (
            !data.password ||
            !data.confirm_password ||
            data.password !== data.confirm_password
        ) {
            message = "Entered password do not match";
        }
        formValidations("password", data.password);
        if (message) {
            throw new Error(message);
        }
        return true;
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            // if (!executeRecaptcha) {
            //     throw new Error("Verification failed, refresh or try again!");
            // }
            // const recaptchaToken = await executeRecaptcha();

            let toastMessage;
            if (!otpSent) {
                // await getOTP({ email: payload.email, recaptchaToken });
                await getOTP({ email: payload.email });
                setOTPSent(true);
                toastMessage = "OTP Sent successfully";
            } else {
                await validations(payload);
                // await resetPassword({ ...payload, recaptchaToken });
                await resetPassword({ ...payload });
                toastMessage = "Password updated successfully";
                navigate("/");
            }
            toast.success(toastMessage);
            // navigate user once data is received
        } catch (error) {
            const { toast: toastMsg } = ExtractErrorMessage(
                error?.response || error
            );
            toast.error(toastMsg);
        } finally {
            setLoading(false);
            // setRefreshReCaptcha((e) => !e);
        }
    };

    return (
        <div className="card px-3 py-3 auth-card">
            <div className="card-body">
                <div className="d-flex flex-column">
                    <h4 className="fs-24 fw-semibold">
                        {t("auth.resetPassTitle")}
                    </h4>
                    <form onSubmit={submitHandler} className="mt-4">
                        <div className="row">
                            <div className="col-12">
                                <FloatingInputUIComponent
                                    name={"email"}
                                    type={"email"}
                                    label={t("auth.enterEmail")}
                                    value={payload.email}
                                    onChange={inputHandler}
                                    required={true}
                                    disabled={otpSent}
                                />
                            </div>
                        </div>
                        {otpSent && (
                            <>
                                <div className="row mt-4">
                                    <div className="col-12">
                                        <FloatingInputUIComponent
                                            type={"otp"}
                                            name={"otp"}
                                            label={t("auth.enterOtp")}
                                            value={payload.otp}
                                            onChange={inputHandler}
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-12">
                                        <FloatingInputUIComponent
                                            type={
                                                showPass ? "text" : "password"
                                            }
                                            name={"password"}
                                            label={t("auth.newPass")}
                                            icon={`/assets/icons/eye-close.svg`}
                                            value={payload.password}
                                            onChange={inputHandler}
                                            required={true}
                                            iconClick={() =>
                                                setShowPass((e) => !e)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-12">
                                        <FloatingInputUIComponent
                                            type={
                                                showPass ? "text" : "password"
                                            }
                                            name={"confirm_password"}
                                            label={t("auth.confirmPass")}
                                            icon={`/assets/icons/eye-close.svg`}
                                            value={payload.confirm_password}
                                            onChange={inputHandler}
                                            required={true}
                                            iconClick={() =>
                                                setShowPass((e) => !e)
                                            }
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        {/* <div className="row">
                            <div className="col-12">
                                <GoogleReCaptcha
                                    onVerify={setRecaptchaToken}
                                    refreshReCaptcha={refreshReCaptcha}
                                />
                            </div>
                        </div> */}
                        <div className="row mt-4">
                            <div className="col-12">
                                <div className="d-flex w-100 justify-content-center">
                                    <ReCaptcha callback={() => setEnableSubmit(true)}/>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`d-flex flex-column w-100 mt-4 ${!otpSent && "pt-5 mt-5"}`}
                        >
                            <ButtonUIComponent
                                isBlock={true}
                                isPrimary={true}
                                type={"submit"}
                                disabled={!enableSubmit}
                                buttonInner={t(
                                    otpSent ? "auth.updatePass" : "auth.getOtp"
                                )}
                                className={`w-100 fw-semibold`}
                            />
                            <ButtonUIComponent
                                isBlock={true}
                                isPrimary={false}
                                type={"button"}
                                buttonInner={t("auth.backLogin")}
                                className={`w-100 btn-text mt-2 fs-14 fw-semibold`}
                                onClick={() => navigate(`/`)}
                            />
                        </div>
                    </form>
                </div>
            </div>
            <DefaultCardLoader show={isLoading || loading} />
        </div>
    );
};

export default AdminResetPasswordComponent;
