import { Fragment } from "react";
import AnnouncementListItemTemplate from "../common/announcement/ListItem";
import ForumListItemTemplate from "../common/forum/ListItem";
import ButtonUIComponent from "./Button";
import DropdownUIComponent, { DropdownItemUIComponent } from "./Dropdown";
import FloatingInputUIComponent from "./Input";
import FloatingSelectUIComponent from "./Input/select";
import ListUIComponent from "./List";
import ListDraggableUIComponent from "./List/ListDraggable";
import TableUIComponent from "./Table";

const UIComponentDevelopmentPage = () => {
    return (
        <div className="container my-5">
            <h2>UI Components</h2>
            <hr />
            <div className="row">
                <div className="col-12">
                    <div className="d-flex flex-column">
                        <strong>Buttons</strong>
                        <div className="d-flex align-items-center mt-2 flex-wrap">
                            <ButtonUIComponent
                                buttonInner={"Button UI"}
                                isPrimary={true}
                                className={`me-3 btn-sm`}
                            />
                            <ButtonUIComponent
                                buttonInner={"Button UI"}
                                isPrimary={true}
                                className={`me-3`}
                            />
                            <ButtonUIComponent
                                buttonInner={"Button UI"}
                                isPrimary={true}
                                className={`me-3 btn-lg`}
                            />
                            <ButtonUIComponent
                                buttonInner={"Button UI"}
                                className={`me-3 btn-sm`}
                            />
                            <ButtonUIComponent
                                buttonInner={"Button UI"}
                                className={`me-3 text-dark`}
                            />
                            <ButtonUIComponent
                                buttonInner={"Button UI"}
                                className={`me-3 btn-lg btn-danger`}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12">
                    <div className="d-flex flex-column">
                        <strong>Dropdown</strong>
                        <div className="d-flex align-items-center mt-4">
                            <DropdownUIComponent
                                btnClassName={`def-color btn-sm`}
                                isPrimary={false}
                                onToggle={() => {}}
                                btnText={`Dropdown`}
                            >
                                <DropdownItemUIComponent>
                                    Hello 1
                                </DropdownItemUIComponent>
                                <DropdownItemUIComponent>
                                    Hello 2
                                </DropdownItemUIComponent>
                                <DropdownItemUIComponent>
                                    Hello 3
                                </DropdownItemUIComponent>
                            </DropdownUIComponent>
                            <DropdownUIComponent
                                btnVariant="primary"
                                btnClassName={`btn-sm`}
                                isPrimary={false}
                            >
                                <DropdownItemUIComponent>
                                    Hello 1
                                </DropdownItemUIComponent>
                                <DropdownItemUIComponent>
                                    Hello 2
                                </DropdownItemUIComponent>
                                <DropdownItemUIComponent>
                                    Hello 3
                                </DropdownItemUIComponent>
                            </DropdownUIComponent>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12">
                    <div className="d-flex flex-column">
                        <strong>Form Fields</strong>
                        <div className="d-flex align-items-center mt-4">
                            <div className="me-3">
                                <FloatingInputUIComponent
                                    label={"Enter Email"}
                                />
                            </div>
                            <div className="me-3">
                                <FloatingInputUIComponent
                                    icon={`./assets/icons/eye-close.svg`}
                                    type={"password"}
                                />
                            </div>
                            <div className="me-3 w-100">
                                <FloatingSelectUIComponent
                                    className="w-100"
                                    name={"select"}
                                    label={"Select option from list"}
                                    defaultOptionPlaceholder="Select Option"
                                    options={[
                                        {
                                            text: "Default",
                                            value: 1
                                        },
                                        {
                                            text: "Default 2",
                                            value: 2
                                        }
                                    ]}
                                    // onChange={(e) => console.log(e)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12">
                    <div className="d-flex flex-column">
                        <strong>Table</strong>
                        <div className="d-flex align-items-center mt-4">
                            <div className="w-100">
                                <TableUIComponent
                                    columns={[
                                        {
                                            title: "Course Name",
                                            render: (record) =>
                                                record.course_name,
                                            width: 280
                                        },
                                        {
                                            title: "Description",
                                            render: (record) => (
                                                <span className="ellipsis">
                                                    {record.description}
                                                </span>
                                            ),
                                            width: 280
                                        },
                                        {
                                            title: "Instructor(s)",
                                            render: (record) =>
                                                record.instructor,
                                            width: 240
                                        },
                                        {
                                            title: "Students",
                                            render: (record) => record.students
                                        },
                                        {
                                            title: "Last Updated On",
                                            render: (record) =>
                                                new Date().toLocaleDateString()
                                        },
                                        {
                                            title: "",
                                            render: (record) => (
                                                <DropdownUIComponent
                                                    className="border-0 rounded-2 d-inline-block lh-1"
                                                    btnClassName={`def-color btn-sm rounded-2 border-0 px-2 py-1 bg-transparent`}
                                                    isPrimary={false}
                                                    btnText={
                                                        <img
                                                            src={`/assets/icons/three-dot.svg`}
                                                            className={
                                                                "img-fluid"
                                                            }
                                                            alt={
                                                                "Three Dot Menu"
                                                            }
                                                        />
                                                    }
                                                    toggleIcon={false}
                                                >
                                                    <DropdownItemUIComponent>
                                                        <div className="py-1">
                                                            Hello 1
                                                        </div>
                                                    </DropdownItemUIComponent>
                                                    <DropdownItemUIComponent>
                                                        <div className="py-1">
                                                            Hello 1
                                                        </div>
                                                    </DropdownItemUIComponent>
                                                    <DropdownItemUIComponent>
                                                        <div className="py-1">
                                                            Hello 1
                                                        </div>
                                                    </DropdownItemUIComponent>
                                                </DropdownUIComponent>
                                            ),
                                            width: 80
                                        }
                                    ]}
                                    dataSource={[
                                        {
                                            course_name: "DSA",
                                            description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
                                            instructor: "Rahul Goyal",
                                            students: 20
                                        },
                                        {
                                            course_name: "DSA",
                                            description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
                                            instructor: "Rahul Goyal",
                                            students: 20
                                        },
                                        {
                                            course_name: "DSA",
                                            description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
                                            instructor: "Rahul Goyal",
                                            students: 20
                                        },
                                        {
                                            course_name: "DSA",
                                            description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
                                            instructor: "Rahul Goyal",
                                            students: 20
                                        },
                                        {
                                            course_name:
                                                "Data structures & algorithms",
                                            description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
                                            instructor: "Rahul Goyal",
                                            students: 20
                                        },
                                        {
                                            course_name: "DSA",
                                            description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
                                            instructor: "Rahul Goyal",
                                            students: 20
                                        },
                                        {
                                            course_name: "DSA",
                                            description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
                                            instructor: "Rahul Goyal",
                                            students: 20
                                        },
                                        {
                                            course_name: "DSA",
                                            description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
                                            instructor: "Rahul Goyal",
                                            students: 20
                                        },
                                        {
                                            course_name: "DSA",
                                            description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
                                            instructor: "Rahul Goyal",
                                            students: 20
                                        },
                                        {
                                            course_name: "DSA",
                                            description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
                                            instructor: "Rahul Goyal",
                                            students: 20
                                        }
                                    ]}
                                    // onRowClick={(record) => console.log(record)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12">
                    <div className="d-flex flex-column">
                        <strong>List</strong>
                        <div className="d-flex align-items-center mt-4">
                            <div className="w-100">
                                <ListDraggableUIComponent
                                    data={[
                                        {
                                            id: 1,
                                            description:
                                                "Lorem ispum set di amor."
                                        },
                                        {
                                            id: 2,
                                            description:
                                                "Lorem ispum set di amor."
                                        }
                                    ]}
                                    render={(record) => (
                                        <AnnouncementListItemTemplate
                                            data={record}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="d-flex align-items-center mt-4">
                            <div className="w-100">
                                <ListUIComponent
                                    data={[{}, {}]}
                                    render={(record) => (
                                        <AnnouncementListItemTemplate />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="d-flex align-items-center mt-4 col-8">
                            <div className="w-100">
                                <ListUIComponent
                                    data={[{}, {}]}
                                    separator={true}
                                    render={(record) => (
                                        <ForumListItemTemplate />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UIComponentDevelopmentPage;
