import _ from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import AdminDashboardTitle from "../../../components/admin/DashboardTitle";
import AdminDashboardTabs from "../../../components/admin/Tabs";
import AnnouncementListItemTemplate from "../../../components/common/announcement/ListItem";
import EmptyBoxComponent from "../../../components/common/reusable/EmptyBox";
import ListUIComponent from "../../../components/UI/List";
import { useModalContext } from "../../../context/modal-context";
import {
    fetchAnnouncementList,
    setAnnouncementFilters,
    setAnnouncementPage
} from "../../../services/admin/reducer/announcements";
import CreateAnnouncementModal from "../modal/CreateAnnouncement";
import { PAGE_LIMIT } from "../../../utils/constants";
import useGetCourseList from "../../../hooks/useGetCourseList";
import DefaultCardLoader from "../../../components/common/Loader";
import { useTranslation } from "react-i18next";
import { checkNextPage } from "../../../utils/fn";

const AdminAnnouncementBaseComponent = () => {
    const { t } = useTranslation();
    const { toggle } = useModalContext();
    const {
        items,
        isLoading,
        isError,
        currentPage,
        filters,
        createdAtFirstItem
    } = useSelector((state) => state.annoucementDetail);
    const hasNextPage = checkNextPage(items?.data || [], currentPage);
    const dispatch = useDispatch();
    const { list: CourseList } = useGetCourseList();

    useEffect(() => {
        dispatch(
            fetchAnnouncementList({
                pageNumber: currentPage || 1,
                createdAtFirstItem: createdAtFirstItem,
                ...filters
            })
        );
    }, [dispatch, currentPage, createdAtFirstItem, filters]);

    useEffect(() => {
        return () => {
            dispatch(setAnnouncementFilters({}));
        };
    }, []);

    const handlePagination = () => {
        dispatch(
            setAnnouncementPage({
                page: currentPage + 1,
                createdAtFirstItem: items?.data[0]?.createdAt
            })
        );
    };

    return (
        <Fragment>
            <AdminDashboardTitle
                title={t(`common.announcements`)}
                showResult={items?.count > 0}
                count={items?.count || 0}
                btnText={t("modal.announcement.createNew")}
                onClick={() => toggle(<CreateAnnouncementModal />)}
            />
            <AdminDashboardTabs />
            {isLoading && _.isEmpty(items?.data || []) ? (
                <div className="container mt-4 mb-5">
                    <div className="row">
                        <div className="col-12">
                            <EmptyBoxComponent isLoading={true} data={[]} />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="container mt-4 mb-5 scrollableDiv">
                    {!isLoading && _.isEmpty(items?.data || []) ? (
                        <div className="row">
                            <div className="col-12">
                                <EmptyBoxComponent
                                    isLoading={false}
                                    data={[]}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="row">
                            <div className="col-12">
                                <InfiniteScroll
                                    dataLength={items?.data?.length || 0}
                                    next={handlePagination}
                                    hasMore={hasNextPage}
                                    // hasMore={
                                    //     !_.isEmpty(items?.data || 0)
                                    //         ? items.data.length % PAGE_LIMIT ===
                                    //           0
                                    //         : false
                                    // }
                                    scrollableTarget="scrollableDiv"
                                >
                                    <ListUIComponent
                                        data={items?.data || []}
                                        separator={false}
                                        render={(record) => (
                                            <AnnouncementListItemTemplate
                                                data={record}
                                                courseData={CourseList}
                                            />
                                        )}
                                    />
                                </InfiniteScroll>
                            </div>
                        </div>
                    )}
                    {isLoading && <div className="text-center">Loading..</div>}
                </div>
            )}
            <DefaultCardLoader isFixed={true} show={isLoading} />
        </Fragment>
    );
};

export default AdminAnnouncementBaseComponent;
