import classNames from "classnames";
import _ from "lodash";
import DropdownUIComponent, { DropdownItemUIComponent } from ".";
import ButtonUIComponent from "../Button";
import useQueryParams from "../../../hooks/useSearchQuery";
import { Capitalize } from "../../../utils/fn";
import { useTranslation } from "react-i18next";

const SortDropdownComponent = ({ options = [], onChange = () => {} }) => {
    const { sortingCriteria, ...rest } = useQueryParams();
    const { t } = useTranslation();

    const handleSort = (opt) => {
        onChange(opt);
    };

    return (
        <DropdownUIComponent
            style={{
                minWidth: 164
            }}
            btnClassName={`btn-sm btn-dark py-2`}
            btnText={
                <div className="d-flex align-items-center">
                    <img
                        src={`/assets/icons/sort.svg`}
                        alt={`Back`}
                        className={`img-fluid`}
                    />
                    <span className="ms-2 text-dark fs-14 fw-semibold">
                        {Capitalize(sortingCriteria) ||
                            t("constants.ALPHABETICAL")}
                    </span>
                </div>
            }
            toggleIcon={false}
        >
            {_.map(options, (option, index) => {
                return (
                    <DropdownItemUIComponent key={index}>
                        <ButtonUIComponent
                            className={classNames(
                                `btn-sm btn-text no-hover py-2`,
                                {
                                    "pb-0": index === options.length - 1,
                                    "pt-1": index === 0
                                }
                            )}
                            buttonInner={
                                <span className="fs-12 fw-semibold text-dark">
                                    {t(`constants.${option}`)}
                                </span>
                            }
                            onClick={(e) => handleSort(option)}
                        />
                    </DropdownItemUIComponent>
                );
            })}
        </DropdownUIComponent>
    );
};

export default SortDropdownComponent;
