import { createSlice } from "@reduxjs/toolkit";
import { FetchCourseCompletion } from "../..";
import { URLS } from "../../../../utils";
import { getTokenFromStorage } from "../../../../utils/fn";
import Axios from "axios";
import { COURSE_CONTENT_GROUP } from "../../../../utils/constants";
import _ from "lodash";

export const PostCourseCompletion = async (id, type, options = {}) => {
    let config = {
        url: `${URLS.API_URL}v1/course-component-completions`,
        method: "POST"
    };

    let payload = {
        courseContentId: id
    };

    if (COURSE_CONTENT_GROUP.assessment.includes(type)) {
        payload = {
            courseAssessmentId: id,
            ...options
        };
    }

    const response = await Axios({
        ...config,
        data: payload,
        headers: {
            Authorization: getTokenFromStorage()
        }
    });
    return response.data;
};

export const courseCompletionSlice = createSlice({
    name: "courseCompletion",
    initialState: {
        items: {},
        isLoading: false,
        isError: false,
        status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null
    },
    reducers: {
        updateCompletionData: (state, action) => {
            try {
                let { payload } = action;
                if (!payload.key) return;
                let existingData = _.cloneDeep(state.items[payload.key]) || {
                    contentId: []
                };
                existingData.contentId = [
                    ...existingData.contentId,
                    payload.id
                ];
                if (
                    [...COURSE_CONTENT_GROUP.content].includes(payload.type) &&
                    existingData.hasOwnProperty("contentCompletionCount")
                ) {
                    existingData.contentCompletionCount =
                        (existingData.contentCompletionCount || 0) + 1;
                }
                if (
                    [...COURSE_CONTENT_GROUP.assessment].includes(
                        payload.type
                    ) &&
                    existingData.hasOwnProperty("assessmentCompletionCount")
                ) {
                    existingData.assessmentCompletionCount =
                        (existingData.assessmentCompletionCount || 0) + 1;
                }
                Object.assign(state.items, { [payload.key]: existingData });
            } catch (error) {
                // console.log(error);
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(FetchCourseCompletion.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
                state.isError = false;
            })
            .addCase(FetchCourseCompletion.fulfilled, (state, action) => {
                state.status = "succeeded";
                const id = action.meta.arg;
                Object.assign(state.items, { [id]: action.payload });
                state.isLoading = false;
                state.isError = false;
            })
            .addCase(FetchCourseCompletion.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.isLoading = false;
                state.isError = true;
            });
    }
});

export const { updateCompletionData } = courseCompletionSlice.actions;

export default courseCompletionSlice.reducer;
