import _ from "lodash";
import { dateTimeFormatter } from "../../../utils/fn";
import DropdownUIComponent, {
    DropdownItemUIComponent
} from "../../UI/Dropdown";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const AnnouncementListItemTemplate = ({ data = {}, courseData = [] }) => {
    const { t } = useTranslation();
    const courseInfo = _.find(courseData || [], (e) => e.id === data.courseId);

    return (
        <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="d-flex flex-column w-100">
                <div className="d-flex align-items-center">
                    <span className="badge rounded-pill text-bg-light">
                        {courseInfo ? (
                            <span className="fs-14 text-gray-600 fw-semibold">
                                {courseInfo?.title}{" "}
                                <span className="text-gray-300 fs-12">
                                    #{courseInfo?.uidCode}
                                </span>
                            </span>
                        ) : (
                            <span className="fs-14 text-gray-600 fw-semibold">
                                {t("tableHead.generalAnnouncement")}
                            </span>
                        )}
                    </span>
                </div>
                <div className="text-gray-600 mt-3 fw-semibold text-wrap text-break">
                    <span>{data.title}</span>
                </div>
                <div className="text-gray-300 fs-14 mt-2 text-break fw-thin">
                    {data.description}
                </div>
                <div className="text-gray-300 fs-12 mt-2 ms-auto">
                    {dayjs(data?.createdAt).format("DD MMM YYYY, hh:mm a")}
                </div>
            </div>
            <div className="d-none" style={{ minWidth: 20 }}>
                <DropdownUIComponent
                    className="border-0 rounded-2 d-inline-block lh-1"
                    btnClassName={`def-color btn-sm rounded-2 border-0 px-2 py-1 bg-transparent mw-22`}
                    isPrimary={false}
                    onToggle={() => {}}
                    btnText={
                        <img
                            src={`/assets/icons/three-dot.svg`}
                            className={"img-fluid"}
                            alt={"Three Dot Menu"}
                        />
                    }
                    toggleIcon={false}
                >
                    <DropdownItemUIComponent>
                        <div className="py-1">Hello 1</div>
                    </DropdownItemUIComponent>
                </DropdownUIComponent>
            </div>
        </div>
    );
};

export default AnnouncementListItemTemplate;
