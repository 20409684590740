import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useAuthContext } from "../../context/auth-context";
import {
    fetchCourseList,
    setCourseFilter
} from "../../services/admin/reducer/courses";
import { useTranslation } from "react-i18next";
import UserCourseSectionComponent from "../../components/user/course/Section";
import useGetCourseBundleList from "../../hooks/useGetCourseBundleList";
import DefaultCardLoader from "../../components/common/Loader";
import { CourseCategory } from "../../utils/constants";

const UserCoursePageComponent = () => {
    const { t } = useTranslation();
    const { userObject } = useAuthContext();
    const { items, isLoading, isError, filters } = useSelector(
        (state) => state.courses
    );
    const {
        list: courseBundleList,
        isLoading: isCourseBundleLoading,
        isError: isCourseBundleError
    } = useGetCourseBundleList();
    const dispatch = useDispatch();

    const [courseList, setCourseList] = useState({});

    useEffect(() => {
        dispatch(fetchCourseList({ ...filters }));
    }, [dispatch, filters]);

    useEffect(() => {
        let listObj = {};
        if (courseBundleList && !_.isEmpty(courseBundleList)) {
            listObj = dataReducer(courseBundleList, listObj);
        }
        if (items && items.data && !_.isEmpty(items.data)) {
            listObj = dataReducer(items.data, listObj);
        }
        setCourseList(listObj);

        return () => {
            setCourseList({});
        };
    }, [courseBundleList, items]);

    useEffect(() => {
        return () => {
            dispatch(setCourseFilter({}));
        };
    }, []);

    const dataReducer = (data, initialObj = {}) =>
        _.reduce(
            data,
            (p, c) => {
                if (c.category in p) {
                    p[c.category].push(c);
                } else {
                    p[c.category] = [c];
                }
                return p;
            },
            initialObj
        );

    return (
        <div className="container mt-4 pt-md-3 mb-5 pb-4 pb-md-5">
            <div className="d-flex justify-content-between flex-column">
                <div className="fs-3 fw-600">
                    {t("common.hello")} {userObject?.name},
                </div>
                <div className="mt-2 text-gray-300">
                    {t("common.welcomeMessage")}
                </div>
            </div>
            {CourseCategory.ACADEMIC.value in courseList && (
                <UserCourseSectionComponent
                    title={t(`constants.${CourseCategory.ACADEMIC.value}`)}
                    items={courseList[CourseCategory.ACADEMIC.value] || []}
                    loading={isLoading}
                />
            )}
            {CourseCategory.COMPETITIVE_EXAM.value in courseList && (
                <UserCourseSectionComponent
                    title={t(
                        `constants.${CourseCategory.COMPETITIVE_EXAM.value}`
                    )}
                    items={
                        courseList[CourseCategory.COMPETITIVE_EXAM.value] || []
                    }
                    loading={isLoading}
                />
            )}
            {CourseCategory.SKILL_DEVELOPMENT.value in courseList && (
                <UserCourseSectionComponent
                    title={t(
                        `constants.${CourseCategory.SKILL_DEVELOPMENT.value}`
                    )}
                    items={
                        courseList[CourseCategory.SKILL_DEVELOPMENT.value] || []
                    }
                    loading={isLoading}
                />
            )}
            {CourseCategory.E_LIBRARY.value in courseList && (
                <UserCourseSectionComponent
                    title={t(`constants.${CourseCategory.E_LIBRARY.value}`)}
                    items={courseList[CourseCategory.E_LIBRARY.value] || []}
                    loading={isLoading}
                />
            )}
            {isLoading && (
                <UserCourseSectionComponent
                    title={""}
                    items={[]}
                    loading={true}
                />
            )}
            {/* <UserCourseSectionComponent
                title={t("common.courseBundles")}
                items={courseBundleList || []}
                loading={isCourseBundleLoading}
                isBundle={true}
            /> */}
            <DefaultCardLoader
                isFixed={true}
                show={isLoading || isCourseBundleLoading}
            />
        </div>
    );
};

export default UserCoursePageComponent;
