import _ from "lodash";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BackButton from "../../components/common/BackButton";
import CourseDescriptionComponent from "../../components/common/courses/CourseDescription";
import CourseInstructorsComponent from "../../components/common/courses/CourseInstructors";
import DefaultCardLoader from "../../components/common/Loader";
import EmptyBoxComponent from "../../components/common/reusable/EmptyBox";
import ButtonUIComponent from "../../components/UI/Button";
import useQueryParams from "../../hooks/useSearchQuery";
import { useAuthContext } from "../../context/auth-context";
import {
    FetchCourseCompletion,
    FetchPreviousContent
} from "../../services/common";
import {
    PostCourseCompletion,
    updateCompletionData
} from "../../services/common/reducer/course/activity";
import { FetchSingleAssessment } from "../../services/common/reducer/course/content";
import { URLS } from "../../utils";
import {
    COURSE_CONTENT_GROUP,
    COURSE_CONTENT_TYPE,
    UserType
} from "../../utils/constants";
import { ExtractErrorMessage } from "../../utils/fn";
import { useTranslation } from "react-i18next";

const iframeConfiguration = (type, data, hash = "", showFullScreen = false) => {
    const fileExtension = (filename) => filename?.split(".").pop();

    const typeCase = type.toUpperCase();
    const media =
        typeCase === COURSE_CONTENT_TYPE[0]
            ? data?.documentUri
            : typeCase === COURSE_CONTENT_TYPE[1]
              ? data?.videoUri
              : typeCase === COURSE_CONTENT_TYPE[2]
                ? hash === "key"
                    ? data?.answerKeyDocumentUri
                    : data?.worksheetDocumentUri
                : "";

    let obj = {
        src: `${URLS.MEDIA_URL}${media}`,
        style: {
            minHeight: 450,
            borderRadius: 12,
            border: "1px solid #efefef",
            objectFit: "contain"
        }
    };
    let srcAppend = "";

    if (type === "document" || type === "worksheet") {
        if (fileExtension(media) === "pdf") {
            // srcAppend = "#toolbar=0&navpanes=0";
            // if (showFullScreen) {
            srcAppend = `#navpanes=0`;
            // }
            obj.style.minHeight = 620;
        }
    } else if (type === "video") {
        const qp = media?.split("?");
        const v = new URLSearchParams(qp?.length > 1 ? qp[1] : "");
        // srcAppend = `;controls=0&rel=0`;
        srcAppend = ``;
        obj = {
            ...obj,
            // src: `https://youtube.com/embed/${v.get("v", "")}`,
            src: `https://www.youtube.com/embed/${v.get("v", "")}?rel=0`,
            allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
            allowFullScreen: true
        };
    }

    return {
        src: `${obj.src}${srcAppend}`,
        style: obj.style,
        allowFullScreen: obj.allowFullScreen || false
    };
};

const AdminDocumentPage = ({ isAdmin = true }) => {
    const { userObject } = useAuthContext();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [showFullScreen, setShowFullScreen] = useState(false);
    const hashValue = useQueryParams({ hash: true });
    const { slug, type, id } = useParams();
    const [load, setLoad] = useState(false);
    const [loading, setLoading] = useState(true);
    const [notFound, setNotFound] = useState(false);
    const [data, setData] = useState({});

    const { items, isLoading: isCompletionLoading } = useSelector(
        (state) => state.courseCompletion
    );
    const courseCompletion =
        items && items.hasOwnProperty(slug) ? items[slug] : {};
    const isDocumentCompleted =
        _.findIndex(courseCompletion?.contentId || [], (e) => e == id) > -1;

    const dispatch = useDispatch();

    useEffect(() => {
        if (!["video", "document", "worksheet"].includes(type)) {
            navigate(`${URLS.DASHBOARD_URL}`);
            return;
        }

        if (id) {
            fetchAssessment();
            fetchCourseCompletion();
        }

        return () => {
            setNotFound(false);
            setLoading(false);
        };
    }, [type, id]);

    const fetchCourseCompletion = async () => {
        try {
            if (
                isAdmin ||
                (!isCompletionLoading &&
                    !_.isEmpty(items) &&
                    items.hasOwnProperty(slug))
            )
                return;
            dispatch(FetchCourseCompletion(slug));
        } catch (error) {}
    };

    const fetchAssessment = async () => {
        try {
            setLoading(true);
            const response = await FetchSingleAssessment(type, id);
            let transformedResponse = response?.data?.data;
            if (COURSE_CONTENT_GROUP.assessment.includes(type)) {
                setData(transformedResponse?.courseAssessment || {});
            } else {
                setData(transformedResponse);
            }
        } catch (error) {
            const { toast: toastMsg } = ExtractErrorMessage(error.response);
            if (error?.response?.status == 404) {
                setNotFound(true);
            }
            toast.error(toastMsg);
        } finally {
            setLoading(false);
        }
    };

    const markAsCompleted = async () => {
        try {
            if (isDocumentCompleted || isAdmin) {
                return;
            }
            setLoad(true);
            await PostCourseCompletion(id, type);
            dispatch(updateCompletionData({ key: slug, id, type }));
        } catch (error) {
        } finally {
            setLoad(false);
        }
    };

    const navigation = async (isNext = 0) => {
        if (isNext && false) {
            navigate(`${URLS.COURSE_BASE_URL}/${slug}/${type}/${data.nextId}`);
        } else {
            try {
                if (!isDocumentCompleted && !isAdmin) {
                    await markAsCompleted();
                };

                let courseOptions = {
                    value: isNext ? data.nextId : data.id,
                    previous: isNext !== 1
                };
                const response = await FetchPreviousContent(
                    data.type,
                    courseOptions.value,
                    courseOptions.previous
                );
                const transformedResponse =
                    COURSE_CONTENT_GROUP.assessment.includes(type)
                        ? response?.data?.data?.courseAssessment
                        : response?.data?.data;

                if (!transformedResponse?.id) {
                    throw new Error("Previous page doesn't exist");
                }
                const routeType = (transformedResponse?.type).toLowerCase();
                navigate(
                    `${URLS.COURSE_BASE_URL}/${slug}/${routeType}/${transformedResponse?.id}`,
                    {
                        replace: true
                    }
                );
            } catch (error) {
                const { toast: toastMsg } = ExtractErrorMessage(
                    error?.response || error
                );
                toast.error(toastMsg);
            }
        }
    };

    return (
        <div className="container mt-4 mb-5">
            <div className="row">
                <div className="d-flex">
                    <BackButton
                        redirectionKey="courses"
                        redirectionConfig={{
                            slug: slug,
                            path: COURSE_CONTENT_GROUP.content.includes(type)
                                ? "content"
                                : "assessment"
                        }}
                    />
                </div>
            </div>
            <div className="row mt-4">
                {notFound && !loading && (
                    <EmptyBoxComponent message={"Not found"} data={[]} />
                )}
                {loading ? (
                    <EmptyBoxComponent loading={loading} data={[1]} />
                ) : (
                    !loading &&
                    !notFound && (
                        <Fragment>
                            <div className="col-lg-8">
                                <div className="card content-card bg-white">
                                    <div className="card-heading border-0">
                                        <div className="d-flex justify-content-between">
                                            <div className="d-flex flex-column">
                                                <div
                                                    className="d-flex fs-14 text-gray-300 align-items-center mb-2"
                                                    style={{ gap: 8 }}
                                                >
                                                    <span>
                                                        {t("tableHead.course")}
                                                    </span>
                                                    <span>
                                                        <img
                                                            src={`/assets/icons/caret-right.svg`}
                                                            alt={"Right arrow"}
                                                            className={
                                                                "img-fluid"
                                                            }
                                                        />
                                                    </span>
                                                    <span>
                                                        {COURSE_CONTENT_GROUP.content.includes(
                                                            type
                                                        )
                                                            ? t(
                                                                  "common.courseContents"
                                                              )
                                                            : t(
                                                                  "common.courseAssessments"
                                                              )}
                                                    </span>
                                                </div>
                                                <div className="fs-5 fw-bold text-gray-600">
                                                    {data?.title || ""}
                                                </div>
                                            </div>
                                            {userObject.type === UserType.STUDENT && <div className="d-flex align-self-end">
                                                <ButtonUIComponent
                                                    className={`btn-text btn-sm p-0 cp`}
                                                    buttonInner={
                                                        <img
                                                            width={28}
                                                            src={`/assets/icons/checked-${isDocumentCompleted ? "mark" : `white`}-circle.svg`}
                                                            alt={`Not marked`}
                                                            className={
                                                                "img-fluid"
                                                            }
                                                        />
                                                    }
                                                    onClick={markAsCompleted}
                                                />
                                                {/* <img src={`/assets/icons/checked-circle.svg`} alt={`Not marked`} className={"img-fluid"}/> */}
                                            </div>}
                                        </div>
                                    </div>
                                    <div className="card-body position-relative pt-0">
                                        <iframe
                                            {...iframeConfiguration(
                                                type,
                                                data,
                                                hashValue,
                                                showFullScreen
                                            )}
                                            frameBorder={0}
                                            width={"100%"}
                                            height={"100%"}
                                            data-full={showFullScreen}
                                        />
                                        {[
                                            COURSE_CONTENT_TYPE[0],
                                            COURSE_CONTENT_TYPE[2]
                                        ].includes(type.toUpperCase()) && (
                                            <ButtonUIComponent
                                                className={
                                                    "btn btn-sm btn-transparent btn-text p-0 showFullScreen"
                                                }
                                                buttonInner={
                                                    showFullScreen ? (
                                                        <div className="d-flex align-items-center">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="32"
                                                                height="32"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth="1.5"
                                                                stroke="#ffffff"
                                                                fill="none"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            >
                                                                <path
                                                                    stroke="none"
                                                                    d="M0 0h24v24H0z"
                                                                    fill="none"
                                                                />
                                                                <path d="M15 19v-2a2 2 0 0 1 2 -2h2" />
                                                                <path d="M15 5v2a2 2 0 0 0 2 2h2" />
                                                                <path d="M5 15h2a2 2 0 0 1 2 2v2" />
                                                                <path d="M5 9h2a2 2 0 0 0 2 -2v-2" />
                                                            </svg>
                                                            <span className="ms-2 fs-14 text-semibold text-gray-100">
                                                                Minimize
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="32"
                                                            height="32"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="1.5"
                                                            stroke="#000000"
                                                            fill="none"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            <path
                                                                stroke="none"
                                                                d="M0 0h24v24H0z"
                                                                fill="none"
                                                            />
                                                            <path d="M4 8v-2a2 2 0 0 1 2 -2h2" />
                                                            <path d="M4 16v2a2 2 0 0 0 2 2h2" />
                                                            <path d="M16 4h2a2 2 0 0 1 2 2v2" />
                                                            <path d="M16 20h2a2 2 0 0 0 2 -2v-2" />
                                                        </svg>
                                                    )
                                                }
                                                title={
                                                    showFullScreen
                                                        ? "Minimize"
                                                        : "Show full screen"
                                                }
                                                onClick={() =>
                                                    setShowFullScreen((e) => !e)
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="mt-3 d-flex justify-content-between">
                                    {_.isEmpty(state) || state?.previous ? (
                                        <ButtonUIComponent
                                            className={`btn-sm bg-white text-dark`}
                                            buttonInner={
                                                <div className="d-flex align-items-center fw-bold text-gray-600">
                                                    <span>
                                                        <img
                                                            src={`/assets/icons/arrow-left.svg`}
                                                            alt={
                                                                "Page Nav left"
                                                            }
                                                            className={
                                                                "img-fluid"
                                                            }
                                                        />
                                                    </span>
                                                    <span className="ms-2">
                                                        {t("common.previous")}
                                                    </span>
                                                </div>
                                            }
                                            onClick={() => navigation()}
                                        />
                                    ) : (
                                        <div></div>
                                    )}
                                    {data?.nextId && (
                                        <ButtonUIComponent
                                            className={`btn-sm bg-white text-dark`}
                                            buttonInner={
                                                <div className="d-flex align-items-center fw-bold text-gray-600">
                                                    <span className="me-2">
                                                        {t("common.next")}
                                                    </span>
                                                    <span>
                                                        <img
                                                            style={{
                                                                transform:
                                                                    "rotate(180deg)"
                                                            }}
                                                            src={`/assets/icons/arrow-left.svg`}
                                                            alt={
                                                                "Page Nav left"
                                                            }
                                                            className={
                                                                "img-fluid"
                                                            }
                                                        />
                                                    </span>
                                                </div>
                                            }
                                            onClick={() => navigation(1)}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="col-lg-4 mt-4 mt-lg-0">
                                <div
                                    className="d-flex flex-column"
                                    style={{ gap: 24 }}
                                >
                                    <CourseInstructorsComponent
                                        isEditable={false}
                                        size={"sm"}
                                    />
                                    <CourseDescriptionComponent
                                        isEditable={false}
                                        size={"sm"}
                                        content={data?.description || ""}
                                    />
                                </div>
                            </div>
                        </Fragment>
                    )
                )}
            </div>
            <DefaultCardLoader isFixed show={loading || load} />
        </div>
    );
};

export default AdminDocumentPage;
