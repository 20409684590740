import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DropdownUIComponent, { DropdownItemUIComponent } from ".";
import { LANG_SESSION } from "../../../utils";

const LANGUAGE = {
    EN: {
        key: "en",
        name: "English",
        short: "en",
        icon: null
    },
    KN: {
        key: "kn",
        name: "ಕನ್ನಡ",
        short: "ಕ",
        icon: null
        // icon: "/assets/icons/kannada.svg"
    }
};

const LanguageDropdown = () => {
    const [lang, setLang] = useState(LANGUAGE.EN);
    const { i18n } = useTranslation();

    useEffect(() => {
        let key = LANG_SESSION;
        const data = localStorage.getItem(key);
        if (data && !_.isEmpty(LANGUAGE[data] || undefined)) {
            setLang(LANGUAGE[data]);
            i18n.changeLanguage(LANGUAGE[data].key);
        }
    }, []);

    const languageChangeHandler = (l) => {
        i18n.changeLanguage(LANGUAGE[l].key);
        setLang(LANGUAGE[l]);
        localStorage.setItem(LANG_SESSION, l);
    };

    return (
        <DropdownUIComponent
            btnText={lang.short.toUpperCase()}
            btnClassName={`btn-sm text-dark bg-gray-100 fs-14 fw-semibold`}
            extraStyles={{
                style: { zIndex: 1021 }
            }}
        >
            {_.map(LANGUAGE, (language, index) => {
                if (language.icon) {
                    return (
                        <DropdownItemUIComponent key={index}>
                            <button
                                onClick={() => languageChangeHandler(index)}
                                className="btn btn-text btn-transparent p-0 m-0 text-dark fw-semibold"
                            >
                                <div className="d-flex align-items-center">
                                    <span>
                                        <img
                                            src={language.icon}
                                            alt={"LANG"}
                                            className={"img-fluid"}
                                        />
                                    </span>
                                    <span className="ms-2 fs-12 fw-semibold">
                                        {language.name}
                                    </span>
                                </div>
                            </button>
                        </DropdownItemUIComponent>
                    );
                }
                return (
                    <DropdownItemUIComponent key={index}>
                        <button
                            onClick={() => languageChangeHandler(index)}
                            className="btn btn-text btn-transparent p-0 pb-1 pt-1 m-0 text-dark fw-semibold fs-12"
                        >
                            {language.short.toUpperCase()} {language.name}
                        </button>
                    </DropdownItemUIComponent>
                );
            })}
        </DropdownUIComponent>
    );
};

export default LanguageDropdown;
