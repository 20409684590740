import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { Provider } from "react-redux";
import { AdminAuthContextProvider } from "../../context/admin-context";
import { ModalContextProvider } from "../../context/modal-context";
import annoucementReducer from "../../services/admin/reducer/announcements";
import courseListReducer from "../../services/admin/reducer/courses";
import institutionReducer from "../../services/admin/reducer/institution";
import liveClassReducer from "../../services/admin/reducer/liveClass";
import studentListReducer from "../../services/admin/reducer/students";
import teacherListReducer from "../../services/admin/reducer/teachers";
import courseDetailReducer from "../../services/common/reducer/course";
import forumDataReducer from "../../services/common/reducer/forum";
import reportedActivityReducer from "../../services/admin/reducer/activity";
import courseCompletionReducer from "../../services/common/reducer/course/activity";
import coursesBundleReducer from "../../services/admin/reducer/courses/bundle";

const store = configureStore({
    reducer: {
        courses: courseListReducer,
        liveClass: liveClassReducer,
        teachers: teacherListReducer,
        institution: institutionReducer,
        students: studentListReducer,
        courseDetail: courseDetailReducer,
        annoucementDetail: annoucementReducer,
        reportedActivity: reportedActivityReducer,
        forumData: forumDataReducer,
        courseCompletion: courseCompletionReducer,
        coursesBundle: coursesBundleReducer
    },
    middleware: (gDm) => gDm()
});

setupListeners(store.dispatch);

const AdminMasterLayout = (props) => {
    return (
        <Provider store={store}>
            <ModalContextProvider>
                <AdminAuthContextProvider>
                    {props.children}
                </AdminAuthContextProvider>
            </ModalContextProvider>
        </Provider>
    );
};

export default AdminMasterLayout;
