import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CourseAssessmentComponent from "../../../components/common/courses/CourseAssessmentDetails";
import CourseDescriptionComponent from "../../../components/common/courses/CourseDescription";
import CourseInstructorsComponent from "../../../components/common/courses/CourseInstructors";
import CourseLayoutComponent from "../../../components/common/courses/CourseLayout";
import CourseLiveClassComponent from "../../../components/common/courses/CourseLiveClass";
import CourseStudyMaterialComponent from "../../../components/common/courses/CourseStudyMaterial";
import DefaultCardLoader from "../../../components/common/Loader";
import {
    CourseDetailOperation,
    updateCourseDetail
} from "../../../services/common/reducer/course";
import { fetchLiveClassesByCourseId } from "../../../services/common/reducer/course/content";
import { ExtractErrorMessage, KeyGenerator } from "../../../utils/fn";

const CourseAdminOverviewComponent = () => {
    const { slug } = useParams();
    const [loading, setLoading] = useState(false);
    const { items, isLoading, isError, liveClasses } = useSelector(
        (state) => state.courseDetail
    );
    const cacheKey = KeyGenerator({ id: slug });
    const courseDetails =
        items && items[cacheKey] && items[cacheKey]?.data
            ? items[cacheKey].data
            : {};

    const dispatch = useDispatch();

    const fetchLiveClasses = useCallback(() => {
        try {
            dispatch(fetchLiveClassesByCourseId({ id: slug }));
        } catch (error) {
            const { toast: toastMsg } = ExtractErrorMessage(error);
            toast.error(toastMsg);
        }
    }, [dispatch, slug]);

    const updateHandler = async (data, cb = () => {}) => {
        try {
            setLoading(true);
            const response = await CourseDetailOperation(
                { ...data, id: slug },
                1
            );
            dispatch(
                updateCourseDetail({ data: response.data, meta: { id: slug } })
            );
            cb(true);
        } catch (error) {
            cb(false);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="row mt-3">
            <div className="col-lg-7 col-12">
                <div className="d-flex flex-column" style={{ gap: 20 }}>
                    <CourseInstructorsComponent isEditable={false} />
                    <CourseDescriptionComponent
                        isEditable={true}
                        content={courseDetails?.description || ""}
                        onSave={updateHandler}
                    />
                    <CourseStudyMaterialComponent
                        isEditable={true}
                        content={courseDetails?.studyMaterial || ""}
                        onSave={updateHandler}
                    />
                    <CourseLayoutComponent
                        isEditable={true}
                        content={courseDetails?.courseLayoutImageUri || ""}
                        onSave={updateHandler}
                    />
                    <CourseAssessmentComponent
                        isEditable={true}
                        content={courseDetails?.gradingCriteria || ""}
                        onSave={updateHandler}
                    />
                </div>
            </div>
            <div className="col-lg-5 col-12 mt-3 mt-lg-0">
                <div className="d-flex flex-column" style={{ gap: 20 }}>
                    <CourseLiveClassComponent
                        data={liveClasses}
                        isLoading={isLoading}
                        getLiveClasses={fetchLiveClasses}
                    />
                </div>
            </div>
            <DefaultCardLoader show={isLoading} isFixed />
        </div>
    );
};

export default CourseAdminOverviewComponent;
