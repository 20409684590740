import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import Axios from "axios";
import { getTokenFromStorage, KeyGenerator } from "../../../../utils/fn";
import { URLS } from "../../../../utils";
import { COURSE_CONTENT_TYPE } from "../../../../utils/constants";

export const fetchCourseContent = createAsyncThunk(
    "courseContent/fetchData",
    async ({ id, ...params }, { getState }) => {
        const { content } = getState().courseDetail;
        const keyParams = { id, ...params };
        const key = KeyGenerator(keyParams);

        // v1/course-contents?courseId
        if (!_.isEmpty(content) && content[key]) {
            return content[key];
        }
        try {
            // await new Promise((resolve) => setTimeout(resolve, 500));
            const response = await Axios.get(
                `${URLS.API_URL}v1/course-contents?courseId=${id}`,
                {
                    headers: {
                        Authorization: getTokenFromStorage()
                    }
                }
            );
            return response.data;
        } catch (error) {
            throw new Error(error.response.data.error);
        }
    }
);

export const fetchCourseAssessment = createAsyncThunk(
    "courseAssessment/fetchData",
    async ({ id, ...params }, { getState }) => {
        const { assessment } = getState().courseDetail;
        const keyParams = { id, ...params };
        const key = KeyGenerator(keyParams);

        // v1/course-contents?courseId
        if (!_.isEmpty(assessment) && assessment[key]) {
            return assessment[key];
        }
        try {
            // await new Promise((resolve) => setTimeout(resolve, 500));
            const response = await Axios.get(
                `${URLS.API_URL}v1/course-assessments?courseId=${id}`,
                {
                    headers: {
                        Authorization: getTokenFromStorage()
                    }
                }
            );
            return response.data;
        } catch (error) {
            throw new Error(error.response.data.error);
        }
    }
);

const formatCourseContentData = (data, action = 0) => {
    let fd = new FormData();
    let checkValues = ["title", "description"];

    if (action === 0) {
        checkValues.push("nextId", "courseId", "type");
    } else if (action === 2) {
        checkValues = ["nextId"];
    }

    if (data?.videoUri || _.isObject(data.documentUri)) {
        checkValues.push("type");
    }

    if (data.documentUri && _.isObject(data.documentUri)) {
        fd.append(
            `courseContentDocument`,
            _.isObject(data.documentUri)
                ? data.documentUri[0]
                : data.documentUri
        );
    }

    let payload = _.omitBy(_.pick(data, checkValues), _.isNil);
    payload = { ...payload };
    if (data?.videoUri && !_.isEmpty(data?.videoUri)) {
        payload["videoUri"] = data.videoUri;
    }

    fd.append(`data`, JSON.stringify(payload));

    // reorder
    if (action === 2) {
        return _.isEmpty(payload) ? { nextId: null } : payload;
    }
    return fd;
};

// action => 0: POST, 1: PUT
export const CourseContentHandler = async (payload, action = 0) => {
    let config = {
        url: `${URLS.API_URL}v1/course-contents`,
        method: "POST"
    };
    if (action === 1) {
        config.url = `${config.url}/${payload.id}`;
        config.method = "PATCH";
    } else if (action === 2) {
        config.url = `${config.url}/reorder/${payload.id}`;
        config.method = "PATCH";
    }

    const response = await Axios({
        ...config,
        data: formatCourseContentData(payload, action),
        headers: {
            Authorization: getTokenFromStorage()
        }
    });
    return response.data;
};

const formatCourseAssessmentData = (data, action = 0) => {
    let fd = new FormData();
    let checkValues = ["title", "description"];

    if (action === 0) {
        checkValues.push("nextId", "courseId", "type", "listQuizQuestions");
    } else if (action === 2) {
        checkValues = ["nextId"];
    }

    if (
        _.isObject(data?.worksheetDocumentUri) ||
        _.isObject(data?.answerKeyDocumentUri)
    ) {
        checkValues.push("type");
    }

    if (data.worksheetDocumentUri && _.isObject(data.worksheetDocumentUri)) {
        fd.append(
            `worksheet`,
            _.isObject(data.worksheetDocumentUri)
                ? data.worksheetDocumentUri[0]
                : data.worksheetDocumentUri
        );
    }

    if (data.answerKeyDocumentUri && _.isObject(data.answerKeyDocumentUri)) {
        fd.append(
            `answerKey`,
            _.isObject(data.answerKeyDocumentUri)
                ? data.answerKeyDocumentUri[0]
                : data.answerKeyDocumentUri
        );
    }

    let payload = _.omitBy(_.pick(data, checkValues), _.isNil);
    payload = { ...payload };
    fd.append(`data`, JSON.stringify(payload));

    // reorder
    if (action === 2) {
        return _.isEmpty(payload) ? { nextId: null } : payload;
    }
    return fd;
};

// action => 0: POST, 1: PUT
export const CourseAssessmentHandler = async (payload, action = 0) => {
    let config = {
        url: `${URLS.API_URL}v1/course-assessments`,
        method: "POST"
    };
    if (action === 1) {
        config.url = `${config.url}/${payload.id}`;
        config.method = "PATCH";
    } else if (action === 2) {
        config.url = `${config.url}/reorder/${payload.id}`;
        config.method = "PATCH";
    }

    const response = await Axios({
        ...config,
        data: formatCourseAssessmentData(payload, action),
        headers: {
            Authorization: getTokenFromStorage()
        }
    });
    return response.data;
};

export const FetchSingleAssessment = async (type, id, pagination = false) => {
    let pg_str = pagination ? `?isGetPrevious=true` : ``;
    let api = `course-contents`;

    if (
        [COURSE_CONTENT_TYPE[2], COURSE_CONTENT_TYPE[4]].includes(
            type.toUpperCase()
        )
    ) {
        api = `course-assessments`;
    }

    return await Axios.get(`${URLS.API_URL}v1/${api}/${id}${pg_str}`, {
        headers: {
            Authorization: getTokenFromStorage()
        }
    });
};

export const fetchLiveClassesByCourseId = createAsyncThunk(
    "courseLiveClass/fetchData",
    async ({ id, ...params }, { getState }) => {
        try {
            const data = getState().courseDetail?.liveClasses;
            const keyParams = { id, ...params };
            const key = KeyGenerator(keyParams);

            // v1/course-contents?courseId
            if (!_.isEmpty(data) && data[key]) {
                return data[key];
            }

            const response = await Axios.get(
                `${URLS.API_URL}v1/events?listCourseIds=${id}`,
                {
                    headers: {
                        Authorization: getTokenFromStorage()
                    }
                }
            );
            return response.data;
        } catch (error) {
            throw new Error(error.response.data.error);
        }
    }
);

export const DeleteCourseContentHandler = async (recordId) => {
    let config = {
        url: `${URLS.API_URL}v1/course-contents/${recordId}`,
        method: "DELETE"
    };
    const response = await Axios({
        ...config,
        headers: {
            Authorization: getTokenFromStorage()
        }
    });
    return response.data;
};

export const DeleteCourseAssessmentHandler = async (recordId) => {
    let config = {
        url: `${URLS.API_URL}v1/course-assessments/${recordId}`,
        method: "DELETE"
    };
    const response = await Axios({
        ...config,
        headers: {
            Authorization: getTokenFromStorage()
        }
    });
    return response.data;
};

export const CourseDisableHandler = async (recordId, isDisabled = true) => {
    let formData = new FormData();
    formData.append("data", JSON.stringify({ isDisabled }));

    const response = await Axios({
        url: `${URLS.API_URL}v1/courses/${recordId}`,
        data: formData,
        method: "PATCH",
        headers: {
            Authorization: getTokenFromStorage()
        }
    });
    return response.data;
};