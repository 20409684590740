import _ from "lodash";
import {
    createDaysForCurrentMonth,
    createDaysForNextMonth,
    createDaysForPreviousMonth,
    getMonthDropdownOptions
} from "./utils/helpers";

const CalendarHeaderComponent = ({
    onYearAndMonthChange = () => {},
    yearAndMonth
}) => {
    const [year, month] = yearAndMonth;

    const monthOptions = getMonthDropdownOptions();
    let currentMonthDays = createDaysForCurrentMonth(year, month);
    let previousMonthDays = createDaysForPreviousMonth(
        year,
        month,
        currentMonthDays
    );
    let nextMonthDays = createDaysForNextMonth(year, month, currentMonthDays);
    let calendarGridDayObjects = [
        ...previousMonthDays,
        ...currentMonthDays,
        ...nextMonthDays
    ];

    const handleMonthNavBackButtonClick = () => {
        let nextYear = year;
        let nextMonth = month - 1;
        if (nextMonth === 0) {
            nextMonth = 12;
            nextYear = year - 1;
        }
        onYearAndMonthChange([nextYear, nextMonth]);
    };

    const handleMonthNavForwardButtonClick = () => {
        let nextYear = year;
        let nextMonth = month + 1;
        if (nextMonth === 13) {
            nextMonth = 1;
            nextYear = year + 1;
        }
        onYearAndMonthChange([nextYear, nextMonth]);
    };

    return (
        <div className="navigation-header w-100 d-flex flex-column">
            <div className="w-100 top d-flex align-items-center justify-content-between px-3 py-1">
                <button
                    className={
                        "btn btn-sm btn-transaprent bg-white border-0 px-0 text-dark d-flex align-items-center"
                    }
                    type={"button"}
                    onClick={handleMonthNavBackButtonClick}
                >
                    <span className="d-flex me-2">
                        <img
                            style={{ transform: "rotate(180deg)" }}
                            src={`/assets/icons/light-caret.svg`}
                            alt={`Left Arrow`}
                            className={"img-fluid"}
                        />
                    </span>
                    <span className="text-gray-300">
                        {monthOptions[(month + 10) % 12].label}
                    </span>
                </button>
                <button
                    className={
                        "btn btn-sm btn-transaprent bg-white border-0 px-0 d-flex align-items-center"
                    }
                    type={"button"}
                    onClick={handleMonthNavForwardButtonClick}
                >
                    <span className="text-gray-300">
                        {monthOptions[month % 12].label}
                    </span>
                    <span className="d-flex ms-2">
                        <img
                            src={`/assets/icons/light-caret.svg`}
                            alt={`Right Arrow`}
                            className={"img-fluid"}
                        />
                    </span>
                </button>
            </div>
            <div className="text-center mt-3">
                <div
                    style={{
                        marginInline: 12,
                        fontWeight: "bold",
                        width: 100,
                        display: "inline-block",
                        textAlign: "center",
                        whiteSpace: "nowrap"
                    }}
                    className={`text-gray-600`}
                >
                    {_.find(monthOptions, (e) => e.value === month).label}
                    &nbsp;
                    {year}
                </div>
            </div>
        </div>
    );
};

export default CalendarHeaderComponent;
