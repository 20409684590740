
import _ from "lodash";
import { Fragment, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import DefaultCardLoader from "../../../components/common/Loader";
import ButtonUIComponent from "../../../components/UI/Button";
import { useModalContext } from "../../../context/modal-context";
import { ExtractErrorMessage, formValidations } from "../../../utils/fn";
import {
    Genders,
    LanguageCode
} from "../../../utils/constants";
import useGetInstitutionList from "../../../hooks/useGetInstitutionList";
import { JoinRequestAPI } from "../../../services/user";
import ReCaptcha from "../../Recaptcha";

/**
 *
 * @param {String} action => create | edit
 * @returns
 */
const TeacherJoinModal = () => {
    const { toggle } = useModalContext();
    const { t } = useTranslation();
    const fields = [
        "teacherJoinTitle",
        "teacherCode",
        "enterTeacherCode",
        "name",
        "email",
        "phone",
        "taughtClassesAndSubjects",
        "enterTaughtClassesAndSubjects",
        "dob",
        "lang",
        "courses",
        "gender",
        "institutionDetails",
        "enterInstitutionDetails",
        "institution",
        "enterName",
        "enterEmail",
        "enterPhone",
        "selectGender",
        "selectInstitution",
        "selectLang",
        "teacherJoinButton",
        "instituteLocation",
        "instituteName",
        "enterInstituteLocation",
        "enterInstituteName",
        "qualifications",
        "enterQualification",
        "yoe",
        "enterYOE"
    ];
    const titles = _.map(fields, (field) => {
        return { value: t(`modal.teacherRequest.${field}`), key: field };
    }).reduce((p, c, i) => {
        p[c.key] = c.value;
        return p;
    }, {});

    const [enableSubmit, setEnableSubmit] = useState(false);
    const [payload, setPayload] = useState({
        teacherCode: null,
        name: null,
        email: null,
        taughtClassesAndSubjects: null,
        phoneNumber: null,
        dateOfBirth: null,
        gender: null,
        languageCode: null,
        institutionId: null,
        institutionDetails: null,
        instituteName: null,
        instituteLocation: null,
        highestQualification: null,
        experienceYears: null
    });
    const [loading, setLoading] = useState(false);
    const { list: institutionList } = useGetInstitutionList();

    const handleOnChange = (inputs) => {
        setPayload({
            ...payload,
            [inputs.name]: inputs.value
        });
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            let { instituteName, instituteLocation, ...data } = payload;
            if (payload.institutionId === "other") {
                data["institutionDetails"] = {
                    name: instituteName,
                    location: instituteLocation
                };
                data.institutionId = null;
            }
            if (payload.phoneNumber) {
                formValidations("phoneNumber", payload.phoneNumber);
                data["phoneNumber"] = `+91${data.phoneNumber}`;
            };

            // API hit
            await JoinRequestAPI("teacher", data);
            toast.success(`Request raised successfully!`);
            toggle();
        } catch (error) {
            const { toast: toastMsg } = ExtractErrorMessage(
                error?.response || error
            );
            toast.error(toastMsg);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Form onSubmit={submitHandler}>
            <Modal.Header style={{ padding: "24px 16px" }}>
                <Modal.Title className="fs-20">
                    {t(titles.teacherJoinTitle)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                <div className="d-flex flex-column">
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                        <div className="col-md-3">
                            <label
                                htmlFor="teacherCode"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.teacherCode}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <input
                                autoComplete={"off"}
                                required
                                className="form-control fs-14"
                                placeholder={t(titles.enterTeacherCode)}
                                value={payload.teacherCode || ""}
                                onChange={(e) =>
                                    handleOnChange({
                                        name: e.target.name,
                                        value: e.target.value
                                    })
                                }
                                name={"teacherCode"}
                            />
                        </div>
                    </div>
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                        <div className="col-md-3">
                            <label
                                htmlFor="name"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.name}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <input
                                autoComplete={"off"}
                                required
                                className="form-control fs-14"
                                placeholder={titles.enterName}
                                value={payload.name || ""}
                                onChange={(e) =>
                                    handleOnChange({
                                        name: e.target.name,
                                        value: e.target.value
                                    })
                                }
                                name={"name"}
                            />
                        </div>
                    </div>
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                        <div className="col-md-3">
                            <label
                                htmlFor="email"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.email}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <input
                                autoComplete={"off"}
                                required
                                className="form-control fs-14"
                                placeholder={titles.enterEmail}
                                value={payload.email || ""}
                                onChange={(e) =>
                                    handleOnChange({
                                        name: e.target.name,
                                        value: e.target.value
                                    })
                                }
                                type={"email"}
                                name={"email"}
                                disabled={false}
                            />
                        </div>
                    </div>
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                        <div className="col-md-3">
                            <label
                                htmlFor="phone"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.phone}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <div className="input-group">
                                <span className="input-group-text fs-14 text-gray-600 fw-semibold phoneExtension">
                                    +91
                                </span>
                                <input
                                    autoComplete={"off"}
                                    maxLength={10}
                                    required={false}
                                    className="form-control fs-14"
                                    placeholder={titles.enterPhone}
                                    value={payload.phoneNumber || ""}
                                    onChange={(e) =>
                                        handleOnChange({
                                            name: e.target.name,
                                            value: e.target.value
                                        })
                                    }
                                    type={"text"}
                                    name={"phoneNumber"}
                                    disabled={false}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-row split d-flex flex-column flex-md-row align-items-md-center border-bottom">
                        <div
                            className="d-flex flex-column flex-md-row align-items-md-center border-end"
                            style={{ flexBasis: "50%" }}
                        >
                            <div className="col-md-4">
                                <label
                                    htmlFor="dob"
                                    className="fs-14 text-gray-300 fw-semibold"
                                >
                                    {titles.dob}
                                </label>
                            </div>
                            <div className="col-md-8 col-lg-6 offset-lg-2 mt-2 mt-md-0 ps-lg-2">
                                <input
                                    autoComplete={"off"}
                                    type={"date"}
                                    className="form-control fs-14"
                                    placeholder={`Select Date`}
                                    name={"dateOfBirth"}
                                    required={false}
                                    value={payload.dateOfBirth || ""}
                                    onChange={(e) =>
                                        handleOnChange({
                                            name: e.target.name,
                                            value: e.target.value
                                        })
                                    }
                                    disabled={false}
                                />
                            </div>
                        </div>
                        <div
                            className="d-flex flex-column flex-md-row align-items-md-center ms-md-auto"
                            style={{ flexBasis: "50%" }}
                        >
                            <div className="col-md-4">
                                <label
                                    htmlFor="gender"
                                    className="fs-14 text-gray-300 fw-semibold"
                                >
                                    {titles.gender}
                                </label>
                            </div>
                            <div className="col-md-8 mt-2 mt-md-0">
                                <select
                                    required={false}
                                    className="form-select fs-14"
                                    placeholder={titles.selectGender}
                                    name={"gender"}
                                    value={payload.gender || ""}
                                    onChange={(e) =>
                                        handleOnChange({
                                            name: e.target.name,
                                            value: e.target.value
                                        })
                                    }
                                    disabled={false}
                                >
                                    <option value="">
                                        {titles.selectGender}
                                    </option>
                                    {Genders &&
                                        _.map(Genders, (item, key) => {
                                            return (
                                                <option
                                                    value={key}
                                                    key={key}
                                                >
                                                    {t(`constants.${item}`)}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                        <div className="col-md-3">
                            <label
                                htmlFor="taughtClassesAndSubjects"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.taughtClassesAndSubjects}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <input
                                autoComplete={"off"}
                                className="form-control fs-14"
                                placeholder={titles.enterTaughtClassesAndSubjects}
                                name={"taughtClassesAndSubjects"}
                                required={true}
                                value={payload.taughtClassesAndSubjects || ""}
                                onChange={(e) =>
                                    handleOnChange({
                                        name: e.target.name,
                                        value: e.target.value
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                        <div className="col-md-3">
                            <label
                                htmlFor="qualifications"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.qualifications}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <input
                                autoComplete={"off"}
                                className="form-control fs-14"
                                placeholder={titles.enterQualification}
                                name={"highestQualification"}
                                required={false}
                                value={payload.highestQualification || ""}
                                onChange={(e) =>
                                    handleOnChange({
                                        name: e.target.name,
                                        value: e.target.value
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                        <div className="col-md-3">
                            <label
                                htmlFor="yoe"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.yoe}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <input
                                autoComplete={"off"}
                                type={"number"}
                                className="form-control fs-14"
                                placeholder={titles.enterYOE}
                                name={"experienceYears"}
                                required={false}
                                value={payload.experienceYears || ""}
                                onChange={(e) =>
                                    handleOnChange({
                                        name: e.target.name,
                                        value: Number(e.target.value)
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center">
                        <div className="col-md-3">
                            <label
                                htmlFor="institution"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.institution}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <select
                                required
                                className="form-select fs-14"
                                placeholder={titles.selectInstitution}
                                name={"institutionId"}
                                value={payload.institutionId || ""}
                                onChange={(e) =>
                                    handleOnChange({
                                        name: e.target.name,
                                        value: e.target.value
                                    })
                                }
                                disabled={false}
                            >
                                <option value="">
                                    {titles.selectInstitution}
                                </option>
                                <option value={"other"}>Other</option>
                                {institutionList &&
                                    _.map(institutionList, (item, key) => {
                                        return (
                                            <option
                                                value={item.id}
                                                key={key}
                                            >
                                                {item.name} [{item.type}]
                                            </option>
                                        );
                                    })}
                            </select>
                        </div>
                    </div>
                    {
                        !!payload.institutionId && payload.institutionId === "other" &&
                        <Fragment>
                            <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                                <div className="col-md-3">
                                    <label
                                        htmlFor="instituteName"
                                        className="fs-14 text-gray-300 fw-semibold"
                                    >
                                        {titles.instituteName}
                                    </label>
                                </div>
                                <div className="col-md-9 mt-2 mt-md-0">
                                    <input
                                        autoComplete={"off"}
                                        required
                                        className="form-control fs-14"
                                        placeholder={titles.enterInstituteName}
                                        value={payload.instituteName || ""}
                                        onChange={(e) =>
                                            handleOnChange({
                                                name: e.target.name,
                                                value: e.target.value
                                            })
                                        }
                                        name={"instituteName"}
                                    />
                                </div>
                            </div>
                            <div className="form-row d-flex flex-column flex-md-row align-items-md-center border-bottom">
                                <div className="col-md-3">
                                    <label
                                        htmlFor="instituteLocation"
                                        className="fs-14 text-gray-300 fw-semibold"
                                    >
                                        {titles.instituteLocation}
                                    </label>
                                </div>
                                <div className="col-md-9 mt-2 mt-md-0">
                                    <input
                                        autoComplete={"off"}
                                        required
                                        className="form-control fs-14"
                                        placeholder={titles.enterInstituteLocation}
                                        value={payload.instituteLocation || ""}
                                        onChange={(e) =>
                                            handleOnChange({
                                                name: e.target.name,
                                                value: e.target.value
                                            })
                                        }
                                        name={"instituteLocation"}
                                    />
                                </div>
                            </div>
                        </Fragment>
                    }
                    <div className="form-row d-flex flex-column flex-md-row align-items-md-center">
                        <div className="col-md-3">
                            <label
                                htmlFor="lang"
                                className="fs-14 text-gray-300 fw-semibold"
                            >
                                {titles.lang}
                            </label>
                        </div>
                        <div className="col-md-9 mt-2 mt-md-0">
                            <select
                                required
                                className="form-select fs-14"
                                placeholder={titles.selectLang}
                                name={"languageCode"}
                                value={payload.languageCode || ""}
                                onChange={(e) =>
                                    handleOnChange({
                                        name: e.target.name,
                                        value: e.target.value
                                    })
                                }
                                disabled={false}
                            >
                                <option value="">
                                    {titles.selectLang}
                                </option>
                                {LanguageCode &&
                                    _.map(LanguageCode, (item, key) => {
                                        return (
                                            <option
                                                value={item.value}
                                                key={key}
                                            >
                                                {t(
                                                    `constants.${item.value}`
                                                )}
                                            </option>
                                        );
                                    })}
                            </select>
                        </div>
                    </div>
                    <div className="row mt-4 mb-4">
                        <div className="col-12">
                            <div className="d-flex w-100 justify-content-center">
                                <ReCaptcha callback={() => setEnableSubmit(true)}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex">
                    <ButtonUIComponent
                        className={`fs-14 me-3 fw-semibold`}
                        buttonInner={t("common.cancel")}
                        type={"button"}
                        onClick={() => toggle()}
                    />
                    <ButtonUIComponent
                        isPrimary={true}
                        className={`fs-14 fw-semibold`}
                        buttonInner={t(titles.teacherJoinButton)}
                        type={"submit"}
                        disabled={!enableSubmit}
                    />
                </div>
            </Modal.Footer>
            <DefaultCardLoader show={loading} />
        </Form>
    );
};

export default TeacherJoinModal;