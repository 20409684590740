import { useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
    PostForumTopic,
    updateForumTopic
} from "../../../services/common/reducer/forum";
import { ExtractErrorMessage } from "../../../utils/fn";
import ButtonUIComponent from "../../UI/Button";
import { useAuthContext } from "../../../context/auth-context";
import UserAvatar from "../avatar";
import DefaultCardLoader from "../../common/Loader";
import { FILE_SIZE } from "../../../utils/constants";
import { useTranslation } from "react-i18next";

const ForumPostBarComponent = () => {
    const { t } = useTranslation();
    const { userObject } = useAuthContext();
    const { slug } = useParams();
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState(undefined);
    const [file, setFile] = useState(undefined);
    const dispatch = useDispatch();

    const submitHandler = async (e) => {
        e.preventDefault();
        if (!title) {
            toast.error(`Enter the question to proceed`);
        }
        try {
            setLoading(true);
            const response = await PostForumTopic({
                courseId: slug,
                title,
                attachment: file
            });
            dispatch(
                updateForumTopic({
                    meta: { id: slug },
                    data: response?.data
                })
            );
            setTitle(undefined);
            setFile(undefined);
            document.getElementById("file").value = null;
        } catch (error) {
            const { toast: toastMsg } = ExtractErrorMessage(error.response);
            toast.error(toastMsg);
        } finally {
            setLoading(false);
        }
    };

    const handleUpload = (e) => {
        if (!file) {
            document.getElementById("file").click();
        } else {
            document.getElementById("file").value = null;
            setFile(undefined);
        }
    };

    const handleFileChange = (e) => {
        let file = e.target.files[0];
        if (file.size / 1000 > FILE_SIZE.IMAGE.size) {
            document.getElementById("file").value = null;
            setFile(undefined);
            toast.error(`${FILE_SIZE.IMAGE.message}`);
            return;
        }
        setFile(file);
    };

    return (
        <div className="d-flex forum-top-bar">
            <Form className="w-100" onSubmit={submitHandler}>
                <div
                    className="d-flex align-items-start w-100"
                    style={{ columnGap: 12 }}
                >
                    <div className="d-flex">
                        <UserAvatar
                            profileImage={userObject?.profileImageUri}
                            name={userObject?.name}
                            width={32}
                            size={"md"}
                        />
                    </div>
                    <input
                        autoComplete={"off"}
                        type={"file"}
                        hidden
                        name={"file"}
                        id={"file"}
                        accept={`image/*`}
                        onChange={handleFileChange}
                    />
                    <div className="d-flex flex-grow-1 titlebar">
                        <textarea
                            autoComplete={"off"}
                            rows={5}
                            disabled={loading}
                            required
                            onChange={(e) => setTitle(e.target.value)}
                            value={title || ""}
                            className="form-control normal no-resize"
                            name={"title"}
                            placeholder={`${t("common.askQuestion")}..`}
                        />
                        <button
                            onClick={handleUpload}
                            type={"button"}
                            className="upload btn btn-transparent btn-text"
                        >
                            {file ? (
                                <small>{file.name} &nbsp;&times;</small>
                            ) : (
                                <img
                                    src={`/assets/icons/upload.svg`}
                                    className={`img-fluid`}
                                    alt={`Upload`}
                                />
                            )}
                        </button>
                    </div>
                    <div className="d-flex">
                        <ButtonUIComponent
                            type={"submit"}
                            isPrimary={true}
                            className={"btn-sm"}
                            buttonInner={t("common.postQuestion")}
                        />
                    </div>
                </div>
            </Form>
            <DefaultCardLoader isFixed={true} show={loading} />
        </div>
    );
};

export default ForumPostBarComponent;
