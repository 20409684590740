import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";

const LandingGalleryComponent = () => {
    const { t } = useTranslation();
    const isBreakPoint = window.innerWidth < 768;
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        padding: 20
    };

    return (
        <div className="container" id="gallery">
            <div className="row">
                <div className="col-12 text-center">
                    <div className="text-primary fs-1 fw-bold head">
                        {t("landing.gallery.title")}
                    </div>
                </div>
                <div className="col-lg-12 mt-4 pt-md-4 px-lg-5">
                    <Slider {...settings}>
                        <div>
                            <div className="row">
                                <div className="col-md-8 h-inherit">
                                    <div className="d-flex h-100">
                                        <img
                                            src={`/assets/images/gallery/g4.webp`}
                                            className={`img-fluid h-100`}
                                            alt={"Image 1"}
                                        />
                                    </div>
                                </div>
                                <div className="col-4 d-none d-md-block h-100">
                                    <div className="d-flex flex-column">
                                        <div className="d-flex">
                                            <img
                                                src={`/assets/images/gallery/g5.webp`}
                                                className={`img-fluid`}
                                                alt={"Image 2"}
                                            />
                                        </div>
                                        <div className="d-flex mt-3">
                                            <img
                                                src={`/assets/images/gallery/g6.webp`}
                                                className={`img-fluid`}
                                                alt={"Image 3"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isBreakPoint && (
                            <div>
                                <div className="row">
                                    <div className="col-md-8 h-inherit">
                                        <div className="d-flex h-100">
                                            <img
                                                src={`/assets/images/gallery/g5.webp`}
                                                className={`img-fluid`}
                                                alt={"Image 4"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {isBreakPoint && (
                            <div>
                                <div className="row">
                                    <div className="col-md-8 h-inherit">
                                        <div className="d-flex h-100">
                                            <img
                                                src={`/assets/images/gallery/g6.webp`}
                                                className={`img-fluid`}
                                                alt={"Image 5"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div>
                            <div className="row">
                                <div className="col-md-8 h-inherit">
                                    <div className="d-flex h-100">
                                        <img
                                            src={`/assets/images/gallery/g3.webp`}
                                            className={`img-fluid`}
                                            alt={"Image 6"}
                                        />
                                    </div>
                                </div>
                                <div className="col-4 d-none d-md-block h-100">
                                    <div className="d-flex flex-column">
                                        <div className="d-flex">
                                            <img
                                                src={`/assets/images/gallery/g1.webp`}
                                                className={`img-fluid`}
                                                style={{
                                                    aspectRatio: "392/261"
                                                }}
                                                alt={"Image 7"}
                                            />
                                        </div>
                                        <div className="d-flex mt-3">
                                            <img
                                                src={`/assets/images/gallery/g2.webp`}
                                                className={`img-fluid`}
                                                style={{
                                                    aspectRatio: "392/261"
                                                }}
                                                alt={"Image 8"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isBreakPoint && (
                            <div>
                                <div className="row">
                                    <div className="col-md-8 h-inherit">
                                        <div className="d-flex h-100">
                                            <img
                                                src={`/assets/images/gallery/g1.webp`}
                                                className={`img-fluid`}
                                                alt={"Image 9"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {isBreakPoint && (
                            <div>
                                <div className="row">
                                    <div className="col-md-8 h-inherit">
                                        <div className="d-flex h-100">
                                            <img
                                                src={`/assets/images/gallery/g2.webp`}
                                                className={`img-fluid`}
                                                alt={"Image 10"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div>
                            <div className="row">
                                <div className="col-md-8 h-inherit">
                                    <div className="d-flex h-100">
                                        <img
                                            src={`/assets/images/gallery/g8.webp`}
                                            className={`img-fluid h-100`}
                                            alt={"Image 11"}
                                        />
                                    </div>
                                </div>
                                <div className="col-4 d-none d-md-block h-100">
                                    <div className="d-flex flex-column">
                                        <div className="d-flex">
                                            <img
                                                src={`/assets/images/gallery/g7.webp`}
                                                className={`img-fluid`}
                                                alt={"Image 12"}
                                            />
                                        </div>
                                        <div className="d-flex mt-3">
                                            <img
                                                src={`/assets/images/gallery/g9.webp`}
                                                className={`img-fluid`}
                                                alt={"Image 13"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isBreakPoint && (
                            <div>
                                <div className="row">
                                    <div className="col-md-8 h-inherit">
                                        <div className="d-flex h-100">
                                            <img
                                                src={`/assets/images/gallery/g7.webp`}
                                                className={`img-fluid`}
                                                alt={"Image 14"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {isBreakPoint && (
                            <div>
                                <div className="row">
                                    <div className="col-md-8 h-inherit">
                                        <div className="d-flex h-100">
                                            <img
                                                src={`/assets/images/gallery/g8.webp`}
                                                className={`img-fluid`}
                                                alt={"Image 15"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default LandingGalleryComponent;
