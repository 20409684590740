import _ from "lodash";
import { useEffect } from "react";
import { Fragment, useState } from "react";
import ButtonUIComponent from "../../UI/Button";
import { useTranslation } from "react-i18next";

const QuizQuestionTemplate = ({
    totalQues = 1,
    isAdmin = false,
    data = {},
    index = 0,
    isEditable = false,
    isSubmitted = false,
    error = false,
    onChange = () => {},
    onRemove = () => {}
}) => {
    const { t } = useTranslation();
    const [template, setTemplate] = useState({});

    useEffect(() => {
        if (!_.isEmpty(data)) {
            setTemplate(_.cloneDeep(data));
        }
    }, [data]);

    const handleOptions = (indice) => {
        if ((!isEditable && isAdmin) || isSubmitted) return;
        const currentOptions = [...template.options];
        let newOptions = _.map(currentOptions, (opt, index) => {
            let isCorrect = index === indice;
            return {
                ...opt,
                correct: isCorrect
            };
        });
        setTemplate({ ...template, options: newOptions });
        onChange({ ...template, options: newOptions });
    };

    const handleInput = (e, index) => {
        if (!isEditable || isSubmitted) return;
        let t = { ...template };
        if (index === undefined) {
            t.question = e.target.value;
        } else {
            t.options[index] = {
                value: e.target.value,
                correct: t.options[index].correct
            };
        }

        setTemplate({ ...t });
        onChange(t);
    };

    return (
        <div className="position-relative">
            <ul className="list-unstyled nm-list mb-0" data-error={error}>
                <li className="text-gray-600 fw-bold d-flex align-items-center">
                    <span>Q{index + 1}.</span>
                    <span className="ms-3 w-100">
                        {isEditable ? (
                            <input
                                autoComplete={"off"}
                                className={"quiz-input"}
                                placeholder={`Enter Question`}
                                value={template.question || ""}
                                onChange={(e) => handleInput(e)}
                            />
                        ) : (
                            template.question
                        )}
                    </span>
                </li>
                {_.map(template.options, (option, index) => {
                    return (
                        <li key={index} className="quiz-option">
                            <span
                                onClick={() => handleOptions(index)}
                                className="submission"
                                data-checked={
                                    option.correct ||
                                    index === template?.config?.correct
                                }
                                data-incorrect={
                                    template.hasOwnProperty("config") &&
                                    index === template.config?.selected &&
                                    template.config?.selected !==
                                        template.config?.correct
                                }
                            ></span>
                            <span className="ms-3 w-100">
                                {isEditable ? (
                                    <input
                                        autoComplete={"off"}
                                        value={option.value || ""}
                                        placeholder={`Enter Option ${index + 1}`}
                                        onChange={(e) => handleInput(e, index)}
                                    />
                                ) : (
                                    option.value
                                )}
                            </span>
                        </li>
                    );
                })}
            </ul>
            {isEditable && totalQues > 1 && (
                <Fragment>
                    <div className="sort-btn">
                        <img src={`/assets/icons/drag.svg`} />
                    </div>
                    <div className="remove-btn">
                        <ButtonUIComponent
                            onClick={onRemove}
                            className={`btn-sm btn-danger bg-transparent border-0 fs-14`}
                            buttonInner={
                                <div className="d-flex align-items-center">
                                    <img src={`/assets/icons/red-minus.svg`} />
                                    <span className="ms-2">
                                        {t("common.removeQuestion")}
                                    </span>
                                </div>
                            }
                        />
                    </div>
                </Fragment>
            )}
        </div>
    );
};

export default QuizQuestionTemplate;
