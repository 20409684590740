import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchCourseBundleList } from "../services/admin/reducer/courses/bundle";

const useGetCourseBundleList = (props) => {
    const { items, isLoading, isError } = useSelector(
        (state) => state.coursesBundle
    );
    const dispatch = useDispatch();

    useEffect(() => {
        if (!props?.skip) {
            dispatch(fetchCourseBundleList(props?.params || {}));
        };
    }, [dispatch]);

    return {
        list: items?.data || [],
        count: items?.count || 0,
        isLoading,
        isError
    };
};

export default useGetCourseBundleList;
