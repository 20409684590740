import { Fragment } from "react";
import UserCourseCardComponent from "./Card";
import _ from "lodash";

const UserCourseSectionComponent = ({
    title = "",
    loading = true,
    items = [],
    fromBundle = false
}) => {
    return (
        <Fragment>
            <div className="row mt-2">
                {title && (
                    <div className="mt-3 fw-600 fs-20 text-gray-600 text-capitalize">
                        {title}
                    </div>
                )}
            </div>
            <div className="row mt-1 mb-4">
                {loading &&
                    _.times(3).map((index) => {
                        return (
                            <UserCourseCardComponent
                                key={index}
                                loading={true}
                            />
                        );
                    })}
                {_.map(items || [], (item, index) => {
                    return (
                        <UserCourseCardComponent
                            data={item}
                            key={index}
                            isBundle={!item?.sourceType}
                            fromBundle={fromBundle}
                        />
                    );
                })}
            </div>
        </Fragment>
    );
};

export default UserCourseSectionComponent;
