// src/courseListSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { URLS } from "../../../../utils";
import Axios from "axios";
import { getTokenFromStorage, KeyGenerator } from "../../../../utils/fn";
import {
    fetchCourseAssessment,
    fetchCourseContent,
    fetchLiveClassesByCourseId
} from "./content";

const fetchCourseData = async (id) => {
    try {
        // await new Promise((resolve) => setTimeout(resolve, 500));
        const response = await Axios.get(`${URLS.API_URL}v1/courses/${id}`, {
            headers: {
                Authorization: getTokenFromStorage()
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.error);
    }
};

// Define async thunk
export const fetchCourseDetails = createAsyncThunk(
    "courseDetail/fetchData",
    async ({ id, ...params }, { getState }) => {
        const { items } = getState().courseDetail;
        const keyParams = { id, ...params };
        const key = KeyGenerator(keyParams);

        if (!_.isEmpty(items) && items[key]) {
            return items[key];
        }
        return fetchCourseData(id, params);
    }
);

const formatCourseData = (data) => {
    let fd = new FormData();
    if (data.thumbnailImageUri) {
        fd.append(
            `thumbnail`,
            _.isObject(data.thumbnailImageUri)
                ? data.thumbnailImageUri[0]
                : data.thumbnailImageUri
        );
    }
    if (data.courseLayoutImageUri) {
        fd.append(
            `courseLayout`,
            _.isObject(data.courseLayoutImageUri)
                ? data.courseLayoutImageUri[0]
                : data.courseLayoutImageUri
        );
    }

    let payload = _.omitBy(
        _.pick(data, [
            "uidCode",
            "title",
            "description",
            "studyMaterial",
            "gradingCriteria",
            "isRemoveCourseLayoutImage"
        ]),
        _.isNil
    );
    payload = { ...payload };
    fd.append(`data`, JSON.stringify(payload));
    return fd;
};

// action => 0: POST, 1: PUT
export const CourseDetailOperation = async (payload, action = 0) => {
    // console.log(payload);
    let config = {
        url: `${URLS.API_URL}v1/courses`,
        method: "POST"
    };
    if (action !== 0) {
        config.url = `${config.url}/${payload.id}`;
        config.method = "PATCH";
    }

    const response = await Axios({
        ...config,
        data: formatCourseData(payload),
        headers: {
            Authorization: getTokenFromStorage()
        }
    });
    return response.data;
};

// Create slice
export const courseDetailSlice = createSlice({
    name: "courseDetail",
    initialState: {
        items: {},
        content: {},
        assessment: {},
        liveClasses: {},
        isLoading: false,
        isError: false,
        status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null
    },
    reducers: {
        updateCourseDetail: (state, action) => {
            try {
                let { payload } = action;
                let key = KeyGenerator(payload.meta);
                Object.assign(state.items, { [key]: { data: payload.data } });
            } catch (er) {
                // console.log(er);
            }
        },
        updateCourseContent: (state, action) => {
            try {
                let { payload } = action;
                if (payload.action === 0) {
                    let key = KeyGenerator(payload.meta);
                    let existingData = state.content[key] || {
                        count: 0,
                        data: []
                    };
                    existingData.data.push(payload.data);
                    existingData.count = (existingData.count || 0) + 1;
                    Object.assign(state.content, { [key]: existingData });
                } else if (payload.action === 1) {
                    let key = KeyGenerator(payload.meta);
                    let existingData = state.content[key] || {
                        count: 0,
                        data: []
                    };
                    let index = _.findIndex(
                        existingData?.data || [],
                        (e) => e.id === payload.data.id
                    );
                    existingData.data[index] = payload.data;
                    existingData.count = (existingData.count || 0) + 1;
                    Object.assign(state.content, { [key]: existingData });
                } else if (payload.action === 2) {
                }
            } catch (error) {}
        },
        reorderCourseContent: (state, action) => {
            try {
                let { payload } = action;
                let key = KeyGenerator(payload.meta);
                let existingData = state.content[key] || {
                    count: 0,
                    data: []
                };
                if (
                    !key ||
                    !existingData ||
                    _.isEmpty(existingData?.data || [])
                )
                    return;

                let { startIndex, endIndex } = payload.data;
                const result = Array.from(existingData?.data);
                const [removed] = result.splice(startIndex, 1);
                result.splice(endIndex, 0, removed);

                existingData.data = result;
                Object.assign(state.content, { [key]: existingData });
            } catch (error) {}
        },
        updateCourseAssessment: (state, action) => {
            try {
                let { payload } = action;
                let key = KeyGenerator(payload.meta);
                let existingData = state.assessment[key] || {
                    count: 0,
                    data: []
                };

                if (
                    !key ||
                    !existingData ||
                    _.isEmpty(existingData?.data || [])
                )
                    return;

                if (payload.action === 0) {
                    existingData.data.push(payload.data);
                    existingData.count = (existingData.count || 0) + 1;
                    Object.assign(state.assessment, { [key]: existingData });
                } else if (payload.action === 1) {
                    let index = _.findIndex(
                        existingData?.data || [],
                        (e) => e.id === payload.data.id
                    );
                    existingData.data[index] = payload.data;
                    existingData.count = (existingData.count || 0) + 1;
                    Object.assign(state.assessment, { [key]: existingData });
                } else if (payload.action === 2) {
                    // reorder
                }
            } catch (error) {}
        },
        reorderCourseAssessment: (state, action) => {
            try {
                let { payload } = action;
                let key = KeyGenerator(payload.meta);
                let existingData = state.assessment[key] || {
                    count: 0,
                    data: []
                };
                if (
                    !key ||
                    !existingData ||
                    _.isEmpty(existingData?.data || [])
                )
                    return;

                let { startIndex, endIndex } = payload.data;
                const result = Array.from(existingData?.data);
                const [removed] = result.splice(startIndex, 1);
                result.splice(endIndex, 0, removed);

                existingData.data = result;
                Object.assign(state.assessment, { [key]: existingData });
            } catch (error) {}
        },
        deleteCourseContent: (state, action) => {
            let { payload } = action;
            let key = KeyGenerator(payload.meta);
            let existingData = state.content[key];
            existingData.data = _.filter(
                existingData?.data || [],
                (e) => e.id !== payload.id
            );
            existingData.count = (existingData.count || 0) + 1;
            Object.assign(state.content, { [key]: existingData });
        },
        deleteCourseAssessment: (state, action) => {
            let { payload } = action;
            let key = KeyGenerator(payload.meta);
            let existingData = state.assessment[key];
            existingData.data = _.filter(
                existingData?.data || [],
                (e) => e.id !== payload.id
            );
            existingData.count = (existingData.count || 0) + 1;
            Object.assign(state.assessment, { [key]: existingData });
        },
        resetCourseDetails: (state, action) => {
            state.items = {};
        },
        resetLiveClasses: (state, action) => {
            state.liveClasses = {};
        },
        setCourseContent: (state, action) => {
            let { payload } = action;
            const key = KeyGenerator({ id: payload.id });
            Object.assign(state.content, { [key]: payload.data });
        },
        setCourseAssessment: (state, action) => {
            let { payload } = action;
            const key = KeyGenerator({ id: payload.id });
            Object.assign(state.assessment, { [key]: payload.data });
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCourseDetails.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
                state.isError = false;
            })
            .addCase(fetchCourseDetails.fulfilled, (state, action) => {
                state.status = "succeeded";
                const key = KeyGenerator(action.meta.arg);
                Object.assign(state.items, { [key]: action.payload });
                state.isLoading = false;
                state.isError = false;
            })
            .addCase(fetchCourseDetails.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(fetchCourseContent.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
                state.isError = false;
            })
            .addCase(fetchCourseContent.fulfilled, (state, action) => {
                state.status = "succeeded";
                const key = KeyGenerator(action.meta.arg);
                Object.assign(state.content, { [key]: action.payload });
                state.isLoading = false;
                state.isError = false;
            })
            .addCase(fetchCourseContent.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(fetchCourseAssessment.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
                state.isError = false;
            })
            .addCase(fetchCourseAssessment.fulfilled, (state, action) => {
                state.status = "succeeded";
                const key = KeyGenerator(action.meta.arg);
                Object.assign(state.assessment, { [key]: action.payload });
                state.isLoading = false;
                state.isError = false;
            })
            .addCase(fetchCourseAssessment.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(fetchLiveClassesByCourseId.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
                state.isError = false;
            })
            .addCase(fetchLiveClassesByCourseId.fulfilled, (state, action) => {
                state.status = "succeeded";
                const key = KeyGenerator(action.meta.arg);
                Object.assign(state.liveClasses, { [key]: action.payload });
                state.isLoading = false;
                state.isError = false;
            })
            .addCase(fetchLiveClassesByCourseId.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.isLoading = false;
                state.isError = true;
            });
    }
});

export const {
    updateCourseDetail,
    updateCourseAssessment,
    updateCourseContent,
    reorderCourseAssessment,
    reorderCourseContent,
    resetCourseDetails,
    resetLiveClasses,
    setCourseAssessment,
    setCourseContent,
    deleteCourseAssessment,
    deleteCourseContent
} = courseDetailSlice.actions;

export default courseDetailSlice.reducer;
